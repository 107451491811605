import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import icon_CheckCircle from "img/icon_CheckCircle.png"
import icon_nonCheckCircle from "img/icon_nonCheckCircle.png"
import icon_arrow from "img/icon_arrow.png"
import icon_X from "img/icon-X.png"

import Button from "./button"
import { useInput } from 'hooks/commonHooks';
import { image_mapping } from 'components/utils/mapping';


const BelongWrap = styled.div`

width: 100%;
margin: 3rem;
margin-top: 0px;
flex: 1;

`

const Belong = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2rem;

display: flex;
align-items: center;

color: #2A3A4B;

margin-bottom: 19px;
`
const CategoryWrap = styled.div`
display: flex;
width: 100%;
height: 121px;
margin-bottom: 34px;

flex-wrap: wrap;
overflow-y: auto;
overflow-x: none;


::-webkit-scrollbar {
  width: 5px;
border-radius: 4px;

}
 
::-webkit-scrollbar-track {
  background: #D3D3D3;
border-radius: 4px;

}
 
::-webkit-scrollbar-thumb {
    background: ${props => props.isBlue == true ? '#4F80E2' : '#17B9B6'};

  border-radius: 4px;

}

`



const CheckImg = styled.img`
width: 27px;
height: 27px;

margin-right: 12px;

`
const Titles = styled.div`
width: 100%;
display: flex;

padding: 16px;
height: 62px;

background: #F1F1F1;
border-radius: 5px;

margin-bottom:24px;

`

const Title = styled.span`
flex:0 0 auto;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2rem;
padding: 0.3rem 0rem;
margin-right: 10px;


border-bottom: 3px solid #F1F1F1;
color: #A4A4A4;
transition: all 250ms ease-in-out;


&:after {
  display:block;
  content: '';
  
  border-bottom: solid 3px ${props => props.isBlue == true ? '#4F80E2' : '#17B9B6'};
  transform: scaleX(0);  
  transition: all 250ms ease-in-out;
  padding-bottom: 0.3rem;
  transform-origin:  0% 50%;
}
&:hover:after {
    transform: scaleX(1); 

}

&.active::after{
    transform: scaleX(1); 

}
&:hover{
    color: ${props => props.isBlue == true ? '#4F80E2' : '#17B9B6'};
    
}

${props => props.focus == true && css`
    color: ${props.isBlue == true ? '#4F80E2' : '#17B9B6'};

    &:after{
        transform: scaleX(1); 
        border-bottom: 3px solid ${props.isBlue == true ? '#4F80E2' : '#17B9B6'} ;
    }

`}

cursor: pointer;

`

const Img = styled.img`
width: 30px;
height: 30px;

cursor: pointer;

${props => props.reverse &&
        css`
transform:rotate(180deg) ;
`}

`

const TitleWrap = styled.div`
flex:1;

align-items: center;

display: flex; 
flex-wrap: nowrap; 
overflow: hidden;

text-overflow:ellipsis;
scroll-behavior: smooth;
`



function Main(props) {

    const [category, cate_handdle, set_cate] = useInput([])

    const titleWrap = useRef(null)

    const [scroll, scroll_handdle, set_scroll] = useInput(false)
    const [index, set_idx] = useState(0)
    const [isEquip, set_isEquip] = useState(false)
    const [is_2nd, set_is_2nd] = useState(false)
    const [isOverflow, set_isOverflow] = useState(false)

    useEffect(() => {
        // set_idx(0)
        set_cate(props.category)
        if (props.is_equip == true)
            set_isEquip(true)
        if (props.is_2nd == true)
            set_is_2nd(true)
        props.outdoor ||
            set_isOverflow(titleWrap.current.scrollWidth > titleWrap.current.offsetWidth)
    }, [props])

    useEffect(() => {
        set_idx(0)

    }, [props.category])

    // const setFocus = (event) => {
    //     const child = event.currentTarget
    //     let menu = child.parentNode.childNodes

    //     for (let a = 0; a < menu.length; a++) {
    //         menu[a].style.color = "#A4A4A4"
    //         menu[a].style.borderBottom = "3px solid #F1F1F1"
    //     }
    //     child.style.color = (isEquip||is_2nd)?"#4F80E2":"#17B9B6"

    //     child.style.borderBottom = (isEquip||is_2nd)?"3px solid #4F80E2":"3px solid #17B9B6"

    // }

    const cateOnClickEvent = (id, name) => {

        props.set_tag2name(id, name)

        if (props.set_tagNodeName != undefined)
            props.set_tagNodeName(id)


        let arr = props.selected.slice()
        let idx = arr.indexOf(id)

        if (idx == -1) {
            arr.push(id)
        } else {
            arr.splice(idx, 1)
        }

        props.set_selected(arr)
    }
    const X_event = (tagId) => {
        console.log(tagId)
        let arr = props.selected.slice()
        let idx = arr.indexOf(tagId)
        arr.splice(idx, 1)

        props.set_selected(arr)

    }
    // {props.checked}
    const spread_cate = (data) => {
        return data.map((obj, idx) => {

            return <Button isBlue={isEquip || is_2nd} checked={props.selected.indexOf(obj.tagId) == -1 ? false : true}
                onClick={() => { props.apeendTag2unit(obj.tagId, (obj.tagClass ? obj.tagClass.unit : '')); cateOnClickEvent(obj.tagId, obj.name) }} id={`category${idx}`} ><CheckImg src={icon_nonCheckCircle} />{isEquip && <CheckImg src={image_mapping[obj.equipClass.equipClassId]} />}{obj.name}</Button>
        })
    }
    // return <Button checked={props.selected.indexOf(obj.tagId)==-1?false:true} onClick={()=>cateOnClickEvent(obj.tagId,obj.name,obj)} id={`category${idx}`} ><CheckImg src={icon_nonCheckCircle} />{obj.name}</Button>
    //
    const spread_title = (data) => {
        return data.map((obj, map_idx) => {
            return <Title focus={map_idx == index} isBlue={isEquip || is_2nd} onClick={(e) => { set_idx(map_idx); }}>{obj.name}</Title>
        })
    }


    const rightButton = (e) => {
        let target = e.currentTarget.parentNode.getElementsByTagName("div")[0]
        var _scrollX = target.scrollLeft;
        target.scrollLeft = _scrollX + 100
        set_scroll(true)

    }

    const leftButton = (e) => {
        let target = e.currentTarget.parentNode.getElementsByTagName("div")[0]
        var _scrollX = target.scrollLeft;
        target.scrollLeft = _scrollX - 100

        if (_scrollX - 100 <= 0) {
            set_scroll(false)
            target.scrollLeft = 0

        }
        else {
            target.scrollLeft = _scrollX - 100

        }
    }

    return (

        <BelongWrap>

            <Belong>{props.title}</Belong>
            <Titles style={{ backgroundColor: props.outdoor && "#ffffff" }}>
                {props.outdoor ||
                    <>
                        {scroll && <Img reverse onClick={leftButton} src={icon_arrow} />}

                        <TitleWrap ref={titleWrap} >
                            {spread_title(props.category)}

                        </TitleWrap>

                        {isOverflow && <Img onClick={rightButton} src={icon_arrow} />}
                    </>
                }
            </Titles>
            <CategoryWrap isBlue={isEquip || is_2nd}>
                {category[index] == null || spread_cate(category[index].sensors)}

            </CategoryWrap>
            <Selected_Wrap isBlue={isEquip || is_2nd}>
                {props.selected.map(val => {
                    return <Selected_item isBlue={isEquip || is_2nd} onClick={() => { X_event(val) }} >{props.tag2name[val]}
                        <X_Img src={icon_X} />
                    </Selected_item>
                })}

            </Selected_Wrap>

        </BelongWrap>


    );
}
const X_Img = styled.img`
width: 1.7rem;
height: 1.7rem;
margin-left: 1.2rem;
`
const Selected_Wrap = styled.div`
flex:1;
height: 9.5rem;
background: #F1F1F1;
border-radius: 8px;
display: flex;
padding: 2.4rem;

flex-wrap: wrap; 
overflow-x: hidden;
overflow-y: auto;

text-overflow:ellipsis;
scroll-behavior: smooth;
::-webkit-scrollbar {
  width: 5px;
border-radius: 4px;

}
 
::-webkit-scrollbar-track {
  background: #D3D3D3;
border-radius: 4px;

}
 
::-webkit-scrollbar-thumb {
  background: ${props => props.isBlue == true ? '#4F80E2' : '#17B9B6'};

  border-radius: 4px;

}

`

const Selected_item = styled.span`
flex:0 0 auto;
display: flex;
align-items: center;
justify-content: center;
height: 4.7rem;
margin-right: 1rem;
margin-bottom: 1rem;
cursor: pointer;
/* padding: 15px; */

background: ${props => props.isBlue ? "#4F80E2" : "#17B9B6"};
border-radius: 5px;

font-family: 'Spoqa Han Sans Neo';
font-weight: 500;
font-size: 1.8rem;
padding: 0 1.5rem;
/* identical to box height, or 91% */


color: #FFFFFF;

`
export default Main;