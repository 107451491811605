import React from 'react';
// import Slider from 'react-slick';
import { nbind } from 'js/common'
import { Link } from "react-router-dom";
import { API } from 'service.js';
import Modal from 'react-modal';
import cookie from 'react-cookies'
import _ from 'lodash'
import moment from 'moment'
import * as common from "js/common";
import pin from "img/map_pin.png";
import MainCharts from 'components/hicharts/MainCharts';
const { kakao } = window;


class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			infowindow_content: '',
			popup: [],
			imgView: 1,
			//문의하기
			userId: '',
			containerId: 5,
			cprId: null,
			weatherData: null,

			curTime: '오전 00 : 00 : 00',
			date: fullDate,
			luna: {
				lunYear: 2079,
				lunMonth: 12,
				lunDay: 10,
			},
			deviceInfo: {
				abnormalActuratorCnt: 0,
				abnormalSensorCnt: 0,
				containerCnt: 0,
				farmCnt: 0,
				farmGatewayCnt: 0,
				memberCnt: 0,
				normalActuratorCnt: 0,
				normalSensorCnt: 0,
			},
			joinMember: 0,
			joinContainer: 0,
			boardStatus: 1, // 1공지사항 ,2 qna
			boardList: {
				content: []
			},
			alramStatus: 1, //1 알람내역, 2 조치내역
			alramList: [],

			sensorList: [],
			//날씨 관려
			//weatherData : null,
			todayInfo: {
				lunarDate: "0000-00-00",
				solarTerm: "-",
				sunRise: "00:00:00",
				sunSet: "00:00:00",
			},
		};
		this.chartView = this.chartView.bind(this);

		nbind(this);
	}
	map;
	clusterer;
	componentDidMount() {
		//cookie.save("userId", "man1")
		if (this.props.user.realm_access.roles[0] == 'ROLE_FARMER') {
			this.props.history.push('/mainUser')
		}
		setInterval(() => {
			let now = new Date();
			let text = '';
			let nowHour = now.getHours();
			if (nowHour <= 12) {
				text = '오전 '
			} else {
				text = '오후 '
			}
			let time = moment().format('hh : mm : ss')
			this.setState({
				curTime: text + time
			})
		}, 1000)

		let mapLoad = false;

		// window.kakao.maps.load(() => {
		// 	this.loadMap();
		// 	mapLoad = true;
		// });

		//맵로드 실패시 다시 시도 --> 현재 문제가 있음 확인중
		/*
		if(!mapLoad){
			console.log("kakao.maps.load fail ");
			setTimeout(
				() => {
					console.log("setTimeout ");
					window.kakao.maps.load(() => {
						this.loadMap();
						mapLoad = true;
					});
				},
				1000*5
			)
		}
		*/

	}


	loadMap() {
		console.log("window.kakao.maps.load");

		let container = document.getElementById("map");
		let options = {
			center: new window.kakao.maps.LatLng(37.51528, 126.9075611),
			level: 13,
			draggable: true,
			scrollwheel: true
		};

		this.map = new window.kakao.maps.Map(container, options);

		this.clusterer = new kakao.maps.MarkerClusterer({
			map: this.map, // 마커들을 클러스터로 관리하고 표시할 지도 객체
			averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정
			minLevel: 10 // 클러스터 할 최소 지도 레벨
		});

		// 마커가 지도 위에 표시되도록 설정합니다
		var positions = [];
		this.setData();
		this.noticeList();
	}

	setData() {
		let obj = {
			userId: cookie.load("userId")
		}
		API.getCprId(obj).then(result => {
			this.setState({ cprId: result.cprIds[0] })
			// 어떤 좌표 ?  this.weatherToday();
			this.farmList();
			this.alramList(1);
			this.deviceCount();
			this.sensorList()
		}).catch(error => console.log(error));


	}
	boardCall(type) {

		if (type == 1) {
			this.noticeList()
		} else {
			this.qnaList()
		}
		this.setState({ boardStatus: type })
	}

	farmList() {
		let obj = {
			userId: cookie.load("userId"),
			cprId: this.state.cprId
		}
		API.farmMap(obj).then(result => {
			console.log("farmMap.length==>" + result.length)
			this.setState({ farmList: result })
			if (result.length > 0) {
				this.markerAdd(result)
				//let position = new kakao.maps.LatLng(result[0].latitude, result[0].longitude)
				//임시로 중앙 좌표를 사용함
				let position = new kakao.maps.LatLng(35.82466091946677, 127.2811245094993)
				//let position = new kakao.maps.LatLng(33.450701, 126.570667);
				this.weatherToday(result[0])
				this.map.panTo(position);
			}

		}).catch(error => console.log(error));

	}

	chartView = (props) => {
		this.setState({ dataPop: true, containerId: 5 })
	}

	markerAdd(data) {
		let imageSize = new kakao.maps.Size(31, 39),  // 마커 이미지의 크기
			imgOptions = {
				offset: new kakao.maps.Point(11, 28) // 마커 좌표에 일치시킬 이미지 내에서의 좌표
			},
			markerImage = new kakao.maps.MarkerImage(pin, imageSize, imgOptions);
		var markers = data.map(function (i, position) {
			//console.log(i,position);
			var marker = new window.kakao.maps.Marker({
				image: markerImage,
				title: i.name + " 농장",
				clickable: true, // 마커를 클릭했을 때 지도의 클릭 이벤트가 발생하지 않도록 설정합니다
				position: new window.kakao.maps.LatLng(i.latitude, i.longitude)
			});

			return marker;
		});

		console.log("markers.length==>" + markers.length)

		this.clusterer.addMarkers(markers);

		let lmap = this.map;

		markers.map(function (m) {
			//console.log(m.Fb);

			// 인포윈도우를 생성하고 지도에 표시합니다
			var infowindow = new kakao.maps.InfoWindow({
				content: "<div style=\"padding:5px;\">" + m.Fb + "</div>"
			});

			// 마커에 마우스오버 이벤트를 등록합니다
			kakao.maps.event.addListener(m, 'mouseover', function () {
				infowindow.open(lmap, m);
				//console.log("mouseover::"+m.title);
			});

			// 마커에 마우스아웃 이벤트를 등록합니다
			kakao.maps.event.addListener(m, 'mouseout', function () {
				infowindow.close(lmap, m);
				//console.log("mouseout::");
			});

			kakao.maps.event.addListener(m, 'click', function () {

				console.log(this);
				//this.setState({dataPop: true, containerId: 5})
			});
		});
	}

	weatherToday(data) {
		const { latitude, longitude } = data;

		let obj = {
			latitude: latitude,
			longitude: longitude
		};
		API.weatherToday(obj).then(result => {
			this.setState({ weatherData: result })
		}).catch(error => console.log('ㅇㅇ', error));
		API.todayInfo(obj).then(result => {
			this.setState({ todayInfo: result })
		}).catch(error => console.log('ㅇㅇ', error));

	}

	qnaList() {
		let obj = {
			userId: cookie.load("userId")
		}
		API.qna(obj).then(result => {
			this.setState({ boardList: result })
		}).catch(error => console.log(error));
	}
	noticeList() {
		let obj = {
			userId: cookie.load("userId")
		}
		API.notice(obj).then(result => {
			this.setState({ boardList: result })
		}).catch(error => console.log(error));
	}

	alramList(type) {
		let obj = {
			userId: cookie.load("userId"),
			cprId: this.state.cprId
		}
		this.setState({ alramStatus: type })
		if (type == 1) {
			API.mainAlramList(obj).then(result => {
				this.setState({ alramList: result })
			}).catch(error => console.log(error));
		} else {
			API.mainAlramList(obj).then(result => {
				this.setState({ alramList: result })
			}).catch(error => console.log(error));
		}

	}
	deviceCount() {
		let obj = {
			userId: cookie.load("userId"),
			cprId: this.state.cprId
		}
		API.deviceCount(obj).then(result => {
			console.log(result)
			this.setState({ deviceInfo: result })
		}).catch(error => console.log(error));
		let now = new Date();

		let date = moment(now).subtract(6, 'days').format('YYYY-MM-DD');
		obj['date'] = date
		API.serviceJoinContainer(obj).then(result => {
			this.setState({ joinContainer: result.containerTotalCnt })
		}).catch(error => console.log(error));

		// error
		API.serviceJoinMember(obj).then(result => {
			this.setState({ joinMember: result.CprMemberCnt })

			console.log(result)
		}).catch(error => console.log(error));


	}
	sensorList() {
		let obj = {
			userId: cookie.load("userId"),
			cprId: this.state.cprId
		}
		API.cprSensor(obj).then(result => {
			console.log(result)
			this.setState({ sensorList: result })
		}).catch(error => console.log(error));
	}
	render() {
		const sliderSetting = {
			dots: true,
			infinite: true,
			// speed: 500,
			// slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000
			// slidesToShow: 1,
			// slidesToScroll: 1,
			// className: 'sample',
		};
		const ItemSetting = {
			dots: false,
			infinite: false,
			// speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			autoplay: false,
			autoplaySpeed: 5000,
			centerPadding: 0
			// slidesToShow: 1,
			// slidesToScroll: 1,
			// className: 'sample',
		};
		let dd = require('img/map_sample.png');
		return (
			<React.Fragment>
				<div className="contentInner mainWrap">
					<div className="dashboard">
						<div className="leftBox width37">
							<div className="card">
								<strong>서비스 지역별 현황</strong>
								<div id="map" style={{ height: 600 }}>

								</div>
							</div>
							{/* 
							abnormalActuratorCnt: 0,
							abnormalSensorCnt: 0,
							containerCnt: 4,
							farmCnt: 2,
							farmGatewayCnt: 4,
							memberCnt: 1,
							normalActuratorCnt: 6,
							normalSensorCnt: 174, */}
							<div className="card">
								<strong>서비스 현황</strong>
								<div className="twoContBox">
									<div>
										<figure className="icon_main_service1"></figure>
										<div>
											<p className="title">전체 사용자</p>
											<p className="number">{this.state.deviceInfo.memberCnt}<span>명</span></p>
											<p className="color1">( + {this.state.joinMember} 금주 )</p>
										</div>
									</div>
									<div>
										<figure className="icon_main_service2"></figure>
										<div>
											<p className="title">시설동 개수</p>
											<p className="number">{this.state.deviceInfo.containerCnt}<span>개</span></p>
											<p className="color2">(  {this.state.joinContainer} 금주 )</p>
										</div>
									</div>
								</div>
							</div>
							<div className="card">
								<strong>센서 / 구동 장치 현황</strong>
								<div className="twoContBox">
									<div>
										<figure className="icon_main_sensor1"></figure>
										<div>
											<p className="title">전체 센서</p>
											<p className="number">{this.state.deviceInfo.normalSensorCnt}<span>개</span></p>
											<p className="color3">( 미동작 {this.state.deviceInfo.abnormalSensorCnt} )</p>
										</div>
									</div>
									<div>
										<figure className="icon_main_sensor2"></figure>
										<div>
											<p className="title">전체 구동장치</p>
											<p className="number">{this.state.deviceInfo.normalActuratorCnt}<span>개</span></p>
											<p className="color3">( 미동작 {this.state.deviceInfo.abnormalActuratorCnt} )</p>
										</div>
									</div>
								</div>
							</div>
							<div className="card">
								<strong>데이터 수집장치 현황</strong>
								<div className="twoContBox">
									<div>
										<figure className="icon_main_data1"></figure>
										<div>
											<p className="title">GateWay</p>
											<p className="number">{this.state.deviceInfo.farmCnt}<span>개</span></p>
											<p className="color3">( 미동작 0 )</p>
										</div>
									</div>
									<div>
										<figure className="icon_main_data2"></figure>
										<div>
											<p className="title">팜인박스</p>
											<p className="number">{this.state.deviceInfo.farmGatewayCnt}<span>개</span></p>
											<p className="color3">( 미동작 0 )</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="rightBox ml20 width100">
							<div className="flex">
								<div className="card width50">
									<strong className="flex a_center between" style={{ background: '#D0BF24' }}>{this.state.date} <span>( 음력 ) {this.state.todayInfo.lunarDate} ( 절기 ) {this.state.todayInfo.solarTerm}</span></strong>
									<div className="mainWeather">
										<strong className="nowTime">{this.state.curTime}</strong>
										<div>
											<div className="weatherSlider">
												{/* <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M11 21L1 11L11 1" stroke="#4F80E2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg> */}
												<div className="weatherCont">
													<div>
														{
															this.state.weatherData ?
																<React.Fragment>
																	{
																		Number(this.state.weatherData.amPty) == 0 ?
																			<i className={['', 'sun', 'cloudSmall', 'cloudMany', 'cloud'][Number(this.state.weatherData.amSky)]}></i>
																			:
																			<i className={
																				{
																					'1': 'rain',
																					'2': 'rain',
																					'3': 'snow',
																					'4': 'rain',
																					'5': 'rain',
																					'6': 'rain',
																					'7': 'rain',
																				}[Number(this.state.weatherData.amSky)]}>
																			</i>
																	}
																</React.Fragment>
																: <i className='sun'></i>

														}
														<div>
															<strong>오전</strong>

															<p>
																<span className="high">최고 : {this.state.weatherData ? this.state.weatherData.amMaxTemp : '-'}℃</span> <br />
																<span className="low">최저 : {this.state.weatherData ? this.state.weatherData.amMinTemp : '-'}℃</span>
															</p>
														</div>
														<p className="sunStatus"><i className="up"></i>일출   {this.state.todayInfo.sunRise}</p>
													</div>
													<div>
														{
															this.state.weatherData ?
																<React.Fragment>
																	{
																		Number(this.state.weatherData.pmPty) == 0 ?
																			<i className={['', 'sun', 'cloudSmall', 'cloudMany', 'cloud'][Number(this.state.weatherData.pmSky)]}></i>
																			:
																			<i className={
																				{
																					'1': 'rain',
																					'2': 'rain',
																					'3': 'snow',
																					'4': 'rain',
																					'5': 'rain',
																					'6': 'rain',
																					'7': 'rain',
																				}[Number(this.state.weatherData.pmSky)]}>
																			</i>
																	}
																</React.Fragment>
																: <i className='sun'></i>

														}
														<div>
															<strong>오후</strong>
															<p>
																<span className="high">최고 : {this.state.weatherData ? this.state.weatherData.pmMaxTemp : '-'}℃</span> <br />
																<span className="low">최저 : {this.state.weatherData ? this.state.weatherData.pmMinTemp : '-'}℃</span>
															</p>
														</div>
														<p className="sunStatus"> <i className="down"></i> 일몰   {this.state.todayInfo.sunSet}</p>
													</div>
												</div>
												{/* <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M1 1L11 11L1 21" stroke="#7D7D7D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
												</svg> */}
											</div>
										</div>
									</div>
								</div>
								<span className="ml20"></span>
								<div className="cardTab width50">
									<Link className="btnLink">
										전체보기
										<svg xmlns="http://www.w3.org/2000/svg" width="9.551" height="16.235" viewBox="0 0 9.551 16.235">
											<g id="그룹_191" data-name="그룹 191" transform="translate(-1929.725 -1193.729)">
												<line id="선_15" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3" />
												<line id="선_16" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3" />
											</g>
										</svg>
									</Link>
									<ul>
										<li className={this.state.boardStatus == 1 ? 'on' : null}><button onClick={() => { this.boardCall(1) }}>공지사항</button></li>
										<li className={this.state.boardStatus == 2 ? 'on' : null}><button onClick={() => { this.boardCall(2) }}>{'Q&A'}</button></li>
									</ul>
									<div>
										<ul>
											{
												this.state.boardList.content.map((item, index) => {
													return (
														<li key={'board' + index}>
															<Link to="/notice/detail/2"> <span>2021.01.06</span> 공지사항 입  asdf asdfasdf asdfasd fasdfasdads asdfffd ß니다</Link>
														</li>
													)
												})
											}
											{this.state.boardList.content.length == 0 ?
												<li className="textCenter">
													등록된 글이 없습니다.
												</li>
												:
												null
											}
										</ul>
									</div>
								</div>
							</div>
							<div className="card">
								<strong>서비스 농장 현황  ( {this.state.sensorList.length} 개 농장 ) </strong>

								<div className="scrollBox" style={{ height: 535 }}>

									<div className="cardList">
										<ul>

											{this.state.sensorList.map((item, index) => {
												return (
													<li>
														<div>
															<div className="farmName">
																<strong>{item.farmName} <span>(농장주명)</span></strong>
																<span className="red">센서알람 : 0개</span>
															</div>
															<div>

																{item.containers.map((itemCont, itemIndex) => {
																	return (
																		<div>
																			<strong>{itemCont.containerName} </strong>
																			<p
																				className={(() => {
																					let hd_val = itemCont.sensors[0] ? itemCont.sensors[0].value : 0
																					if (hd_val <= 1.5) {
																						return "yellow";
																					} else if (hd_val > 1.5 && hd_val <= 3.5) {
																						return "yellow";
																					} else if (hd_val > 3.5 && hd_val <= 4.5) {
																						return "green"
																					} else if (hd_val > 4.5 && hd_val <= 7.5) {
																						return "green";
																					} else if (hd_val > 7.5 && hd_val <= 9.5) {
																						return "red";
																					} else {
																						return "red";
																					}
																				})()}

																			>
																				{(() => {
																					let hd_val = itemCont.sensors[0] ? itemCont.sensors[0].value : 0
																					if (hd_val <= 1.5) {
																						return "심각 증산";
																					} else if (hd_val > 1.5 && hd_val <= 3.5) {
																						return "조금적은증산";
																					} else if (hd_val > 3.5 && hd_val <= 4.5) {
																						return "적은 증산"
																					} else if (hd_val > 4.5 && hd_val <= 7.5) {
																						return "정적 증산";
																					} else if (hd_val > 7.5 && hd_val <= 9.5) {
																						return "많은 증산";
																					} else {
																						return "매우많은증산";
																					}
																				})()}
																			</p>
																		</div>
																	)
																})}

															</div>
														</div>
													</li>

												)
											})}
											{/* <li>
												<div>
													<div className="farmName">
														<strong>문영농장 <span>(농장주명)</span></strong>
														<span className="red">센서알람 : 3개</span>
													</div>
													<div>
														<div>
															<strong>1동 <span className="red">센서장애 : 3개</span></strong>
															<p className="greenBg">적정증산</p>
														</div>
														<div>
															<strong>2동 <span className="red">센서장애 : 3개</span></strong>
															<p className="redBg">많은증산</p>
														</div>
													</div>
												</div>
											</li>
											<li>
												<div>
													<div className="farmName">
														<strong>문영농장 <span>(농장주명)</span></strong>
														<span className="red">센서알람 : 3개</span>
													</div>
													<div>
														<div>
															<strong>1동 <span className="red">센서장애 : 3개</span></strong>
															<p className="greenBg">적정증산</p>
														</div>
														<div>
															<strong>2동 <span className="red">센서장애 : 3개</span></strong>
															<p className="redBg">많은증산</p>
														</div>
													</div>
												</div>
											</li>
											<li>
												<div>
													<div className="farmName">
														<strong>문영농장 <span>(농장주명)</span></strong>
														<span className="red">센서알람 : 3개</span>
													</div>
													<div>
														<div>
															<strong>1동 <span className="red">센서장애 : 3개</span></strong>
															<p className="greenBg">적정증산</p>
														</div>
														<div>
															<strong>2동 <span className="red">센서장애 : 3개</span></strong>
															<p className="redBg">많은증산</p>
														</div>
													</div>
												</div>
											</li>
											 */}
										</ul>
									</div>
								</div>
							</div>
							<div className="cardTab ">
								<Link className="btnLink">
									전체 알람 내역 보기
									<svg xmlns="http://www.w3.org/2000/svg" width="9.551" height="16.235" viewBox="0 0 9.551 16.235">
										<g id="그룹_191" data-name="그룹 191" transform="translate(-1929.725 -1193.729)">
											<line id="선_15" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3" />
											<line id="선_16" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3" />
										</g>
									</svg>
								</Link>
								<ul>
									<li className={this.state.alramStatus == 1 ? 'on' : null}><button onClick={() => { this.alramList(1) }}>알람내역</button></li>
									<li className={this.state.alramStatus == 2 ? 'on' : null}><button onClick={() => { this.alramList(2) }}>조치내역</button></li>

								</ul>
								<div>
									<div className="tableBox small border">
										<table>
											<thead>
												<tr>
													<th>등급</th>
													<th>장치번호</th>
													<th>장치위치</th>
													<th>장치명</th>
													<th>상태값</th>
													<th>설명</th>
													<th>발생일시</th>
													<th>시간</th>
												</tr>
											</thead>
											<tbody>
												{this.state.alramList.map((item, index) => {
													<tr>
														<td></td>
														<td>F-001</td>
														<td>부산시 &#62; 사하구 &#62; 명품표고농장 &#62; 시설동1</td>
														<td>화재감지경보 1</td>
														<td>22</td>
														<td>화재발생</td>
														<td>2020-01-21 </td>
														<td>AM 09 : 50</td>
													</tr>
												})}
												{this.state.alramList.length == 0 ?
													<React.Fragment>
														<tr>
															<td colSpan="8" rowSpan="5" style={{ height: 180 }}>
																<span >
																	등록된 내역이 없습니다.
																</span>
															</td>

														</tr>

													</React.Fragment>
													:
													null
												}


											</tbody>
										</table>

									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={this.state.dataPop}
						onRequestClose={() => { this.setState({ dataPop: false }) }}
						shouldCloseOnOverlayClick={true}
						className="modalBox modalSizeS"
						style={{ content: { minWidth: 1200 } }}
					>
						<MainCharts userId={this.state.userId} containerId={this.state.containerId} />
					</Modal>

				</div>

			</React.Fragment>
		);
	}
}

let today = new Date().toLocaleString()
let fullDate = moment(new Date()).format('YYYY-MM-DD')
let fullDate2 = moment(new Date()).format('YYYYMMDD')

export default Main;
