import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
// import CloseButton from './CloseButton'
import sprout from 'img/gray_sprout.png'
import blue_sprout from 'img/blue_sprout.png'
import checkCircle from 'img/CheckCircle.png'
import blue_checkCircle from 'img/blue_CheckCircle.png'
import bug_off from 'img/Bug_off.png'
import bug_on from 'img/Bug_on.png'
import blue_circle from 'img/blue_circle.png'
import circle from 'img/circle.png'

const Top = styled.div`
display:flex ;
justify-content:space-between ;
margin-bottom: 2.5rem;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2.6rem;
line-height: 46px;


color: #2A3A4B;
`


const Wrapper = styled.div`
display: flex;
flex: 1;
height: 300px;
flex-wrap: wrap;
`

const Label = styled.label`
  cursor: pointer;
`;


const Item = styled.div`
display:flex ;
position: relative;
align-items:center ;
justify-content:center ;
height: 10.5rem;
padding:1.2rem 1.8rem ;
padding-top: 4.2rem;
cursor: pointer ;

margin-bottom:2.4rem ;
margin-right:12px ;

border: 1px solid #DFDFDF;
background: #FFFFFF;
box-sizing: border-box;
box-shadow: 0px 4px 8px rgba(125, 140, 154, 0.3);
border: 0.1rem solid rgba(125, 140, 154, 0.3);
border-radius: 8px;


font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;

color: #2A3A4B;

`
const Item_input = styled.input`
  opacity: 0;

  &:checked  {
    &+ ${Item}{
        .first{
                content : url(${blue_checkCircle});
            }
        .second{
            ${props => props.bug ? css`
            content : url(${bug_on});
            `
        : css`
            content : url(${blue_sprout});
            `

    }
            
            }
    border: 2px solid #17B9B6;

      }

    
    
  }
`;
const Circle = styled.img`
width: 1.8rem;
height: 1.8rem;
margin: 0 0.7rem;
cursor: pointer;

${props => props.is_blue &&
        css`
content: url(${blue_circle});

`}
`
const Circle_Wrapper = styled.div`
width: 100%;
display: flex;
justify-content: center;
margin-bottom: 2rem;
`
function Modal(props) {

    const [toolIdx, set_toolIdx] = useState(0)
    const [maxToolIdx, set_maxToolIdx] = useState(0)
    const [toolPosition, set_toolPosition] = useState(0)

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            props.onClose(e)
        }
    }

    const close = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }
    useEffect(() => {
        set_maxToolIdx(Math.floor(props.farm.length / 9))
    }, [props])

    return (
        <>
            <ModalOverlay visible={props.visible} />
            <ModalWrapper onClick={onMaskClick} visible={props.visible}>
                <ModalInner>
                    <Top>
                        <Title>농장선택</Title>
                        <CloseButton onClick={close}>닫기</CloseButton>
                    </Top>
                    <Wrapper>
                        {
                            props.length == 0 ||
                            props.farm.slice(toolIdx * 9, toolIdx * 9 + 9).map((val, idx) => {
                                return <Label >
                                    <Item_input bug={val.productCode.slice(0, 6) == 'INSECT'} onClick={() => props.set_idx(idx + toolIdx * 9)} checked={idx + toolIdx * 9 == props.idx} type='radio' name='farmList' />
                                    <Item>
                                        <Img className='first' style={{ left: '12px' }} src={checkCircle} /> <Img className='second' style={{ right: '12px' }} src={val.productCode.slice(0, 6) == 'INSECT' ? bug_off : sprout} />

                                        {val.name}</Item>
                                </Label>
                            })
                        }
                        <Circle_Wrapper>
                            {maxToolIdx > 0 && <Circle onClick={() => { set_toolPosition(0); set_toolIdx(toolIdx - toolPosition) }} is_blue={toolPosition == 0} src={circle} />}
                            {maxToolIdx >= 1 && <Circle onClick={() => { set_toolPosition(1); set_toolIdx(toolIdx + 1 - toolPosition) }} is_blue={toolPosition == 1} src={circle} />}
                            {maxToolIdx >= 2 && <Circle onClick={() => { set_toolPosition(2); set_toolIdx(toolIdx + 2 - toolPosition) }} is_blue={toolPosition == 2} src={circle} />}
                        </Circle_Wrapper>
                    </Wrapper>
                </ModalInner>
            </ModalWrapper>
        </>
    )
}


const Img = styled.img`
position: absolute;
 top:12px;
 
`

const ModalWrapper = styled.div`
  box-sizing: border-box;

  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;
max-width: 80rem;
   top: 25%;
  animation: zoomIn 0.2s none;
  
  margin: 0 auto;
  padding: 3.5rem;

`

const CloseButton = styled.div`
display:flex ;

height: 53px;


background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-weight: 500;
font-size: 1.8rem;
padding: 1.2rem 2.8rem;
text-align:center ;
justify-content:center ;
align-items: center;
color: #FFFFFF;

cursor:pointer ;
`
export default Modal