import React from 'react';
import styled from 'styled-components';
function Main(props) {
    return (
        <LeftDownWrap>
            <LeftDownTitle color={props.titleColor} backgroundColor={props.backgroundColor}>{props.title}</LeftDownTitle>
            <LeftDownText color={props.color}>{props.int}
                <Unit color={props.color}  unit={props.unit}>{props.unit}</Unit>
            </LeftDownText>
        </LeftDownWrap>

    );
}

const LeftDownWrap = styled.div`
width:100% ;
display:flex ;
height:4.4rem;
margin-bottom:1.2rem ;
`
const LeftDownTitle = styled.div`
width: 11.6rem;
display:flex ;
align-items:center ;
justify-content:center ;
background: ${props => props.backgroundColor || "#AAEAE9"};

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;

font-size: 1.6rem;

color: ${props => props.color || "#656565"};
`

const LeftDownText = styled.div`
flex:1 ;
background: #F5F5F5;
text-align:right ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2rem;
color: ${props => props.color || "##2A3A4B"};
display:flex ;
align-items:center;
justify-content:flex-end ;

padding: 1.2rem;
`
const Unit = styled.div`
display:flex ;

height:100% ;

align-items:${props=>props.unit=='℃'?"flex-start":"center"} ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 1.6rem;
margin-left:3px ;
color: ${props => props.color || "#656565"};
content:${props => props.unit || "%"} ;

`
export default Main;