import React, { useEffect, useState } from "react";
import { useInput } from "hooks/commonHooks";
import api, { useInterval } from "components/utils/api";
import _ from "lodash";
import styled from "styled-components";
import FarmInformation from "components/mainPage/farmInformation";
import WeatherInformation from "components/mainPage/weatherInformation";
import Announcement from "components/mainPage/announcement";
import EnvInformation from "components/mainPage/envInformation";
import { API } from "service";
import cookie from "react-cookies";

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 14% 230px 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
`;

const Wraps = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  min-width: 1300px;

  width: 100%;

  flex-wrap: wrap;
`;

function Main() {
  const [farm_data, farm_handdle, set_farm] = useInput([]);
  const [idx, idx_handdle, set_idx] = useInput(0);
  const [intever, set_intever] = useState(null);

  const [targetFarm, setTargetFarm] = useState({});
  useEffect(() => {
    API.getContainers().then((val) => {
      set_farm(val);
    });
    set_idx(sessionStorage.getItem("indwkdu") || 0);
    // start_interver()
    // useInterval(start_interver,true?null:3)
  }, []);

  const start_interver = () => {
    try {
      API.getContainers()
        .then((val) => {
          set_farm(val);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("indwkdu", idx);
  }, [idx]);
  return (
    <Wrapper>
      <Wraps>
        <FarmInformation idx={idx} set_idx={set_idx} farm={farm_data} />
        <WeatherInformation idx={idx} farm={farm_data} />
        <Announcement farmData={farm_data} targetFarmIdx={idx} />
      </Wraps>

      <EnvInformation start={start_interver} idx={idx} farm={farm_data} />
    </Wrapper>
  );
}

// class Main extends React.Component{
// 	render(){
// 		return <div>test</div>
// 	}
// }
export default Main;
