import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';
import { API } from 'service';
import styled, { keyframes } from 'styled-components';

import productImg from 'img/icon-flower.png'
import calendarImg from 'img/Calendar.png'
import magnifyingImg from 'img/magnifying-glass.png'



const SamplePriceCompo = () => {

    const [apiLoading, setApiLoading] = useState(false);
    const [apiData, setApiData] = useState({});

    const [apiError, setApiError] = useState(false);
    const [prevPriceData, setPrevPriceData] = useState({
        countyname: '',
        itemname: '',
        kindname: '',
        marketname: '',
        price: 0,
        regday: '',
        yyyy: '',
    });
    const [curPriceData, setCurPriceData] = useState({
        countyname: '',
        itemname: '',
        kindname: '',
        marketname: '',
        price: 0,
        regday: '',
        yyyy: '',
    });

    useEffect(() => {

        fetchData();

        return () => setApiLoading(false);
    }, []);

    const fetchData = async () => {
        /* 
           0 : 일
           1 : 월
           2 : 화
           3 : 수
           4 : 목
           5 : 금
           6 : 토
        */
        try {
            // 오늘 주말인지 체크
            let curDate = moment().format('YYYY-MM-DD');
            let curDateWeek = new Date(curDate).getDay();

            if (curDateWeek == 0) {
                curDate = moment(curDate).subtract(2, 'days').format('YYYY-MM-DD');
            } else if (curDateWeek == 1) {
                curDate = moment(curDate).subtract(3, 'days').format('YYYY-MM-DD');
            } else {
                curDate = moment(curDate).subtract(1, 'days').format('YYYY-MM-DD');
            }

            // curDate가 월요일 일 때 체크
            let prevDate;
            if (curDateWeek === 2) {
                prevDate = moment(curDate).subtract(3, 'days').format('YYYY-MM-DD');
            } else {
                prevDate = moment(curDate).subtract(1, 'days').format('YYYY-MM-DD');
            }

            // return;
            let fetchURL = `https://www.kamis.or.kr/service/price/xml.do?action=periodProductList&p_cert_key=8b0476ec-e33a-4561-b46d-7f538e2c93b9&p_cert_id=dev.suhwooms&p_returntype=json&p_startday=${prevDate}&p_endday=${curDate}&p_itemcategorycode=200&p_itemcode=242&p_kindcode=03&p_productrankcode=04&p_countrycode=1101&p_convert_kg_yn=Y&p_productclscode=02`

            let priceFetchRes = await API.proxy(fetchURL);

            if (priceFetchRes.data.error_code != '000') {
                setApiError(true);
                setApiLoading(true);
                return;
            }
            const fetchData = priceFetchRes.data.item;

            const actData = fetchData.filter(e => !Array.isArray(e.itemname))

            if (actData.length < 1) {
                setApiError(true);
                return;
            }
            if (actData[0] !== undefined) {
                setPrevPriceData(actData[0]);
            }
            if (actData[1] !== undefined) {
                setCurPriceData(actData[1]);
            }

            setTimeout(() => {
                setApiLoading(true);
            }, 800)

        } catch (error) {
            alert(error.message);
            setApiLoading(true);
            setApiError(true);
        }

    }




    if (!apiLoading) {
        return (
            <SampleOuterWrapper>
                <LoaingOuter visible={apiLoading} >
                    <LoadingInner />
                    <div style={{ marginTop: 10 }}> 데이터를 받아오고 있습니다. 잠시 기다려 주세요.</div>
                </LoaingOuter>
            </SampleOuterWrapper>
        )
    }

    if (apiError) {
        return (
            <SampleOuterWrapper>api Error</SampleOuterWrapper>
        )
    }

    const diffPriceControl = () => {

        if (curPriceData.price == 0 || prevPriceData.price == 0) return;

        const diffPrice = Number(curPriceData.price.replaceAll(',', '')) - Number(prevPriceData.price.replaceAll(',', ''));
        if (typeof diffPrice != 'number') return;

        let upDownFlag = null;
        if (diffPrice > 0) {
            upDownFlag = true;
        } else if (diffPrice < 0) {
            upDownFlag = false;
        }
        return (
            <>
                <PriceSpan upDownFlag={upDownFlag}>
                    (
                    {/* <img src={upDownFlag === true ? redTriangle : blueTriangle} alt="" style={{ height: '16px' }} /> */}
                    <span>{upDownFlag === null ? '-' : upDownFlag == true ? '▲' : '▼'}</span>
                    <span>  &nbsp;{diffPrice}</span>
                    )
                </PriceSpan>
                <span style={{ fontSize: 12 }}>
                    &nbsp; (전 거래일 대비)
                </span>
            </>
        );

    }

    return (
        <>
            <SampleOuterWrapper>
                <TitleWrapper>
                    <SampleTitleRowWrapper>
                        <img src={productImg} alt="" />
                        <InnerTitle>품목명</InnerTitle>
                    </SampleTitleRowWrapper>
                    <SampleTitleRowWrapper>
                        <img src={calendarImg} alt="" />
                        <InnerTitle>기준일</InnerTitle>
                    </SampleTitleRowWrapper>
                    <SampleTitleRowWrapper>
                        <img src={magnifyingImg} alt="" />
                        <InnerTitle>가격</InnerTitle>
                    </SampleTitleRowWrapper>
                </TitleWrapper>
                <ContentWrapper>
                    <ContentRowWrapper>
                        <ContentText>
                            {curPriceData.kindname}
                        </ContentText>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentText>
                            {curPriceData.yyyy}/{curPriceData.regday}
                        </ContentText>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentText>
                            {curPriceData.price}원  &nbsp;{diffPriceControl()}
                        </ContentText>
                    </ContentRowWrapper>
                </ContentWrapper>
            </SampleOuterWrapper>
            <OuterLinkWrapper><a href="https://www.kamis.or.kr/customer/main/main.do" target="_blank" rel="noopener noreferrer"> 더 많은 가격정보 확인&nbsp;▶ </a></OuterLinkWrapper>
        </>
    )

}

export default SamplePriceCompo;

const loadingSpin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform : rotate(360deg);
    }
`

const LoaingOuter = styled.div`
    display : ${props => !props.visible ? 'flex' : 'none'};
    /* display : flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width : 100%;
    height : 100%;
`

const LoadingInner = styled.div`
    box-sizing: border-box;
    width : 64px;
    height : 64px;
    border-radius: 50%;
    border : 8px solid transparent;
    border-top-color : #17B9B6;
    border-bottom-color : #17B9B6;
    animation: ${loadingSpin} .8s ease infinite;
`

const OuterLinkWrapper = styled.div`
    display : flex;
    justify-content: center;
    align-items: center;
    width : 100%;
    height : 10%;
    background-color: #F5F5F5;

    & a{
        cursor: pointer;

        &:hover{
            color : blue;
        }
    }

`

const SampleOuterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #F5F5F5;
    width : 100%;
    height : 70%;
    padding : 20px 20px 0px 20px;
`;

const TitleWrapper = styled.div`
    
    display : flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width : 30%;
    height : 90%;
`
const SampleTitleRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width : 100%;
    height : 33.3%;

    & img{
        width : 20%;
    }
`
const InnerTitle = styled.span`
    font-family: Spoqa Han Sans Neo;
    font-size : 16px;
    color : #A4A4A4;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 2rem;
`

const ContentWrapper = styled.div`
    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 90%;
`

const ContentRowWrapper = styled.div`
    display : flex;
    flex-direction: row;
    width : 100%;
    height: 33.3%;
    align-items: center;
`

const ContentText = styled.span`
    display: flex;
    flex-direction: row;
    font-family: Spoqa Han Sans Neo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 2rem;
    color: #2A3A4B;
    text-align: left;
`
const PriceSpan = styled.span`
    display: flex;
    align-items: center;
    height: 100%;

    color : ${props => (props.upDownFlag == undefined || props.upDownFlag == null) ? 'black' : props.upDownFlag === true ? 'red' : 'blue'}
`