import React from 'react';
import styled from 'styled-components';

const Wrapper=styled.div`
display: flex;
text-align: center;
justify-content: center;
align-items: center;
width: 98px;
height: 37px;
margin-right: 5px;

background: ${props=>props.color2};
border: 1px solid ${props=>props.color1};
box-sizing: border-box;
border-radius: 18.5px;



font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 16px;

color: ${props=>props.color1};
`

const message={
    "heat":"난방필요",
    "humidity":"가습필요",
    "cool":"냉방필요",
    "water":'제습필요',
    "comfort":"적정상태"
}

const color1={
    "heat":"#E16A6A",
    "humidity":"#F68F16",
    "cool":"#4F80E2",
    "water":"#845EC2",
    "comfort":"#17B9B6"
}

const color2={
    "heat":"#FFCFCF",
    "humidity":"#FFEFCA",
    "cool":"#CBDAFA",
    "water":"#ECD0FF",
    "comfort":"#AAEAE9",
}

function Main(props) {
    


    return (
        <Wrapper color1={color1[props.type]} color2={color2[props.type]}>
            {message[props.type]}
        </Wrapper>
      );
}

export default Main;