export const RIGHTSIDEWINDOW = Object.freeze({
    equipClass : 'RIGHTSIDEWINDOW',
    status : 'SIDEVALUE',
    control : 'SIDECONTROL',
});
export const LEFTSIDEWINDOW = Object.freeze({
    equipClass : 'LEFTSIDEWINDOW',
    status : 'SIDEVALUE',
    control : 'SIDECONTROL',
});
export const TOPWINDOW = Object.freeze({
    equipClass : 'TOPWINDOW',
    status : 'SIDEVALUE',
    control : 'SIDECONTROL',
});
export const DIFFISURE = Object.freeze({
    equipClass : 'DIFFISUER',
    status : 'DIFFIUSERVALUE',
    control : 'DIFFISURECONTROL',
    act : {
        on : 'ON',
        off : 'OFF',
    }
});
export const CARBONHEATER = Object.freeze({
    equipClass : 'CARBONHEATER',
    status : 'CARBONHEATSTATUS',
    control : 'DIFFISURECONTROL',
    act : {
        on : 'ON',
        off : 'OFF'
    }
});
// 제주도용
export const HEATEXCHANGER = Object.freeze({
    equipClass : 'HEATEXCHANGER',
    status : 'HEATEXCONTROL',
    control : 'HEATEXCONTROL',
    act : {
        off : 'OFF',
        weak : 'WEAK',
        middle : 'MIDDLE',
        gale : 'GALE'
    }
});
export const HEATEXCHANGERCO2 = Object.freeze({
    equipClass : 'HEATEXCHANGERCO2',
    status : 'HEATEXSTATCOMMON',
    control : 'HEATEXCONTROL',
    act : {
        off : 'OFF',
        weak : 'WEAK',
        middle : 'MIDDLE',
        gale : 'GALE'
    }
});
export const LIGHT = Object.freeze({
    equipClass : 'LIGHT',
    status : 'LIGHTSTATUS',
    control : 'CONTROLTARGETVALUE',
    act : {
        on : 'ON',
        off : 'OFF'
    }
});
export const IRRIGATIONMOTOR = Object.freeze({
    equipClass : 'IRRIGATIONMOTOR',
    status : 'IRRIGATIONMOTORSTATUS',
    control : 'IRRIGATIONMOTORCONTROL',
    act : {
        on : 'ON',
        off : 'OFF',
    },
    otherState : {
        startTime : 'IRRIGATIONMOTORONTIME',
        runTime : 'IRRIGATIONMOTORRUNTIME',
    }
});
export const HVAC = Object.freeze({
    equipClass : "HVAC",
    status : 'HVACCURTEMP',
    envState : {
        setTemp : 'HVACSETTEMP',
        setHum : 'HVACHUM',
        tempStep : 'HVACTEMPSTEP',
        dayTemp : 'HVACDAYTEMP',
        nightTemp : 'HVACNIGHTTEMP',
        
        curTemp : 'HVACCURTEMP',
        curHum : 'HVACCURHUM'
    },
    timeState : {
        startTime : 'HVACSTARTTIME',
        endTime : 'HVACENDTIME',
    },
    driveStatus : {
        drive : 'HVACDRIVE',
        fanStatus : 'HVACFANSTATUS',
        coolingStatus : 'HVACCOOLINGSTATUS',
        heatStatus : 'HVACHEATSTATUS',
        humdiStatus : 'HVACHUMIDISTATUS',
        dehumdiStatus : 'HVACDEHUMIDISTATUS',
    }
})
export const AIRCON = Object.freeze({
    equipClass : 'AIRCON',
    status : 'AIRCONSTATUS',
    controlAddr : 'CONTROLTARGET',
    controlValue : 'CONTROLTARGETVALUE',
    envState : {
        tempMax : 'AIRCONTEMPMAX',
        tempMin : 'AIRCONTEMPMIN',
    },
    act : {
        off : 'OFF'
    }
});
export const HEATEXCHANGERCO2HUM = Object.freeze({
    equipClass : 'HEATEXCHANGERCO2HUM',
    status : 'HEATEXSTATCOMMON',
    controlAddr : 'CONTROLTARGET',
    controlValue : 'CONTROLTARGETVALUE',
    driveControl : 'AIRCONDRIVE',
    act : {
        off : 'OFF',
        weak : 'WEAK',
        middle : 'MIDDLE',
        gale : 'GALE'
    },
    envState : {
        co2Min : 'HEATEXCO2MIN',
        co2Max : 'HEATEXCO2MAX',
        humMin : 'HEATEXHUMMIN',
        humMax : 'HEATEXHUMMAX',
    }
});
export const HUMIDIFIER = Object.freeze({
    equipClass : 'HUMIDIFIER',
    status : 'HUMIDIFIERHUMSTAT',
    controlAddr : 'CONTROLTARGET',
    controlValue : 'CONTROLTARGETVALUE',
    act : {
        off : 'OFF',
        on : 'ON',
    },
    envState : {
        humMin : 'HUMIDIFIERHUMMIN',
        humMax : 'HUMIDIFIERHUMMAX',
    }
});
/* 탄소난방 비 제어 모듈 */
export const CARBONHEATERNONECONTROL = Object.freeze({
    equipClass : 'CARBONHEATERNONECONTROL',
    status : 'CARBONHEATSTATBIT',
    controlAddr : 'CONTROLTARGET',
    controlValue : 'CONTROLTARGETVALUE',
});
export const HEATMOTOR = Object.freeze({
    equipClass : 'HEATMOTOR',
    status : 'MOTORSTATUS',
    controlAddr : 'CONTROLTARGET',
    controlValue : 'CONTROLTARGETVALUE',
    act : {
        off : 'OFF'
    }
});
export const HEATFAN = Object.freeze({
    equipClass : 'HEATFAN',
    status : 'FANSTATUS',
    controlAddr : 'CONTROLTARGET',
    controlValue : 'CONTROLTARGETVALUE',
    act : {
        off : 'OFF'
    }
});
export const SECONDLEFTAST = Object.freeze({
    equipClass : 'LEFTAST',
    status : 'SIDEVALUE',
    act : {
        sideCommand : 'SIDECOMMAND',
        sideControl : 'SIDECONTROL'
    },
    isDrive : 'WINDOWDRIVE',
});
export const HEATMOTORTEMP = Object.freeze({
    equipClass : 'HEATMOTORTEMP',
    status : 'CARBONHEATSTATBIT',
    controlAddr : 'CONTROLTARGET',
    controlValue : 'CONTROLTARGETVALUE',
    act : {
        off : 'OFF',
        on : 'ON'
    },
    env : {
        min : 'HEATMOTORTEMPMIN',
        max : 'HEATMOTORTEMPMAX'
    }
})