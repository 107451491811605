import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const SearchBox = (props) => {
    let obj = {
        params: {
            size: props.pageListSize,
            page: 0
        },
    };
    let selectedObj = {
        params: {
            size: props.pageListSize,
            page: 0
        },
    };

    const [searchList, setSearchList] = useState(obj);
    const [selected, setSelected] = useState(selectedObj);

    const handleClick = i => {
        props.onSearchChange(i, searchList);
        setSearchList(obj);
        setSelected(selectedObj);
    }

    const searchListHandler = e => {
        let object = searchList;
        let selectedObject = selected;
        if(e.target.value === "noValue" || e.target.value === ""){
            delete object.params[e.target.name];
        } else {
            object.params[e.target.name] = e.target.value;
        }
        selectedObject.params[e.target.name] = e.target.value;
        setSearchList(object);
        setSelected(selectedObject);
    }

    return (
        <>
            <div className="whBox mt20">
                <div className="searchBox">
                    {props.searchContent.map((value, index) => {
                        if (value.type == 'SELECT') {
                            return (
                                <div className="selectBox mr10" key={"wer"+index}>
                                    <select name={value.classCode}
                                        onChange={(e) => { searchListHandler(e) }}
                                        key={"s"+index}
                                    >
                                        <option value="noValue" key={index}>{value.name}</option>
                                        {value.searchOptions.map((options, index) => {
                                            let id = uuidv4();
                                            return (
                                                <option value={options.value} key={id}>{options.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            )
                        } else if (value.type == 'TEXT') {
                            let id = uuidv4();
                            return (
                                <div className="inputBox ml10" key={"wer"+index}>
                                    <input type="text" name={value.classCode} key={id}
                                        onKeyPress={event => {
                                            if (event.key === 'Enter') {
                                                handleClick(1)
                                            }
                                        }}
                                        onChange={(e) => {
                                            searchListHandler(e)
                                        }}
                                        ></input>
                                </div>
                            )
                        }
                    })
                    }
                    <button onClick={() => { handleClick(1) }} type="button">검색</button>
                </div>
            </div>
        </>
    )
}

export default SearchBox;