import { max } from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import button_arrow from 'img/button_arrow.png'
import button_arrow_clicked from 'img/button_arrow_clicked.png'
import clicked_rightArrow from 'img/button_arrow_clicked.png'

const Wrapper = styled.div`
width: 100%;
max-height: 810px;


background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
min-width: 1300px;

padding: 32px 28px;
`
const Title = styled.div`
width: 100%;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 28px;
/* identical to box height */

display: flex;
align-items: center;

color: #2A3A4B;

`

const SensorWrapper = styled.div`
width: 100%;
`

const SensorItem = styled.div`
width: 100%;
display: flex;
padding:18px 30px;
background:${props => props.isODD ? '#F5F5F5' : '#FFFFFF'} ;

`
const Names = styled.div`
width: 80%;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.8rem;
/* identical to box height */

display: flex;
align-items: center;

color: #656565;

`
const Setting = styled.div`
flex: 1;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */

display: flex;
align-items: center;

color: #656565;

`
const Blue = styled.span`
color: #17B9B6;
font-size: inherit;
`
const Button = styled.div`
background: #F5F5F5;
border-radius: 2.25806px;
width: 28px;
height: 28px;
margin: 0px 3px;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
cursor: pointer;
color: #8B9295;

${props => props.checked && css`
color:#FFFFFF;
background-color: #17B9B6;
`}
`
const ButtonWrap = styled.div`
display: flex;

justify-content: flex-end;
margin-top:26px;
`
const Arrow = styled.img`
width: 2.8rem;
height: 2.8rem;
cursor: pointer;

&:hover{
    content: url(${clicked_rightArrow});
}
`

const Span=styled.span`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.8rem;
/* identical to box height */

display: flex;
align-items: center;

color: #17B9B6;
`

function Main(props) {

    let [idx, set_idx] = useState(0)
    let [maxIdx, set_maxIdx] = useState(0)
    const [sensors, set_sensors] = useState([])
    const [startIdx, set_startIdx] = useState(0)
    const [endIdx, set_endIdx] = useState(5)

    useEffect(() => {
        let sensor = []
        set_idx(0)
        set_maxIdx(0)
        set_endIdx(5)
        if (props.length != 0) {

            props.preProcessed.map(val =>
                val.sensors.map(data =>{
                sensor.push({
                    alarmLowerLimit: data.tagRange.alarmLowerLimit,
                    alarmUpperLimit: data.tagRange.alarmUpperLimit,
                    name: val.name + ' > ' + data.name,
                    unit: val.unit
                })}
                )
            )
            set_sensors(sensor)
            let max = Math.floor(sensor.length / 10) + (sensor.length % 10 == 0 ? 0 : 1)
            set_maxIdx(max)
            if(max<5){
                set_endIdx(max)
            }

        }
    }, [props])

    const rightButton = () => {
        if (endIdx == idx+1) {
            set_startIdx(startIdx + 1)
            set_endIdx(endIdx + 1)
        }
        set_idx(idx + 1)

    }
    const leftButton = () => {
        if (idx == startIdx) {
            set_startIdx(startIdx - 1)
            set_endIdx(endIdx - 1)
        }
        set_idx(idx - 1)



    }

    const spreadButton = () => {
        var arr = []
        if (startIdx > 0) {
            arr.push(<Arrow style={{transform:'rotate(180deg)'}} src={button_arrow} onClick={leftButton} />)
        }

        for (let i = startIdx; i < endIdx; i++) {
            arr.push(<Button checked={i == idx} onClick={() => { set_idx(i) }}>{i + 1}</Button>)
        }
        if (endIdx < maxIdx) {
            arr.push(<Arrow src={button_arrow} onClick={rightButton} />)
        }
        return arr
    }

    const nameFunction=(str)=>{
        return str.split('>').map((val,idx)=>{
            if(idx==0){
                return <Span >{val} </Span>
            }
            return <> <span style={{margin:'0 0.3rem '}}/>><span style={{margin:' 0 0.3rem'}}/> {val}</>
        })
    }
    
    return (

        <>
            {props.preProcessed.length == 0 ||


                <Wrapper>
                    <Title>센서알림 설정 현황</Title>


                    <SensorWrapper>
                        <SensorItem style={{margin:'1.4rem 0'}}>
                            <Names>센서이름</Names>
                            <Setting>MIN</Setting>
                            <Setting><Blue>MAX</Blue></Setting>
                        </SensorItem>

                        {
                            sensors.slice(10 * idx, 10 * idx + 10).map((arr, idx) => {
                                return <SensorItem isODD={idx % 2 == 0}>
                                    <Names>{nameFunction(arr.name)}</Names>
                                    <Setting>{arr.alarmLowerLimit}{arr.unit}</Setting>
                                    <Setting><Blue>{arr.alarmUpperLimit}{arr.unit}</Blue></Setting>
                                </SensorItem>
                            })
                        }

                    </SensorWrapper>

                    <ButtonWrap>
                        {spreadButton()}

                    </ButtonWrap>
                </Wrapper>
            }
        </>
    );
}

export default Main;