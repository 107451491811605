import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class GatewayDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 1,
			list : []
		};
		nbind(this);
	}

	componentDidMount() {
		this.callData()
	}
	callData () {
		let obj = {
			id : this.props.match.params.id
		};

	
		this.props.setLoading(true)
		API.farmgatewayDetail(obj).then(result => {
			this.setState({
				list : result.content ,
				pageSize : result.totalPages
			})
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
		
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">프로토콜 종류 상세</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td><input type="text"/></td>
                                            
                                        </tr>
                                        <tr>
                                            <th>전화번호</th>
                                            <td><input type="text"/></td>
                                            <th>이메일</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>팩스번호</th>
                                            <td><input type="text"/></td>
                                            <th>우편번호</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>담당자</th>
                                            <td><input type="text"/></td>
                                            <th>담당자 번호</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>담당자 직급</th>
                                            <td><input type="text"/></td>
                                            <th></th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>주소</th>
                                            <td><input type="text"/></td>
                                            <td colSpan="2"><button className="btn ml20">주소 검색</button></td>
                                        </tr>
                                        <tr>
                                            <th>상세주소</th>
                                            <td>
                                                <input type="text" name="" id=""/>
                                            </td>
                                            <th>사용여부</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" placeholder="제목 + 내용">
                                                        <option value="">이름</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>

                                                </div>
                                            </td>
                                            
                                        </tr>
                                       
                                        <tr>
                                            <th>비고</th>
                                            <td colSpan="3">
                                                <input type="text"/>
                                            </td>
                                        
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button  className="btn mr5 mt10">수정</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default GatewayDetail;