export default {	
	"menu.stockExchange": "거래소",
	"menu.myInfo": "내 정보",
	"menu.myInfo.requestWithdrawal": "입출금 신청",
	"menu.myInfo.tradeResultList": "거래결과 내역확인",
	"menu.myInfo.profitList": "수익률 내역",
	"menu.myInfo.changeUserInfo": "회원 정보 수정",
	"menu.myInfo.account": "계정 정보관리",
	"menu.myInfo.userCertify": "회원인증",
	"menu.myInfo.invitedList": "추천인 목록",
	"menu.myInfo.invitedProfit": "추천 수익",
	"menu.customerService": "고객센터",
	"menu.customercommon.notice": "공지사항",
	"menu.customerService.event": "이벤트",
	"menu.customerService.guide": "이용방법",
	"menu.customerService.faq": "FAQ",
	"menu.customerService.enquiry": "1:1 문의",
	"menu.login": "로그인",
	"menu.join": "회원가입",

	"footer1": "상호: (주)케이엔소프트 | 사업자등록번호: 324-88-01617 | 대표자: 현경남",
	"footer2": "서울시 광진구 아차산로 375",
	"footer3": "COPYRIGHT (C) 2020 BITPANG ALL RIGHTS RESERVED",

	"main.stockExchangeShortcut": "거래소 바로가기",
	'main.noticeExplain': '차세대 트레이딩 플랫폼,\n비트팡의 공지사항을 안내해드립니다.',
	"main.guideExplain": "안전하고 편리한 비트팡을 경험해보세요.\n비트팡의 사용방법을 안내해드립니다.",
	"main.question": "문의사항",
	"main.questionExplain": "궁금하신 것은 언제든지 문의주세요.",
	"main.otimizedForChorme": "본 서비스는 Chrome에 최적화 되어있습니다.",

	"join.allAgree": "모두 동의",
	"join.termsAgree": "이용 약관 동의 [ 필수 ]",
	"join.privacyPolicyAgree": "개인정보 처리방침 동의 [ 필수 ]",
	"join.marketingAgree": "마케팅 정보 수신동의 [ 선택 ]",
	"join.recommender": "추천인",
	"join.recommenderPh": "추천인 코드 입력",
	"join.id": "ID (E-mail)",
	"join.idPh": "이메일 주소 입력",
	"join.duplicateCheck": "중복체크",
	"join.authenticationCode": "인증코드",
	"join.authenticationCodePh": "메일로 발송된 인증코드 6자리 입력",
	"join.sendCode": "코드발송",
	"join.codeCertify": "인증코드 확인",
	"join.namePh": "이름 입력",
	"join.pw": "비밀번호",
	"join.pwPh": "비밀번호 입력",
	"join.pwConfirm": "비밀번호 확인",
	"join.pwConfirmPh": "비밀번호 재입력",
	"join.pwPolicy": "영문+숫자+특수문자($@$!%*#?^&) 조합 9~20자를 입력해 주시기 바랍니다.​",
	"join.secondaryPw": "2차 비밀번호",
	"join.secondaryPwPh": "2차 비밀번호 입력",
	"join.secondaryPwConfirm": "2차 비밀번호 확인",
	"join.secondaryPwConfirmPh": "2차 비밀번호 재입력",
	"join.secondaryPwPolicy": "2차 비밀번호 는 숫자 6자리이며 서비스 이용중 출금 등 본인 확인이 필요할 때 사용됩니다.",
	"join.successJoin": "축하합니다. 회원가입이 완료되었습니다!",

	"login.saveId": "아이디 저장",
	"login.findId": "아이디 찾기",
	"login.findPw": "비밀번호 찾기",
	"login.findPw2": "2차 비밀번호 찾기",
	"login.nowPw": "현재 비밀번호",
	"login.nowPw2": "현재 2차 비밀번호",
	"login.changePw": "변경할 비밀번호",
	"login.changePw2": "변경할 2차 비밀번호",
	"login.changePwCheck": "변경할 비밀번호 확인",
	"login.changePw2Check": "변경할 2차 비밀번호 확인",
	"login.joinSuccess": "회원가입 완료",

	"myInfo.withdrawal": "입출금하기",
	"myInfo.deposit": "입금하기",
	"myInfo.withdraw": "출금하기",
	"myInfo.addBonus": "추가보너스",
	"myInfo.giveBonus": "지급보너스",
	"myInfo.saveMoney": "보유금액",
	"myInfo.possibleWithdrawAmount": "출금가능금액",
	"myInfo.depositWithdrawType": "입출금 구분",
	"myInfo.withdrawalTradeList": "입출금 거래내역",
	"myInfo.depositAddress": "입금 대상 계좌",
	"myInfo.depositName": "입금자 명",
	"myInfo.depositBank": "입금 은행",
	"myInfo.depositAmount": "입금 액",
	"myInfo.howDepositRequest": "입금 신청 방법",
	"myInfo.depositRequest": "입금 신청",
	"myInfo.depositCancel": "입금 취소",
	"myInfo.depositReject": "입금 반려",
	"myInfo.depositProcess": "입금 처리중",
	"myInfo.howDepositRequestDesKRW1": "1. [입금신청]버튼을 클릭하여 입금하실 금액을 설정하여 신청을 완료합니다.​",
	"myInfo.howDepositRequestDesKRW2": "2. 반드시 [입금자명]으로 [입금계좌]의 은행에서 [입금대상계좌]로 [입금 신청 금액]을 이체​",
	"myInfo.howDepositRequestDesKRW3": "3. 시스템 운영자의 확인 후, 자산내역에 반영됨​",
	"myInfo.howDepositRequestDesBTC1": "1. 고객 전용 Wallet 주소를 확인하시고 해당 주소로 입금하실 금액을 이체",
	"myInfo.howDepositRequestDesBTC2": "2. 시스템 운영자의 확인 후, 자산내역에 반영됨​",
	"myInfo.depositWarningBTC1": "1. 본인의 보유액을 다른 회원에게 양도 및 양수할 수 없습니다.",
	"myInfo.depositWarningBTC2": "2. 고객 ID, 고객명은 수정할 수 없습니다.​",
	"myInfo.depositWarningBTC3": "3. 고객의 과실에 대한 타 계좌로의 입금은 당사에서 책임지지 않습니다.입금 시, 꼭 지갑 주소를 정확히 확인해 주시기 바랍니다.​",
	"myInfo.depositWarningBTC4": "4. 입 / 출금 관련 문의는 평일 11: 00~19: 00 가능합니다(주말 및 공휴일 휴무)​",
	"myInfo.depositWarningBTC5": "5. 1 일 산정 기준은 매일 00: 00~23: 59(Local Time) 입니다.​",
	"myInfo.depositWarningKRW1": "1. 본인의 보유액을 다른 회원에게 양도 및 양수 할 수 없습니다.",
	"myInfo.depositWarningKRW2": "2. 고객님의 아이디 및 고객명은 수정할 수 없습니다.",
	"myInfo.depositWarningKRW3": "3. 고객의 과실에 대한 타 계좌로의 입금은 당사에서 책임지지 않습니다. 임금 시, 꼭 입금계좌 정보를 정확히 확인해 주시기 바랍니다.",
	"myInfo.depositWarningKRW4": "4. 입/출금 관련 문의는 평일 11: 00~19: 00 가능합니다(주말 및 공휴일 휴무)​.",
	"myInfo.depositWarningKRW5": "5. 1일 산정 기준은 매일 00: 00~23: 59(Local Time) 입니다.​",
	"myInfo.requestTime": "신청 시간",
	"myInfo.depositRequestTime": "입금 신청 시간",
	"myInfo.requestAmount": "신청 금액",
	"myInfo.depositRequestAmount": "입금 신청 금액",
	// "myInfo.withdrawalStatus.request": "요청",
	// "myInfo.withdrawalStatus.": "",
	// "myInfo.withdrawalStatus.": "",
	// "myInfo.withdrawalStatus.": "",
	// "myInfo.withdrawalStatus.": "",
	// "myInfo.withdrawalStatus.": "",
	"myInfo.depositChecking": "입금확인 중",
	"myInfo.depositSuccess": "입금완료",
	"myInfo.depositFail": "입금실패",
	"myInfo.withdrawRequest": "출금 신청",
	"myInfo.withdrawCancel": "출금 취소",
	"myInfo.withdrawReject": "출금 반려",
	"myInfo.withdrawProcess": "출금 처리중",
	"myInfo.withdrawRequestTime": "출금 신청 시간",
	"myInfo.withdrawStatus.request": "출금요청",
	"myInfo.withdrawStatus.confirm": "출금승인",
	"myInfo.withdrawStatus.reject": "출금거부",
	"myInfo.withdrawStatus.sending": "출금처리중",
	"myInfo.withdrawStatus.succeess": "출금완료",
	"myInfo.withdrawStatus.fail": "출금실패",
	"myInfo.addressKRW": "계좌",
	"myInfo.depositAgreeExplain": "입금 신청 방법과 주의사항을 모두 확인하였습니다.",
	"myInfo.withdrawAgreeExplain": "출금 신청 방법과 주의사항을 모두 확인하였습니다.",
	"myInfo.withdrawAccount": "출금 계좌",
	"myInfo.withdrawReg": "출금 계좌 등록",
	"myInfo.withdrawMod": "출금 계좌 수정",
	"myInfo.withdrawAddress": "출금 대상 계좌",
	"myInfo.withdrawRequestAmount": "출금 신청액",
	"myInfo.requestDateTime": "신청 일시",
	"myInfo.statusOrConfirmDt": "상태 및 승인일시",
	"myInfo.withdrawName": "예금주",
	"myInfo.price": "금액",
	"myInfo.targetWallet": "대상 지갑주소",
	"myInfo.withdrawalAddress": "대상 입출금 주소",
	"myInfo.confirmDateTime": "승인일시",
	"myInfo.startTime": "시작시간",
	"myInfo.categorize": "구분",
	"myInfo.status": "상태",
	"myInfo.request": "요청",
	"myInfo.cancel": "취소",
	"myInfo.confirm": "승인",
	"myInfo.reject": "반려",
	"myInfo.sending": "완료",
	"myInfo.success": "성공",
	"myInfo.fail": "요청",
	"myInfo.baseIndex": "기준지수",
	"myInfo.endDate": "종료시간",
	"myInfo.contractPrice": "거래대금",
	"myInfo.result": "결과",
	"myInfo.totalRegUser": "총 가입회원",
	"myInfo.totalInvitedFee": "누적 추천 수익",
	"myInfo.regDate": "가입일",
	"myInfo.userId": "회원 아이디",
	"myInfo.noInvitedList": "추천인 목록이 없습니다.",
	"myInfo.periodTotalInvitedFee": "기간별 총 누적 추천 수익",
	"myInfo.periodInvitedFee": "기간별 누적 추천 수익",
	"myInfo.rejectReason": "반려 사유",
	"myInfo.userOut": "회원 탈퇴",
	"myInfo.kycReset": "KYC 인증 초기화",
	"myInfo.phoneChange": "휴대전화 변경",
	"myInfo.phoneCertify": "휴대전화 인증",
	"myInfo.countryCode": "국가번호",
	"myInfo.phone": "휴대전화",
	"myInfo.phoneNum": "휴대전화 번호",
	"myInfo.codeCreate": "인증번호 발급",
	"myInfo.code": "인증번호",
	"myInfo.grossTransactionAmount": "총 거래금액",
	"myInfo.grossTransactionProfit": "총 거래수익",
	"myInfo.grossYield": "총 수익률",
	"myInfo.tradingDay": "거래일",
	"myInfo.dailyTurnover": "일별 거래액",
	"myInfo.dailyGainsAndLosses": "일별 손익액",
	"myInfo.dailyTransactionFee": "일별 거래 수수료",
	"myInfo.totalRateOfReturn": "총 수익률",
	"myInfo.myCode": "본인 추천인 코드",
	"myInfo.userName": "회원 이름",
	"myInfo.name": "이름",
	"myInfo.firstName": "이름",
	"myInfo.lastName": "성",
	"myInfo.birthday": "생년월일",
	"myInfo.depositWallet": "입금 계좌 정보",
	"myInfo.letsCertify": "인증하기",
	"myInfo.sendCode": "인증번호 받기",
	"myInfo.withdrawAddressInfo": "출금 계좌 정보",
	"myInfo.withdrawRegModExplain1": "출금을 위해서는 본인 명의 계좌의 등록이 필요합니다.",
	"myInfo.withdrawRegModExplain2": "본인 계좌가 아닐 경우 또는 가상 계좌나 2금융권 계좌일 경우 이용이 제한될 수 있습니다.",
	"myInfo.howWithdrawRequest": "출금 신청 방법",
	"myInfo.howWithdrawRequestDesBTC1": "1. \"출금 지갑 주소\"에 출금하실 대상 지갑 주소를 입력",
	"myInfo.howWithdrawRequestDesBTC2": "2. \"출금 신청액\"에 출금하실 금액 입력",
	"myInfo.howWithdrawRequestDesBTC3": "3. \"출금 신청\" 버튼 클릭​",
	"myInfo.howWithdrawRequestDesKRW1": "1. \"출금 대상 계좌\"에 출금하실 대상 계좌 번호를 입력",
	"myInfo.howWithdrawRequestDesKRW2": "2. \"출금 신청액\"에 출금하실 금액 입력",
	"myInfo.howWithdrawRequestDesKRW3": "3. \"출금 신청\" 버튼 클릭​",
	"myInfo.withdrawWarningBTC1": "1. 본인의 보유액을 다른 회원에게 양도 및 양수할 수 없습니다.",
	"myInfo.withdrawWarningBTC2": "2. 고객 ID, 고객명은 수정할 수 없으며, 출금을 원하시는 금액을 ‘출금 신청액’ 항목에 입력하시면 됩니다.​",
	"myInfo.withdrawWarningBTC3": "3. 고객의 과실에 대한 타 계좌로의 입금은 당사에서 책임지지 않습니다. 출금 시, 꼭 지갑 주소를 정확히 확인해 주시기 바랍니다.​",
	"myInfo.withdrawWarningBTC4": "4. 1일 출금 한도는 2BTC이며, 1회 출금 한도는 0.5 BTC 입니다.​",
	"myInfo.withdrawWarningBTC5": "5. 출금 시에는 출금 수수료가 발생합니다(0.001 BTC). 또한, 출금 신청금액이 2 BTC 이상일 시 고객센터로 연락주시기 바랍니다.​",
	"myInfo.withdrawWarningBTC6": "6. 입/출금 관련 문의는 평일 11:00~19:00 가능합니다(주말 및 공휴일 휴무)​",
	"myInfo.withdrawWarningBTC7": "7. 1일 산정 기준은 매일 00:00~23:59(Local Time)입니다.​",
	"myInfo.withdrawWarningKRW1": "1. 본인의 보유액을 다른 회원에게 양도 및 양수할 수 없습니다.",
	"myInfo.withdrawWarningKRW2": "2. 고객 ID, 고객명은 수정할 수 없으며, 출금을 원하시는 금액을 ‘출금 신청액’ 항목에 입력하시면 됩니다.​",
	"myInfo.withdrawWarningKRW3": "3. 고객의 과실에 대한 타 계좌로의 입금은 당사에서 책임지지 않습니다. 출금 시, 꼭 은행 및 계좌번호를 확인해 주시기 바랍니다.​​",
	"myInfo.withdrawWarningKRW4": "4. 1일 출금 한도는 {dayMax} 원이며, 1회 출금 한도는 {max} 원 입니다. 또한, 출금 처리는 신청자 급증 등의 사유로 최대 6시간이 소요됩니다.",
	"myInfo.withdrawWarningKRW5": "5. 최소 출금금액은 {min} 원이며, 출금 시에는 출금 수수료가 발생합니다(1회당 {fee} 원).",
	"myInfo.withdrawWarningKRW6": "6. 입/출금 관련 문의는 평일 11:00~19:00 가능합니다(주말 및 공휴일 휴무)​",
	"myInfo.withdrawWarningKRW7": "7. 1일 산정 기준은 매일 00:00~23:59(Local Time)입니다.​",
	"myInfo.possibleServiceAfterCertify": "모든 회원 인증 후, 사용할 수 있는 서비스입니다.",
	"myInfo.possibleServiceAfterAddressReg": "출금 계좌가 등록되지 않았습니다. 출금 계좌 등록 후, 다시 신청해 주시기 바랍니다.",
	"myInfo.changePw": "비밀번호 변경",
	"myInfo.changePw2": "2차 비밀번호 변경",
	"myInfo.kycCertify": "KYC 인증",
	"myInfo.certify": "인증",
	"myInfo.uncertify": "미인증",
	"myInfo.certifySuccess": "인증완료",
	"myInfo.audit": "심사중",
	"myInfo.unrequest": "미신청",
	"myInfo.reset": "초기화",
	"myInfo.country": "국적",
	"myInfo.gender": "성별",
	"myInfo.passportNum": "여권번호",
	"myInfo.passportImg": "여권사진",
	"myInfo.frontImg": "여권을 든 정면사진",
	"myInfo.certifyRequest": "인증신청",
	"myInfo.pictureInfo": "촬영안내",
	"myInfo.pictureExplain1": "1. 신분증에 성명, 촬영일을 메모해서 붙여주세요.​",
	"myInfo.pictureExplain2": "2. 신분증에 노출되는 주민등록번호 뒤 7자리는 가려주세요.​",
	"myInfo.pictureExplain3": "3. 신분증과 얼굴이 잘 보이도록 촬영해 주세요.​",
	"myInfo.warning": "주의사항",
	"myInfo.warningExplain1": "1. 신분증의 복사본 또는 화면 캡쳐본은 인정되지 않습니다.​",
	"myInfo.warningExplain2": "2. 촬영일을 기준으로 제출일 이전에 찍은 사진은 반려됩니다.​",
	"myInfo.warningExplain3": "3. 메모가 부탁되지 않았거나 주민등록번호가 모두 노출된 경우 반려됩니다.​",
	"myInfo.warningExplain4": "4. 제출된 사진은 비대면 실명확인 용도로만 사용되며, 시스템에 의해 확인 후 자동 파기됩니다.​",
	"myInfo.warningExplain5": "5. 이미지 파일은 jpg, png, jpeg 의 형식이며, 파일 사이즈는 최대 10mb 미만만 업로드 가능합니다.​",
	"myInfo.userOutWarning1": "탈퇴 후, 귀하는 귀하의 계정에 액세스 및 거래를 수행하거나 어떠한 Bit Pang 서비스도 이용할 수 없게 됩니다.",
	"myInfo.userOutWarning2": " 이 요청을 제출하기 전에 계정에서 사용 가능한 자금을 인출하는 것이 좋습니다.",
	"myInfo.userOutWarning3": "계약 및 관련 법에 따라 당사는 귀하의 개인 데이터 삭제를 진행할 수 있으며 동시에 당사 서비스를 귀하에게 제공할 수 없습니다.",

	"service.title": "제목",
	"service.content": "내용",
	"service.file": "첨부파일",
	"service.regDate": "작성시간",
	"service.viewCount": "조회수",
	"service.answerYn": "답변여부",
	"service.answerContent": "답변내용",
	"service.categorize": "분류",
	"service.commentChecking": "미답변",
	"service.commentSuccess": "답변완료",
	"service.fileFormExplain": "파일명, 지원형식은 jpg / png / jpeg 이며, 크기는 5 mb를 초과할 수 없습니다.",
	"service.enquiryCategorize1": "비트팡 거래소 관련",
	"service.enquiry.exchangeQ1": "Q. 매수, 매도란 무엇인가요?",
	"service.enquiry.exchangeA1": "A. 매수: \n\r시작가(n)에 n Point를 증가한 n 이상의 값에 도달했을 때, 실현(매도 실격) ​되는 것을 말합니다.​ 매도: 시작가(n) 에 n Point를 감소한 n 이하의 값에 도달했을 때, 실현(매수 실격)​ 되는 것을 말합니다.",
	"service.enquiry.exchangeQ2": "Q. 질문2",
	"service.enquiry.exchangeA2": "A. 답변2",
	"service.enquiry.exchangeQ3": "Q. 질문3",
	"service.enquiry.exchangeA3": "A. 답변3",
	"service.enquiry.exchangeQ4": "Q. 질문4",
	"service.enquiry.exchangeA4": "A. 답변4",
	"service.enquiry.exchangeQ5": "Q. 질문5",
	"service.enquiry.exchangeA5": "A. 답변5",
	"service.enquiry.exchangeQ6": "Q. 질문6",
	"service.enquiry.exchangeA6": "A. 답변6",
	"service.enquiryCategorize2": "입출금 관련",
	"service.enquiry.withdrawalQ1": "Q. 내 정보에 입력했던 계좌와 다른 계좌번호로 입출금",
	"service.enquiry.withdrawalA1": "A. 답변1",
	"service.enquiry.withdrawalQ2": "Q. 질문2",
	"service.enquiry.withdrawalA2": "A. 답변2",
	"service.enquiry.withdrawalQ3": "Q. 질문3",
	"service.enquiry.withdrawalA3": "A. 답변3",
	"service.enquiry.withdrawalQ4": "Q. 질문4",
	"service.enquiry.withdrawalA4": "A. 답변4",
	"service.enquiry.withdrawalQ5": "Q. 질문5",
	"service.enquiry.withdrawalA5": "A. 답변5",
	"service.enquiry.withdrawalQ6": "Q. 질문6",
	"service.enquiry.withdrawalA6": "A. 답변6",

	"main.todayDontView": "오늘 하루 안보기",

	"join.codeCheck": "코드확인",

	"login.pwFind": "비밀번호 찾기",
	"login.id": "ID(이메일)",
	"login.tempPwCreate": "임시 비밀번호 발급",
	"login.tempPw2Create": "임시 2차 비밀번호 발급",

	"message.title": "메시지함",
	"message.detail": "메시지 상세",
	"message.remove": "메시지 삭제",
	"message.selectedRemove": "선택삭제",
	"message.sendDateTime": "수신일시",
	"message.noMsg": "받은 메시지가 없습니다.",
	"message.doYouRemoveSelectedMsg": "선택한 메시지를 삭제하시겠습니까?",

	"msg.myInfoAfterLogin": "내 정보는 로그인 후 확인 가능합니다.",
	"msg.stockExchangeAfterLogin": "거래소 서비스 이용은 로그인 후 가능합니다",
	"msg.nowPwInput": "현재 비밀번호를 입력해 주세요.",
	"msg.nextPwInput": "변경할 비밀번호를 입력해 주세요.",
	"msg.nowPwAndNextPwSamePw": "현재 비밀번호와 변경할 비밀번호가 동일합니다.\n다르게 입력해 주세요.",
	"msg.nowPwAndNextPwSamePw2": "현재 2차 비밀번호와 변경할 2차 비밀번호가 동일합니다.\n다르게 입력해 주세요.",
	"msg.pwPolicy": "비밀번호는 9~20자 영문, 숫자, 특수문자를 사용해야 합니다.",
	"msg.pwNotMatch": "비밀번호가 일치하지 않습니다.",
	"msg.changePwNotMatch": "비밀번호가 일치하지 않습니다.",
	"msg.nowPw2Input": "현재 2차 비밀번호를 입력해 주세요.",
	"msg.nextPw2Input": "변경할 2차 비밀번호를 입력해 주세요.",
	"msg.pw2Policy": "2차 비밀번호는 6자리의 숫자를 사용해야 합니다.",
	"msg.inputPw2OnlyNum": "2차 비밀번호는 숫자만 입력 가능합니다.",
	"msg.pw2NotMatch": "2차 비밀번호가 일치하지 않습니다.",
	"msg.changePw2NotMatch": "변경할 2차 비밀번호가 일치하지 않습니다.",
	"msg.pwChangeSuccess": "비밀번호가 변경되었습니다.",
	"msg.nowPwNotMatch": "현재 비밀번호가 일치하지 않습니다.",
	"msg.kycResetSuccess": "KYC 인증이 초기화되었습니다.",
	"msg.kycResetFail": "KYC 인증 초기화가 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.alreadyCodeSend": "이미 인증번호가 전송됐습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.codeSendSuccess": "인증코드를 전송했습니다.",
	"msg.selectCountryCode": "국가번호를 선택해 주세요.",
	"msg.inputPhoneNum": "휴대전화 번호를 입력해 주세요.",
	"msg.inputPhoneNumOnlyNum": "휴대전화 번호는 숫자만 입력해 주세요.",
	"msg.inputCode": "인증번호를 입력해 주세요.",
	"msg.phoneCertifySuccess": "휴대전화 인증이 완료되었습니다.",
	"msg.phoneChangeSuccess": "휴대전화 변경이 완료되었습니다.",
	"msg.codeNotMatch": "인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.",
	"msg.userOutSuccess": "회원 탈퇴가 완료되었습니다.",
	"msg.userOutFail": "회원 탈퇴에 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.selectDepositBank": "입금은행을 선택해 주세요.",
	"msg.inputDepositAmount": "입금 신청 금액을 입력해 주세요.",
	"msg.inputDepositAmountMinAmount": "입금 신청 금액은 최소 1,000 원 이상 입력 가능합니다.",
	"msg.checkCheckBox": "체크박스에 체크 해주세요.",
	"msg.depositRequestSuccess": "입금신청이 완료되었습니다.",
	"msg.depositRequestFail": "입금신청이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.errorCode": "인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.",
	"msg.pw2Wrong": "2차 비밀번호가 일치하지 않습니다.",
	"msg.messageDeleteSuccess": "메시지가 삭제되었습니다.",
	"msg.messageDeleteFail": "메시지를 삭제하는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.idCheckFail": "아이디 중복체크에 실패했습니다.\n잠시 후, 다시 시도해주십시오.",
	"msg.idCheckExistFail": "이미 사용중인 아이디입니다.",
	"msg.codeSendFail": "인증코드를 전송하는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.codeCheckSuccess": "유효한 인증코드입니다.",
	"msg.codeCheckFail": "유효하지 않은 인증코드입니다.\n확인 후, 다시 시도해 주십시오.",
	"msg.doYouResetKyc": "KYC 인증을 초기화 하시겠습니까?",
	"msg.doYouOutUser1": "주의: 회원을 탈퇴하시면 보유하신 모든 자산은 삭제됩니다.\n회원 탈퇴를 계속 하시겠습니까?",
	"msg.doYouOutUser2": "주의: 회원의 계정과 모든 개인 데이터를 삭제합니다. 계속 하시겠습니까?",
	"msg.imgUploadWarning": "이미지 파일은 jpg, png, jpeg의 형식이며, 파일 사이즈는 최대 10MB 미만만 업로드 가능합니다.",
	"msg.inputName": "이름을 입력해 주세요.",
	"msg.firstNameOnlyKo": "이름은 한글만 입력 가능합니다.",
	"msg.lastNameOnlyKo": "성은 한글만 입력 가능합니다.",
	"msg.nameOnlyKoOrEn": "이름은 한글 또는 영문만 입력 가능합니다.",
	"msg.inputFirstName": "이름을 입력해 주세요.",
	"msg.inputLastName": "성을 입력해 주세요.",
	"msg.selectCountry": "국적을 선택해 주세요.",
	"msg.selectYear": "년도를 선택해 주세요.",
	"msg.selectMonth": "월을 선택해 주세요.",
	"msg.selectDay": "일을 선택해 주세요.",
	// "msg.inputBirthday": "생년월일을 입력해 주세요.",
	// "msg.inputBirthday2": "생년월일(YYMMDD)을 입력해 주세요.",
	// "msg.inputBirthdayOnlyNum": "생년월일은 숫자만 입력해야 합니다.",
	// "msg.inputBirthdayFormat": "생년월일 형식에 맞게 입력해야 합니다.",
	"msg.selectGender": "성별을 선택해 주세요.",
	"msg.inputPassportNum": "여권번호 입력해 주세요.",
	"msg.inputPassportNumOnlyNum": "여권번호는 숫자만 입력해야 합니다.",
	"msg.selectPassportImg": "여권사진을 선택해 주세요.",
	"msg.selectPhotoImg": "신분증 사진을 선택해 주세요.",
	"msg.selectFrontImg": "신분증을 든 정면사진을 선택해 주세요.",
	"msg.kycRequestSuccess": "KYC 인증을 신청했습니다.",
	"msg.kycRequestFail": "KYC 인증 신청에 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.doYouRegThisQuestion": "해당 문의사항을 등록하시겠습니까?",
	"msg.regSuccess": "등록되었습니다",
	"msg.servicePreparing": "서비스 준비중입니다.",
	"msg.inputInvitedCode": "추천인 코드를 입력해 주세요.",
	"msg.invitedCodeCheckSuccess": "유효한 추천인 코드입니다.",
	"msg.invitedCodeCheckFail": "유효하지 않은 추천인 코드입니다.",
	"msg.inputId": "아이디를 입력해 주세요.",
	"msg.notMatchIdForm": "아이디가 이메일 형식에 맞지 않습니다.",
	"msg.idCheckSuccess": "사용 가능한 아이디입니다.",
	"msg.idNeedCheck": "아이디 중복체크가 되지 않았습니다.",
	"msg.codeSendAndInfoCheck": "인증번호를 발송했습니다.\n인증번호가 오지 않으면 입력하신 정보가 회원정보와 일치하는지 확인해 주세요.",
	"msg.inputPw": "비밀번호를 입력해 주세요.",
	"msg.inputPw2": "2차 비밀번호를 입력해 주세요.",
	"msg.invitedCodeNeedCheck": "추천인 코드체크를 해주십시오.",
	"msg.essentialValueNeedCheck": "필수값이 체크되지 않았습니다.\n체크한 후 다시 시도해 주십시오.",
	"msg.alreadyIdExist": "이미 존재하는 아이디입니다.\n변경후, 다시 시도해 주십시오.",
	"msg.userJoinFailBecauseDupPhoneAccount": "비트팡은 본인 명의의 핸드폰 당 회원계정 한개만 등록이 가능합니다.",
	"msg.userJoinFail": "회원가입이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.joinEmailSend": "사용 가능한 메일주소이며 인증코드를 발송했습니다.",
	"msg.dormantAccount": "휴면 계정입니다.",
	"msg.loginPwWrong": "비밀번호가 일치하지 않습니다.\n5번 실패시 로그인이 제한됩니다.\n(로그인 실패 횟수: {count}회)",
	"msg.loginFail": "로그인이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.loginLock": "로그인 제한중입니다.\n로그인 가능까지 {sec}초 남았습니다.",
	"msg.emailSendFail": "이메일 발송에 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.tempPwSendSuccess": "임시 비밀번호가 발급됐습니다.",
	"msg.tempPw2SendSuccess": "임시 2차 비밀번호가 발급됐습니다.",
	"msg.idSaveWarning": "개인 정보를 위해 개인 PC에서만 사용하세요.",
	"msg.notJoinUser": "존재하지 않는 아이디입니다.\n재확인 후, 입력해 주시기 바랍니다.",
	"msg.tempPwSendFail": "임시 비밀번호가 발급이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.tempPw2SendFail": "임시 2차 비밀번호가 발급이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.youNeedLogin": "로그인 후 이용이 가능한 서비스입니다.",
	"msg.logoutSuccess": "로그아웃 되었습니다.",
	"msg.notLogin": "로그인 상태가 아닙니다.",
	"msg.logoutFail": "로그아웃이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.doYouLogout": "로그아웃하시겠습니까?",
	"msg.serviceLoadFail": "서비스를 불러오는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.dataLoadFail": "데이터를 불러오는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.withdrawAfterPhoneKycCertify": "출금은 휴대전화와 KYC 인증을 한 후, 이용할 수 있습니다.",
	"msg.selectWithdrawAddressKRW": "출금 대상 계좌를 선택해 주세요.",
	"msg.inputWithdrawAddressKRW": "출금 대상 계좌를 입력해 주세요.",
	"msg.inputOnlyNumWithdrawAddressKRW": "출금 대상 계좌는 숫자만 입력 가능합니다.",
	"msg.inputWithdrawNameKRW": "예금주를 입력해 주세요.",
	"msg.inputWithdrawAddressBTC": "출금 지갑 주소를 입력해 주세요.",
	"msg.inputWithdrawAmount": "출금 신청액을 입력해 주세요.",
	"msg.inputWithdrawAmountMinAmount": "출금 신청 금액은 최소 1,000 원 이상 입력 가능합니다.",

	"msg.errorMaxDayAmountKRW": "출금 신청액이 1일 출금 최대 금액을 초과했습니다.\n(1일 출금 최대 금액: {max} 원, 오늘 누적 출금 신청금액: {today} 원)",
	"msg.errorMaxOnceAmountKRW": "출금 신청액이 1회 출금 최대 금액을 초과했습니다.\n(1회 출금 최대 금액: {max} 원)",
	"msg.errorTotalAmountKRW": "총 출금 신청액(출금 신청액+수수료)이 보유금액을 초과했습니다.\n(수수료: {fee} 원)",
	"msg.errorMinOnceAmountKRW": "출금 신청액이 1회 출금 최소 금액을 초과했습니다.\n(1회 출금 최소 금액: {min} 원)",

	"msg.errorMaxDayAmountBTC": "출금 신청액이 1일 출금 최대 금액을 초과했습니다.\n(1일 출금 최대 금액: {max} BTC, 오늘 누적 출금 신청금액: {today} BTC)",
	"msg.errorMaxOnceAmountBTC": "출금 신청액이 1회 출금 최대 금액을 초과했습니다.\n(1회 출금 최대 금액: {max} BTC)",
	"msg.errorTotalAmountBTC": "총 출금 신청액(출금 신청액+수수료)이 보유금액을 초과했습니다.\n(수수료: {fee}) BTC",
	"msg.errorMinOnceAmountBTC": "출금 신청액이 1회 출금 최소 금액을 초과했습니다.\n(1회 출금 최소 금액: {min} BTC)",
	
	"msg.inputWithdrawCode": "인증번호를 입력해 주세요.",
	"msg.withdrawRequestSuccess": "출금 신청이 완료되었습니다.",
	"msg.alreadyDepositRequest": "아직 처리되지 않은 입금 신청건이 존재합니다.\n처리된 후, 다시 시도해 주십시오.",
	"msg.alreadyWithdrawRequest": "아직 처리되지 않은 출금 신청건이 존재합니다.\n처리된 후, 다시 시도해 주십시오.",
	"msg.withdrawRequestFail": "출금 신청이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.notEnoughMoney": "보유 자산이 부족합니다.",
	"msg.selectCategorize": "분류를 선택해 주세요.",
	"msg.inputTitle": "제목을 입력해 주세요.",
	"msg.inputContent": "내용을 입력해 주세요.",
	"msg.selectWithdrawAddress": "출금 계좌를 선택해 주세요.",
	"msg.inputWithdrawAddress": "출금 계좌를 입력해 주세요.",
	"msg.inputWithdrawAddressOnlyNumber": "출금 계좌는 숫자만 입력 가능합니다.",
	"msg.withdrawRegSuccess": "출금 계좌가 등록됐습니다.",
	"msg.withdrawRegFail": "출금 계좌 등록이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.withdrawModSuccess": "출금 계좌가 변경됐습니다.",
	"msg.withdrawModFail": "출금 계좌 변경이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.Pw2Wrong": "2차 비밀번호가 일치하지 않습니다.",
	"msg.selectedMsgDontExist": "선택된 메시지가 없습니다.",
	"msg.priceUnit" : '{amount}원 단위만 가능합니다.',
	"msg.minPriceUnit" : '{amount}원 이상부터 거래 가능합니다.',
	"msg.recaptcha":'reCAPTCHA를 체크해주세요.',
	"msg.certifyInfoAndInputInfoNotMatch":'인증하신 정보와 입력하신 정보가 일치하지 않습니다.',
	"msg.doYouCancelThisDepositRequest":'해당 입금 신청 건을 취소하시겠습니까?',
	"msg.doYouCancelThisWithdrawRequest":'해당 출금 신청 건을 취소하시겠습니까?',
	"msg.thisDepositRequestCancleSuccess": "해당 입금 신청 건이 취소됐습니다.",
	"msg.thisWithdrawRequestCancleSuccess": "해당 출금 신청 건이 취소됐습니다.",
	"msg.alreadyThisDepositRequestProcess": "이미 처리된 입금 신청 건입니다.",
	"msg.alreadyThisWithdrawRequestProcess": "이미 처리된 출금 신청 건입니다.",
	"msg.thisDepositRequestWrong": "존재하지 않는 입금 신청 건입니다.",
	"msg.thisWithdrawRequestWrong": "존재하지 않는 출금 신청 건입니다.",
	"msg.thisDepositRequestCancleFail": "해당 입금 신청 건을 취소하는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	"msg.thisWithdrawRequestCancleFail": "해당 출금 신청 건을 취소하는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.",
	
	"common.all": "전체",
	"common.confirm": "확인",
	"common.cancel": "취소",
	"common.login": "로그인",
	"common.logout": "로그아웃",
	"common.view": "보기",
	"common.search": "검색",
	"common.find": "찾기",
	"common.enterKeyword": "검색어 입력",
	"common.list": "목록",
	"common.title": "제목",
	"common.register": "등록하기",
	"common.shortRegister": "등록",
	"common.change": "변경",
	"common.close": "닫기",
	"common.remove": "삭제",
	"common.fee": "수수료",
	"common.call": "Call",
	"common.push": "Push",
	"common.deposit": "입금",
	"common.withdraw": "출금",
	"common.callSuccess": "Call 실현",
	"common.pushSuccess": "Push 실현",
	"common.callFail": "Call 실격",
	"common.pushFail": "Push 실격",
	"common.proceeds": "수익금",
	"common.time": "시간",
	"common.number": "No.",
	"common.warning": "경고",
	"common.certify": "인증",
	"common.no": "No.",
	"common.terms": "이용약관",
	"common.privacy": "개인정보취급방침",
	"common.none": "없음",
	"common.noneSelect": "- 선택 -",
	"common.guideDetail": "이용 약관 상세보기",
	"common.privacyDetail": "개인정보 처리방침 상세보기",
	"common.marketingDetail": "마케팅 정보 상세보기",
	"common.handlingTime": "처리 시간",
	"common.month1": "1월",
	"common.month2": "2월",
	"common.month3": "3월",
	"common.month4": "4월",
	"common.month5": "5월",
	"common.month6": "6월",
	"common.month7": "7월",
	"common.month8": "8월",
	"common.month9": "9월",
	"common.month10": "10월",
	"common.month11": "11월",
	"common.month12": "12월",
	"common.day1": "1일",
	"common.day2": "2일",
	"common.day3": "3일",
	"common.day4": "4일",
	"common.day5": "5일",
	"common.day6": "6일",
	"common.day7": "7일",
	"common.day8": "8일",
	"common.day9": "9일",
	"common.day10": "10일",
	"common.day11": "11일",
	"common.day12": "12일",
	"common.day13": "13일",
	"common.day14": "14일",
	"common.day15": "15일",
	"common.day16": "16일",
	"common.day17": "17일",
	"common.day18": "18일",
	"common.day19": "19일",
	"common.day20": "20일",
	"common.day21": "21일",
	"common.day22": "22일",
	"common.day23": "23일",
	"common.day24": "24일",
	"common.day25": "25일",
	"common.day26": "26일",
	"common.day27": "27일",
	"common.day28": "28일",
	"common.day29": "29일",
	"common.day30": "30일",
	"common.day31": "31일",

	"select.searchType.title": "제목",
	"select.searchType.content": "내용",
	"select.searchType.titleAndContent": "제목+내용",
	"select.gender.male": "남성",
	"select.gender.female": "여성",
	"select.enquiryCategory1": "비트팡 거래소 관련",
	"select.enquiryCategory2": "입출금 관련",
	"select.enquiryCategory3": "거래 및 정산 관련",
	"select.enquiryCategory4": "게시판 관련",
	"select.enquiryCategory5": "회원 등록 및 수정, 탈퇴, 기타",
	"select.enquiryCategory6": "추천인 관련",
	"select.enquiryCategory7": "KYC 및 AML 인증 관련",

	"table.none.no": "내역이 없습니다.",
	"table.none.noMatch": "조건과 일치하는 리스트가 없습니다.",

	"pager.first": "처음",
	"pager.prev": "이전",
	"pager.next": "다음",
	"pager.last": "마지막",

	"selLang.ko": "ko",
	"selLang.en": "en",

	"select.noneYear": "- 년도 -",
	"select.noneMonth": "- 월 -",
	"select.noneDay": "- 일 -",

}
