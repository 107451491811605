import React, { useCallback, useEffect, useState } from 'react';
import { API } from 'service';
import Pager from 'components/layout/include/pager';
import SearchBox from '../../components/common/searchBox';
import { reverseIndexing } from 'js/common';
const CompanyMember = ({history}) => {
    let defaultState = {
        content : []
    }

    const [companyMember, setCompanyMember] = useState(defaultState);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState();
    const [pageListSize, setPageListSize] = useState(10);
    const [request, setRequest] = useState();
    const [selected, setSelected] = useState();
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        let obj = {
            params : {
                size : pageListSize,
                page: Number(pageIndex) -1
            }
        };
        setRequest(obj);
        callData(obj);
    }, []);

    const onChangePage = useCallback(value => {
        setPageIndex(value);
        let obj = request;
        obj.params.page = Number(value) - 1;
        setRequest(obj);
        callData(obj);
    });

    const onSearchChange = useCallback((i, value) => {
        setPageIndex(i);
        setRequest(value);
        callData(value);
    });

	const callData = value => {
        API.farmUserList(value).then(result => {
            setCompanyMember(result);
            setPageSize(result.totalPages);
        }).catch(error => {
            console.log(error);
        });
	}

    const handleCheckboxChange = e => {
        if(e.target.checked){
            setIsChecked(true);
            setSelected(e.target.value);
        } else {
            setIsChecked(false);
        }
    }

    const handleDeleteUser = () => {
        let obj = {
            memberIds : [selected]
        }
        API.companyMemberDelete(obj).then(result => {
            console.log(result);
            callData(request);
        }).catch(error => {
            console.log(error);
        });
    }

    const searchContent = [
        {
            name : "등급",
            classCode : 'roleCode',
            type : 'SELECT',
            searchOptions : [
                {
                    name : "농장주",
                    value : "FARMER"
                },
                {
                    name : "컨설턴트",
                    value : "CONSULTANT"
                },
            ]
        },
        {
            name : "메세지 전송여부",
            classCode : 'pushYn',
            type : 'SELECT',
            searchOptions : [
                {
                    name : "Y",
                    value : "Y"
                },
                {
                    name : "N",
                    value : "N"
                },
            ]
        },
        {
            name : "이름",
            classCode : 'name',
            type : 'TEXT'
        }
    ]

    return (
        <React.Fragment>
            <div className="contentInner">
                <div className="contentArea">
                    <h2 className="pageTitle textCenter">농장 사용자</h2>
                    <div>
                        <SearchBox 
                            searchContent = {searchContent}
                            onSearchChange = {onSearchChange }
                            pageIndex = {pageIndex}
                            pageSize = {pageSize}
                            pageListSize = {pageListSize}
                        />
                        <div className="tableBox mt30">
                            <table>
                                <colgroup>
                                    <col width="50" />
                                    <col width="50" />
                                    <col width="300" />
                                    <col width="150" />
                                    <col width="250" />
                                    <col width="150" />
                                    <col width="150" />
                                    <col width="150" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>번호</th>
                                        <th>ID</th>
                                        <th>이름</th>
                                        <th>연락처</th>
                                        <th>E-Mail</th>
                                        <th>메세지 전송 여부</th>
                                        <th>등급</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        companyMember.content.length === 0 ?
                                        <tr style={{ cursor: 'default' }}>
                                            <td colSpan='100' className="textCenter">등록된 유져가 존재하지 않습니다</td>
                                        </tr>
                                        : companyMember.content.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className="textCenter">
                                                        <div className="checkBox">
                                                            <input type="checkbox" name="type" id={'t' + item.id} value={item.id} onChange={handleCheckboxChange}/>
                                                            <label htmlFor={'t' + item.id}></label>
                                                        </div>
                                                    </td>
                                                    <td className="textCenter">{reverseIndexing(companyMember.totalElements, pageIndex,index, pageListSize)}</td>
                                                    <td className="textCenter"><a href="javascript:void(0);" onClick={() => { history.push({ pathname: `/company/member/detail/${item.id}`, state : { memberId : item.id} }) }}>{item.userId}</a></td>
                                                    <td className="textCenter">{item.name}</td>
                                                    <td className="textCenter">{item.mobilePhoneNumber}</td>
                                                    <td className="textCenter">{item.email}</td>
                                                    <td className="textCenter">{item.pushYn}</td>
                                                    <td className="textCenter">{item.roleCode}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="textRight">
                                <button className="btn mr10 mt10" onClick={() => {
                                    !isChecked ? alert("삭제할 유져를 선택해주세요") : handleDeleteUser()
                                }}>삭제</button>
                            </div>
                            <Pager pageIndex={pageIndex} pageSize={pageSize} onChangePage={onChangePage } />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CompanyMember;