import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import thermometer from "img/icons_item/temperature.png";
import co2 from "img/icons_item/co2.png";
import critical from "img/icons_item/critical.png";
import drop from "img/icons_item/drop.png";
import question from "img/icons_item/question.png";
import common_sensor from "img/icons_item/sensor.png";

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(125, 140, 154, 0.3);
  border: 0.1rem solid rgba(125, 140, 154, 0.2);
  border-radius: 5px;
  width: ${(props) => (props.long ? "calc( 11.3% + 2.8rem)" : "11.3%")};
  width: 11.8%;

  height: 0px;
  ${(props) => {
    if (props.isExcess) {
      return `
        border: 1px solid #E56F6F;
        background:#fffff;
        & div{
            color: #E56F6F;
        }`;
    } else if (props.isConnectionError) {
      return `
                border: 1px solid #858585;
                background:#F5F5F5;
                & div{
                      color: #858585;
                    }`;
    }
  }}

  border-top:none;

  margin: 0px 4px;
  padding-bottom: 9%;
  margin-bottom: 23px;
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5px;
`;
const Img = styled.img`
  width: 20px;
  height: 20px;
`;

const Title = styled.div`
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-size: 1.6rem;
  font-weight: 500;
  /* white-space:nowrap ; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */

  color: #2a3a4b;
  margin-left: 0.3rem;
`;

const Text = styled.div`
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 38px;

  text-align: center;
  color: #2a3a4b;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
`;
const CelsiusDegree = styled.div`
  display: flex;
  align-items: ${(props) => (props.unit == "℃" ? "flex-start" : "flex-end")};

  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  color: #2a3a4b;
  padding: 0.8rem 0.5rem;
`;
const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;
  ${(props) =>
    props.long &&
    css`
      padding: 0 1.4rem;
    `}
`;
const Wrap = styled.div`
  display: flex;
`;
const TopBar = styled.div`
  width: 100%;
  height: 1.8rem;
  background-color: #17b9b6;
  border-radius: 5px 5px 0px 0px;

  ${(props) => {
    if (props.isExcess) {
      return `
        background-color: #E56F6F;
        }`;
    } else if (props.isConnectionError) {
      return `
               
                    background-color: #858585;
`;
    }
  }}
`;
function Main(props) {
  const [isExcess, set_isExcess] = useState(false);
  const [isConnectionError, set_isConnectionError] = useState(false);

  const [name, set_name] = useState("");
  const [value, set_value] = useState("0");
  const [unit, set_unit] = useState("");
  const [code, setCode] = useState("TEMP");

  useEffect(() => {
    if (props != []) {
      let sensor = props.data;

      set_name(sensor.name);
      set_value(sensor.value);
      set_unit(sensor.tagClass.unit);
      set_isExcess(props.data.isExcess);
      set_isConnectionError(props.data.set_isConnectionError);
      setCode(props.data.tagClass.code);
      if (
        sensor.value > sensor.tagRange?.alarmUpperLimit ||
        sensor.value < sensor.tagRange?.alarmLowerLimit
      ) {
        set_isExcess(true);
        // props.add_warning()
      }
      if (sensor.normalYn == "N") {
        set_isConnectionError(true);
        // props.add_connectionError()
      }
    }
  }, [props]);
  const ImageSrc = () => {
    if (code === undefined) {
      return question;
    }

    let src = thermometer;
    if (isExcess) {
      src = critical;
    } else if (isConnectionError) {
      src = question;
    } else if (code.includes("TEMP")) {
      src = thermometer;
    } else if (code.includes("HUM")) {
      src = drop;
    } else if (code.includes("CO2")) {
      src = co2;
    } else {
      src = common_sensor;
    }
    return src;
  };

  const convertSnsrValue = (snsr) => {
    if (typeof snsr == "number" && !Number.isInteger(snsr)) {
      snsr = Number(snsr.toFixed(1));

      return snsr;
    } else if (snsr === 0) {
      return snsr;
    } else {
      return snsr;
    }

    // return Math.floor(snsr);
  };

  return (
    <Wrapper
      long={name.length > 5}
      isExcess={isExcess}
      isConnectionError={isConnectionError}
    >
      <TopBar isExcess={isExcess} isConnectionError={isConnectionError} />
      <Top>
        <TitleWrap>
          <Img src={ImageSrc()} />
          <Title>{name}</Title>
        </TitleWrap>
      </Top>

      <Top style={{ paddingBottom: "1.8rem" }}>
        <Wrap>
          <Text>{convertSnsrValue(value)}</Text>
          <CelsiusDegree unit={unit}> {unit}</CelsiusDegree>
        </Wrap>
      </Top>
    </Wrapper>
  );
}

export default Main;
