import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class GatewayDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 1,
			list : []
		};
		nbind(this);
	}

	componentDidMount() {
		this.callData()
	}
	callData () {
		let obj = {
			id : this.props.match.params.id
		};

	
		this.props.setLoading(true)
		API.farmgatewayDetail(obj).then(result => {
			this.setState({
				list : result.content ,
				pageSize : result.totalPages
			})
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
		
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">CCTV 등록</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td><input type="text"/></td>
                                            <th>종류</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>URL</th>
                                            <td><input type="text"/></td>
                                            <th>비밀번호</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>IP</th>
                                            <td><input type="text"/></td>
                                            <th>CH</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>VPORT</th>
                                            <td><input type="text"/></td>
                                            <th>WPORT</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>농장</th>
                                            <td>
                                                <div className="selectBox w100">
                                                    <select name="" id="" placeholder="제목 + 내용">
                                                        <option value="">농장</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>

                                                </div>
                                            </td>
                                            <th>시설동</th>
                                            <td>
                                                <div className="selectBox w100">
                                                    <select name="" id="" placeholder="제목 + 내용">
                                                        <option value="">시설동</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제어 가능 여부</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" placeholder="제목 + 내용">
                                                        <option value="">이름</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>

                                                </div>
                                            </td>
                                            <th>제어_URL</th>
                                            <td>
                                                <input type="text" name="" id=""/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>설치날짜</th>
                                            <td>
                                                <DatePicker
                                                    className="dfhadjklfhls"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={this.state.searchStartDate}
                                                    maxDate={this.state.searchendDate}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { if (date === null) { date = this.state.searchEndDate; } this.setState({ searchStartDate: new Date(date) }) }}
                                                />
                                            </td>
                                            <th>설치비용</th>
                                            <td>
                                                <div className="selectBox">
                                                   <input type="text"/>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>무상수리 만료일</th>
                                            <td>
                                                <DatePicker
                                                    className="dfhadjklfhls"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={this.state.searchStartDate}
                                                    maxDate={this.state.searchendDate}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { if (date === null) { date = this.state.searchEndDate; } this.setState({ searchStartDate: new Date(date) }) }}
                                                />
                                            </td>
                                            <th>담당자</th>
                                            <td>
                                                <div className="selectBox">
                                                   <input type="text"/>
                                                </div>
                                            </td>  
                                        </tr>
                                        <tr>
                                            <th>사용여부</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" placeholder="제목 + 내용">
                                                        <option value="">이름</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>

                                                </div>
                                            </td>
                                            <th>담당자 연락처</th>
                                            <td>
                                                <input type="text"/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비고</th>
                                            <td colSpan="3">
                                                <input type="text"/>
                                            </td>
                                        
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button  className="btn mr5 mt10">저장</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default GatewayDetail;