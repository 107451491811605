import { injectIntl } from 'react-intl'
import { connect } from 'react-redux';
import * as actions from 'reducers/actions';
// Layout1 =========
// 메인
import Main from 'pages';
import MainUser from 'pages/indexUser';
import LookUp from 'pages/LookUp';
import Login from 'pages/Login';
import ReplaceUserPw from 'pages/findPw';
import FindUserId from 'pages/findUserId';

import Control from 'pages/control';

import Data from 'pages/data';
import Data2 from 'pages/data/index2';
import Data3 from 'pages/data/index3';



import Gateway from 'pages/gateway';
import GatewayRegister from 'pages/gateway/gatewayRegister';
import GatewayDetail from 'pages/gateway/detail';


import Devicetag from 'pages/gateway/devicetag';

import DeviceDetail from 'pages/gateway/deviceDetail';
import DeviceRegister from 'pages/gateway/deviceRegister';
import TagDetail from 'pages/gateway/tagDetail';
import TagRegister from 'pages/gateway/tagRegister';
import Logic from 'pages/gateway/logic';

import Farm from 'pages/farm';
import FarmManagement from 'pages/farmManagement'
import FarmDetail from 'pages/farm/farmDetail';
import FarmRegister from 'pages/farm/farmRegister';
import FarmInfo from 'pages/farm/farmInfo';
import FarmContainerDetail from 'pages/farm/containerDetail';
import FarmContainerRegister from 'pages/farm/containerRegister';
import FarmUserDetail from 'pages/farm/userDetail';
import FarmUserRegister from 'pages/farm/userRegister';

import Product from 'pages/farm/product';
import ProductRegister from 'pages/farm/productRegister';
import ProductDetail from 'pages/farm/productDetail';




import Company from 'pages/company';
import CompanyDetail from 'pages/company/detail';
import CompanyRegister from 'pages/company/register';
import CompanyMember from 'pages/farm/companyMember';
import companyMemberDetail from 'pages/farm/companyMemberDetail';
import CompanyMemberAlwns from 'pages/farm/companyMemberAlwnsRegister';

import User from 'pages/user';
import UserDetail from 'pages/user/detail';
import UserRegister from 'pages/user/register';

import Protocol from 'pages/protocol';
import ProtocolDetail from 'pages/protocol/detail';
import ProtocolRegister from 'pages/protocol/register';

import Cctv from 'pages/cctv';
import CctvDetail from 'pages/cctv/detail';
import CctvRegister from 'pages/cctv/register';


import historyControl from 'pages/history/control'; // 제어이력
import historyControlDetail from 'pages/history/controlDetail'; // 제어이력
import eventControl from 'pages/history/event'; // 이벤트이력
import userControl from 'pages/history/user'; // 사용자 접속이력


import Notice from 'pages/board/notice';
import NoticeRegister from 'pages/board/noticeRegister';
import NoticeDetail from 'pages/board/noticeDetail';
import Qna from 'pages/board/qna';
import QnaRegister from 'pages/board/qnaRegister';
import QnaDetail from 'pages/board/qnaDetail';
import Faq from 'pages/board/faq';
import FaqRegister from 'pages/board/faqRegister';
import FaqDetail from 'pages/board/faqDetail';

import Join from 'pages/Join';

import Profile from 'pages/mypages/Profile';
import Another from 'pages/mypages/Another';

// Layout2 =======
import NotFound from 'pages/404';

import popUp from 'pages/popUp';

// Layout3 =======
// 거래소
// import Chart2 from 'pages/chart2';


// function intlConnect(component){
// 	return (
// 		injectIntl(connect(
// 			state => ({
// 				user: state.user,
// 				common: state.common,
// 			})
// 		)(component))
// 	)
// }
function intlConnectDispatch(component) {
	return (
		injectIntl(connect(
			state => ({
				user: state.user,
				common: state.common,
			}),
			dispatch => ({
				setLoading:(data) => {
					dispatch(actions.SET_LOADING(data))
				},
				handleUserInfo: (data) => {
					dispatch(actions.SET_USER_INFO(data))
				},
				handleAlert: (data) => {
					dispatch(actions.SET_ALERT(data))
				},
				handleConfirm: (data) => {
					dispatch(actions.SET_CONFIRM(data))
				},
			})
		)(component))
	)
}

export const routes = [
	{
		path: "/",
		name : 'd',
		components: intlConnectDispatch(Main),
	},
	{
		path: "/mainUser",
		components: intlConnectDispatch(MainUser),
	},
	{
		path: "/lookUp",
		components: intlConnectDispatch(LookUp),
	},
	{
		path: "/notification",
		components: intlConnectDispatch(FarmManagement),
	},
	
	// {
	// 	path: "/qna",
	// 	components: intlConnectDispatch(FarmManagement),
	// }
	,

	
	{
		path: "/data",
		components: intlConnectDispatch(Data),
	},
	{
		path: "/data2",
		components: intlConnectDispatch(Data2),
	},
	{
		path: "/data3",
		components: intlConnectDispatch(Data3),
	},
	{
		path: "/control",
		components: intlConnectDispatch(Control),
	},
	{
		path: "/gateway",
		components: intlConnectDispatch(Gateway),
	},
	
	{
		path: "/gateway/gatewayregister",
		components: intlConnectDispatch(GatewayRegister),
	},
	{
		path: "/gateway/detail/:id?",
		components: intlConnectDispatch(GatewayDetail),
	},
	{
		path: "/gateway/devicetag/:id?",
		components: intlConnectDispatch(Devicetag),
	},
	{
		path: "/gateway/devicetag/:id?/deviceDetail/:deviceId?",
		components: intlConnectDispatch(DeviceDetail),
	},
	{
		path: "/gateway/devicetag/:id?/deviceRegister",
		components: intlConnectDispatch(DeviceRegister),
	},
	{
		path: "/gateway/devicetag/:id?/tagDetail/:deviceId?",
		components: intlConnectDispatch(TagDetail),
	},
	{
		path: "/gateway/devicetag/:id?/tagRegister",
		components: intlConnectDispatch(TagRegister),
	},
	{
		path: "/gateway/logic/:id?",
		components: intlConnectDispatch(Logic),
	},
	
	{
		path: "/farm",
		components: intlConnectDispatch(Farm),
	},
	{
		path: "/farm/farmregister",
		components: intlConnectDispatch(FarmRegister),
	},
	{
		path: "/farm/detail/:id?",
		components: intlConnectDispatch(FarmDetail),
	},
	{
		path: "/farm/:id?/info",
		components: intlConnectDispatch(FarmInfo),
	},
	{
		path: "/farm/:id?/info/containerDetail/:containerId?",
		components: intlConnectDispatch(FarmContainerDetail),
	},
	{
		path: "/farm/:id?/info/containerRegister",
		components: intlConnectDispatch(FarmContainerRegister),
	},
	{
		path: "/farm/:id?/info/:containerId/product",
		components: intlConnectDispatch(Product),
	},
	{	
		path: "/farm/:id?/info/:containerId/productRegister",
		components: intlConnectDispatch(ProductRegister),
	},
	{
		path: "/farm/:id?/info/:containerId/product/:productId",
		components: intlConnectDispatch(ProductDetail),
	},
	{
		path: "/farm/:id?/info/userDetail/:userId?",
		components: intlConnectDispatch(FarmUserDetail),
	},
	{
		path: "/farm/:id?/info/userRegister",
		components: intlConnectDispatch(FarmUserRegister),
	},

	{
		path: "/company",
		components: intlConnectDispatch(Company),
	},
	{
		path: "/company/register",
		components: intlConnectDispatch(CompanyRegister),
	},
	{
		path: "/company/detail/:id?",
		components: intlConnectDispatch(CompanyDetail),
	},
	{
		path: "/company/member",
		components: intlConnectDispatch(CompanyMember)
	},
	{
		path: "/company/member/detail/:id",
		components: intlConnectDispatch(companyMemberDetail)
	},
	{
		path: "/company/member/alwns/register",
		components: intlConnectDispatch(CompanyMemberAlwns)
	},
	{
		path: "/user",
		components: intlConnectDispatch(User),
	},
	{
		path: "/user/register",
		components: intlConnectDispatch(UserRegister),
	},
	{
		path: "/user/detail/:id?",
		components: intlConnectDispatch(UserDetail),
	},
	{
		path: "/protocol",
		components: intlConnectDispatch(Protocol),
	},
	{
		path: "/protocol/register",
		components: intlConnectDispatch(ProtocolRegister),
	},
	{
		path: "/protocol/detail/:id?",
		components: intlConnectDispatch(ProtocolDetail),
	},
	{
		path: "/cctv",
		components: intlConnectDispatch(Cctv),
	},
	{
		path: "/cctv/register",
		components: intlConnectDispatch(CctvRegister),
	},
	{
		path: "/cctv/detail/:id?",
		components: intlConnectDispatch(CctvDetail),
	},
	
	{
		path: "/history/control",
		components: intlConnectDispatch(historyControl),
	},
	{
		path: "/history/control/detail",
		components: intlConnectDispatch(historyControlDetail),
	},
	{
		path: "/history/event",
		components: intlConnectDispatch(eventControl),
	},
	{
		path: "/history/user",
		components: intlConnectDispatch(userControl),
	},
	
	{
		path: "/notice",
		
		components: intlConnectDispatch(Notice),
	},
	{
		path: "/register",
		components: intlConnectDispatch(NoticeRegister),
	},
	{
		path: "/noticeregister",
		components: intlConnectDispatch(NoticeRegister),
	},
	{
		path: "/notice/detail/:id?",
		components: intlConnectDispatch(NoticeDetail),
	},
	{
		path: "/notice/:id/edit",
		components: intlConnectDispatch(NoticeRegister),
	},
	{
		path: "/faq",
		components: intlConnectDispatch(Faq),
	},
	{
		path: "/faqregister",
		components: intlConnectDispatch(FaqRegister),
	},
	{
		path: "/faq/detail/:id?",
		components: intlConnectDispatch(FaqDetail),
	},
	{
		path: "/faq/:id/edit",
		components: intlConnectDispatch(FaqRegister),
	},
	{
		path: "/qna",
		components: intlConnectDispatch(Qna),
	},
	{
		path: "/qnaregister",
		components: intlConnectDispatch(QnaRegister),
	},
	{
		path: "/qna/detail/:id?",
		components: intlConnectDispatch(QnaDetail),
	},
	{
		path: "/qna/:id/edit",
		components: intlConnectDispatch(QnaRegister),
	},
	{
		path:"/mypage/profile",
		components: intlConnectDispatch(Profile),
	},
	{
		path:"/mypage/another",
		components: intlConnectDispatch(Another),
	},
	// {
	// 	path: "/mainUser",
	// 	components: intlConnectDispatch(MainUser),
	// },
		
	
];

export const routes2 = [
	
];

export const routes3 = [
	{
		path: "/login",
		components: intlConnectDispatch(Login)
	},
	{
		path: "/findpw",
		components: intlConnectDispatch(ReplaceUserPw)
	},
	{
		path: "/finduserid",
		components: intlConnectDispatch(FindUserId)
	},
	
	
	{
		path: "*",
		components: intlConnectDispatch(NotFound)
	}
];

export const route_popUp=[
	{
		path: "/popUp",
		components: (popUp),
	},

	
];

