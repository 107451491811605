import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import CKEditor from 'components/layout/include/CKEditor';
import cookie from 'react-cookies'
import * as common from "js/common";
class Gateway extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
			list : [],
			keyword : '',
            pageSize : 1,
			
			request:{
				size : 10,
				page : 1,				
				startTime :  String(common.toLocalDate((new Date).setDate((new Date).getDate() - 6 ))),
				endTime: String(common.toLocalDate(new Date)),
				solutionYn : null
			}
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	onChangePage(index) {
		this.setState({ request : {...this.state.request, page : index }},()=>{
			this.callData();
		});	
	}

	componentDidMount() {
		this.callData()
	}
	
	callData () {
		let obj = {
			params : {
				size : 10,
				page: Number(this.state.request.page) -1
			}
		};		
		

		console.log(this.state)
		
		if(this.state.request.startTime){
			obj.params['startTime'] = this.state.request.startTime
		}		
		if(this.state.request.endTime){
			obj.params['endTime'] = this.state.request.endTime
		}
		if(this.state.request.solutionYn){
			obj.params['solutionYn'] = this.state.request.solutionYn
		}


		this.props.setLoading(true)

		
		let api;
		if(this.props.user.realm_access.roles.includes("ROLE_CPR"))
			api = API.eventRecordListWithInfoInCpr(obj)
		else
			api = API.eventRecordListWithInfo(obj)
		
		api.then(result => {
			this.setState({
				list : result.content ,
				pageSize : result.totalPages
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			alert('데이터를 불러오지 못했습니다')
			this.props.setLoading(false)
		});
		
	}

	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">센서 에러 내력</h2>
                        <div>
                            <div className="whBox">
                                <div className="searchBox">
									<div className="datePickerDuration">
										<DatePicker
											className="dfhadjklfhls"
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={new Date(this.state.request.startTime)}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { 
												if (date === null) { date = this.state.request.startTime; } 
												let data = this.state.request;
												data.startTime = String(common.toLocalDate(date));
												this.setState({ request : data }) 
											}}
										/>
										<span className="dash">~</span>
										<DatePicker
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={new Date(this.state.request.endTime)}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { 
												if (date === null) { date = this.state.request.endTime; } 
												let data = this.state.request;
												console.log(date)
												data.endTime = String(common.toLocalDate(date));
												console.log(data.endTime)
												this.setState({ request : data }) 
												
											}}
										/>
									</div>
									<div className = "selectBox">										
										<select onChange={(e) => this.setState({ request : {...this.state.request, solutionYn : e.target.value}})}>
											<option value=''>모두</option>
											<option value={'Y'}>조치 완료</option>
											<option value={'N'}>조치 미완료</option>
										</select>
									</div>
									<button type="button" class="ml10" onClick={() => {this.onChangePage(1)}}>검색</button>			
                                </div>
                            </div>
                            <div className="tableBox mt30">
                                <table>
									<colgroup>"
										<col width="70"/>
										<col width="70"/>
										<col width="70"/>
										<col width="50"/>
										<col width="60"/>
										<col width="60"/>	
										<col width="60"/>	
										<col width="60"/>												
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th>농장</th>
                                            <th>시설동</th>
                                            <th>태그이름</th>
                                            <th>에러 값</th>
                                            <th>에러 코드</th>
											<th>발생 시각</th>
											<th>조치 내용</th>
											<th>조치 시각</th>
                                        </tr>
                                    </thead>
                                    <tbody>										
										{
											this.state.list.length === 0 ?
												<tr style={{ cursor: 'default' }}>
													<td colSpan='100' className="textCenter">센서 에러내력이 없습니다.</td>
												</tr>
											:
												this.state.list.map((item,index)=>{
													return(												
														<tr>
															<td className="textCenter">{item.farmName}</td>
															<td className="textCenter">{item.containerName}</td>
															<td className="textCenter">{item.tagName}</td>
															<td className="textCenter">{item.errorValue}</td>
															<td className="textCenter">{item.errorCode}</td>
															<td className="textCenter">{common.toDateString(item.regDate, `time`)}</td>
															<td className="textCenter">{item.solutionContent}</td>
															<td className="textCenter">{common.toDateString(item.solutionDate, `time`)}</td>
														</tr>
													)
												}
										)}
                                    </tbody>
                                </table>
                                <Pager pageIndex={this.state.request.page} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Gateway;