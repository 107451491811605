import { ControlMessage } from 'components/mainPage/material/commonCompo';
import { imageTable } from 'components/utils/mapping';
import { getEquipSensorInnerTagObj, getEquipSensorInnerTagValue, integerCheck } from 'components/utils/util';
import { LEFTSIDEWINDOW, RIGHTSIDEWINDOW, SECONDLEFTAST, TOPWINDOW } from 'constants/equipInfo';
import React, { useEffect, useState } from 'react'
import { API } from 'service';
import styled, { css } from 'styled-components'


const AstControlModal = ({ data, fetchRes, fetchResControl }) => {

    const [targetData, setTargetData] = useState(data);
    const [fetchResult, setFetchResult] = useState(null);

    const [inputFeildValue, setInputFeildValue] = useState('');

    useEffect(() => {
        setTargetData(data);
    }, [data]);


    const imgControl = () => {

        try {

            let imgSource;
            switch (targetData.equipClass.code) {
                case LEFTSIDEWINDOW.equipClass:
                case SECONDLEFTAST.equipClass:
                    imgSource = imageTable.LEFTSIDEWINDOWIMG.on;
                    break;
                case RIGHTSIDEWINDOW.equipClass:
                    imgSource = imageTable.RIGHTSIDEWINDOWIMG.on;
                    break;
                case TOPWINDOW.equipClass:
                    imgSource = imageTable.TOPWINDOWIMG.on;
                    break;
                default:
                    imgSource = imageTable.LEFTSIDEWINDOWIMG.on;
            }
            return imgSource;
        } catch (error) {
            return imageTable.LEFTSIDEWINDOWIMG.off;
        }

    }

    const valueControl = () => {

        try {
            return getEquipSensorInnerTagValue(targetData, LEFTSIDEWINDOW.status);
        } catch (error) {
            return 'error';
        }

    }

    const fetchEquipControl = (stand, controlValue) => {

        try {
            fetchResControl(null);
            if (stand !== 'whole') {
                if (!integerCheck(inputFeildValue)) {
                    alert('입력값은 정수로 입력 해 주세요.')
                    return;
                }

                if (inputFeildValue > 60 || inputFeildValue < 0) {
                    alert('입력값은 0 ~ 60 사이로 입력 해 주세요.');
                    return;
                }
            }
            const { equipClass: { code: equipClassCode, equipProtocolMap: equipClassValue }, equipId, seq } = targetData;
            const actuators = targetData.actuators.find(e => e.tagClass.code === SECONDLEFTAST.act.sideControl);
            if (actuators === undefined) {
                throw Error('actuator is not found');
            }
            const { tagClass: { code: classCode, encoding }, tagId: actId, gatewayMap: { map: address }, bitSeq } = actuators;
            let fetchData = {
                equipClassCode,
                equipId,
                equipClassValue,
                params: [
                    { // 값 보내는 커맨드
                        id: actId,
                        address,
                        classCode,
                        seq,
                        value: stand !== 'whole' ? Number(inputFeildValue) : 0,
                        encoding,
                        bitSeq
                    },
                    { // 열건지 닫을건지 하는 커맨드
                        id: actId,
                        address: "501",
                        classCode: "SIDECOMMAND",
                        encoding: "LOWBYTE_HEXDEC",
                        seq,
                        value: controlValue,
                        bitSeq
                    },
                ]
            }
            // 0003 : 전체 열림
            // 0004 : 전체 닫힘
            // 1003 : 10초 열림
            // 1004 : 10초 닫힘

            // return;
            new Promise((resolve, reject) => {
                API.equipControl(fetchData)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
                .then(res => {
                    if (res !== 200) {
                        throw Error('contorl Error ' + res)
                    }
                    fetchResControl(true);
                    alert('제어 요청이 완료 되었습니다.');
                })
                .catch(err => {
                    fetchResControl(false);
                    alert(err.message);
                })

        } catch (error) {
            fetchResControl(false);
            alert(error.message);
        }

    }

    const fetchStopControl = () => {

        try {
            const { equipClass: { code: equipClassCode, equipProtocolMap: equipClassValue }, equipId, seq } = targetData;
            const actuators = targetData.actuators.find(e => e.tagClass.code === SECONDLEFTAST.act.sideControl);
            if (actuators === undefined) {
                throw Error('actuator is not found');
            }
            const { tagClass: { code: classCode, encoding }, tagId: actId, gatewayMap: { map: address }, bitSeq } = actuators;
            let fetchData = {
                equipClassCode,
                equipId,
                equipClassValue,
                params: [
                    { // 열건지 닫을건지 하는 커맨드
                        id: actId,
                        address: "501",
                        classCode: SECONDLEFTAST.act.sideCommand,
                        encoding: "LOWBYTE_HEXDEC",
                        seq,
                        value: 0,
                        bitSeq
                    },
                ]
            }

            // return;
            new Promise((resolve, reject) => {
                API.equipControl(fetchData)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
                .then(res => {
                    if (res !== 200) {
                        throw Error('contorl Error ' + res)
                    }
                    fetchResControl(true);
                    alert('제어 요청이 완료 되었습니다.');
                })
                .catch(err => {
                    fetchResControl(false);
                    alert(err.message);
                })
        } catch (error) {
            alert(error.message);
            fetchResControl(false);
        }

    }

    const onChangeValue = (e) => {
        setInputFeildValue(e.target.value)
    }
    return (
        <Wrapper>
            <Wrap>
                <Img src={imgControl()} />
                <TempWrap>
                    <InerPercent> {valueControl()} <Unit>%</Unit> </InerPercent>
                </TempWrap>
                <Straight>
                    <button onClick={() => fetchEquipControl('whole', 4)} style={{ lineHeight: '4.5rem', minWidth: '11.6rem', height: '4.5rem', backgroundColor: 'white', color: '#17B9B6', border: '1px solid #17B9B6' }} className='btn white mr10'>전체닫기</button>
                    <button onClick={() => fetchEquipControl('whole', 3)} style={{ lineHeight: '4.5rem', minWidth: '11.6rem', height: '4.5rem' }} className='btn'>전체열기</button>
                </Straight>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "100%",
                    marginTop: '10px',
                    cursor: 'pointer',
                }}
                    onClick={fetchStopControl}
                >
                    <div style={{
                        width: '58%',
                        textAlign: 'center',
                        padding: '10px 0px 10px 0px',
                        border: '1px solid red',
                        borderRadius: 4,
                        color: 'red'
                    }}>동작 중지</div>
                </div>
            </Wrap>
            <Wrap second>
                <Dongzak>동작설정</Dongzak>
                <Spbe>
                    <Input value={inputFeildValue} style={{ color: '#2A3A4B', height: '45px', fontSize: '2rem' }} type="number" onChange={onChangeValue} placeholder="0 ~ 60 입력" />
                    <Span>
                        <Text>초 동안</Text>
                    </Span>
                </Spbe>
                <ConfirmWrap>
                    <Confirm onClick={() => fetchEquipControl('close', 4)} style={{ backgroundColor: "#F5F5F5", color: "#4F80E2" }}>닫기</Confirm>
                    <Confirm onClick={() => fetchEquipControl('open', 3)}>열기</Confirm>
                </ConfirmWrap>
                <MessageWrapper>
                    <ControlMessage fetchRes={fetchRes} />
                </MessageWrapper>
            </Wrap>
        </Wrapper>
    )

}

const MessageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding : 10px 0px 10px 0px;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`


const Wrap = styled.div`
display:flex ;
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
    background: #F5F5F5;
    border: 1px solid #C9C9C9;
    box-sizing: border-box;
    border-radius: 10px;
    padding:2.5rem;
    margin-left: 3rem;
`}

`
const Img = styled.img`
width: 130px ;
height: 130px ;
`
const TempWrap = styled.div`
width:100% ;
height: 4rem;
display:flex ;
justify-content:center ;
align-items:center ;
margin-bottom:20px ;
`
const InerPercent = styled.div`
display:flex ;

font-family: Spoqa Han Sans Neo;
align-items:flex-end ;
justify-content:flex-end ;
font-weight: 500;
font-size: 2.6rem;
/* line-height: 46px; */
/* identical to box height */


color: #2A3A4B;
`
const Unit = styled.div`
display:flex ;

align-items:flex-end ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #2A3A4B;
`

const Status = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2.6rem;
/* line-height: 46px; */
/* identical to box height */

color: #17B9B6;
`

const Straight = styled.div`
display: flex;
`

const Dongzak = styled.div`
display:flex ;
width:100% ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;

color: #747474;
`

const Spbe = styled.div`
width: 100%;
margin-top: 1rem;
display: flex;
justify-content: space-between;
align-items: flex-end;
padding-top: 3.6rem;
`

const Input = styled.input`
width : 70%;
height: 45px;

background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 5px;

text-align:right ;
padding:5px 12px;

font-family: Spoqa Han Sans Neo;

font-size: 2rem;

/* identical to box height */

`

const Span = styled.div`

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 22px;

color: #2A3A4B;
height: 100%;
display: flex;
align-items: flex-end;
`
const Text = styled.span`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 2rem;
margin: auto;

color: #2A3A4B;
margin-right:1.5rem;
`
const ConfirmWrap = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
margin-top : 10px;
`

const Confirm = styled.button`
display:flex ;
width:48% ;
height: 4.5rem;

background: #4F80E2;
border: 1px solid #4F80E2;
box-sizing: border-box;
border-radius: 5px;

align-items:center ;
justify-content:center ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;

color: #FFFFFF;
`

export default AstControlModal;