import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { image_mapping, offImage_mapping } from 'components/utils/mapping'

import Message from './include/message'
import Input from './include/input'
import { useInput } from 'hooks/commonHooks'

import off from 'img/tools_item/04_exchanger_ off.png'
import one from 'img/tools_item/03_exchanger_ light.png'
import two from 'img/tools_item/02_exchanger_ middle.png'
import three from 'img/tools_item/01_exchanger_ strong.png'
const image = {
    0: off,
    1: one,
    2: two,
    3: three
}

const Label = styled.label`
  cursor: pointer;
  margin-left: 12px;
  width: 100%;
flex:1;

`;


const Button = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    background: #F5F5F5;

    font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2rem;

border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 5px;


color: #17B9B6;


`;
const Button_input = styled.input`
  opacity: 0;
  display: none;
  &:checked  {
      &+ ${Button}{
        background: #17B9B6;
        color: #FFFFFF;
      }
  }
`;


const Label_Wrap = styled.div`
width: 34.9rem;
height: 4.4rem;
display: flex;
margin-bottom: 6px;
`

function Modal(props) {
    const [data, set_data] = useState([])

    const [isOn, set_isOn] = useState(false)

    const [co2Min, co2Min_handdle, set_co2Min] = useInput('')
    const [co2Max, co2Max_handdle, set_co2Max] = useInput('')


    const [status, status_handdle, set_status] = useInput(0)

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            props.onClose(e)
        }
    }

    const close = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const array_find = (arr, code) => {
        let return_arr = {}
        return_arr = arr.filter(val => {
            if (val.tagClass.code == code) {
                return true
            }
            return false
        })

        if (return_arr.length == 0)
            return_arr = [{ 'value': 'err' }]
        return return_arr[0]
    }

    useEffect(() => {
        if (props.length != 0) {
            set_data(props.data)
            console.log(props)



            set_co2Max(array_find(props.sensors, "HEATEXCO2MAX")["value"].toString())
            set_co2Min(array_find(props.sensors, "HEATEXCO2MIN")["value"].toString())

            if (props.data.length != 0) {

            }
            console.log(props.status.value)
            if (props.status.value == 0)
                set_isOn(false)
            else if (props.status.value == 1)
                set_isOn(true)
        }
    }, [props])

    return (
        <>
            {data.length == 0 ||
                <>
                    <ModalOverlay visible={props.visible} />
                    <ModalWrapper onClick={onMaskClick} visible={props.visible}>
                        <ModalInner>
                            <Top>
                                <Title>{data.name}</Title>
                                <CloseButton onClick={close}>닫기</CloseButton>
                            </Top>

                            <Wrapper>
                                <Wrap>
                                    <Img src={image[status]} />


                                    <div>
                                        <Label_Wrap>
                                            <Label>
                                                <Button_input value={1} onClick={status_handdle} checked={status == 1} type="radio" name='changer' />
                                                <Button>약</Button>
                                            </Label>
                                            <Label>
                                                <Button_input value={2} onClick={status_handdle} checked={status == 2} type="radio" name='changer' />
                                                <Button>중</Button>
                                            </Label>
                                            <Label>
                                                <Button_input value={3} onClick={status_handdle} checked={status == 3} type="radio" name='changer' />
                                                <Button>강</Button>
                                            </Label>
                                        </Label_Wrap>

                                        <Label_Wrap>
                                            <Label>
                                                <Button_input value={0} onClick={status_handdle} checked={status == 0} type="radio" name='changer' />
                                                <Button>끄기</Button>
                                            </Label>
                                        </Label_Wrap>
                                    </div>
                                    <Message success={true} />
                                </Wrap>

                                <Wrap second>
                                    <Dongzak>동작설정</Dongzak>

                                    {/* title titleColor color unit data handleChange */}
                                    <Input titleColor="#747474" color="#17B9B6" title="CO₂ 최소값" data={co2Min} handleChange={co2Min_handdle} unit="ppm" />
                                    <Input titleColor="#747474" color="#17B9B6" title="CO₂ 최대값" data={co2Max} handleChange={co2Max_handdle} unit="ppm" />

                                    <Message success={true} />

                                    <Confirm>동작설정</Confirm>
                                </Wrap>
                            </Wrapper>
                        </ModalInner>
                    </ModalWrapper>
                </>
            }

        </>
    )
}

const Dongzak = styled.div`
display:flex ;
width:100% ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;

color: #747474;
`

const Confirm = styled.button`
display:flex ;
width:100% ;
background: #4F80E2;
border-radius: 5px;
height: 4.5rem;

align-items:center ;
justify-content:center ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;

color: #FFFFFF;
`

const ModalWrapper = styled.div`
  box-sizing: border-box;
 

  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 25%;
  animation: zoomIn 0.2s none;
  margin: 0 auto;
  padding: 3.5rem;

  width: 80rem;
`

const CloseButton = styled.div`
display:flex ;
width: 131px;
height: 53px;

line-height:51px ;
background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;

text-align:center ;
justify-content:center ;

color: #FFFFFF;

cursor:pointer ;
`
const Top = styled.div`
display:flex ;
justify-content:space-between ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 2.6rem;
line-height: 46px;


color: #2A3A4B;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`

const Wrap = styled.div`
display:flex ;
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:2.5rem;
margin-left: 3rem;
`}

`

const Img = styled.img`
width: 130px ;
height: 130px ;
margin-bottom: 1.2rem;
`

export default Modal