import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighStock from "highcharts/highstock";
import { API } from 'service.js';

class MainCharts extends Component {
	constructor(props) {
		super(props);

		this.state = {
			containerId : props.containerId,
			userId : props.userId,
			sensorLists: [],
			chartOptions: {
				chart: {
					type: 'line',
					zoomType: 'y',
				},
				rangeSelector: {
					buttons: [{
						count: 30,
						type: 'minute',
						text: '30M'
					}, {
						count: 12,
						type: 'hour',
						text: '12H'
					}, {
						count: 1,
						type: 'day',
						text: 'D'
					}, {
						count: 1,
						type: 'week',
						text: 'W'
					}, {
						count: 1,
						type: 'month',
						text: 'M'
					}, {
						count: 1,
						type: 'year',
						text: 'Y'
					}, {
						type: 'all',
						text: 'All'
					}],
					inputEnabled: true,
					selected: 1
				},
				title: {
					text: ''
				},
				plotOptions: {
					line: {
						gapSize:5
						//color: '#d62020'
						//  },
						//  bar: {
						//color: '#d52020'
						//  },
						//  column: {
					},
					series: {
						marker: {
							radius: 2
						},
						animation: true,
						step: false,
						turboThrehold:1000,
						borderWidth: 0
					}
				},
				tooltip: {
					valueDecimals: 1,
					valueSuffix: '℃',
					xDateFormat:'%Y-%m-%d<br/>%H:%M:%S %p'
					// reformat the tooltips so that local times are displayed
					//formatter: function() {
					//var d = new Date(this.x + (myOffset*60000));
					//var n = (this.point.name === undefined) ? '' : '<br/>' + this.point.name;
					//return this.series.name + ':<b>' + this.y + '</b>' + n + '<br/>' + d.toDateString() + '<br/>' + d.toTimeString().replace(/\(.*\)/, "");
					//}
				},
				xAxis: {
					type: 'datetime',
					ordinal:false,
					//min: Date.UTC(2013,02,28),
					dateTimeLabelFormats : {
						hour: '%l %p',
						minute: '%l:%M %p'
					},
					title: {
						text: '시간'
					}
				},
				yAxis: [{
					title: {
						text: 'Temperature  ℃'
					},
					id: 'T'
				}, {
					title: {
						text: 'Other'
					},
					opposite: true,
					id: 'O'
				}],
				exporting: {
					enabled: true,
					csv: {
						dateFormat: '%d/%m/%Y %I:%M:%S %p'
					}
				},
				legend: {
					enabled: true
				},
				navigator: {
					baseSeries: 0,  //select which series to show in history navigator, First series is 0
					series: {
						includeInCSVExport: false
					}
				},
				series: []
			},
			hoverData: null
		};
	}

	setHoverData = (e) => {
		// The chart is not updated because `chartOptions` has not changed.
		this.setState({ hoverData: e.target.category })
	}

	updateSeries = () => {
		// The chart is updated only with new options.
		this.setState({});
	}

	afterChartCreated = (chart) => {
		this.internalChart = chart;
	}

	componentDidMount() {
		// GET request using fetch with error handling
		let obj = {
			containerId : this.props.containerId,
			userId : this.props.userId
		};
		let _this = this;
		//최초 설정
		API.mainChartSensors(obj).then(  response => {
				const data =  response;

				const arraySeries = [];
				data[0].containerList[0].sensorList.map((sensors) => {
					console.info('There data sensors', sensors);
					const array = [];
					sensors.valueList.map((values) => {
						array.push( [values.time, values.value] );
						return array;
					});
					arraySeries.push( { name: sensors.sensorName , data: array, tooltip: { valueDecimals: 2 },} );
				});
				//console.info('There data array', arraySeries);

				_this.setState({
					chartOptions: {
						series: arraySeries
					}
				})

		}).catch( error => console.log(error));

		API.sensorInfo(obj).then(result => {
			this.setState({ sensorLists: result[0]["containers"][0].sensors })
			//console.log("sensorInfo 11 >>> " + result[0]["containers"][0].sensors[0].name  )
			//console.log("sensorInfo >>> "+result.containers[0].sensors )

			//console.log( this.state.sensorLists  )

		}).catch( error => console.log(error));

		//일정시간 간격으로 업데이트
		/*
		_this.interval = setInterval(function() {
			API.mainChartSensors(obj).then(  response => {
				const data =  response;

				const arraySeries = [];
				data[0].containerList[0].sensorList.map((sensors) => {
					console.info('There data sensors', sensors);
					const array = [];
					sensors.valueList.map((values) => {
						array.push( [values.time, values.value] );
						return array;
					});
					arraySeries.push( { name: sensors.sensorName , data: array, tooltip: { valueDecimals: 2 },} );
				});
				//console.info('There data array', arraySeries);

				_this.setState({
					chartOptions: {
						series: arraySeries
					}
				})

			}).catch( error => console.log(error));

		}, 1000 * 60 * 5 ); // setInterval
        */

	}

	render() {
		const { chartOptions, hoverData } = this.state;

		return (
			<div className="modalWrap">
				<div className="modalHeader"><strong>재배 환경 데이터 정보</strong></div>
				<div className="modalContent ">
					<div className="dataPopCont">
						{
							this.state.sensorLists.map((item,index)=>{
								return (
								<div>
									<strong>{item.name}</strong>
									<p className="number">{item.value}<span className="top"></span>{item.unit}</p>
									<p className="guideText">정상 <span>{item.alarmLowerLimit} ~ {item.upperLimit}</span></p>
									<p className="alramText">알람 <span>0</span>건</p>
								</div>
								)
							})
						}
						{/*
						<div>
							<strong>습도</strong>
							<p className="number">19<span className="top">%</span></p>
							<p className="guideText">정상 <span>18 ~ 20</span>%</p>
							<p className="alramText">알람 <span>3</span>건</p>
						</div>
						<div>
							<strong>Co2</strong>
							<p className="number">1070<span className="bottom">ppm</span></p>
							<p className="guideText">정상 <span>18 ~ 20</span>ppm</p>
							<p className="alramText">알람 <span>3</span>건</p>
						</div>
						<div>
							<strong>상대습도</strong>
							<p className="number">19<span className="top">% </span></p>
							<p className="guideText">정상 <span>18 ~ 20</span>% </p>
							<p className="alramText">알람 <span>3</span>건</p>
						</div>
						*/}
					</div>
					<div className="dataPopChart p20">
						<div className="between flex ">
							<div className="">
								분석항목
								{/*
											<div className="selectBox ml10">
												<select name="" id="" placeholder="농장">
													<option  value={'ㅇ'}>습도</option>
													<option  value={'ㅇ'}>습도</option>
													<option  value={'ㅇ'}>습도</option>
												</select>
											</div>
											<div className="checkBox ml20"><input type="checkbox" name="type" id="PT1"/> <label htmlFor="T1">온도</label></div>
											<div className="checkBox ml10"><input type="checkbox" name="type" id="PT2"/> <label htmlFor="T2">습도</label></div>
											*/}
							</div>
							{/*
										<div>
											<div className="datePickerDuration">
												<span>시작일</span>
												<DatePicker
                                                    className="mr10"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={this.state.searchStartDate}
                                                    maxDate={this.state.searchendDate}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { if (date === null) { date = this.state.searchEndDate; } this.setState({ searchStartDate: new Date(date) }) }}
                                                />
                                                <span>종료일</span>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={this.state.searchEndDate}
                                                    minDate={this.state.searchStartDate}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { if (date === null) { date = this.state.searchStartDate; } this.setState({ searchEndDate: new Date(date) }) }}
                                                />
                                                </div>
										</div>
										*/}
						</div>
						{/*
									<div className="mt10 textRight">
										<div className="selectBox ml10">
											<select name="" id="" placeholder="농장">
												<option  value={'ㅇ'}>습도</option>
												<option  value={'ㅇ'}>습도</option>
												<option  value={'ㅇ'}>습도</option>
											</select>
										</div>
									</div>
									*/}
						<div className="chartBox">
							<div>
								<div className="App">
									{
										<HighchartsReact
											highcharts={HighStock}
											constructorType={"stockChart"}
											options = { this.state.chartOptions }
										/>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>




		)
	}
}

export default MainCharts;