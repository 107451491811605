import React from 'react';
import { nbind } from 'js/common'
import Modal from 'react-modal';
// import { Link, withRouter } from "react-router-dom";
// import { join } from 'path';
import { API } from '../service';
import * as common from "js/common";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { selNoneMonth, selNoneDay } from 'js/selectData.js';

let modalJoinSuccessView = false;
let alert = '';
//import QrCodeCreate from "../components/qrCode/qrCodeCreate.jsx";
class Join extends React.Component {
	_ismounted = false;
	constructor(props) {
		super(props);
		this.state = {
			recommender: '',
			recommenderCheck: false,
			userId: '',
			userIdCheck: false,
			userIdCodeTimer: '',
			userIdCodeSec: 0,
			userIdCodeGoalSec: 600,
			certifyCode: '',
			phone:'',
			userName: '',
			// selNoneYear: [],
			// year: '',
			// month: '',
			// day: '',
			userPw: '',
			userPwCheck: '',
			userPw2: '',
			userPwCheck2: '',
			// 체크값
			checkAll: false,
			checkAge : false,
			checkTerms: false,
			checkUser: false,
			checkAll2 : false,
			checkMarketing: false,
			checkMarketing2:false,
			
			encData: '',
			name : '',
			// modalJoinSuccessView: false,
			modalTermsView: false,
			modalTermsView2: false,
			modalTitle: '',
			modalContent: '',
			
			joinAgreeStep : false,
			
			//sms
			codeSend: false,
			codeCheck: false,
		};
		this.interval = '';
		nbind(this);
	}
	
	componentDidMount() {
		alert = this.alert;
	}
	

	changeStateFromId(e){
		this.setState({ [e.target.id] : e.target.value});
	}
	
	
	sendCode() {
		let s = this.state;
		let emailReg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*[.][a-zA-Z]{2,3}$/i;
		if (!emailReg.test(s.userId)) {
			this.alert('아이디가 이메일 형식에 맞지 않습니다.', `msg.notMatchIdForm`);
			document.getElementById('email').focus();
			return false;
		}
		// if ( !s.userIdCheck ) {
		// 	this.alert('아이디 중복체크가 되지 않았습니다.\n아이디 중복체크를 한 후, 다시 시도해 주십시오.', 'msg.idNeedCheck');
		// 	document.getElementById('idCheck').focus();
		// 	return false;
		// }
		clearTimeout(this.state.userIdCodeTimer);
		let obj = {
			email: s.userId,
			type: 2, // 1: 1,2차 비밀번호, 2: 회원가입
			locale: this.props.common.lang.toUpperCase()
		};
		API.sendEmail(obj).then(result => {
			let code = result.status;
			if ( result.result ) {
				this.alert('사용 가능한 메일주소이며 인증코드를 발송했습니다', 'msg.joinEmailSend');
				this.setState({
					userIdCodeSec: this.state.userIdCodeGoalSec,
					codeSend: true,
					codeCheck: false,
				}, () => {
					this.checkUserIdCodeTime();
					document.getElementById('certifyCode').focus();
				})
			} else {
				if (code === -1000) {
					console.log(222);
					this.alert('이미 사용중인 아이디입니다.', `msg.idCheckExistFail`);
					document.getElementById('userId').focus();
					return false;
				} else {
					this.alert('인증코드를 전송하는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.', `msg.codeSendFail`);
					document.getElementById('userId').focus();
					return false;
				}
			}
		}).catch(error => console.log(error));
	}
	
	nextStep(){
		if(this.state.checkUser && this.state.checkTerms){
			this.setState({joinAgreeStep : true});
		}else{
			this.alert('약관에 모두 동의 해주세요.')
		}
	}
	checkAll(){	
		let all = this.state.checkAll;
		console.log(all)
		if ( !all ) {
			this.setState({
				checkAll: true,
				checkTerms: true,
				checkUser: true,
			});
			document.getElementById('checkAll').checked = true;
			document.getElementById('checkTerms').checked = true;
			document.getElementById('checkUser').checked = true;
		} else {
			this.setState({
				checkAll: false,
				checkTerms: false,
				checkUser: false,
			});
			document.getElementById('checkAll').checked = false;
			document.getElementById('checkTerms').checked = false;
			document.getElementById('checkUser').checked = false;
		}
	}
	checkAll2(){	
		let all = this.state.checkAll2;
		
		if ( !this.state.checkAll2 ) {
			this.setState({
				checkAll2:true,
				checkMarketing: true,
				checkMarketing2:true
			});
			document.getElementById('checkMarketing').checked = true;
			document.getElementById('checkMarketing2').checked = true;
		} else {
			this.setState({
				checkAll2:true,
				checkMarketing: false,
				checkMarketing2:false
			});
			document.getElementById('checkMarketing').checked = false;
			document.getElementById('checkMarketing2').checked = false;
		}
		
	}
	tryJoin() {
		let s = this.state;
		
		// 아이디 체크
		let emailReg = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*[.][a-zA-Z]{2,3}$/i;
		if (s.userId === '') {
			this.alert('이메일을 입력해 주세요.', 'msg.inputId');
			document.getElementById('userId').focus();
			return false;
		} else if (!emailReg.test(s.userId)) { // 이메일 정규식 체크
			this.alert('이메일 형식에 맞지 않습니다.', 'msg.notMatchIdForm');
			document.getElementById('userId').focus();
			return false;
		} else if (s.userId.indexOf(',') > -1 || s.userId.indexOf(' ') > -1) {
			this.alert('아이디에 쉼표나 공백은 사용할 수 없습니다.', '');
			document.getElementById('userId').focus();
			return false;
		} 
		// else if (!s.userIdCheck) { // ID 중복확인 여부 체크
		// 	this.alert('아이디 중복확인을 해주십시오.', 'msg.idNeedCheck');
		// 	document.getElementById('idCheck').focus();
		// 	return false;
		// } 
		else {}
		// // 인증번호 체크
		// if (s.certifyCode === '') {
		// 	this.alert('인증번호를 입력해 주세요.', `msg.inputCode`);
		// 	document.getElementById('certifyCode').focus();
		// 	return false;
		// } else {}

		// 이름 체크
		let nameReg = /^[가-힣|a-z|A-Z|\*]+$/;
		if (s.userName === '') {
			this.alert('이름을 입력해 주세요.', `msg.inputName`);
			document.getElementById('userName').focus();
			return false;
		} else if (!nameReg.test(s.userName)) {
			this.alert('이름은 한글 또는 영문만 입력 가능합니다.', `msg.nameOnlyKoOrEn`);
			document.getElementById('userName').focus();
			return false;
		} else {}

		// // 생년월일 체크
		// if (s.year === '') {
		// 	this.alert('년도를 선택해주세요.', `msg.selectYear`);
		// 	document.querySelectorAll('#year input[id|="react-select"]')[0].focus();
		// 	return false;
		// } else if (s.month === '') {
		// 	this.alert('월을 선택해주세요.', `msg.selectMonth`);
		// 	document.querySelectorAll('#month input[id|="react-select"]')[0].focus();
		// 	return false;
		// } else if (s.day === '') {
		// 	this.alert('일을 선택해주세요.', `msg.selectDay`);
		// 	document.querySelectorAll('#day input[id|="react-select"]')[0].focus();
		// 	return false;
		// } else { }
		
		// 비밀번호 체크
		if ( s.userPw === '' ) {
			this.alert('비밀번호를 입력해 주세요.', 'msg.inputPw');
			document.getElementById('userPw').focus();
			return false;
		} else if (s.userPwCheck === '') {
			this.alert('비밀번호를 입력해 주세요.', 'msg.inputPw');
			document.getElementById('userPwCheck').focus();
			return false;
		} else if (s.userPw !== s.userPwCheck) {
			this.alert('비밀번호가 일치하지 않습니다.', 'msg.pwNotMatch');
			document.getElementById('userPw').focus();
			return false;
		} else if (!(/^(?=.*[a-zA-Z])(?=.*[!@#$%^~*+=-])(?=.*[0-9]).{8,20}$/i).test(s.userPw)) {
			this.alert('비밀번호는 8~20자 영문, 숫자, 특수문자를 사용해야 합니다.', 'msg.pwPolicy');
			document.getElementById('userPw').focus();
			return false;
		} else if(s.userPw.indexOf(',') > -1 || s.userPw.indexOf(' ') > -1){
			this.alert('비밀번호에 쉼표나 공백은 사용할 수 없습니다.', '');
			document.getElementById('userPw').focus();
			return false;
		} else {}
	
		
		let obj = {
			email: s.userId,
			name: s.userName,
			tempCode: s.certifyCode,
			password: s.userPw,
			cellphone : s.phone,
			agreeUseTerm: s.checkTerms ? 1 : 0,
			agreePrivacyPolicy: s.checkUser ? 1 : 0,
			agreeReceiveEmail: s.checkMarketing ? 1 : 0,
			agreeReceiveSms: s.checkMarketing ? 1 : 0,
			userType: "DEFAULT"
		};


		// let obj = {
			// "agreePrivacyPolicy": false,
			// "agreeReceiveEmail": false,
			// "agreeReceiveSms": false,
			// "agreeSellerTerm": false,
			// "agreeUseTerm": false,
			// "birth": "string",
			// "cellphone": "string",
			// "certificationCode": "string",
			// "email": "string",
			// "gender": "MAN",
			// "interest": "BEAUTY",
			// "name": "string",
			// "password": "string",
			// "storeCategory": "BEAUTY",
			// "storeIntroduction": "string",
			// "storeName": "string",
			// "userType": "DEFAULT"
		// };
		if(!s.codeCheck){
			this.alert('휴대폰인증을 완료해주세요.')
			return false
		}
		API.join(obj).then(result => {
			let code = result.status;
			console.log(result);
			if (result.responseCode == "SUCCESS") {
				
				this.alert('회원가입이 완료되었습니다.', 'join.successJoin');
				this.props.history.push('/login');
			} else {
				this.alert('회원가입에 실패했습니다.', '');
			}
		}).then(result2 => {
		}).catch(error => console.log(error));
	}
	
	
	// 약관 상세보기
	readTerms(type) {
		if( type === 1){
			this.setState({modalTermsView:true})
		}else{
			this.setState({modalTermsView2:true})
		}
		

		// let obj = {
		// 	id: '', // 빈값일 경우 가장 최신 약관 불러옴
		// 	type: type,
		// 	locale: 'KO',
		// };
		// API.policyRead(obj).then(result => {
		// 	let code = result.status;
		// 	if (result.result) {
		// 		let data = result.value;
		// 		if ( code === 100 ) {
		// 			// 등록된게 없을 때
		// 			if ( data.boardIdx === '') {
		// 				this.alert('서비스 준비중입니다.', `msg.servicePreparing`)
		// 			} else {
		// 				let obj = {};
		// 				obj.modalContent = data.content;
		// 				obj.modalTermsView = true;
		// 				if ( type === 1 ) {
		// 					obj.modalTitle = 'common.guideDetail';
		// 				} else if ( type === 2 ) {
		// 					obj.modalTitle = 'common.privacyDetail';
		// 				} else if ( type === 3 ) {
		// 					obj.modalTitle = 'common.marketingDetail';
		// 				} else {}
		// 				this.setState(obj);
		// 			}
		// 		}
		// 	} else {
		// 		this.alert('데이터를 불러오는데 실패했습니다.\n잠시 후, 다시 시도해 주십시오.', `msg.dataLoadFail`)
		// 	}
		// }).catch(error => console.log(error));
	}
	checkTerm(type){
		if(type == 'terms'){
			this.setState({ checkTerms : !this.state.checkTerms },()=>{
				if(!this.state.checkTerms){
					document.getElementById('checkAll').checked = false;
					this.setState({ checkAll : false });
				}else{
					this.checkAllStatus();
				}
			});
		}
		if(type == 'user'){
			if(this.state.checkUser){
				document.getElementById('checkAll').checked = false;
				this.setState({ checkAll : false });
			}
			this.setState({ checkUser : !this.state.checkUser },()=>{
				if(!this.state.checkUser){
					document.getElementById('checkAll').checked = false;
					this.setState({ checkAll : false });
				}else{
					this.checkAllStatus();
				}
			});
		}
		if(type == 'marketing'){
			
			this.setState({ checkMarketing : !this.state.checkMarketing },()=>{
				if(!this.state.checkMarketing){
					//document.getElementById('checkAll2').checked = false;
					this.setState({ checkAll2 : false });
				}else{
					this.checkAllStatus2();
				}
			});
		}
		if(type == 'marketing2'){
			
			this.setState({ checkMarketing2 : !this.state.checkMarketing2 },()=>{
				if(!this.state.checkMarketing2){
					//document.getElementById('checkAll2').checked = false;
					this.setState({ checkAll2 : false });
				}else{
					this.checkAllStatus2();
				}
			});
		}
		
	}
	checkAllStatus2(){
		const {checkMarketing,checkMarketing2} = this.state
		if(checkMarketing&&checkMarketing2){
			//document.getElementById('checkAll2').checked = true;
			this.setState({ checkAll2 : true });
		}
	}
	checkAllStatus(){
		const {checkTerms,checkUser} = this.state
		if(checkTerms&&checkUser){
			document.getElementById('checkAll').checked = true;
			this.setState({ checkAll : true });
		}
	}
	codeSend(){
		let s = this.state;
		let obj = {
			cellphone : s.phone,
		};
		if(this.state.phone === '') {
			this.alert('휴대전화를 입력해주세요.')
			return false
		}
		
		API.codeSend(obj).then(result => {
			let code = result.status;
			console.log(result);
			if (result.responseCode == "SUCCESS") {
				this.setState({codeSend : true , codeCheck:false, code:''})
				this.alert('인증번호가 전송되었습니다');
				
			} else {
				this.alert('인증번호 전송에 실패했습니다.', '');
			}
		}).catch(error => console.log(error));
	}	
	codeCheck(){
		let s = this.state;
		let obj = {
			cellphone : s.phone,
			code : s.code
		};
		if(this.state.phone === '') {
			this.alert('휴대전화를 입력해주세요.')
			return false
		}
		if(this.state.code === '') {
			this.alert('인증번호를 입력해주세요.')
			return false
		}
		API.codeCheck(obj).then(result => {
			let code = result.status;
			console.log(result);
			if (result.responseCode == "SUCCESS") {
				this.setState({codeCheck : true , })
				this.alert('인증되었습니다.');
				
			} else {
				this.alert('잘못된 인증번호입니다. 다시 입력해주세요.');
			}
		}).catch(error => console.log(error));
	}
    render() {
        return (
			<React.Fragment>
				<div className="contentInner joinWrap pb40">
					<div className="contentArea bottomWrap">
							<strong className="joinTitle f_light">셀러 계정 만들기</strong>
							
							{!this.state.joinAgreeStep ? 
							<div>
								<div className="joinCheckArea verticalBox" style={{marginBottom:'30px'}}>
									<div className="joinCheckBox">
										<input type="checkbox" id="checkAll" onChange={() => { this.checkAll(); }} />
										<label className="f_bold" htmlFor="checkAll">
											모두 동의
										</label>
									</div>
								</div>
								<div className="joinCheckBox">
								<input type="checkbox" id="checkTerms" onChange={() => { this.checkTerm('terms') }} />
								<label htmlFor="checkTerms">
								서비스 이용약관 동의 <span className="redPoint">*</span>
								</label>
								<button type="button"  onClick={() => { this.readTerms(1) }}>자세히 보기</button>
								</div>
								<div className="joinCheckBox">
								<input type="checkbox" id="checkUser" onChange={() => { this.checkTerm('user')  }} />
								<label htmlFor="checkUser">
								개인정보 수집 및 이용 동의<span className="redPoint">*</span>
								</label>
								<button type="button"  onClick={() => { this.readTerms(2) }}>자세히 보기</button>
								</div>
								<div className="bottomBtnBox">
								<div className="leftBox">
									<button className="txtcolor1" onClick={()=> {this.historyBack()}}>이전</button>
								</div>
								<div className="rightBox">
									<button onClick={()=> this.nextStep()}>다음 단계</button>
								</div>
							</div>
							</div>
							:
							<div>
								<div>
									<div className="borderInputBox">
										<label htmlFor="">이메일<span className="redPoint">*</span></label>
										<input
											type="text" id="userId" name="userId" placeholder="입력"
											onChange={(e) => { this.setState({ userId: e.target.value, userIdCheck: false }) }}
											onKeyPress={(e) => { if (e.key === "Enter") {  } }}
										/>
									</div>
								
									<div className="borderInputBox">
										<label htmlFor="">비밀번호<span className="redPoint">*</span></label>
										<input
											type="password" id="userPw" name="userPw" placeholder="입력"
											onChange={(e) => { this.setState({ userPw: e.target.value }); }}
											onKeyPress={(e) => { if (e.key === "Enter") { }}}
										/>
									</div>
						
									<div className="borderInputBox">
										<label htmlFor="">비밀번호 확인<span className="redPoint">*</span></label>
										<input
											type="password" id="userPwCheck" name="userPwCheck"  placeholder="입력"
											onChange={(e) => { this.setState({ userPwCheck: e.target.value }); }}
											onKeyPress={(e) => { if (e.key === "Enter") { } }}
										/>
									</div>
									<div className="borderInputBox">
										<label htmlFor="">성명<span className="redPoint">*</span></label>
										<input
											type="text" id="name" name="name"  placeholder="입력"
											onChange={(e) => { this.setState({ userName: e.target.value }); }}
											onKeyPress={(e) => { if (e.key === "Enter") { } }}
										/>
									</div>
									<div className="borderInputBox btnInputBox ">
										<label htmlFor="">휴대전화<span className="redPoint">*</span></label>
										<input
											type="text" id="phone" name="phone"  placeholder="숫자만 입력"
											onChange={(e) => { this.setState({ phone: e.target.value , codeCheck:false}); }}
											onKeyPress={(e) => { if (e.key === "Enter") { } }}
										/>
										{this.state.phone.length < 11 ? 
										<button className="btn white gray">인증번호전송</button>
										: 
										<button className="btn white" onClick={()=>{this.codeSend()}}>인증번호전송</button>
										}
										
									</div>
									{this.state.codeSend ?
									
									<div className="borderInputBox btnInputBox">
										<label htmlFor="">인증번호<span className="redPoint">*</span></label>
										<input
											type="text" id="codeCheck" name="codeCheck"  placeholder="숫자만입력"
											onChange={(e) => { this.setState({ code: e.target.value }); }}
											onKeyPress={(e) => { if (e.key === "Enter") { } }}
										/>
										{this.state.codeCheck ? 
										<button className="btn black">인증완료</button> : 
										<button className="btn white" onClick={()=>{this.codeCheck()}}>인증하기</button>
										}
										
									</div>
									:null
									}
									
									
								</div>
								{/* <div className="choiceBox">
									<strong>선택입력</strong>
									<div className="borderInputBox">
										<label htmlFor="">성별</label>
										<div>
											<ul>
												<li><button>선택안함</button></li>
												<li><button>남자</button></li>
												<li><button>여자</button></li>
											</ul>
										</div>
									</div>
									<div className="borderInputBox">
										<label htmlFor="">관심 분야</label>`
										<div>

										</div>
									</div>
									<div className="borderInputBox">
										<label htmlFor="">관심 분야</label>
										<div>

										</div>
									</div>
									<div className="borderInputBox">
										<label htmlFor="">추천 코드</label>
										<input
											type="text" autoFocus id="recommender" name="recommender" placeholder="입력"
											onChange={(e) => { this.changeStateFromId(e); this.setState({ recommenderCheck: false }) }}
											onKeyPress={(e) => { if (e.key === "Enter") { this.checkInvited() } }}
										/>
									</div>
								</div>
			*/}
								<div className="joinCheckArea verticalBox mt20">
									<div className="joinCheckBox">
										<div className="checkBox">
											{/* <input type="checkbox" id="checkAll2" onChange={() => { this.checkAll2(); }} /> */}
											<label htmlFor="" style={{color:'#a9a9a9'}}>
												이용 혜택 및 정보 수신 동의 
												{/* (선택) */}
											</label>
										</div>
										
									</div>
									
								</div>
								<div>
									<div className="checkBox">
										<input type="checkbox" id="checkMarketing2" onChange={() => { this.checkTerm('marketing2')  }} />
										<label htmlFor="checkMarketing2" >
											메세지
										</label>
									</div>
									<div className="checkBox">
										<input type="checkbox" id="checkMarketing" onChange={() => { this.checkTerm('marketing')  }} />
										<label htmlFor="checkMarketing">
											이메일
										</label>	 
									</div>
								</div>
								<p style={{fontSize:'13px',fontWeight:300,marginTop:'20px'}}>
                                    서비스 이용에 필요한 안내사항 및 주문/배송에 대한 정보는 수신여부와 관계없이 전송됩니다.
                                </p>
								<div className="bottomBtnBox">
									<div className="leftBox">
										<button className="txtcolor1" onClick={()=> {this.historyBack()}}>이전</button>
									</div>
									<div className="rightBox">
										<button onClick={()=> this.tryJoin()}>셀러 계정 만들기</button>
									</div>
								</div>
							</div>
							}
							
						
					</div>
					{/* 약관 종류 상세 팝업 */}
					<Modal
						isOpen={this.state.modalTermsView}
						className="modalBox modalSizeS"
					>
						<div className="modalWrap">
							<div className="modalHeader">
								<strong>서비스 이용약관</strong>
								<button type="button" className="icon close xBkThin" onClick={() => { this.setState({modalTermsView : false}) }}></button>
							</div>							
							<div className="modalContent full">
								<div className="termsBox">
									<strong>서비스 이용약관</strong>
									<p>2020년 12월 28일 업데이트</p>

									제 1장 총칙<br/>
									제 1조 목적<br/>
									본 약관은 회원이 ㈜언노운즈(이하 "회사")에서 제공하는 페이버릿(온라인 커머스 SNS) 관련 제반 서비스(이하 "서비스")와 ㈜언노운즈가 운영하는 온라인 쇼핑몰(이하 “스토어”)에서 제공하는 서비스를 이용함에 있어 회원과 회사간의 서비스 이용에 관한 권리 및 의무와 책임사항, 비회원의 서비스 및 스토어 이용안내를 규정하는 것을 목적으로 합니다.
									<br/>
									<br/>
									제 2조 용어의 정의<br/>
									1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br/>
									① 서비스 : 구현되는 단말기(PC, TV, 휴대형 단말기 등의 각종 유무선 장치를 포함)와 상관없이 “이용자”가 이용할 수 있도록 “회사”가 제공하는 언노운즈 서비스와 이를 이용하여 개발한 API 응용 애플리케이션 또는 웹서비스를 의미합니다.
									<br/>
									② 스토어 : 회사가 재화 또는 용역(이하 재화 등이라 함)을 “이용자”에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 온라인 쇼핑몰을 운영하는 사업자의 의미로도 사용합니다.
									<br/>
									③ 회원 : “회사”와 서비스 이용계약을 체결하고 “회사”가 제공하는 서비스를 이용하는 모든 사용자 및 스토어에 개인정보를 제공하여 회원등록을 한 일반 사용자로서, 스토어의 정보를 지속적으로 제공받으며, 스토어가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
									<br/>
									④ 비회원 : 회원에 가입하지 않고 “회사”가 제공하는 서비스와 스토어를 이용하는 자를 말합니다.
									<br/>
									⑤ 이용자 : “회원”과 “비회원” 모두를 말합니다.
									<br/>
									⑥ 이메일 : “회원”의 식별 및 서비스 이용을 위하여 “회원”이 선정하고 “회사”가 부여한 이메일 주소를 의미합니다..
									<br/>
									⑦ 비밀번호 : “회원”의 개인 정보 및 확인을 위해서 “회원”이 정한 문자 또는 숫자의 조합을 의미합니다.
									<br/>
									⑧ 도메인 : “회원”의 서비스 이용을 위하여 “회원”이 신청하여 “회사”가 부여한 고유한 인터넷 주소를 의미합니다. “회사”는 제공하는 제반 서비스를 위해서 서비스에 따라 별도의 도메인 주소를 추가적으로 제공합니다.
									<br/>
									⑨ 게시물 : “회원”이 서비스를 이용함에 있어 “회원”이 서비스와 스토어에 게시한 문자, 문서, 그림, 음성, 링크, 파일 혹은 이들의 조합으로 이루어진 정보 등 모든 정보나 자료를 의미합니다.
									<br/>
									⑩ 쿠폰 : 물품을 구매할 때 표시된 금액 또는 비율만큼 물품대금에서 할인 받을 수 있는 회사 전용의 온라인 또는 오프라인 쿠폰을 의미합니다.
									<br/>
									⑪ 포인트 : “회사”가 제공하는 서비스를 이용한 결과에 따라 일정한 기준에 따라 회사가 회원에게 부여하는 일정한 비현금성 자산을 의미합니다.
									<br/>
									⑫ 리뷰 : 상품 구매 후 해당 상품에 대한 종합적인 평가를 기록한 글, 이미지의 게재물을 의미합니다.
									<br/>
									⑬ 본인인증 : 휴대전화 인증(또는 i-PIN 인증)을 통하여 회원이 본인인지 여부를 확인하거나 공적 기관이 정한 기재 항목을 확인하는 것을 의미합니다.
									<br/>
									⑭ 휴면계정 : 일정 기간 사용하지 않아 로그인 등 일부 기능이 제한된 계정을 의미합니다.
									<br/>
									⑮ 탈퇴 : “회사”와 관계하고 있던 서비스나 조직의 관계를 끊고 물러남을 의미합니다.
									<br/>
									2. 본 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계법령 및 서비스 별 안내에서 정하는 바에 의합니다.
									<br/>
									<br/>
									제 2장 회원 계정 및 이용계약<br/>
									제 3조 회원의 아이디 및 비밀번호<br/>
									1. "회원"은 아이디와 비밀번호에 관해서 관리책임이 있습니다.
									<br/>
									2. "회원"은 아이디 및 비밀번호를 제3자가 이용하도록 제공하여서는 안됩니다.
									<br/>
									3. "회사"는 "회원"이 아이디 및 비밀번호를 소홀히 관리하여 발생하는 서비스 이용상의 손해 또는 "회사"의 고의 또는 중대한 과실이 없는 제3자의 부정이용 등으로 인한 손해에 대해 책임을 지지 않습니다.
									<br/>
									4. "회원"은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.
									<br/>
									<br/>
									제 4조 이용계약의 체결<br/>
									1. 이용계약은 "회원"이 언노운즈 서비스 및 제반 서비스에서 제공하는 회원가입 페이지에서 서비스 이용약관에 동의한 후 이용신청을 하고 신청한 내용에 대해서 "회사"가 승낙함으로써 체결됩니다.
									<br/>
									2. 회원가입은 개인의 경우 만 14세 이상이면 신청할 수 있으며, 법인 사용자의 경우는 가입이 허용되지 않습니다.
									<br/>
									3. 국내에 거주하는 외국인의 경우, 인증 절차를 정상적으로 진행하였다면 서비스를 이용할 수 있습니다.
									<br/>
									4. "회사"는 이용약관에 동의한 후 이용신청한 사용자에 대해서 원칙적으로 접수 순서에 따라 서비스 이용을 승낙함을 원칙으로 합니다. 다만 업무 수행상 또는 기술상 지장이 있을 경우 일정시간 가입승인을 유보할 수 있습니다.
									<br/>
									5. "회사"는 다음 각 호에 해당하는 신청에 대해서 승낙하지 않거나 사후에 이용계약을 해지할 수 있습니다.
									<br/>
									① 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
									<br/>
									② 제3자의 전자우편 주소를 이용하여 신청한 경우
									<br/>
									③ 허위의 정보를 기재하거나, "회사"가 필수적으로 입력을 요청한 부분을 기재하지 않은 경우
									<br/>
									④ 부정한 용도로 서비스를 사용하고자 하는 경우
									<br/>
									⑤ 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
									<br/>
									⑥ "회사"의 정책에 적합하지 않는 회원으로 판단되는 경우나 서비스 제공이 곤란한 경우
									<br/>
									⑦ 회원의 이용 목적이나 서비스 이용 방법이 "회사"의 재산권이나 영업권을 침해하거나 침해할 우려가 있는 경우
									<br/>
									⑧ 비정상적인 방법을 통하여 아이디 및 도메인을 대량으로 생성하는 행위를 하는 경우
									<br/>
									6. "회사"는 "회원"에 대해 회사정책에 따라 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
									<br/>
									7. "회원"은 "회사"에 언제든지 회원 탈퇴를 요청하여 이용계약을 해지할 수 있습니다.
									<br/>
									8. "회원"은 회원 가입 시 기재한 개인정보의 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여 기재하여야 합니다. 변경의 지체로 인하여 발생한 "회원"의 손해에 대해 "회사"는 책임을 지지 않습니다.
									<br/>
									9. "회사"는 관련 법률 및 "회사"의 개인정보취급방침에서 정한 바에 따라 "회원"에게 요청하는 "회원"정보 및 기타정보 항목을 추가, 삭제 등 변경하여 수집 및 이용할 수 있습니다.
									<br/>
									<br/>
									제5조 회사의 의무<br/>
									1. "회사"는 계속적이고 안정적인 서비스의 제공을 위하여 최선을 다하여 노력합니다.
									<br/>
									2. "회사"는 "이용자“가 안전하게 서비스를 이용할 수 있도록 현재 인터넷 보안기술의 발전수준과 "회사"가 제공하는 서비스의 성격에 적합한 보안시스템을 갖추고 운영해야 합니다.
									<br/>
									3. "회사"는 “이용자”로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우를 이를 처리하여야 합니다. 이때 처리과정에 대해서 고객에게 메일 및 게시판 등의 방법으로 전달합니다.
									<br/>
									4. "회사"는 정보통신망 이용촉진 및 정보보호에 관한 법률, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.
									<br/><br/>

									제6조 이용자의 의무<br/>
									1. "이용자"는 다음 각호에 해당하는 행위를 해서는 안됩니다.
									<br/>
									① 이용 신청 또는 회원정보 변경 시 허위내용 등록
									<br/>
									② 타인의 정보 도용
									<br/>
									③ "회사"의 운영자, 임직원, "회사"를 사칭하거나 관련 정보를 도용
									<br/>
									④ "회사"가 게시한 정보의 변경
									<br/>
									⑤ "회사"와 기타 제3자의 저작권, 영업비밀, 특허권 등 지적재산권에 대한 침해
									<br/>
									⑥ "회사"와 다른 "이용자" 및 기타 제3자를 희롱하거나, 위협하거나 명예를 손상시키는 행위
									<br/>
									⑦ 외설, 폭력적인 메시지, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
									<br/>
									⑧ "회사"와 사전 협의되지 않은 광고 및 홍보물을 게시하는 행위
									<br/>
									⑨ 해킹을 통해서 “이용자”의 정보를 취득하는 행위
									<br/>
									⑩ 기타 현행 법령에 위반되는 불법적인 행위
									<br/>
									2. "회사"는 "이용자“가 전항에서 금지한 행위를 하는 경우, 위반 행위의 경중에 따라 서비스의 이용정지/계약의 해지 등 서비스 이용 제한, 수사 기관에의 고발 조치 등 합당한 조치를 취할 수 있습니다.
									<br/>
									3. "이용자"는 "회사"의 명시적 사전 동의가 없는 한 서비스의 이용권한 및 기타 이용계약상의 지위를 제3자에게 양도, 증여, 대여할 수 없으며 이를 담보로 제공할 수 없습니다.
									<br/>
									4. "이용자“는 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, "회사"가 통지하는 사항 등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다.
									<br/>
									5. "이용자“는 "회사"의 사전 허락 없이 "회사"가 정한 이용 목적과 방법에 반하여 영업/광고활동 등을 할 수 없고, "이용자”의 서비스 이용이 "회사"의 재산권, 영업권 또는 비즈니스 모델을 침해하여서는 안됩니다.
									<br/>
									<br/>
									제 3장 서비스<br/>
									제 7조 서비스의 제공 및 변경<br/>
									1. "회사"는 "이용자“에게 아래와 같은 서비스를 제공합니다.
									<br/>
									① 언노운즈(모바일 쇼핑 SNS링크서비스) 관련 서비스
									<br/>
									② 온라인 쇼핑몰(스토어) 서비스
									<br/>
									가) 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
									<br/>
									나) 구매계약이 체결된 재화 또는 용역의 배송
									<br/>
									다) 기타 “스토어” 정하는 업무
									<br/>
									2. "회사"는 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 "이용자"에게 공지사항 게시판에 공지하는 방법으로 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
									<br/>
									3. "회사"는 "이용자"와 별도로 서면 계약을 체결하여 언노운즈 서비스 및 제반 서비스의 특성을 이용할 수 있는 명시적인 권리를 부여하지 아니하는 한, "이용자"에게 "회사" 또는 서비스의 상호, 상표, 서비스표, 로고, 도메인 네임 및 기타 식별력 있는 브랜드 특성을 이용할 수 있는 권리를 부여하지 않습니다.
									<br/>
									4. "회사"가 제공하는 서비스의 형태와 기능, 디자인 등 필요한 경우 수시로 변경되거나, 중단될 수 있습니다. "회사"는 이 경우 개별적인 변경에 대해서 "이용자"에게 사전 통지하지 않습니다. 다만, "이용자"에게 불리한 것으로 판단되는 경우 전자게시판에 공지하는 방법으로 이를 공지합니다.
									<br/>
									5. 전 항에 의해서 제공되는 서비스가 변경 및 중단될 경우 무료로 제공되는 서비스에 대해서는 "이용자"에게 별도로 보상하지 않습니다. 다만, 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “회사”는 "이용자"에게 게시판에 공지하는 방식으로 통지하고 "회원"들에게 포인트를 지급합니다. 포인트의 산정방식은 게시판을 통하여 사전공지가 이루어지며, 본사 정책에 따라 지급시기와 내용, 지급원인은 변경될 수 있습니다.
									<br/>
									<br/>
									제 8조 제3자와 "이용자"의 정보 및 게시물 공유<br/>
									1. "회사"는 개인정보 취급방침에 명시되어 있는 방법으로만 "이용자"의 개인 정보를 제3자와 공유합니다.
									<br/>
									2. "회사"는 서비스와 관련하여 "이용자"의 사전 동의 없이 "이용자"의 전체 또는 일부의 개인정보를 특정 "이용자"의 정보를 구별할 수 없는 통계자료로 작성하여 이를 사용할 수 있습니다.
									<br/>
									3. 제 3자가 이용자에게 보여주기 적합한 광고를 매칭하기 위하여 따로 사용자의 개인정보가 수집되거나 이용될 수 있습니다.
									<br/>
									<br/>
									제 9조 광고의 게재
									<br/>
									1. "회사"는 서비스 운영과 관련하여 회원정보, 이용자가 입력한 정보를 활용하여 다양한 배너와 링크(Link)를 포함한 광고를 게재할 수 있습니다. 이는 광고주와의 계약관계에 의하거나 제공받은 컨텐츠의 출처를 밝히기 위한 조치입니다. "이용자"는 서비스 이용 시 노출되는 맞춤 광고 게재에 대해 동의합니다.
									<br/>
									2. "회사"는 “서비스”에 광고가 표시될 수 있으며 그 중 일부는 타사의 유료 광고일 수 있습니다.
									<br/>
									3. 제품 또는 서비스 결제 또는 배송과 관련한 이용자와 광고업체의 거래는 전적으로 이용자와 광고업체 사이의 행위입니다. 적용 법률이 허용하는 최대 한도 내에서 회사는 이러한 거래를 통해 발생하는 어떠한 손해 또는 피해에 대해서도 책임을 지지 않습니다. "회사"의 서비스는 외부 사이트, 앱 또는 기타 리소스에 대한 링크를 포함할 수 있습니다. 게재된 광고나 서비스를 통한 광고주의 판촉활동에 이용자가 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.
									<br/>
									4. 게시물에 포함되어 있는 링크를 클릭하여 타 사이트의 페이지로 옮겨갈 경우 해당 사이트는 "회사"와 무관합니다.
									<br/>
									<br/>
									제 10조 전자우편을 통한 정보의 제공
									<br/>
									1. "회사"는 "이용자"가 서비스 이용에 필요하다고 인정되는 다양한 정보를 "이용자"가 제공한 전자우편 주소로 제공할 수 있습니다.
									<br/>
									2. "회사"는 서비스 운영을 위해 회원정보를 활용하여 영리목적의 광고성 전자우편을 전송할 수 있습니다. "이용자"가 이를 원하지 않는 경우에는 언제든지 서비스 홈페이지 또는 서비스 내부 설정페이지 등을 통하여 수신거부를 할 수 있습니다.
									<br/>
									3. "회사"는 다음 각호에 해당하는 경우 "이용자"의 동의여부와 상관없이 전자우편으로 발송할 수 있습니다.
									<br/>
									①	이용 신청에서 입력한 전자우편 주소의 소유를 확인하기 위해서 인증메일을 발송하는 경우
									<br/>
									②	"이용자"의 정보가 변경되어 확인하기 위해서 인증메일을 발송하는 경우
									<br/>
									③	기타 서비스를 제공함에 있어 "이용자"가 반드시 알아야 하는 중대한 정보라고 "회사"가 판단하는 경우
									<br/>
									<br/>
									제 11조 서비스 이용의 제한
									<br/>
									1. "회사"는 천재지변이나 국가비상사태, 해결이 곤란한 기술적 결함 또는 서비스 운영의 심각한 변화 등 불가항력적인 경우가 발생 또는 발생이 예상될 때는 서비스의 전부 또는 일부를 예고 없이 제한하거나 중지할 수 있습니다.
									<br/>
									2. 서비스를 이용하게 됨으로써 서비스 영역에서 발생하는 "이용자" 사이의 문제에 대해 "회사"는 책임을 지지 않습니다.
									<br/>
									3. "회원"의 관리 소홀로 인하여 ID 및 비밀번호의 유출로 인해 "회원"에게 서비스 이용상의 손해가 발생하거나 제3자에 의한 부정이용 등으로 "회원"의 의무조항을 위반한 경우 ID및 해당 도메인의 이용이 제한될 수 있습니다.
									<br/>
									4. "회사"가 본 약관 제6조의 위반 행위를 조사하는 과정에서 당해 "회원" ID및 도메인이 특정 위반행위에 직접적으로 관련되어 있는 경우 등 다른 "이용자"의 권익 보호 및 서비스의 질서유지를 위해 불가피할 경우에는 해당 ID 및 도메인의 이용을 일시적으로 정지할 수 있습니다. 이에 대해 "이용자"는 서비스 홈페이지 또는 전자 우편 등을 통해 이의신청을 할 수 있습니다.
									<br/>
									<br/>

									제 12조 게시물의 권리와 책임
									<br/>
									1. "회원"이 서비스 내에 작성한 게시물에 대한 책임 및 권리는 게시물을 등록한 "회원"에게 있습니다.
									<br/>
									2. "회사"는 "이용자"가 작성한 컨텐츠에 대해서 감시 및 관리할 수 없으며 이에 대해서 책임지지 않습니다. "회사"는 "이용자"가 등록하는 게시물의 신뢰성, 진실성, 정확성 등에 대해서 책임지지 않으며 보증하지 않습니다. 단, "회사"의 운영정책 내지 관련 법령에 반하는 게시물(광고 및 홍보 게시물, 외설, 폭력적인 메시지, 기타 공서양속에 반하는 게시물 등)에 대해서는 이를 제재하는 조치는 행해질 수 있습니다.
									<br/>
									3. 서비스에 대한 저작권 및 지적재산권, "회사"가 작성한 게시물의 저작권은 "회사"에 귀속됩니다. 단, "이용자“가 단독 또는 공동으로 작성한 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
									<br/>
									4. "이용자"는 자신이 서비스 내에 게시한 게시물을 "회사"가 국내ㆍ외에서 다음 목적으로 사용하는 것을 허락합니다. 다만, "회사"가 본 조에 따라 "이용자"의 “게시물”을 이용하는 경우 저작권법 규정을 준수하며, "이용자"는 언제든지 고객센터를 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
									<br/>
									①	서비스(제3자가 운영하는 사이트 또는 미디어의 일정 영역 내에 서비스가 제공되는 경우를 포함합니다)내에서 게시물을 사용하기 위하여 게시물의 크기를 변환하거나 단순화하는 등의 방식으로 수정하는 것
									<br/>
									②	"회사"의 서비스를 홍보하기 위한 목적으로 미디어, 통신사 등에게 게시물의 내용을 보도, 방영하게 하거나 게시물을 복제ㆍ전송ㆍ전시하는 것 단, 이 경우 "회사"는 "이용자"의 개별 동의없이 미디어, 통신사 등에게 게시물 또는 “이용자“의 정보를 제공하지 않습니다.
									<br/>
									5. "회원"이 회원탈퇴를 한 경우에는 본인 도메인에 기록된 저작물 일체는 삭제됩니다. 단, 저작물이 공동 저작을 통해 작성된 경우에는 공동 저작자의 도메인에 해당 게시물이 남을 수 있고, 제3자에 의하여 보관되거나 무단복제 등을 통하여 복제됨으로써 해당 저작물이 삭제되지 않고 재 게시된 경우에 대하여 "회사"는 책임을 지지 않습니다. 또한, 본 약관 및 관계 법령을 위반한 "회원"의 경우 다른 "회원"을 보호하고, 법원, 수사기관 또는 관련 기관의 요청에 따른 증거자료로 활용하기 위해 회원탈퇴 후에도 관계 법령이 허용하는 한도에서 "회원"의 아이디 및 회원정보를 보관할 수 있습니다.
									<br/>
									6. "이용자"의 게시물 또는 저작물이 "회사" 또는 제3자의 저작권 등 지적재산권을 침해함으로써 발생하는 민형사상의 책임은 전적으로 "이용자"가 부담하여야 합니다.
									<br/>
									<br/>

									제 13조 게시물의 관리
									<br/>
									1. "이용자"의 게시물이 정보통신망법 및 저작권법 등 관련법에 위반되는 내용을 포함하는 경우, 권리자 등은 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련법에 따라 조치를 취하여야 합니다.
									<br/>
									2. "회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 본 약관 및 기타 "회사" 정책, 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.
									<br/>
									3. "이용자"가 비공개로 설정한 게시물에 대해서는 "회사"를 포함한 다른 사람이 열람할 수 없습니다. 단, 법원, 수사기관이나 기타 행정기관으로부터 정보제공을 요청받은 경우나 기타 법률에 의해 요구되는 경우에는 "회사"를 포함한 다른 사람이 해당 게시물을 열람할 수 있습니다.
									<br/>
									<br/>
									제 14조 서비스 이용의 변경 및 중지
									<br/>
									1."회원"은 "회사"에 언제든지 회원 탈퇴를 요청할 수 있으며, 서비스의 이용중지를 원하는 경우에는 "회사"가 제공하는 서비스 페이지 또는 전자우편 등의 방법으로 "회사"에 중지신청을 할 수 있습니다. "회사"는 이와 같은 요청을 받았을 경우, "회사"가 별도로 고지한 방법에 따라 신속하게 처리합니다.
									<br/>
									2."회사"는 "이용자"가 본 약관 제6조의 이용자의 의무를 위반한 경우 및 서비스의 정상적인 운영을 방해한 경우에는 사전 통보 후 “이용자” 자격을 제한, 이용계약을 해지하거나 또는 기간을 정하여 서비스의 이용을 중지할 수 있습니다.
									<br/>
									3."회사"는 전항에도 불구하고, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다.
									<br/>
									4.“회사”는 “이용자”가 6개월 이상 비밀번호를 변경하지 않고 이용중인 경우, “이용자”에 비밀번호 변경 안내를 할 수 있습니다.
									<br/>
									5.“회사”는 “이용자”의 아이디/비밀번호 변경 및 조회 서비스를 본인 e-mail 정보를 제공해 준 “이용자”에 한해서 제공합니다.
									<br/>
									6.“회사”는 “이용자”가 6개월 이상 로그인 하지 않으면, 휴면계정으로 간주하고 회사가 제공하는 서비스 이용을 제한할 수 있습니다. 휴면계정으로 지정된 이후 다시 서비스를 이용하려면 거래안전을 위해 회사가 정하는 본인확인 절차 등을 이행하여야 합니다.
									<br/>
									7.“회사”는 휴면 회원 처리로 전환된 날을 기준으로, 1년 후에 휴면 계정 정보 삭제를 진행합니다.
									<br/>
									8.“이용자”는 상기 조항으로 인한 계정 삭제 처리된 이후, 재가입을 할 수 있습니다.
									<br/><br/>

									제 15조 책임 제한
									<br/>
									1. "회사"는 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등 "이용자"의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
									<br/>
									2. "회사"는 서비스를 통하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 보증하지 않습니다.
									<br/>
									3. "회사"는 "이용자" 간 또는 "이용자"와 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
									<br/>
									4. "회사"는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.
									<br/>
									5. "회사"는 천재지변, 전쟁, 기간통신사업자의 서비스 중지, 제3자가 제공하는 오픈아이디의 인증 장애, 해결이 곤란한 기술적 결함 기타 불가항력으로 인하여 서비스를 제공할 수 없는 경우 책임이 면제됩니다.
									<br/>
									6. "회사"는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대하서는 책임이 면제됩니다.
									<br/>
									7. "이용자"는 자신의 결정에 의하여 "회사"의 서비스를 사용하여 특정 프로그램이나 정보 등을 다운받거나 접근함으로써 입게 되는 컴퓨터 시스템상의 손해나 데이터, 정보의 상실에 대한 책임을 집니다.
									<br/>
									8. "회사"는 기간통신사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우에는 책임이 면제됩니다.
									<br/>
									9. "회원"의 컴퓨터 오류, 신상정보 및 전자우편 주소의 부정확한 기재, 비밀번호 관리의 소홀 등 "이용자"의 귀책사유로 인해 손해가 발생한 경우 "회사"는 책임을 지지 않습니다.
									<br/>
									10. "회사"는 "이용자"의 컴퓨터 환경이나 "회사"의 관리 범위에 있지 아니한 보안 문제로 인하여 발생하는 제반 문제 또는 현재의 보안기술 수준으로 방어가 곤란한 네트워크 해킹 등 "회사"의 귀책사유 없이 발생하는 문제에 대해서 책임을 지지 않습니다.
									<br/>
									11. "회사"는 서비스가 제공한 내용에 대한 중요 정보의 정확성, 내용, 완전성, 적법성, 신뢰성 등에 대하여 보증하거나 책임을 지지 않으며, 사이트의 삭제, 저장실패, 잘못된 인도, 정보에 대한 제공에 대한 궁극적인 책임을 지지 않습니다. 또한, "회사"는 "이용자"가 서비스 내 또는 웹사이트상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성, 완결성, 품질 등 내용에 대해서는 책임을 지지 않습니다.
									<br/>
									12. "회사"는 "이용자" 상호간 또는 "이용자"와 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.
									<br/>
									13. "회사"는 "이용자"가 서비스를 이용하여 기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
									<br/>
									14. "회사"는 "이용자"의 게시물을 등록 전에 사전심사 하거나 상시적으로 게시물의 내용을 확인 또는 검토하여야 할 의무가 없으며, 그 결과에 대한 책임을 지지 않습니다.
									<br/>
									<br/>

									제 16조 해지 및 탈퇴
									<br/>
									1. "회원"은 "회사"에 언제든지 회원 탈퇴를 요청할 수 있으며, 서비스의 이용중지를 원하는 경우에는 "회사"가 제공하는 서비스 페이지 또는 전자우편 등의 방법으로 "회사"에 중지신청을 할 수 있습니다. "회사"는 이와 같은 요청을 받았을 경우, "회사"가 별도로 고지한 방법에 따라 신속하게 처리합니다.
									<br/>
									2. "회사"는 "이용자"가 본 약관 제6조의 이용자의 의무를 위반한 경우 및 서비스의 정상적인 운영을 방해한 경우에는 사전 통보 후 “이용자” 자격을 제한, 이용계약을 해지하거나 또는 기간을 정하여 서비스의 이용을 중지할 수 있습니다.
									<br/>
									3. "회사"는 전항에도 불구하고, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망법을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다.
									<br/>
									4. “회사”는 “이용자”가 6개월 이상 비밀번호를 변경하지 않고 이용중인 경우, “이용자”에 비밀번호 변경 안내를 할 수 있습니다.
									<br/>
									5. “회사”는 “이용자”의 아이디/비밀번호 변경 및 조회 서비스를 본인 e-mail 정보를 제공해 준 “이용자”에 한해서 제공합니다.
									<br/>
									6. “회사”는 “이용자”가 6개월 이상 로그인 하지 않으면, 휴면계정으로 간주하고 회사가 제공하는 서비스 이용을 제한할 수 있습니다. 휴면계정으로 지정된 이후 다시 서비스를 이용하려면 거래안전을 위해 회사가 정하는 본인확인 절차 등을 이행하여야 합니다.
									<br/>
									7. “회사”는 휴면 회원 처리로 전환된 날을 기준으로, 1년 후에 휴면 계정 정보 삭제를 진행합니다.
									<br/>
									8. “이용자”는 상기 조항으로 인한 계정 삭제 처리된 이후, 재가입을 할 수 있습니다.
									<br/>
									<br/>
									제 4장 스토어
									<br/>
									제 17조 구매신청
									<br/>
									1. “스토어”이용자는 “스토어”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “스토어”는 "이용자"가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
									<br/>
									① 재화 등의 검색 및 선택
									<br/>
									② 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
									<br/>
									③ 약관내용, 청약철회권이 제한되는 서비스, 배송료, 설치비 등의 비용부담과 관련한 내용에 대한 확인
									<br/>
									④ 이 약관에 동의하고 위 3호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)
									<br/>
									⑤ 재화 등의 구매신청 및 이에 관한 확인 또는 “스토어”의 확인에 대한 동의
									<br/>
									⑥ 결제방법의 선택
									<br/>
									2. “스토어”가 제3자에게 구매자 개인정보를 제공 또는 위탁할 필요가 있는 경우 실제 구매신청 시 “이용자”의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이 때 “스토어”는 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용 목적 및 보유 또는 이용 기간 등을 구매자에게 명시하여야 합니다. 다만 정보통신망이용촉진 및 정보보호 등에 관한 법률 제25조 제1항에 의한 개인정보 취급위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.
									<br/>
									<br/>
									제 18조 (이용계약의 성립)
									<br/>
									1. “스토어”는 제16조와 같은 구매 신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
									<br/>
									① 신청 내용에 허위, 기재누락, 오기가 있는 경우
									<br/>
									② 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
									<br/>
									③ 기타 구매신청에 승낙하는 것이 “스토어” 기술상 현저히 지장이 있다고 판단하는 경우
									<br/>
									2. “스토어”의 승낙이 제10조의 수신확인통지형태로 "이용자"에게 도달한 시점에 계약이 성립한 것으로 봅니다.
									<br/>
									3. “스토어”의 승낙의 의사표시에는 "이용자"의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소등에 관한 정보 등을 포함하여야 합니다.
									<br/>
									4. 구매 서비스 이용은 최초 본인 확인된 1개 아이디(ID)에 한하여 가능합니다.
									<br/>
									5. 개인 회원이 가입 시에 본인확인기관을 통하여 휴대전화 인증(또는 i-PIN 인증)으로 본인확인절차를 진행해야 하며(본인확인 이외에 회사가 정하는 별도의 수단으로 추가 인증을 요구할 수 있음), 회사는 본인확인절차를 따를 수 없는 이용 신청이 있으면 해당 가입자에게 증빙자료를 요청할 수 있습니다.
									<br/>
									6. 탈퇴 후 회원 재가입, 임의 해지 등을 반복하거나 다수의 아이디(ID)로 가입하여 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등으로 경제적 이익을 편법으로 수취하고, 이 과정에서 명의를 무단으로 사용하는 등 편법과 불법 행위를 하는 회원에 법적 제재 및 서비스 이용을 제한할 수 있습니다.
									<br/>
									<br/>
									제 19조 (지급방법)
									<br/>
									“스토어”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다. 단, “스토어”는 "이용자"의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
									<br/>
									1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
									<br/>
									2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
									<br/>
									3. 온라인 무통장 입금
									<br/>
									4. 전자화폐에 의한 결제
									<br/>
									5. “스토어”가 지급한 “포인트”에 의한 결제
									<br/>
									6. “스토어”와 계약을 맺었거나 “스토어”이 인정한 상품권에 의한 결제
									<br/>
									7. 기타 전자적 지급 방법에 의한 대금 지급 등
									<br/>
									<br/>
									제 20조 (수신확인통지 ․ 구매신청 변경 및 취소)
									<br/>
									1. “스토어”는 "이용자"의 구매신청이 있는 경우 "이용자"에게 수신확인통지를 합니다.
									<br/>
									2.수신확인통지를 받은 "이용자"는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 "스토어"는 배송전에 "이용자"의 요청이 있는 경우에는 지체없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제22조의 청약철회 등에 관한 규정에 따릅니다.
									<br/>
									<br/>
									제 21조 (재화 등의 공급)
									<br/>
									1. “스토어”는 "이용자"와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, "이용자"가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “스토어”가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 “스토어”는 "이용자"가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
									<br/>
									2. “스토어”는 "이용자"가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “스토어”가 약정 배송기간을 초과한 경우에는 그로 인한 "이용자"의 손해를 배상하여야 합니다. 다만 “스토어”가 고의 및 과실이 없음을 입증한 경우에는 그러하지 아니합니다.
									<br/>
									<br/>
									제 22조 (환급)
									<br/>
									“스토어”는 "이용자"가 구매 신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 "이용자"에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
									<br/>
									<br/>
									제 23조 (청약철회)
									<br/>
									1. “스토어”와 재화 등의 구매에 관한 계약을 체결한 "이용자"는 전자상거래 등에서의 소비자보호에 관한 법률 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다.
									<br/>
									2. "이용자"는 재화 등을 배송 받은 경우 다음 각 호에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
									<br/>
									① "이용자"에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다.)
									<br/>
									② "이용자"의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
									<br/>
									③ 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우
									<br/>
									④ 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
									<br/>
									⑤ "스토어"가 특정 재화 등에 대하여 청약철회 시 회복할 수 없는 중대한 피해가 예상되어 사전에 청약철회 제한에 관하여 고지하고, "이용자"의 동의가 이루어진 경우
									<br/>
									3. 제2항 제2호 내지 제4호의 경우에 “스토어”가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 "이용자"의 청약철회 등이 제한되지 않습니다.
									<br/>
									4. "이용자"는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시 또는 광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
									<br/>
									<br/>
									제 24조 (청약철회 등의 효과)
									<br/>
									1. “스토어”는 "이용자"로부터 재화 등을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “스토어”가 "이용자"에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 전자상거래 등에서의 소비자보호에 관한 법률 시행령 제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
									<br/>
									2. “스토어”는 위 대금을 환급함에 있어서 "이용자"가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
									<br/>
									3. 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 "이용자"가 부담합니다. “스토어”는 "이용자"에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시 및 광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “스토어”가 부담합니다.
									<br/>
									4. "이용자"가 재화 등을 제공받을 때 발송비를 부담한 경우에 “스토어”는 청약철회시 그 비용을 누가 부담하는지를 "이용자"가 알기 쉽도록 명확하게 표시합니다.
									<br/>
									<br/>
									제 25조 (저작권의 귀속 및 이용제한)
									<br/>
									1. “스토어”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “스토어”에 귀속합니다.
									<br/>
									2. "이용자"는 “스토어”를 이용함으로써 얻은 정보 중 “스토어”에게 지적재산권이 귀속된 정보를 “스토어”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
									<br/>
									3. “스토어”는 약정에 따라 "이용자"에게 귀속된 저작권을 사용하는 경우 당해 "이용자"에게 통보하여야 합니다.
									<br/>
									<br/>
									제 26조(분쟁해결)
									<br/>
									1. “스토어”는 "이용자"가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치 및 운영합니다.
									<br/>
									2. “스토어”는 "이용자"로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 "이용자"에게 그 사유와 처리일정을 즉시 통보해 드립니다.
									<br/>
									3. “스토어”와 "이용자"간에 발생한 전자상거래 분쟁과 관련하여 "이용자"의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시․도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
									<br/>
									<br/>

									제 5장 포인트
									<br/>
									제 27조 포인트 적립
									<br/>
									1. “포인트”는 "회원"이 당사에서 주최하는 이벤트 및 적립 서비스 등 마케팅 활동 등과 관련하여 획득한 포인트와 “스토어”에서 재화를 구입하여 부여받은 적림금을 말하며 “비회원”은 포인트의 적립이 불가능 합니다.
									<br/>
									2. “포인트”는 이벤트에 참여하는 즉시 자동 일괄 적립되나 일부 이벤트의 경우 본사 방침에 의하여 일괄 적립됩니다. 적립되는 포인트는 가용화 되는 즉시부터 서비스 구매 시, 사용할 수 있습니다. 가용화 되어 사용 가능한 포인트는 당사 '어플리케이션'내에서 확인이 가능합니다.
									<br/>
									3. “포인트”는 이벤트 참여에 따라 적립이 가능합니다. 단, 일부 이벤트의 경우 자체 정책에 따라 적립 횟수나 한도를 다르게 설정할 수 있습니다.
									<br/>
									4. “포인트” 적립기준은 당사와 제휴사의 내부 방침에 따라 변경될 수 있으며, 변경시에는 유예기간을 두고 공지사항 게시판에 고지하는 방식으로 "이용자"에게 사전고지 합니다.
									<br/>
									<br/>
									제 28조 포인트 사용
									<br/>
									1. “포인트”를 사용하기 위해서는 "회원" 가입을 통해 "회원"의 지위를 취득해야 합니다. 적립된 포인트 사용 순서는 "회원"이 보유 중인 사용 가능한 포인트 (이하 "가용포인트"라 함)에서 중 소멸 일자가 빠른 포인트부터 우선적으로 차감 됩니다.
									<br/>
									2. "회원"은 상품/서비스의 결제 수단으로 사용하는 것 외에 당사나 제휴사가 지정한 사은품 (상품권 포함)을 포인트를 사용하여 교환해 수령할 수 있습니다. 단, 이를 위해서는 당사나 제휴사가 정한 조건을 준수하여야 합니다.
									<br/>
									3. 만 14세 미만의 미성년자 "회원"이 포인트를 사용하는 경우, 최초 "회원"가입 시 법정대리인의 동의를 통해 "회원" 가입을 완료한 것으로 포인트 사용에 대한 법정대리인의 동의도 획득한 것으로 간주합니다.
									<br/>
									4. “포인트”는 “회사”가 제공하는 “서비스”와 “스토어”에서 현금처럼 사용이 가능하나, “회사”에서 이용을 제한하는 재화의 구매에는 사용할 수 없으며, 현금으로 교환할 수 없습니다.
									<br/>
									5. "회원"은 포인트를 타인에게 양도하거나 대여 또는 담보의 목적으로 이용할 수 없습니다.
									<br/>
									6. 포인트 사용 후 사용된 포인트의 적립 원천이 되는 이벤트 참여 행위의 취소로 인해 잔여 포인트가 마이너스(-)인 경우 잔여 가용포인트가 0 포인트가 되기 전까지 적립되는 포인트는 사용이 불가합니다.
									<br/>
									<br/>
									제 29조 포인트 정정, 취소 및 소멸
									<br/>
									1. 적립된 포인트에 오류가 있을 경우 "회원"은 오류발생 시점부터 60일 이내에 당사나 해당 제휴사에 정정 신청을 하여야 하며, 당사나 해당 제휴사는 "회원"의 정정 신청일로부터 최대 3개월 이내에 정정절차를 마쳐야 합니다. 단, "회원"은 필요한 경우 포인트 적립 오류를 증명할 수 있는 객관적인 자료를 제시하여야 합니다.
									<br/>
									2. 포인트는 회원 탈퇴 시 소멸됩니다. 최초 적립된 시점부터 36개월간 추가 적립이 없을 경우 “회원”이 보유한 포인트는 소멸됩니다. 소멸예정일이 도래하여 포인트를 소멸시키는 경우 소멸예정 포인트, 소멸시기 등 포인트 소멸과 관련된 내용을 최소 15일전에 e-mail을 발송합니다.
									<br/>
									3. "회원"이 구매한 서비스 등을 취소 및 환불하는 경우, 적립이나 사용한 포인트는 취소됩니다.
									<br/>
									4. ”회원”이 “회사”가 제공하는 각종 할인 쿠폰, 포인트를 부정한 목적으로 사용 시, “회사”는 사전 통보 없이 할인 쿠폰 및 포인트를 박탈할 수 있습니다.
									<br/>
									<br/>
									제 6장 개인정보 보호
									<br/>
									제 30조 개인정보보호 의무
									<br/>
									1. "회사"는 정보 통신망 법 등 관계 법령이 정하는 바에 따라 "이용자"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 "회사"의 개인정보취급방침이 적용됩니다. 다만, "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이 적용되지 않습니다.
									<br/>
									2. "회사"는 서비스를 중단하거나 “이용자"가 개인정보 제공 동의를 철회한 경우에는 신속하게 "이용자"의 개인정보를 파기합니다. 단, 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법률에서 정하는 바에 따라 일정 정보는 보관할 수 있습니다.
									<br/>
									3. "회사"는 서비스 개선 및 "이용자" 대상의 서비스 소개 등의 목적으로 "이용자"의 동의 하에 관계 법령에서 정하는 바에 따라 추가적인 개인정보를 수집할 수 있습니다.
									<br/>
									4. "회사"는 법률에 특별한 규정이 있는 경우를 제외하고는 "이용자"의 별도 동의 없이 "이용자"의 계정정보를 포함한 일체의 개인정보를 제3자에게 공개하거나 제공하지 아니합니다.
									<br/>
									5. "회사"는 향후 제공하는 서비스에서 "이용자"의 편의를 위해서 "이용자"의 계정 정보를 사용할 수 있도록 링크 및 기타 방법을 제공할 수 있습니다.
									<br/>
									<br/>
									제 7장 기타
									<br/>
									제 31조 약관의 게시와 효력, 개정
									<br/>
									1. 본 약관은 “서비스”와 “스토어”를 이용하고자 하는 모든 "이용자"에 대하여 그 효력이 발생합니다.
									<br/>
									2. 본 약관의 내용은 서비스 내에 게시하는 방법으로 "이용자"에게 공지하고, 이에 동의한 "이용자"가 “회사”가 제공하는 서비스를 이용함으로써 효력이 발생합니다.
									<br/>
									3. "회사"가 약관을 변경할 경우에는 적용일자 및 변경사유를 서비스 내의 공지사항 게시판에 공지하는 방법으로 고지하며 그 적용일자 7일 전부터 공지합니다. 다만, "이용자"에게 불리한 약관의 변경인 경우에는 그 적용일자 30일 전부터 공지하며, 어플리케이션 알림(PNS)의 발송, 앱내 공지메뉴, 이메일 등으로 "이용자"에게 개별 통지합니다. 단, "이용자"의 연락처 미기재, 변경 후 미수정 등으로 인하여 개별 통지가 어려운 경우에 한하여 서비스 내의 공지사항 게시판에 공지를 함으로써 개별 통지한 것으로 간주합니다
									<br/>
									4. "이용자"는 "회사"가 전항에 따라 변경하는 약관에 동의하지 않을 권리가 있으며, 이 경우 "회원" "회사"에서 제공하는 서비스 이용 중단 및 탈퇴 의사를 표시하고 서비스 이용 종료를 요청할 수 있습니다. 다만, "회사"가 "이용자"에게 변경된 약관의 내용을 통보하면서 약관 변경 적용일까지 거부 의사 표시를 하지 않을 경우 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 불구하고, 거부의 의사표시를 하지 아니한 경우 "이용자"가 변경된 약관에 동의하는 것으로 봅니다.
									<br/>
									<br/>
									제 32조(재판권 및 준거법)
									<br/>
									1. ”회사”와 "이용자"간에 발생한 본 약관과 관련된 분쟁에 관한 소송은 서울중앙지방법원 전속관할로 합니다.
									<br/>
									2. ”회사”와 "이용자"간에 제기된 본 약관과 관련된 소송에는 한국 법을 적용합니다.
									<br/>
									<br/>
									제 33조 약관의 해석과 예외 준칙
									<br/>
									1. "회사"는 제공하는 개별 서비스에 대해서 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우 개별 서비스의 이용약관을 우선하여 적용합니다.
									<br/>
									2. 본 약관에 명시되지 않은 사항이 관계법령에 규정되어 있을 경우에는 그 규정에 따릅니다.
									<br/>

								</div>

							</div>
						</div>
					</Modal>

					<Modal
						isOpen={this.state.modalTermsView2}
						className="modalBox modalSizeS"
					>
						<div className="modalWrap">
							<div className="modalHeader">
								<strong>개인정보 수집 및 이용 동의</strong>
								<button type="button" className="icon close xBkThin" onClick={() => { this.setState({modalTermsView2 : false}) }}></button>
							</div>							
							<div className="modalContent full">
								<div className="termsBox">
									<strong>페이버릿 개인정보 수집 및 이용 동의</strong>
									<p>2020년 12월 28일 업데이트</p>
										

										주식회사 언노운즈(이하 '회사')은 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’), 개인정보보호법 등 관련 법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
										<br/>
										회사는 개인정보처리방침을 개정하는 경우 개별 공지 (또는 웹 사이트 공지사항)를 통하여 공지합니다.
										<br/>
										본 방침은 2020년 12월 28일부터 시행됩니다.
										<br/>

										1. 개인정보 수집 목적 및 항목
										<br/>
										회사는 다음의 목적을 위해 개인정보를 처리합니다.
										<br/>
										<br/>
										<table>
											<tbody>
											<tr>
												<th>목적</th>
												<th>구분</th>
												<th>필수항목</th>
												<th>선택항목</th>
											</tr>
											<tr>
												<td rowSpan="2">회원가입 및 관리</td>
												<td>본인확인</td>
												<td>전화번호</td>
												<td></td>
											</tr>
											<tr>
												<td>회원제 서비스 제공 및 유지관리</td>
												<td>이메일, 비밀번호, 성명</td>
												<td>성별, 생년월일</td>
											</tr>
											<tr>
												<td rowSpan='5'>재화 또는 서비스 제공</td>
												<td>회원 주문</td>
												<td>수취인 성명, 연락처, 주소</td>
												<td></td>
											</tr>
											<tr>
												<td>비회원 주문</td>
												<td>구매자 성명, 연락처<br/>수취인 성명, 연락처, 주소</td>
												<td></td>
											</tr>
											<tr>
												<td>카드 결제</td>
												<td>카드번호, 유효기간, 생년월일, 비밀번호(앞 두자리)</td>
												<td></td>
											</tr>
											<tr>
												<td>무통장 입금</td>
												<td>입금자 성명</td>
												<td></td>
											</tr>
											<tr>
												<td>현금 영수증</td>
												<td>입금자 성명, 휴대전화 번호</td>
												<td></td>
											</tr>
											<tr>
												<td colSpan="2">서비스 이용 문의</td>
												<td colSpan="2">카카오톡 ID</td>
											</tr>
											<tr>
												<td rowSpan="3">마케팅 정보</td>
												<td>뉴스레터</td>
												<td>이메일</td>
												<td></td>
											</tr>
											<tr>
												<td>이벤트 알림</td>
												<td>이메일, 휴대전화 번호</td>
												<td></td>
											</tr>
											<tr>
												<td>맞춤 서비스</td>
												<td>이메일, 휴대전화 번호, 쿠키</td>
												<td></td>
											</tr>
											<tr>
												<td colSpan="2">채용</td>
												<td colSpan="2">지원자 이력서</td>
											</tr>
											</tbody>
										</table>
										<br/>
										<br/>
										•	회사가 처리하는 회원 정보의 목적과 항목이 변경될 경우 관련 법령에 따라 사전에 동의를 요청합니다.
										<br/>
										2. 개인정보의 보유 및 이용 기간
										<br/>
										•	관련법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 보유·이용합니다.
										<br/>
										•	각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
										<br/>
										<br/>
										<table>
											<tbody>
											<tr>
												<th>목적</th>
												<th>근거</th>
												<th>기간</th>
											</tr>
											<tr>
												<td>거래내역 및 증빙서류와 관련 정보</td>
												<td>국제 기본법, 법인 세법</td>
												<td>5년</td>
											</tr>
											<tr>
												<td>표시 광고에 대한 기록</td>
												<td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
												<td>6개월</td>
											</tr>
											<tr>
												<td>계약 또는 청약철회 등에 관한 기록</td>
												<td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
												<td>5년</td>
											</tr>
											<tr>
												<td>대금 결제 및 재화 등의 공급에 관한 기록</td>
												<td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
												<td>5년</td>
											</tr>
											<tr>
												<td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
												<td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
												<td>5년</td>
											</tr>
											<tr>
												<td>접속에 관한 기록 보존</td>
												<td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
												<td>3년</td>
											</tr>
											<tr>
												<td>대금 결제 및 재화 등의 공급에 관한 기록</td>
												<td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
												<td>3개월</td>
											</tr>
											<tr>
												<td>상법 등 관련 법력에 따라 보존할 필요성이 있는 경우</td>
												<td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
												<td>10년</td>
											</tr>
											</tbody>
										</table>
										<br/>
										<br/>
										3. 개인정보의 처리 위탁
										<br/>
										•	회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
										<br/>
										서버 관리 사업자 및 카드PG사 추가 해야함 (수탁자/ 위탁업무 / 연락처 )
										<br/>
										•	회사는 위탁 계약 체결 시 관련법령에 따라 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다
										<br/>
										•	위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
										<br/>
										4. 개인정보의 국외 이전
										<br/>
										•	회사는 개인정보를 국외의 다른 사업자에게 제공하지 않습니다.
										<br/>
										<br/>
										5. 개인정보의 제3자 제공에 관한 사항
										<br/>
										•	회사는 다음의 목적을 위해 이용자의 사전동의를 받아 제품을 구매한 셀러에게만 제공합니다.
										<br/>

										<table>
											<thead>
												<tr>
													<td>제공처</td>
													<td>제공목적</td>
													<td>제공항목</td>
													<td>제공사 보유기간</td>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>셀러</td>
													<td>구매한 상품의 배송, 교환, 환불</td>
													<td>구매자의 개인정보(ID,성명,이메일,전화번호,수취인 성명, 수취인 연락처,배송지)</td>
													<td>중계의뢰 서비스 계약종료 시</td>
												</tr>
											</tbody>
										</table>
										<br/>
										<br/>
										
										•	회사는 상기 목적 외 개인정보 제3자 제공이 필요할 경우, 관련법령에 따라 사전에 동의를 요청합니다. 이 경우 해당내용을 본 개인정보처리방침에 공개하도록 하겠습니다.
										<br/>
										•	회사는 이용자의 개인정보를 사전동의 없이 제 3자에게 제공할 수 없습니다. 다만 다음의 경우에는 예외로 합니다.
										<br/>
										o	통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
										<br/>
										o	관계법령에 의하여 국가기관으로부터 요구 받은 경우
										<br/>
										o	범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의 요청이 있는 경우
										<br/>
										o	기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
										<br/>
										<br/>
										6. 이용자의 권리와 의무 및 그 행사방법
										<br/>
										•	만 14세 미만 아동의 개인정보를 수집하지 않습니다.
										<br/>
										•	이용자는 등록되어 있는 이용자 본인의 개인정보 관련하여, 다음과 같은 권리를 행사할 수 있습니다.
										<br/>
										o	이용자는 서면, 전자우편, 홈페이지 등을 통하여 개인정보 처리 정지 및 삭제를 요구 할 수 있으며 회사는 요청내용을 확인한 즉시결과를 이용자에게 제출합니다.
										<br/>
										o	이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요청한 경우에는 정정 또는 삭제를 완료하기 전까지 당해 개인정보를 이용하거나 제공하지 않습니다
										<br/>
										o	개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
										<br/>
										•	이용자 권리 행사(열람, 정정, 처리정지, 삭제)는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출해야 합니다.
										<br/>
										•	개인정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
										<br/>
										•	이용자는 개인정보주체로서 다음과 같은 의무를 가집니다.
										<br/>
										o	이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.
										<br/>
										o	이용자는 계정 등에 대해 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.
										<br/>
										o	타인의 개인정보를 도용하여 서비스 신청 시 이용자격 상실과 함께 관계법령에 의거하여 처벌될 수 있습니다.
										<br/>
										<br/>
										7. 개인정보의 파기
										<br/>
										•	회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체 없이 해당 개인정보를 파기합니다. 파기의 절차 및 방법은 다음과 같습니다.
										<br/>
										o	파기절차
										<br/>
										♣	이용자가 입력한 정보는 목적 달성 후 즉시 파기 합니다. 다만, 1년간 서비스를 이용하지 않은 회원은 이를 이메일로 고지하고 이용자의 별도의 요청이 없는 경우 이용고객정보와 분리하여 안전하게 별도 보관합니다.
										<br/>
										♣	별도 DB로 옮겨진 개인정보는 법률에 의한 경우를 제외하고는 다른 목적으로 이용되지 않으며 관리자 외 접근을 엄격하게 통제하고 있습니다.
										<br/>
										o	파기방법
										<br/>
										♣	전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
										<br/>
										♣	종이에 출력된 개인정보는 파쇄기를 통하여 파기합니다.
										<br/>
										<br/>
										8. 개인정보 자동 수집 장치의 설치와 운영 및 거부에 관한 사항
										<br/>
										•	회사는 이용자에게 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
										<br/>
										•	쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자 PC 컴퓨터의 하드디스크에 저장되기도 합니다.
										<br/>
										o	쿠키의 사용목적
										<br/>
										♣	이용자에게 최적화된 정보 제공
										<br/>
										♣	개인 맞춤형 상품 광고 및 마케팅
										<br/>
										♣	로그인 세션 유지
										<br/>
										o	쿠키의 설치∙운영 및 거부 방법 : 아래 방법을 통해 쿠키 저장을 거부 할 수 있습니다.
										<br/>
										♣	Internet Explorer의 경우 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정
										<br/>
										♣	Microsoft Edge의 경우 : 웹브라우저 상단 메뉴 > 설정 > 고급 설정 보기 > 쿠키 메뉴의 옵션 설정
										<br/>
										♣	Chrome의 경우 : 웹브라우저 상단 메뉴 > 설정 > 고급 > 콘텐츠 설정 > 쿠키 메뉴의 옵션 설정
										<br/>
										o	쿠키 저장을 거부할 경우, 서비스 이용에는 문제가 없으나 맞춤형 서비스를 이용 하실 수 없습니다.
										<br/>
										<br/>
										9. 개인정보보호책임자 안내
										<br/>
										•	회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.
										<br/>
										o	개인정보보호 책임자
										<br/>
										♣	성명 : 김형호
										<br/>
										♣	직책 : 대표이사
										<br/>
										♣	연락처 : paul@favorit.kr
										<br/>
										•	이용자는 회사 서비스를 이용하면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자로 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
										<br/>
										<br/>
										10. 개인정보의 안전성 확보 조치
										<br/>
										•	회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
										<br/>
										o	개인정보 취급 직원의 최소화 및 교육
										<br/>
										♣	개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
										<br/>
										o	내부관리계획의 수립 및 시행
										<br/>
										♣	개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
										<br/>
										o	접속기록의 보관 및 위변조 방지
										<br/>
										♣	개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
										<br/>
										o	개인정보의 암호화
										<br/>
										♣	이용자의 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.
										<br/>
										o	해킹 등에 대비한 기술적 대책
										<br/>
										♣	회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
										<br/>
										o	개인정보에 대한 접근 제한
										<br/>
										♣	개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있습니다.
										<br/>
										<br/>
										11. 정보주체의 권익침해에 대한 구제방법
										<br/>
										•	정보주체의 권익침해 관련 자세한 도움이 필요하시면 아래 기관에 문의하여 주시기 바랍니다.
										<br/>
										o	개인정보 침해신고센터 (한국인터넷진흥원 운영)
										<br/>
										♣	소관업무 : 개인정보 침해사실 신고, 상담 신청
										<br/>
										♣	홈페이지 : privacy.kisa.or.kr
										<br/>
										♣	전화 : (국번없이) 118
										<br/>
										♣	주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
										<br/>
										o	개인정보 분쟁조정위원회
										<br/>
										♣	소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
										<br/>
										♣	홈페이지 : www.kopico.go.kr
										<br/>
										♣	전화 : (국번없이) 1833-6972
										<br/>
										♣	주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
										<br/>
										o	대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
										<br/>
										o	경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)
										<br/>
										<br/>
										12. 개인정보처리방침 변경에 관한 사항
										<br/>
										
										•	이 개인정보처리방침은 시행일로부터 적용되며, 관련법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 홈페이지를 통하여 고지할 것입니다.
										<br/><br/>
										


										개인정보 수집 및 이용 안내 (고객) <br/>
										<br/>
										주식회사 언노운즈(이하 '회사')는 수집한 개인정보를 관리함에 있어서 「정보통신방 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보보호법」에서 규정하고 있는 책임과 의무를 준수하고 있습니다. 개인정보 수집 및 이용과 관련하여 다음의 몇 가지 사항을 안내 드리오니 관련내용을 숙지하신 후 해당 내용에 동의하여 주시기 바랍니다.
										<br/>
										개인정보 수집 및 이용 동의 (필수)
										<br/>
										•	페이버릿 서비스 이용자의 개인정보를 수집하는 목적은 다음과 같습니다.
										<br/>
										<table>
											<tbody>
											<tr>
												<th>수집 목적</th>
												<th>수집 항목</th>
											</tr>
											<tr>
												<td>본인 확인</td>
												<td>전화번호</td>
											</tr>
											<tr>
												<td>회원 가입 및 서비스 제공</td>
												<td>이메일, 비밀번호, 성명</td>
											</tr>
											</tbody>
										</table>
										<br/>
										•	동의를 거부할 권리가 있으나, 동의거부에 따른 서비스 이용에 제한이 있을 수 있습니다.
										<br/>
										•	회사는 계약 및 서비스 이행을 위해 개인정보 처리업무를 위탁할 수 있으며, 개인정보처리방침에 그 내용을 고지합니다.
										<br/>
								</div>

							</div>
						</div>
					</Modal>

					{/* 회원가입 완료 팝업 */}
					<Modal
						isOpen={modalJoinSuccessView}
						className="modalBox modalSizeS"
						style={{ content: { minWidth: 440 } }}
					>
						<div className="modalWrap">
							<h2>
								{this.iText('회원가입 완료', `login.joinSuccess`)}
							</h2>
							<div className="modalContent">
								<p style={{ textAlign: 'center' }}>{this.iText('축하합니다. 회원가입이 완료되었습니다!', 'join.successJoin')}</p>
								<div className="btnWrap">
									<button className="btn" onClick={() => {this.goMainPage()}}>{this.iText('확인', `common.confirm`)}</button>
								</div>
							</div>
						</div>
					</Modal>
				</div>
				{/* <!-- 본인인증 서비스 팝업을 호출하기 위해서는 다음과 같은 form이 필요합니다. --> */}
				
			</React.Fragment>
		);
    }
}

export default Join;