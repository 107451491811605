import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import cookie from 'react-cookies'
import {FarmSearchModal} from 'components/common/modal';
import searchIcon from "img/icon-search.png";

class GatewayDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params : {
                classCode: "",
                delYn: null,
                farmId: null,
                installCost: null,
                installDate: null,
                ip: "",
                manager: null,
                modDate: null,
                modId: null,
                name: "",
                note: null,
                port: 502,
                protocolId: '',
                readTerm: 60,
                regDate: null,
                regId: null,
                statusChangedYn: "N",
                useYn: "N",
                warrantyEndDate: null,
                farmName:'',
            },
			list : [],
            protocolList : [],
            farmList : [],
            gatewayTypeList : [],
            modalVisible:false,
            validCheckList : Object.freeze(['name','ip','port','protocolId','farmName','classCode']),
		};
		nbind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.mappingData = this.mappingData.bind(this);
        this.modalHandler = this.modalHandler.bind(this);
        this.name = React.createRef();
        this.ip = React.createRef();
        this.port = React.createRef();
        this.protocolId = React.createRef();
        this.farmName = React.createRef();
        this.classCode = React.createRef();
        this.contentInner = React.createRef();
	}
	componentDidMount() {
        // this.getFarmList();
        this.protocolCall();
        this.getGateWayList();
        window.addEventListener('click',this.handleCloseModal);
	}
    protocolCall(){
        API.protocol().then(result => {
			
            this.setState({
                protocolList : result
           })
		}).catch(error => console.log(error));
    }
    
    // getFarmList(){		
	// 	let obj = {
	// 		userId : cookie.load("userId"),
	// 	}
	// 	API.userFarmList(obj).then(result => {
	// 		this.setState({farmList : result})	
			
									
	// 	}).catch( error => console.log(error));			
	// }
    getGateWayList(){		
		API.gatewayType().then(result => {
			this.setState({gatewayTypeList : result})	
		}).catch( error => console.log(error));			
	}
    save(){

        for(let i = 0; i < this.state.validCheckList.length; i++){
            let target = this.state.validCheckList[i];
            if(!this.state.params[target] /*|| !this.state.params[target].trim()*/){
                this[target].current.focus();
                return alert('유효한 입력이 아니거나 필수값이 입력되지 않았습니다\n*항목은 필수 입력값 입니다.');
            }
        }

        this.props.setLoading(true)
        
        API.farmgatewayRegiste(this.state.params).then(result => {
            let windowConfirm = window.confirm('등록 완료 이전페이지로 돌아가시겠습니까?');
            if(windowConfirm){
                window.history.back();
            }
		}).catch(error => {
            console.log(error)
        }).
        finally(()=>{
            this.props.setLoading(false)
        });
    }

    componentWiilUnMount(){
        window.removeEventListener('click',this.handleCloseModal);
    }

    handleCloseModal(e){
        if(this.state.modalVisible && e.target.id == 'modal'){
            this.setState({modalVisible:false});
        }
    }
    modalHandler(){
        this.setState({modalVisible:false})
    }
    mappingData(data){
        this.setState({params:{...this.state.params,farmName:data.name,farmId:data.id}});
    }
	render() {
		return (
			<React.Fragment>
                    {this.state.modalVisible &&
                    <FarmSearchModal 
                            el={this.contentInner.current}
                            mappingData={this.mappingData}
                            apiName={'fetchFarmName'}
                            modalHandler={this.modalHandler}
                            keyword={'farmName'}
                            loading={this.props.setLoading}
                        />
                    }
				<div className="contentInner" ref={this.contentInner}>
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장 게이트웨이 등록</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름*</th>
                                            <td>
                                                <input type="text" value={this.state.params.name} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.name = e.target.value////////
                                                        this.setState({ params: data }) 
                                                    }
                                                }
                                                ref={this.name}
                                                />
                                            </td>
                                            <th>농장*</th>
                                            <td>
                                                <div style={{display:'flex',alignItems:'center'}}>
                                                    <input type='text' value={this.state.params.farmName} readOnly style={{width:'90%'}} ref={this.farmName}/>
                                                    <img src={searchIcon} style={{resizeMode:'contain',width:'5%',marginLeft:'auto'}} onClick={() =>this.setState(prev => ({modalVisible : !prev.modalVisible}))}/>
                                                </div>
                                            </td>
                                            {/* <td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.params.farmId} placeholder="제목 + 내용"
                                                        onChange={(e) => {
                                                            let data = this.state.params; 
                                                            data.farmId = e.target.value///////////
                                                            this.setState({ params: data })
                                                        }}>
                                                        <option value="">선택</option>
                                                        {this.state.farmList.map((item,index)=>{
                                                            return(
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        })}
                                                        
                                                    </select>
                                                </div>
                                            </td> */}
                                        </tr>
                                        <tr>
                                            <th>IP*</th>
                                            <td><input type="text" value={this.state.params.ip}onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.ip = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}
                                                ref={this.ip}
                                                />
                                            </td>
                                            <th>PORT*</th>
                                            <td><input type="text" value={this.state.params.port} onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.port = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}
                                                ref={this.port}
                                            />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>종류*</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.params.classCode} placeholder="제목 + 내용"
                                                    onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.classCode = e.target.value///////////
                                                        this.setState({ params: data })
                                                    }}
                                                    ref={this.classCode}
                                                    >
                                                        <option value="">선택</option>
                                                        {this.state.gatewayTypeList.map((item,index)=>{
                                                            return(
                                                                <option value={item.minorCode}>{item.name}</option>
                                                            )
                                                        })}
                                                        
                                                    </select>

                                                </div>
                                            </td>
                                            <th>설치 날짜</th>
                                            <td>
                                                <DatePicker
                                                    className="dfhadjklfhls"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date()}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.installDate; } 
                                                        let data = this.state.params; 
                                                        let strDate = date.toISOString();
                                                        data.installDate = strDate.substring(0,strDate.lastIndexOf('T')).trim();
                                                        this.setState({ params: data }) 
                                                    }}
                                                    value={this.state.params.installDate}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>프로토콜*</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.params.protocolId} placeholder="제목 + 내용"
                                                    onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.protocolId = e.target.value///////////
                                                        this.setState({ params: data })
                                                    }}
                                                    ref={this.protocol}
                                                    >
                                                        <option value="">선택</option>
                                                        {this.state.protocolList.map((item,index)=>{
                                                            return(
                                                                <option value={item.id}>{item.name}</option>
                                                            )
                                                        })}
                                                        
                                                    </select>

                                                </div>
                                            </td>
                                            <th>장치 담당자</th>
                                            <td>
                                               <input type="text" name="" value={this.state.params.manager} onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.manager = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>설치비용</th>
                                            <td>
                                                <input type="text" name="" value={this.state.params.installCost} onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.installCost = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}/>
                                            </td>
                                            <th>무상수리 만료일</th>
                                            <td>
                                                <DatePicker
                                                    className="dfhadjklfhls"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date()}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.warrantyEndDate; } 
                                                        let data = this.state.params; 
                                                        let strDate = date.toISOString();
                                                        data.warrantyEndDate = strDate.substring(0,strDate.lastIndexOf('T')).trim();
                                                        this.setState({ params: data }) 
                                                    }}
                                                    value={this.state.params.warrantyEndDate}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>사용여부</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" value={this.state.params.useYn} id="" placeholder="제목 + 내용" onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.useYn = e.target.value
                                                        this.setState({ params: data })
                                                    }}>
                                                        <option value="">사용여부</option>
                                                        <option value="Y">사용</option>
                                                        <option value="N">미사용</option>
                                                    </select>

                                                </div>
                                            </td>
                                            <th>읽는 주기</th>
                                            <td>
                                                <input type="number" value={this.state.params.readTerm} onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.readTerm = e.target.value///////////
                                                    this.setState({ params: data })
                                                }} />

                                            </td>
                                        </tr>
                                        <tr>
                                            <th>메모</th>
                                            <td colSpan="3">
                                                <textarea name=""  value={this.state.params.note}id="" cols="30" rows="10" onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.note = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}></textarea>
                                            </td>
                                        
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button  onClick={()=>this.save()} className="btn mr5 mt10">저장</button>
                                <Link to="/gateway" className="btn gray ml30">취소</Link>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default GatewayDetail;