import React from 'react';
import styled from 'styled-components';
import icon_success from 'img/success.png'
import icon_fail from 'img/fail.png'


export const ControlMessage = ({ fetchRes }) => {

    if (fetchRes == null) {
        return <></>;
    }

    return (
        <MessageWrap>
            <MessageImg src={fetchRes ? icon_success : icon_fail} />
            <Message success={fetchRes}>제어 {fetchRes ? '성공' : '실패'}</Message>
        </MessageWrap>

    );
}

// E56F6F
const MessageWrap = styled.div`
display:flex ;
align-items:center ;
justify-content:center ;
`
const MessageImg = styled.img`
width:1.8rem ;
height:1.8rem ;
margin-right:3px ;
`
const Message = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 23px;


color: ${props => props.success ? '#2A3A4B' : '#E56F6F'};
`
