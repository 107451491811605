import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { code_mapping, imageTable, image_mapping, offImage_mapping } from 'components/utils/mapping';

import heatexOff from 'img/tools_item/04_exchanger_ off.png'
import heatexWeak from 'img/tools_item/03_exchanger_ light.png'
import heatexMiddle from 'img/tools_item/02_exchanger_ middle.png'
import heatexGail from 'img/tools_item/01_exchanger_ strong.png'
import red_question from 'img/red_question.svg'
import common from 'img/tools_item/common.png'
import PropTypes from 'prop-types';
import { euqipClassProps, sensorProps } from 'js/propType';
import { CARBONHEATERPOWER, CIRCULATIONFAN, HEATEXCHANGER, HVAC, HVACTEMP, HVACTEMPHUM, IRRIGATIONMOTOR, LEFTSIDEWINDOW, LIGHT, NUTRIENTEQUIP, PERIODIRRIGATIONMOTOR, PERIODSTEPMOTOR, RIGHTSIDEWINDOW, SECONDLEFTAST, TIMECONTROLIRRIGATIONMOTOR, TIMECONTROLLIGHT, TOPWINDOW } from 'constants/equipInfo';
import { getEquipSensorInnerTagObj, statusTagControl } from 'components/utils/util';


const Wrapper = styled.div`
background: #FFFFFF;

transition-duration: 0.3s;

box-shadow: 0px 4px 8px rgba(125, 140, 154, 0.3);
border:0.1rem solid rgba(125, 140, 154, 0.2);

border-radius: 5px;
width: 11.8%;
height: 0px;
padding-bottom:14%;

margin: 0px 4px ;
margin-bottom: 23px;
max-height: 15rem;
cursor: pointer;

&:hover{
    transform: translate(0, -2rem);
}
`

const Wrap = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
margin-top: 1.8rem;

`

const Text = styled.div`
width: 100%;
font-family: Spoqa Han Sans Neo;
font-size: 1.6rem;
text-align: center;
font-weight: 500;
color: #2A3A4B;

`

const Img = styled.img`
width: 50px;
height: 50px;
`

const On = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2rem;
line-height: 23px;
/* identical to box height */

text-align: center;

/* color: ${props => props.equipClass == 15 ? "#F6BD16" : "#17B9B6"}; */
`

const Image_wrap = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
margin-top: 0.8rem;
`


// 장비
function Main(props) {
    const [name, set_name] = useState('')
    const [equipId, set_equipId] = useState(0)
    const [equipClassId, set_equipClassId] = useState(0)
    const [value, set_value] = useState(0)

    const [unit, set_unit] = useState("")
    const [status, set_status] = useState("")

    const [connectionError, set_connectionError] = useState(false);

    const equipObjRef = useRef(props.data);



    const valueControl = (tagObj) => {
        try {
            if (tagObj.tagClass.isDigital == 'N') {
                return tagObj.value;
            }
            const targetValue = tagObj.tagClass.tagClassActions.find(e => e.value == tagObj.value);
            if (targetValue == undefined) {
                return 'targetObj is not found'
            }
            let value = targetValue.name;
            if (tagObj.tagClass.code == HEATEXCHANGER.status) {
                switch (targetValue.name) {
                    case 'OFF':
                        value = 'OFF';
                        break;
                    case 'WEAK':
                        value = '약';
                        break;
                    case 'MIDDLE':
                        value = '중';
                        break;
                    case 'GAIL':
                        value = '강';
                        break;
                }
            }
            return value;
        } catch (error) {
            console.error(error.message);
            return 'targetObj is not found'
        }
    }

    useEffect(() => {
        try {
            set_connectionError(false)
            if (props != []) {
                let equip = props.data
                if (equip.normalYn == 'N') {
                    set_connectionError(true)
                }
                set_name(equip.name)
                set_equipId(equip.equipId)
                set_equipClassId(equip.equipClass.equipClassId)
                let statusTag = statusTagControl(equip, equip.equipClass.code)

                if (statusTag === undefined) {
                    console.log(equip.equipClass.code)
                    return;
                }

                if (statusTag == undefined || statusTag.normalYn === 'N') {
                    set_value('통신 에러')
                    set_unit('');
                    set_status("err");
                    return;
                }
                set_value(valueControl(statusTag));

                if (statusTag == false) {
                    set_unit('')
                } else {
                    set_unit(statusTag.tagClass.unit ? statusTag.tagClass.unit : '');
                }
                equipObjRef.current = equip;
            }
        } catch (err) {
            console.log(err);
            alert(err.message)
        }
    }, [props]);

    const imageControl = () => {

        try {
            if (equipObjRef.current == undefined) throw Error('no equip Data');

            let classCode = equipObjRef.current?.equipClass.code;
            if (!classCode) throw Error('no classCode');
            let statusTag = statusTagControl(equipObjRef.current, classCode);

            if (statusTag == false) throw Error('no equip status tag');

            let img;
            let imgObj;
            // 모든 장비별 이미지
            // normalYn 체크하여 off도 체크 해야함
            // statusTag.tagClass.isDigital 값으로 tagClassActions 객체를 사용할건지 판단
            // tagClassAction 사용 시 tagClassActions의 name으로 on / off 판단

            /* 
                classCode로 장비 이미지 불러온 후 normalYn, off시 off 이미지 맵핑    
            */

            if (classCode.includes('HEATEX')) {

                let equipValue = statusTag.value;
                const { name } = statusTag.tagClass.tagClassActions.find(e => e.value === equipValue);

                if (name === 'OFF') {
                    img = heatexOff;
                } else if (name === 'WEAK') {
                    img = heatexWeak;
                } else if (name === 'MIDDLE') {
                    img = heatexMiddle;
                } else if (name === 'GAIL') {
                    img = heatexGail;
                } else {
                    img = heatexOff;
                }

                return img;
            }

            switch (classCode) {
                case LEFTSIDEWINDOW.equipClass:
                case SECONDLEFTAST.equipClass:
                    imgObj = imageTable.LEFTSIDEWINDOWIMG;
                    break;
                case RIGHTSIDEWINDOW.equipClass:
                    imgObj = imageTable.RIGHTSIDEWINDOWIMG;
                    break;
                case TOPWINDOW.equipClass:
                    imgObj = imageTable.TOPWINDOWIMG;
                    break;
                case CARBONHEATERPOWER.equipClass:
                case NUTRIENTEQUIP.equipClass:
                    imgObj = imageTable.NANBANGIMG;
                    break;
                case LIGHT.equipClass:
                case TIMECONTROLLIGHT.equipClass:
                    imgObj = imageTable.LIGHTIMG;
                    break;
                case CIRCULATIONFAN.equipClass:
                    imgObj = imageTable.FANIMG;
                    break;
                case HVAC.equipClass:
                case HVACTEMPHUM.equipClass:
                case HVACTEMP.equipClass:
                    imgObj = imageTable.HVACIMG;
                    break;
                case HEATEXCHANGER.equipClass:
                    imgObj = imageTable.LIGHTIMG;
                    break;
                case TIMECONTROLIRRIGATIONMOTOR.equipClass:
                case IRRIGATIONMOTOR.equipClass:
                case PERIODIRRIGATIONMOTOR.equipClass:
                    imgObj = imageTable.GWANSUIMG;
                    break;
                case PERIODSTEPMOTOR.equipClass:
                    imgObj = imageTable.GEARIMG;
                    break;
                default:
                    imgObj = null;
            }

            if (imgObj == null || imgObj == undefined) {
                return common;
            }

            if (statusTag.normalYn == 'N') {
                return imgObj.off
            }

            return imgObj.on;

        } catch (error) {
            console.log(error.message);
            return common;
        }

    }

    return (
        <>
            {connectionError ?
                <Wrapper style={{ backgroundColor: '#F5F5F5' }}>
                    <Wrap>
                        <Text style={{ color: '#858585' }}>{name}</Text>
                    </Wrap>
                    <Image_wrap>
                        <Img src={offImage_mapping[equipClassId]} />
                    </Image_wrap>
                    <Wrap style={{ marginTop: '0.6rem' }}>
                        <ConnectionErrorImg src={red_question} />
                        <ConnectionErrorMessage>점검필요</ConnectionErrorMessage>
                    </Wrap>
                </Wrapper>
                :
                <Wrapper onClick={() => props.onClick(equipId)}>
                    <Wrap>
                        <Text>{name} </Text>
                    </Wrap>
                    <Image_wrap>
                        <Img src={imageControl()} />
                    </Image_wrap>
                    <Wrap style={{ marginTop: '0.6rem' }}>
                        <On style={{ color: 'rgb(137, 140, 143)' }}>
                            {value}
                        </On>
                        <CelsiusDegree style={{ color: 'rgb(137, 140, 143)' }} unit={unit}> {unit}</CelsiusDegree>
                    </Wrap>
                </Wrapper>
            }
        </>
    );
}

// Main.propTypes = {
//     data: {
//         actuators: [],
//         equipClass: euqipClassProps,
//         equipId: PropTypes.number,
//         localtion: PropTypes.string,
//         name: PropTypes.string,
//         order: PropTypes.number,
//         sensors: PropTypes.arrayOf(PropTypes.shape({ sensorProps }))
//     }
// }

const ConnectionErrorMessage = styled.span`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
/* identical to box height */

text-align: center;

color: #858585;
`
const ConnectionErrorImg = styled.img`
width: 2rem;
height: 2rem;
`

const CelsiusDegree = styled.div`
display: flex;
height: 23px;
align-items: ${props => props.unit == '℃' ? 'flex-start' : 'flex-end'};
/* color:#F6BD16; */
 font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.4rem;
color: #17B9B6;
padding: 0.2rem 0;
 `
export default Main;