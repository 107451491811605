import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import cookie from "react-cookies"; // *
import MainChartsData from "../../components/hicharts/MainChartsData";

class MainData extends React.Component {
	constructor(props) {
		super(props);
		this.childElement = React.createRef();
		this.state = {
			userId: cookie.load("userId"),
			containerId: 5,
			containerList: [],
			localList : [],
			treeNodeId : null,
			farmList : [],
			selectFarm : null,

			email :  '',
			content : '',
			user : '',
			pageIndex:1,
			pageSize : 1,
			list : []
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}
	onChangePage(index) {
		this.setState({ pageIndex: index }, () => alert('페이지변경'));
	}
	componentDidMount() {
		this.localList()
	}

	localList(){
		let obj = {
			userId: this.state.userId
		};

		API.localList(obj).then(result => {
			this.setState({localList : result,treeNodeId : result[0] ? result[0].id : null})
			if(result[0]){
				this.localFarmList(result[0].id)
			}else{

			}
		}).catch(error => console.log(error));
	}
	localFarmList (id){
		if(id){
			let obj = {
				userId: this.state.userId,
				treeNodeId : id//id
			};
			console.log(id)
			API.localFarmList(obj).then(result => {
				this.setState({farmList :result, selectFarm : result[0]? result[0] : null ,farmIndex : 0})
				this.containerList(result[0].id)
			}).catch(error => console.log(error));
		}else{
			this.setState({farmList :[], selectFarm :  null })
		}
	}

	farmChange(index){
		let target = this.state.farmList[index]
		this.containerList(target.id)
		//this.state.containerId = index;
	}

	containerList(id){
		let obj = {
			userId: this.state.userId,
			farmId : id
		};
		API.containerList(obj).then(result => {
			this.setState({containerList : result, containerId : result[0].id}, () => {
				this.changeContainer(result[0].id);
			});
		}).catch(error => console.log('ㅇㅇ',error));
	}

	changeContainer(id){
		//console.info("0 >>"+id);
		//console.info("state after >>"+this.state.containerId);

		this.setState({containerId : id}, () => {
			//console.info("state befaore 11 >>"+this.state.containerId);
			this.childElement.current.updateSeries()
		});

		console.info("state befaore 22 >>"+this.state.containerId);
	}


	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle">메인 데이터 분석</h2>
						<div>
							<div className="whBox">
								<div className="searchBox">
									<div className="locationSelect">
										<div className="selectBox">
											<select name="" id="" placeholder="지역" onChange={(e)=>this.localFarmList(e.target.value)}>
											<option value={''}>지역을 선택해주세요.</option>
											{this.state.localList.map((item,index)=>{
												return(
													<option selected={this.state.treeNodeId ==  item.id} value={item.id}>{item.name}</option>
												)
											})}
										</select>
										</div>
										<div className="selectBox">
											<select name="" id="" placeholder="농장" onChange={(e)=>this.farmChange(e.target.value)}>
											{this.state.farmList.map((item,index)=>{
												return(
													<option selected={this.state.selectFarm.id ==  item.id} value={index}>{item.name}</option>
												)
											})}
										</select>
										</div>
										<div className="selectBox">
											<select name="" id="" placeholder="지역" onChange={(e)=>this.changeContainer(e.target.value)}>
												{this.state.containerList.map((item,index)=>{
													return(
														<option selected={this.state.containerId ==  item.id} value={item.id}>{item.name}</option>
													)
												})}
											</select>
										</div>
									</div>
									{/*
									<div className="datePickerDuration flex">
										<span >시작일</span>
										<DatePicker
											className="mr10"
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={this.state.searchStartDate}
											maxDate={this.state.searchendDate}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { if (date === null) { date = this.state.searchEndDate; } this.setState({ searchStartDate: new Date(date) }) }}
										/>
										<span>종료일</span>
										<DatePicker
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={this.state.searchEndDate}
											minDate={this.state.searchStartDate}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { if (date === null) { date = this.state.searchStartDate; } this.setState({ searchEndDate: new Date(date) }) }}
										/>
									</div>

									<button className="ml10" type="button">검색</button>
									*/}
								</div>
							</div>
							<div className="mt30">
								{/*
								<ul className="boxTabBtn mb-20">
									<li className="on"><button>기본</button></li>
									<li><button>일별통계</button></li>
									<li><button>월별통계</button></li>
								</ul>
								*/}
								<div className="whBox p30">
									<div className="textRight">
										<button className="btn_down">차트옵션</button>
									</div>

									<MainChartsData userId={this.state.userId} containerId={this.state.containerId} ref={this.childElement} />

								</div>
							</div>
							{/*
                            <div className="whBox mt30 mb30 p30 ">
                                <div className="flex between">
                                    <div className="selectBox">
                                        <select name="" id="" placeholder="제목 + 내용">
                                            <option value="">평균</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                    <button className="btn_down">엑셀다운</button>
                                </div>
                                <div className="mt10 ">
                                    <div className="checkBox"><input type="checkbox" name="type" id="T1"/> <label htmlFor="T1">내부 온도1</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T2"/> <label htmlFor="T2">내부 온도2</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T3"/> <label htmlFor="T3">외부 온도1</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T4"/> <label htmlFor="T4">외부 온도2</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T5"/> <label htmlFor="T5">내부 습도1</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T6"/> <label htmlFor="T6">내부 습도2</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T7"/> <label htmlFor="T7">외부 습도1</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T8"/> <label htmlFor="T8">외부 습도2</label></div>
                                </div>
                            </div>
                            */}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default MainData;