import React, { Children } from 'react';
import styled from 'styled-components';
import icon_CheckCircle from "img/icon_CheckCircle.png"


function Main(props) {
    return ( 
        <Label >
        <Switch_input onClick={props.onClick} checked={props.checked} name="test" type="checkbox" />
        
        <Switch >{props.children.map(val=>{return val})}</Switch>
    </Label>
     );
}




const Label = styled.label`
  cursor: pointer;
  margin-left: 12px;
`;


const Switch = styled.div`
display: flex;

    
padding: 10px 20px 10px 10px;
height: 51px;
margin-right: 10px;
background: #FFFFFF;
border: 2px solid #DFDFDF;
box-sizing: border-box;
box-shadow: 0px 4px 5px rgba(125, 140, 154, 0.4);
border-radius: 5px;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;

margin-bottom: 12px;
cursor: pointer;

`;
const Switch_input = styled.input`
  opacity: 0;

  &:checked  {
    &+ ${Switch}{
        
        border: 2px solid #17B9B6;
      }

      &+ ${Switch}>img{
        content: url(${icon_CheckCircle});
      }
    
  }
`;

export default Main;
