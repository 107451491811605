import React from 'react';
import styled from 'styled-components';


function Main(props) {
    return (
        <SubWrap>
            <SubTitle color={props.titleColor}>{props.title}</SubTitle>
            <InputWrapper>
                <Input style={{color:props.color,fontSize:'2rem'}} type="number" color={props.color} value={props.data} onChange={props.handleChange} />
                <Unit color={props.color} unit={props.unit}>{props.unit}</Unit>
            </InputWrapper>
        </SubWrap>

    );
}

const SubWrap = styled.div`
width:100% ;
margin: 0.5rem 0;

`

const SubTitle = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.6rem;
line-height: 26px;

color:${props=>props.color||'#747474'} ;
margin-bottom:5px ;
`
const Input = styled.input`
flex:1;
background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 5px;
text-align:right ;
padding:0.5rem 1.2rem;

font-family: Spoqa Han Sans Neo;
font-style: normal;

font-size: 2rem;
line-height: 46px;
/* identical to box height */

color:${props=>props.color||'#2A3A4B'} ;
`
const Unit = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
display:flex ;
height:40px ;
align-items:${props=>props.unit=="℃"?'flex-start':'center'} ;

margin-left:5px ;
color:${props=>props.color||'#2A3A4B'} ;
`
const InputWrapper = styled.div`
display:flex ;
align-items:flex-end ;
`
export default Main;