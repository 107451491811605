import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux';
import { nbind } from 'js/common'
import * as actions from 'reducers/actions';
import _ from 'lodash';

class Alert extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
			visible: false,
			id: '',
			timer: '',
		};
		nbind(this);
	}
	
	componentDidMount() {
		let id = this.props.common.alert.id;
		if ( id !== '' && !_.isNil(id) ) {
			this.alert('', id);
		}
	
	}
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	}
	onRouteChanged() {
		// console.log("ROUTE CHANGED");
		// this.setState({ visible: false });
		// this.props.handleAlert({ id: '', visible: false })
		this.setState({ visible: true })
	}
	UNSAFE_componentWillReceiveProps (nextProps) {
		let visible = nextProps.common.alert.visible;
		
		if (this.props.location.pathname === nextProps.location.pathname){
			if (visible) {
				this.setState({ visible: false }, () => {
					this.setState({ visible: true })
				});
			} else {
				this.setState({ visible: false });
			}
		}else{
			if (visible) {
				this.setState({ visible: true })
			} else {
				this.setState({ visible: false });
			}
		}
		
		
	}
	// componentWillUnmount () {
	// 	this.props.handleAlert({id: '', visible: false})
	// }
	render () {
		return (
			<React.Fragment>
				{!this.props.common.alert.visible ?
					<div className="messageArea">
						<div className={"messageBox " + (this.props.common.alert.visible ? 'alertAnimation':'')}>
							<p style={{ whiteSpace: 'pre-line' }}>{this.iText(this.props.common.alert.value, this.props.common.alert.id, this.props.common.alert.value )}</p>
						</div>
						{/* <div className="messageDim">
							
						</div> */}
					</div>
					:
					null
				}
			</React.Fragment>
		)
	}
}

export default withRouter(injectIntl(connect(
	state => ({
		common: state.common,
	}),
	dispatch => ({
		handleAlert: (data) => {
			dispatch(actions.SET_ALERT(data))
		},
	})
)(Alert)));