import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { API } from 'service';
import styled, { keyframes } from 'styled-components';


const PestInformationCard = () => {

    return (
        <div>
            pestInformation
        </div>
    )

}

export default PestInformationCard;