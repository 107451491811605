const initialState = {
	// 선택 언어
	lang: false,
	alert: {
		visible: false,
		id: ''
	},
	confirm: {
		visible: false,
		id: ''
	},
	loading : false
};

export default function Reducer(state = initialState, action) {
	switch (action.type) {
		case 'LANG':
			return {
				...state,
				lang: action.data
			};
		case 'ALERT':
			return {
				...state,
				alert: action.data
			};
		case 'CONFIRM':
			return {
				...state,
				confirm: action.data
			};
		case 'LOADING':
			return {
				...state,
				loading: action.data
			};
		default:
			return state;
	}
}