import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import HighStock from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HDChartsData from "../../components/hicharts/HDChartsData";
import MainChartsData from "../../components/hicharts/MainChartsData";
import cookie from "react-cookies"; // *

class HDData extends React.Component {
	constructor(props) {
		super(props);
		this.childElement = React.createRef();
		this.state = {
			userId: cookie.load("userId"),
			containerId: 5,
			farmId:null,
			containerList: [],
			localList : [],
			treeNodeId : null,
			farmList : [],
			selectFarm : null,

			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 1,
			list : []
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}
	onChangePage(index) {
		this.setState({ pageIndex: index }, () => alert('페이지변경'));
	}
	componentDidMount() {
		this.localList()
	}

	localList(){
		let obj = {
			userId: this.state.userId
		};

		API.localList(obj).then(result => {
			this.setState({localList : result,treeNodeId : result[0] ? result[0].id : null})
			if(result[0]){
				this.localFarmList(result[0].id)
			}else{

			}
		}).catch(error => console.log(error));
	}
	localFarmList (id){
		if(id){
			let obj = {
				userId: this.state.userId,
				treeNodeId : id
			};
			console.log(id)
			API.localFarmList(obj).then(result => {
				this.setState({farmList :result, selectFarm : result[0]? result[0] : null ,farmIndex : 0})
				//this.containerList(result[0].id)
				this.farmChange(0)
			}).catch(error => console.log(error));
		}else{
			this.setState({farmList :[], selectFarm :  null })
		}
	}

	farmChange(index){
		let target = this.state.farmList[index]
		//this.containerList(target.id)
		//this.state.containerId = index;

		this.setState({farmId : target.id}, () => {
			console.info("state farmId 11 >>"+this.state.farmId);
			this.childElement.current.updateSeries()
		});
	}


	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle">HD 데이터 분석</h2>
                        <div>
                            <div className="whBox">
                                <div className="searchBox">
									<div className="locationSelect">
									<div className="selectBox">
										<select name="" id="" placeholder="지역" onChange={(e)=>this.localFarmList(e.target.value)}>
										<option value={''}>지역을 선택해주세요.</option>
										{this.state.localList.map((item,index)=>{
											return(
												<option selected={this.state.treeNodeId ==  item.id} value={item.id}>{item.name}</option>
											)
										})}
									</select>
									</div>
									<div className="selectBox">
										<select name="" id="" placeholder="농장" onChange={(e)=>this.farmChange(e.target.value)}>
										{this.state.farmList.map((item,index)=>{
											return(
												<option selected={this.state.selectFarm.id ==  item.id} value={index}>{item.name} 농장</option>
											)
										})}
									</select>
									</div>
									</div>
									{/*
									<div className="datePickerDuration flex">
										<span >시작일</span>
										<DatePicker
											className="mr10"
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={this.state.searchStartDate}
											maxDate={this.state.searchendDate}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { if (date === null) { date = this.state.searchEndDate; } this.setState({ searchStartDate: new Date(date) }) }}
										/>
										<span>종료일</span>
										<DatePicker
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={this.state.searchEndDate}
											minDate={this.state.searchStartDate}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { if (date === null) { date = this.state.searchStartDate; } this.setState({ searchEndDate: new Date(date) }) }}
										/>
									</div>

									<button className="ml10" type="button">검색</button>
									*/}
                                </div>
                            </div>
							<div className="mt30">

								<div className="whBox p30">
                                    <div className="textRight">
                                        <button className="btn_down">차트옵션</button>
                                    </div>
									<HDChartsData userId={this.state.userId} farmId={this.state.farmId} ref={this.childElement}  />
								</div>
							</div>

                        
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

 
export default HDData;