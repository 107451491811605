import React from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  justify-content:center ;
`;
const Switch = styled.div`
display: flex;
align-items: center;
justify-content: space-around;
  position: relative;
  width: 229px;
  height: 48px;
  background: #E5E5E5;
  border-radius: 27.6264px;
  padding: 1.6rem;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "OFF";
    position: absolute;
    width: 128px;
    height: 48px;
    justify-content:right ;
    margin-left:98px ;
    background: #898C8F;
    border-radius: 26.8633px;
    font-family: Spoqa Han Sans Neo;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align:center ;
    line-height:48px ;

color: #FFFFFF;
    top: 50%;
    left: 4px;
    transform: translate(0, -50%);
  }

  
`;
const Switch_input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    &:before {
        content: "ON";
        background-color:#17B9B6 ;
      transform: translate(-105px, -50%);
    }
  }
`;

const Text=styled.span`

    font-family: Spoqa Han Sans Neo;
    font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 25px;


color: #C9C9C9;

`

function Main(props) {
    return (
        <Label>
            <Switch_input checked={props.isOn} type="checkbox" onChange={props.handleChange} />
            <Switch>
            <Text>ON</Text>
            <Text>OFF</Text>
              </Switch>
        </Label>
    );
}

export default Main;