import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import * as common from "js/common";


class deviceDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
		nbind(this);
	}
	componentDidMount() {
	}
    
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">제어 이력 상세정보</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>											
											<th>장치</th>
											<td>
                                            	<input type="text" value={this.props.location.state.item.equipName}/>
											</td>
											<th>제어 계정</th>
											<td>
                                            	<input type="text" value={this.props.location.state.item.regId}/>
											</td>
                                        </tr>
										<tr>
                                            <th>프로토콜 맵</th>
											<td>
                                            	<input type="text" value={this.props.location.state.item.protocolMap}/>	
											</td>
                                            <th>제어 시각</th>
											<td>
                                            	<input type="text" value={common.toDateString(this.props.location.state.item.regDate, `time`)}/>
											</td>
                                        </tr>
										<tr>									
                                            <th>길이</th>
											<td>
                                            	<input type="text" value={this.props.location.state.item.length}/>
											</td>
                                        </tr>
                                        <tr>					
                                            <th>제어 로그</th>
											<td colSpan="3" >
                                            	<textarea type="text"  value={this.props.location.state.item.content}  cols="30" rows="10"/>
											</td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                {/* <button onClick={()=>{this.update()}} className="btn mr5 mt10">수정</button> */}
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">목록</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default deviceDetail;