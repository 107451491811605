import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class deviceDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gatewayInfo : {
				name : null
			},
			containerList : [],
			typeList : [],
			deviceTypeList : [],
			gatewayContainerList : [],

			list : [
				{
					"name": null,        
			        "classCode": null,
			        "farmGatewayId": this.props.match.params.id,        
			        "classValue": null,
			        "containerId": null
				}
			],
			count : 1
		};
		nbind(this);
	}
	
	componentDidMount() {
		this.dataCall()
	}
	dataCall () {
		let obj = {
			id : this.props.match.params.id
		};
		// API.farmgatewayDetail(obj).then(result => {
        //     this.setState({gatewayInfo : result})
		// }).catch(error => console.log(error));
        API.deviceTypeList(obj).then(result => {
            this.setState({deviceTypeList : result})
		}).catch(error => console.log(error));

        API.gatewayContainerList(obj).then(result => {
            this.setState({gatewayContainerList : result})
		}).catch(error => console.log(error));
        
	}
	addItem (){
		
		let list = this.state.list;
		console.log(this.state.count)
		for( var i = 0;  i < this.state.count;i++ ){
			console.log(this.state.count)
			list.push({
				"name": null,        
		        "classCode": null,
		        "farmGatewayId": this.props.match.params.id,        
		        "classValue": null,
		        "containerId": null
			})
		}
		this.setState({list})
	}
	save(){
		
        this.props.setLoading(true)
        for(let i in this.state.list){
			let t = this.state.list[i]
			
			
			if(!this.nullCheck(t.name) || !this.nullCheck(t.containerId) || !this.nullCheck(t.classCode) ){
				window.alert('이름,시설동,장치종류를 모두 입력해주세요.')
				this.props.setLoading(false)
				return false
			}
		}
        API.deviceRegiste(this.state.list).then(result => {
            console.log(result)
			this.setState({
                //params : result
           	})
			this.props.setLoading(false)
			this.props.history.push({ pathname: `/gateway/devicetag/${this.props.match.params.id}` }) 
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
    }
	deleteItem(index){
		let list = this.state.list
		list.splice(index,1)
		this.setState({list})
	}
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">장치 등록하기</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox  mt30">
                                <table>
									<colgroup>
										<col width="50"/>
										<col width="150"/>
										<col width="160"/>
										<col width="150"/>
										<col width="150"/>
									</colgroup>
                                    <thead>
                                        <tr>
											<th>번호</th>
                                            <th>이름</th>
                                            <th>장치종류</th>
                                            <th>시설동</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
										
                                         {	
											this.state.list.map((item, index) => {
												return (

												<tr key={index} >
														
													<td className="bold textCenter">{(index +1)}</td>
													<td>
														<input type="text" value={item.name} onChange={(e) => {
															let data = this.state.list; 
															data[index].name = e.target.value///////////
															this.setState({ list: data })
														}}/>
													</td>
													{/* <td className="textCenter">
														{this.state.gatewayInfo.name}
													</td> */}
													<td className="textCenter">
														<div className="selectBox">
																<select value={item.classCode} onChange={(e) => {
																		let data = this.state.list; 
																		data[index].classCode = e.target.value///////////
																		this.setState({ list: data })
																	}} >
																	<option value="">장치종류 선택</option>
																	{this.state.deviceTypeList.map((item,index)=>{
																		return(
																				<option value={item.code}>{item.name}</option>
																		)
																	})}
																</select>
														</div>
													</td>
													<td className="textCenter">
														<div className="selectBox">
																<select value={item.containerId} onChange={(e) => {
																		let data = this.state.list; 
																		data[index].containerId = e.target.value///////////
																		this.setState({ list: data })
																	}} >
																	<option value="">시설동 선택</option>
																	{this.state.gatewayContainerList.map((item,index)=>{
																	return(
																			<option value={item.id}>{item.name}</option>
																	)
																})}
															</select>
														</div>
													</td>
													<td className="textCenter">
														{	this.state.list.length > 1?
															<button onClick={()=>{this.deleteItem(index)}} className="btn gray ml30">삭제</button>
															: null
														}
														
													</td>
												</tr>
												)
											})
										}
                                    </tbody>
                                </table>
								<div className="textCenter mt30">
									행개수
									<input type="number" className="ml10" style={{width:"100px"}} name="" id="" value={this.state.count} onChange={(e)=>{this.setState({count:e.target.value})}} />
									<button onClick={()=>{this.addItem()}} className="btn ml10 mt10">추가</button>
									
                            	</div>
							</div>
                                 
                            <div className="textCenter mt30">
                                <button onClick={()=>{this.save()}} className="btn mr5 mt10">등록</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default deviceDetail;