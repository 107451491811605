import React from 'react';
import { nbind,reverseIndexing } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import cookie from 'react-cookies'
import * as common from "js/common";
import SearchBox from 'components/common/searchBox';
class Farm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 10,
			list : [],
			keyword : '',
			checkList :[],
			pageListSize : 10,
			totalElements:0,
			request : {
				params : {
					size : 10,
					page: 1
				}
			}
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	onChangePage(index) {
		this.setState({ pageIndex: index },()=>{
			let obj = this.state.request;
			obj.params.page = Number(index) - 1;
			this.callData(obj);
		});
	}

	componentDidMount() {
		let obj = {
			params : {
				userId : cookie.load("userId"),
				size : 10,
				page: Number(this.state.pageIndex) -1
			}
		};
		this.callData(obj)
	}
	searchData (){
		this.setState({pageIndex : 1});
		this.callData()
	}
	callData (obj) {

		if(this.state.keyword != '' ){
			obj.params['name'] = this.state.keyword
		}
		this.props.setLoading(true)
		API.qnaList(obj).then(result => {
			this.setState({
				list : result.content ,
				pageSize : result.totalPages,
				checkList : [],
				totalElements : result.totalElements
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			this.props.setLoading(false)
		});
		
	}
	deleteList() {
		if(this.state.checkList.length < 1){
			alert('선택된 목록이 없습니다.')
		}
		let obj = {
			userId : cookie.load("userId"),
			ids: this.state.checkList
		};

		this.props.setLoading(true)
		API.noticeDelete(obj).then(result => {
			this.callData()
		}).catch(error => console.log(error));
		
	}
	handleCheckboxChange = event => {
		let newArray = [...this.state.checkList, event.target.value];
		if (this.state.checkList.includes(event.target.value)) {
		  newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		  checkList: newArray
		});
	};
	searchContent = [
        {
            name : "이름",
            classCode : 'title',
            type : 'TEXT'
        }
    ];
	onSearchChange(i, value) {
        this.setState({pageIndex : i, request : value}, ()=>this.callData(value));
    };
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">{'Q&A'}</h2>
                        <div>
						<SearchBox 
                            searchContent = {this.searchContent}
                            onSearchChange = {this.onSearchChange}
                            pageIndex = {this.state.pageIndex}
                            pageSize = {this.state.pageSize}
                            pageListSize = {this.state.pageListSize}
                        />
                            <div className="tableBox mt30">
                                <table>
									<colgroup>
										<col width="50"/>
										<col width="150"/>
										<col width="50"/>
										<col width="50"/>
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th>번호</th>
                                            <th>제목</th>
											<th>작성자</th>
                                            <th>등록날짜</th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
                                       
										{	this.state.list.length === 0 ?
											<tr style={{ cursor: 'default' }}>
												<td colSpan='100' className="textCenter">등록된 리스트가 없습니다.</td>
											</tr>
											:
											this.state.list.map((item, index) => {
												return (
													<tr key={index} onClick={() => { this.props.history.push({ pathname: `/qna/detail/${item.id}` }) }}>
														<td className="bold textCenter">{reverseIndexing(this.state.totalElements,this.state.pageIndex,index, this.state.pageListSize)}</td>
														<td className="bold textCenter">{item.title}</td>
														<td className="bold textCenter">{item.regId}</td>
														<td className="bold textCenter">{common.toLocalDate(item.regDate)}</td>
													</tr>
												)
											})
										}
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to="/qnaregister" className="btn mr10 mt10">등록</Link>
                                </div>
                                <Pager pageIndex={this.state.pageIndex} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Farm;