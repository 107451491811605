import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import CKEditor from 'components/layout/include/CKEditor';
import cookie from 'react-cookies'
import * as common from "js/common";
class Gateway extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

            pageIndex:1,
            pageSize : 1,
			list : [],
			keyword : '',
			conTainer:{
				
					    "classCode": "",
					    "name": "",
					    "delYn": "",
					    "maxLodage": null,
					    "proprietyLoadage": null,
					    "rowNumber": null,
					    "colNumber": null,
					    "layerNumber": null,
					    "installDate": null,
					    "note": "",
					    "area": null,
					    "portNumber": null,
					    "pushYn": "",
					    "seq": null,
					    "farmId": 1
					
					
			},


			farmInfo :{
                "id":1,
                "memberId":1,
                "cprId":1,
                "treeNodeId":1,
                "name":"",
                "productCode":"",
                "telephoneNumber":null,
                "mobileNumber":null,
                "faxNumber":null,
                "email":null,
                "roadNameAddress":"",
                "addrDetail":"",
                "zipCode":"",
                "latitude":"",
                "longitude":"",
                "employeeNumber":null,
                "openDate":"2",
                "contractDate":"",
                "note":null,
                "delYn":"N",
                "area":100
            },



			farmInfoList : {
                list : [],
                pageIndex:1,
                pageSize : 10,
                classCode : null,
                useYn : null,
                containerId : null,
                name : null,
            },
            farmInfoUserList : {
                list : [],
                pageIndex:1,
                pageSize : 10,
                classCode : null,
                useYn : null,
                equipId : null, 
                name : null,
                containerId: null,
            },

            checkfarmInfo : [],
            checkfarmInfoUser : []


		};
		//nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	onChangePage(index) {
		this.setState({ pageIndex: index }, () => {this.callData()});		
	}

	componentDidMount() {
		this.callData()
	}

	searchData (){
		this.setState({pageIndex : 1});
		this.callData()
	}
	callData () {
		
		let obj = {
			params : {
				containerId : this.props.match.params.containerId,
				userId : cookie.load("userId"),
				size : 10,
				page: this.state.pageIndex -1
			}
		};

		if(this.state.keyword != '' ){
			obj.params['name'] = this.state.keyword
		}

		this.props.setLoading(true)
		API.productList(obj).then(result => {
			this.setState({
				list : result.content ,
				pageSize : result.totalPages,
				checkList : []
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			this.props.setLoading(false)
		});
		
	}
	deleteList() {
		if(this.state.checkList.length < 1){
			alert('선택된 목록이 없습니다.')
		}
		let obj = {
			ids: this.state.checkList			
		};
		
		
		if(window.confirm('선택하신 장치를 삭제하시겠습니까?')){
			this.props.setLoading(true)
			API.productDelete(obj).then(result => {					
				this.callData()
			}).catch(error => console.log(error));		
		}
	}

	handleCheckboxChange = event => {
		let newArray = [...this.state.checkList, event.target.value];
		if (this.state.checkList.includes(event.target.value)) {
		  newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		  checkList: newArray
		});
	};
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter ">생산물</h2>
                        <div>						
							<div className="textCenter">
								
								<div className="tableBox detail width50 inlineBlock">
									<div className="inlineBlock">
										<table>
											<tbody>
												<tr>
													<th>농장</th>
													<td><input value={this.props.location.state.farmName} readOnly type="text"/></td>                               	
													<th>시설동</th>								
	                                				<td><input value={this.props.location.state.containerName} readOnly type="text"/></td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>		
                            <div className="tableBox mt30">
                                <table>
									<colgroup>"
										<col width="50"/>
										<col width="50"/>
										<col width="300"/>
										<col width="150"/>
										<col width="250"/>
										<col width="150"/>
										<col width="150"/>
										
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>번호</th>
                                            <th>이름</th>
                                            <th>수확 주기</th>
                                            <th>평균 생산량</th>
                                            <th>시작 날짜</th>
                                            <th>종료 날짜</th>                                  
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
										{
											this.state.list.length === 0 ?
											<tr style={{ cursor: 'default' }}>
												<td colSpan='100' className="textCenter">등록된 리스트가 없습니다.</td>
											</tr>
											:
											this.state.list.map((item,index)=>{
												return(
													<tr>
														<td className="textCenter">			
															<div className="checkBox"><input type="checkbox" name="type" id={'t'+item.id} value={item.id} onChange={this.handleCheckboxChange} checked={this.state.checkList.indexOf(String(item.id)) > -1} /><label htmlFor={'t'+item.id}></label></div>															
														</td>
														<td className="textCenter">{(this.state.pageIndex - 1) + '' + (index +1)}</td>
														<td className="textCenter"><a href="javascripot:void(0);" onClick={() => { this.props.history.push({ pathname: `/farm/3/info/3/product/${item.id}` }) }}>{item.name}</a> </td>																										
														<td className="textCenter">{item.productionCycle}</td>
														<td className="textCenter">{item.averageOutput}</td>
														<td className="textCenter">{common.toLocalDate(item.startDate)}</td>
														<td className="textCenter">{common.toLocalDate(item.endDate)}</td>
													</tr>											
												)
											})
										}
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to={`/farm/${this.props.match.params.id}/info/${this.props.match.params.containerId}/productRegister`} className="btn mr10 mt10">등록</Link>
									<button onClick={() =>{ this.deleteList()}} className="btn mr10 mt10">삭제</button>
                                </div>
                                <Pager pageIndex={this.state.pageIndex} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Gateway;