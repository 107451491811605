import React, { useEffect } from 'react';
import styled from 'styled-components';
import bell from "img/icon_Bell.png"
import question from "img/icon_question.png"
import warning from "img/icon_warning.png"
const Wrapper=styled.div`
display: flex;
align-items: center;
margin-right: 0.8rem;
`

const Img=styled.img`
width: 20px;
height: 20px;
margin-right: 0.5rem;
`

const Text=styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;

margin-right: 8px;
color:${props=>props.color};
`
function Main(props) {
    const color={
        'bell':"#F6BD16",
        'warning':"#E56F6F",
        'question':"#858585"
    }

    const img_src={
        'bell':bell,
        'warning':warning,
        'question':question
    }
  


    return (
        <Wrapper>
            <Img src={img_src[props.type]}/>
            <Text color={color[props.type]}>{props.data}개</Text>
        </Wrapper>

      );
}

export default Main;