import React,{useEffect,useCallback,useState,useRef} from 'react';
import cookie from 'react-cookies';

/*functions*/
import { API } from 'service.js';
import { mobileCheck,emailCheck } from 'js/vaildChecker';
/*components*/
import MyPageWorkSpace from 'components/layout/include/MyPage';



const Profile = props =>{

    const [updateFlag,setUpdateFlag] = useState(false);
    const [userInfo,setUserInfo] = useState({userId:'',name:'',mobilePhoneNumber:'',email:'',pushYn:'N'});
    const phoneEl = useRef(null),
          emailEl = useRef(null);

    useEffect(()=>{
        
        fetchUser();

    },[]);

    const fetchUser = () =>{
        const userId = cookie.load("userId");
        const fetchUserInfo = API.farmerInfo({userId});
        fetchUserInfo.then(res =>{
            setUserInfo(res[0]);
        }).
        catch(err =>{
            console.log(err);
        })
    }

    const onChangeinput = (flag,data)=>{
        setUserInfo({...userInfo,[flag] : data.target.value});
    };

    const fetchUpdateUser = useCallback(e =>{

        e.preventDefault();
        let mobileValid = mobileCheck(userInfo.mobilePhoneNumber);
        if(!mobileValid){
            alert('전화번호는 -을 포함한 형식으로 입력 해 주세요.');
            phoneEl.current.focus();
            return;
        }
        let emailValid = emailCheck(userInfo.email);
        if(!emailValid){
            alert('올바른 이메일 형식이 아닙니다.');
            emailEl.current.focus();
            return;
        }
        const fetchUpdateUserInfo = API.userInfoUpdate(userInfo);
        fetchUpdateUserInfo.then(res =>{
            setUserInfo(res);
        }).
        catch(err=>{
            alert(err);
        }).
        finally(()=>{
            updateControl()
        })
    },[userInfo])

    const updateControl = () =>{
        setUpdateFlag(false);
        fetchUser();
    }


    return(
        <MyPageWorkSpace>
            <div className='profileWrapper'>
                <div className='profileTitle'>마이페이지</div >
                <div className='contentInnerWarpper'>
                    <div className='inputWrapper'>
                        <div>유저 아이디</div>
                        <input readOnly value={userInfo.userId}/>
                    </div>
                    <div className='inputWrapper'>
                        <div>이름</div>
                        <input value={userInfo.name} readOnly={!updateFlag} onChange={e=>onChangeinput('name',e)}/>
                    </div>
                    <div className='inputWrapper'>
                        <div>연락처</div>
                        <input value={userInfo.mobilePhoneNumber} readOnly={!updateFlag}  onChange={e=>onChangeinput('mobilePhoneNumber',e)} ref={phoneEl}/>
                    </div>
                    <div className='inputWrapper'>
                        <div>이메일</div>
                        <input value={userInfo.email} readOnly={!updateFlag} onChange={e=>onChangeinput('email',e)} ref={emailEl}/>
                    </div>
                    <div className='inputWrapper'>
                        <div>푸쉬알람 발송 여부</div>
                        <select value={userInfo.pushYn} onChange={(e)=>onChangeinput('pushYn',e)} disabled={!updateFlag}>
                            <option value='Y' label='Y'>Y</option>
                            <option value='N' label='N'>N</option>
                        </select>
                    </div>
                    <div className='bntWrapper'>
                        <button className='submitBnt' type={!updateFlag?'button':'submit'}
                            onClick={
                            !updateFlag ?   
                                ()=>setUpdateFlag(true)
                            :
                                fetchUpdateUser
                        }>{!updateFlag?'정보수정' : '수정완료'}</button>
                        {updateFlag && <button className='cancelBnt' onClick={updateControl}>수정취소</button>}
                    </div>
                </div>
            </div>
        </MyPageWorkSpace>
    )

}


export default Profile;