import React, { useEffect, useState } from 'react';

import _ from 'lodash'
import styled from 'styled-components';

import FarmSelect from 'components/dataLookUp/farmSelect'
import OutSideSensor from 'components/popUp/outSideSensor'
import Graph from 'components/popUp/graphComponent'

import { API } from 'service';
import { data2graph, getTimeStamp } from 'components/utils/api'
import { useInput } from 'hooks/commonHooks';
const Wrapper = styled.div`
width: 100%;

padding:49px 14% 230px 14%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: #f5f5f5;
`

const arr2str = (arr) => {
	let str = ''
	for (let i of arr) {
		str = str + i + ','
	}
	str = str.slice(0, -1)
	return str
}


const Date2cal = day => {
	let year = day.getFullYear();
	let month = day.getMonth() + 1
	let date = day.getDate(); // 일
	if (month / 10 < 1)
		month = '0' + month
	if (date / 10 < 1)
		date = '0' + date
	return '' + year + month + date
}

function Main(props) {
	const [name, set_name] = useState('')
	const [depth, set_depth] = useState()

	const [sensor, set_sensor] = useState([])
	const [equip, set_equip] = useState([])

	const [graph, set_graph] = useState({})

	const [selectedSensor, set_selectedSensor] = useState([])
	const [selectedEquip, set_selectedEquip] = useState([])
	const [tag2name, set_tag2name] = useState({})

	const [startDay, startDayHanddle, set_startDay] = useInput(getTimeStamp(-30))
	const [endDay, endDayHanddle, set_endDay] = useInput(getTimeStamp())

	const [standardTime, standardTimeHanddle, set_standardTime] = useInput(new Date().getHours() + ':' + new Date().getMinutes())
	const [searchType, searchTypeHanddle, set_searchType] = useInput('daily')
	const [updateTime, set_updateTime] = useState('')

	const [outdoor, set_outdoor] = useState(false)
	const [broad, set_broad] = useState([])

	const [broadSensor, set_broadSensor] = useState([])
	const [selectedbroadSensor, set_selectedbroadSensor] = useState([])

	const [tag2unit, set_tag2unit] = useState({})

	const [farm, set_farm] = useState([])


	function reload_broad() {
		let stnIds = farm.stnIds

		let today = new Date();
		let endday = new Date(endDay)
		let startday = new Date(startDay)

		if (Date2cal(today) == Date2cal(endday))
			endday = new Date(endday.setDate(endday.getDate() - 1))

		let stand = standardTime.slice(0, 2).split(':')[0]
		if (stand.length == 1) {
			stand = '0' + stand
		}

		let data = {
			stnIds: stnIds,
			startDt: Date2cal(startday),
			endDt: Date2cal(endday),
			startHh: stand,
			endHh: stand

		}

		API.getBroad(data).then(async val => {
			let res = val.response
			if (res.header.resultCode == '00') {
				if (res.body.numOfRows < res.body.totalCount) {
					console.log('추가')
					console.log()
					res.body.items.item = res.body.items.item.concat(await add_reload_board())
				}
				set_broad(res.body.items.item)
			}
			else
				set_broad([])

		}).catch(err => console.log(err))

	}
	async function add_reload_board(pageNo = 2) {
		let stnIds = farm.stnIds

		let today = new Date();
		let endday = new Date(endDay)
		let startday = new Date(startDay)

		if (Date2cal(today) == Date2cal(endday))
			endday = new Date(endday.setDate(endday.getDate() - 1))

		let stand = standardTime.slice(0, 2).split(':')[0]
		if (stand.length == 1) {
			stand = '0' + stand
		}

		let data = {
			stnIds: stnIds,
			startDt: Date2cal(startday),
			endDt: Date2cal(endday),
			startHh: stand,
			endHh: stand,
			pageNo: pageNo

		}

		return API.getBroad(data).then(async val => {
			console.log(val)
			let res = val.response
			if (res.header.resultCode == '00') {
				if (999 * pageNo < res.body.totalCount) {
					return res.body.items.item.concat(await add_reload_board(pageNo + 1))
				}
				else {
					return res.body.items.item
				}
			}
			// add_reload_board
			// set_broad(res.body.items.item)
			else
				set_broad([])

		}).catch(err => console.log(err))


	}

	useEffect(() => {

		set_outdoor(false)

		let params = new URL(window.location.href);
		params = params.searchParams
		const outdoor = params.get('outdoor');


		let farm = sessionStorage.getItem("farm")
		if (farm != null) {
			farm = JSON.parse(farm)
			set_farm(farm)
			if (outdoor == 'true') {

				let arr = [{
					sensors: [{ tagId: 'ta', name: '기온', tagClass: { unit: '°C' } },
					{ tagId: 'rn', name: '강수량', tagClass: { unit: 'mm' } },
					{ tagId: 'ws', name: '풍속', tagClass: { unit: 'm/s' } },
					{ tagId: 'wd', name: '풍향', tagClass: { unit: '' } },
					{ tagId: 'hm', name: '습도', tagClass: { unit: '%' } },
					{ tagId: 'pv', name: '증기압', tagClass: { unit: 'hPa' } },
					{ tagId: 'td', name: '이슬점온도', tagClass: { unit: '°C' } },
					{ tagId: 'pa', name: '현지기압', tagClass: { unit: 'hPa' } },
					{ tagId: 'ps', name: '해면기압', tagClass: { unit: 'hPa' } },
					{ tagId: 'ss', name: '일조량', tagClass: { unit: 'hr' } },
					{ tagId: 'icsr', name: '일사량', tagClass: { unit: 'MJ/m2' } },
					{ tagId: 'dsnw', name: '적설량', tagClass: { unit: 'cm' } },
					{ tagId: 'ts', name: '지면온도', tagClass: { unit: '°C' } },]
				}]

				set_broadSensor(arr)
				set_outdoor(true)

				let stnIds = farm.stnIds

				let today = new Date();
				let endday = new Date(endDay)
				let startday = new Date(startDay)

				if (Date2cal(today) == Date2cal(endday))
					endday = new Date(endday.setDate(endday.getDate() - 1))

				let stand = standardTime.slice(0, 2).split(':')[0]
				if (stand.length == 1) {
					stand = '0' + stand
				}

				let data = {
					stnIds: stnIds,
					startDt: Date2cal(startday),
					endDt: Date2cal(endday),
					startHh: stand,
					endHh: stand

				}

				API.getBroad(data).then(val => {
					console.log(val)
					let res = val.response
					if (res.header.resultCode == '00') {
						set_broad(res.body.items.item)
					}
					else
						set_broad([])

				}).catch(err => console.log(err))

			}

			let sensors = []
			let equip = []
			sensors = farm.equips.filter(val => {
				if (val.equipClass.enableControlYn == 'N') {
					return true
				} else {
					equip.push(val)
				}
			})
			try {
				set_updateTime(farm.modDate.slice(0, 10) + " " + farm.modDate.slice(11, 19))
			} catch (error) {
				console.log(farm)
			}
			set_equip(equip)
			set_sensor(sensors)
			set_name(farm.name)
			set_depth(farm.depth)
		}

		else {
			window.location.href = '/404'

		}

	}, [])

	useEffect(() => {
		console.log(broad)
	}, [broad])


	useEffect(() => {

		if (selectedSensor.length == 0 && selectedbroadSensor.length == 0 && selectedEquip.length==0) {
			set_graph('')
		} else {
			search()
		}
	}, [selectedSensor, selectedEquip, standardTime, searchType, updateTime, selectedbroadSensor, broad])

	useEffect(() => {
		reload_broad()
	}, [startDay, endDay])


	const search = () => {
		let str = ''
		for (let i of selectedSensor) {
			str = str + i + ','
		}
		for (let i of selectedEquip) {
			str = str + i + ','
		}
		str = str.slice(0, -1)

		if (selectedSensor.length == 0 && selectedEquip.length == 0 && selectedbroadSensor.length == 0)
			return

		if (searchType == '') {
			alert('검색타입을 선택해주십시오')
			return
		}
		else if (startDay == '') {
			alert('시작일을 선택해주십시오')
			return
		}
		else if (endDay == '') {
			alert('종료일을 선택해주십시오')
			return
		}
		else if (standardTime == '' && searchType != 'hourly') {
			alert('기준시간을 선택해주십시오')
			return
		}


		if (str == '')
			str = '16548'
		
		let endDateTime = endDay + 'T00:00:00Z'
		if (searchType == 'hourly')
			endDateTime = endDay + 'T23:59:59Z'
		let param = {
			'tagIds': str,
			startDateTime: startDay + 'T00:00:00Z',
			endDateTime: endDateTime,
			type: searchType,
			stdTime: standardTime
		}


		API.getSensorData(param).then(val => {

			val.map(data => { data.time = data.time.slice(0, 10) + ' ' + data.time.slice(11, -6) + '00' })

			// console.log(broad)
			set_graph(data2graph(val, selectedSensor, selectedEquip, tag2name, tag2unit, broad, selectedbroadSensor))
		}).catch(err => console.log(err))


	}

	const setTime = time => {
		// set_updataTime(
		set_selectedSensor([])
		set_selectedEquip([])
		try {
			let ti = time.slice(0, 10) + " " + time.slice(11, 19)
			console.log(ti)
			set_updateTime(ti)

		} catch (error) {

		}
	}

	const apeendTag = (tag, id) => {
		tag2name[tag] = id
	}
	const apeendTag2unit = (tag, unit) => {
		tag2unit[tag] = unit
	}



	return (
		<Wrapper >

			<OutSideSensor set_tag2name={apeendTag} selectedSensor={selectedSensor} updateTime={updateTime}
				selectedEquip={selectedEquip} set_selectedSensor={set_selectedSensor} name={name}
				set_selectedEquip={set_selectedEquip} equip={equip} sensor={sensor} outdoor={outdoor}
				broadSensor={broadSensor} set_broadSensor={set_broadSensor} selectedbroadSensor={selectedbroadSensor}
				set_selectedbroadSensor={set_selectedbroadSensor} tag2name={tag2name} apeendTag2unit={apeendTag2unit}
			/>

			<Graph search={search} graph={graph} startDayHanddle={startDayHanddle} searchTypeHanddle={searchTypeHanddle} depth={depth} set_startDay={set_startDay} set_endDay={set_endDay}
				startDay={startDay} endDay={endDay} searchType={searchType} standardTime={standardTime} standardTimeHanddle={standardTimeHanddle}/>
		</Wrapper>
	)
}


export default Main;

