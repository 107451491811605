import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { API } from 'service';

const CompanyMemberDetail = (props) => {
    const history = useHistory();
    const [memberId, setMemberId] = useState(props.match.params.id);
    const defaltValue = {
        id : "",
        name : "",
        pushYn : "",
        mobilePhoneNumber : "",
        roleCode : ""
    }
    const [memberStatus, setMemberStatus] = useState(defaltValue);
    const callData = () => {
        let obj = {
            id: memberId,
        };

        API.farmUserInfo(obj).then(result => {
            setMemberStatus(result);
        }).catch(error => {
            console.log(error);
        });
    }

    const updateMemberRole = () => {
        let obj = {
            params : {
                id : memberStatus.memberId,
                roleCode : memberStatus.roleCode
            }
        }
        API.userInfoUpdate(obj).then(res => {
            alert("수정이 완료되었습니다");
            history.goBack();
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        callData();
    }, []);

    return (
        <>
            <div className="contentInner">
                <div className="contentArea">
                    <h2 className="pageTitle textCenter">사용자 상세정보</h2>
                    <div className="noticeDetail">
                        <div className="tableBox detail mt30">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>ID</th>
                                        <td><input readOnly type="text" value={memberStatus.id} /></td>
                                    </tr>
                                    <tr>
                                        <th>이름</th>
                                        <td><input readOnly type="text" value={memberStatus.name} /></td>
                                    </tr>
                                    <tr>
                                        <th>연락처</th>
                                        <td><input readOnly type="text" value={memberStatus.mobilePhoneNumber} /></td>
                                    </tr>
                                    <tr>
                                        <th>E-Mail</th>
                                        <td><input readOnly type="text" value={memberStatus.email} /></td>
                                    </tr>
                                    <tr>
                                        <th>메세지 전송 여부</th>
                                        <td><input readOnly type="text" value={memberStatus.pushYn} /></td>
                                    </tr>
                                    <tr>
                                        <th>등급</th>
                                        <td>
                                            <div className="selectBox w100">
                                            <select readOnly value={memberStatus.roleCode} onChange={(e) => {
                                                setMemberStatus({...memberStatus, roleCode : e.target.value});
                                            }}>
                                                <option value="FARMER">FARMER</option>
                                            </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="textCenter mt30">
                            <button onClick={() => { updateMemberRole() }} className="btn mr5 mt10">수정</button>
                            <button onClick={() => { history.goBack() }} to="/gateway" className="btn gray ml30">취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyMemberDetail;