import React, { Suspense, useEffect,useMemo, useRef, useState } from 'react';

import { API } from 'service.js';
import _ from 'lodash'
import CKEditor from 'components/layout/include/CKEditor';
import { Link } from "react-router-dom";
import paperclip from 'img/paperclip.png'
import { useInput } from 'hooks/commonHooks';
import cookie from 'react-cookies'
import ReactQuill from 'react-quill';

function NoticeRegister(props){
    const [content,set_content]=useState('')
    const [title,title_handdle,set_title]=useInput('')
    const [file,file_handdle,set_file]=useInput([])
    const [deleteFile,set_deleteFile]=useState([])
    const [edit,set_edit]=useState(false)
    const [postId,set_postId]=useState('')
    const [isCpr,set_isCpr]=useState(false)
   
    const editor = useRef(null)

    const imageHandler = () => {
        console.log('에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!');

        // 1. 이미지를 저장할 input type=file DOM을 만든다.
        const input = document.createElement('input');
        // 속성 써주기
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
        // input이 클릭되면 파일 선택창이 나타난다.

        // input에 변화가 생긴다면 = 이미지를 선택
        input.addEventListener('change', async () => {
            console.log('온체인지');
            const file = input.files[0];
            // multer에 맞는 형식으로 데이터 만들어준다.
            const formData = new FormData();
            formData.append('images', file); // formData는 키-밸류 구조
            // 백엔드 multer라우터에 이미지를 보낸다.
            try {
                //   https://www.farminsf.com/api/v2/files/images
                const result = await API.noticeImage(formData);
                console.log('성공 시, 백엔드가 보내주는 데이터', result);
                const IMG_URL = 'https://www.farminsf.com/api/v2/files/' + result.data[0].name;
                // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
                // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
                // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

                // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
                const local_editor = editor.current.getEditor(); // 에디터 객체 가져오기
                // 1. 에디터 root의 innerHTML을 수정해주기
                // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
                // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
                // editor.root.innerHTML =
                //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

                // 2. 현재 에디터 커서 위치값을 가져온다
                const range = local_editor.getSelection();
                // 가져온 위치에 이미지를 삽입한다
                local_editor.insertEmbed(range.index, 'image', IMG_URL);
            } catch (error) {
            }
        });
    };





    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    ['image'],
                    [{ header: [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                ],
                handlers: {
                    // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
                    image: imageHandler,
                },
            },
        };
    }, []);
    // 위에서 설정한 모듈들 foramts을 설정한다
    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'image',
    ];


    
    useEffect(()=>{console.log(content)},[content])
    const fileClick=(e)=>{
        let file_arr=file.slice()
        if(file_arr.length==0){
            set_file(e.target.files)
        }
        
        for(let i of e.target.files){
            file_arr.find(val=>{
                console.log(val==i)
            })
            file_arr.push(i)
        }
        
        set_file(file_arr)
    }
    const fileDelete=(idx)=>{
        let file_arr=file.slice()
        deleteFile.push(file_arr[idx].name)
        file_arr.splice(idx, 1);
        set_file(file_arr)
    }
    const update=()=>{
        let data=new FormData()
        data.append('title',title.toString())
        data.append('content',content.toString())

        for (let i = 0; i < deleteFile.length; i++) {
            data.append(`deleteFiles[${i}]`, deleteFile[i]);
            
        }
        for (let i = 0; i < file.length; i++) {
            data.append(`files`, file[i]);
            
        }

          let bool=window.confirm("수정하시겠습니까?")
          if(bool)
        API.faqUpdate(data,postId).then(
            arr=>{
                alert('수정 성공')
                window.location.href=`/faq/detail/${postId}`
            }).catch(err=>alert(err))

    }

    const register=()=>{
        if(cookie.load('role')!='corporation'){
            alert('권한이 없습니다.')
            return
        }
        let data=new FormData()
        data.append('title',title)
        data.append('content',content)
        
        for (let i = 0; i < file.length; i++) {
            data.append('files', file[i]);
        }
        
        if(window.confirm("등록하시겠습니까?"))
        console.log(data)
            API.faqRegiste(data).then(arr=>{
                alert('등록 성공')
                window.location.href=`/faq`
            }).catch(err=>console.dir(err))
    }


    useEffect(()=>{
        if(cookie.load('role')!='corporation'){
            alert('비정상적인 접근입니다.')    
            window.location.href='/'
        }

        let id=props.match.params.id
        if(id!=undefined){
            set_edit(true)
            set_postId(id)

            API.faqDetail({id:id}).then(val=>{
                set_content(val.content)
                set_title(val.title)
                set_file(val.files)
                console.log(val)
            }).catch(err=>console.log(err))
        }
		
        
    },[])	
   	
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
                        <div>
                            
                            <div className="tableBox detail mt30">
                                <table>
                                   
                                    <tbody>
                                        <tr>
                                            <th>제목</th>
                                            <td colSpan="5"><input type="text" value={title} onChange={title_handdle} /></td>
                                        </tr>
                                       
                                        <tr>
                                            <td colSpan="6">
                                                <Suspense fallback={'Loading...'}>
                                                {/* <CKEditor data={this.state.params.content} editorData={this.updateContent} /> */}
                                                {/* <CKEditor data={content} editorData={set_content}/> */}

                                                <ReactQuill
                                                    ref={editor}
                                                    theme="snow"
                                                    placeholder="플레이스 홀더"
                                                    value={content}
                                                    onChange={set_content}
                                                    modules={modules}
                                                    formats={formats}
                                                />


                                                </Suspense>
                                            </td>
                                        </tr>
                                       
                                        <tr>
                                            <td colSpan="5">
                                                <div className='fileDiv'>
                                                <img src={paperclip} alt="" />
                                                     파일첨부
                                                    <input multiple onChange={fileClick} type="file" name="" id="file"/>
                                                    <label for='file'>{file.length==0?'파일선택':'파일추가'}</label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                {file.length==0||
                                                <div className='fileList'>
                                                    {/* file */}
                                                    {file.map((val,idx)=>{
                                                        return <>{(val.originName||val.name)+'.'+(val.extension||'')} <button onClick={()=>fileDelete(idx)}  className='miniBtn gray '>삭제</button><br/> </>
                                                    })}
                                                </div>
                                                    }
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                
                               <div className="textRight mt30">

                                   {edit?
                               <button className="btn " onClick={update}>수정</button> :
                               <button className="btn " onClick={register}>등록</button> 
                                   }
                                   
                                    
                                   <Link to="/faq" className="btn gray ml6">취소</Link>
                               </div>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
}
export default NoticeRegister;