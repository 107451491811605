import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { API } from 'service.js';

class HDChartsData extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// To avoid unnecessary update keep all options in the state.
			userId : props.userId,
			farmId : props.farmId,
			cprId : props.cprId,
			chartOptions: {
				chart: {
					type: 'scatter',
					height: '45%',
					//zoomType: 'xy'
					plotBackgroundImage: 'hd_back.png',
				},
				title: {
					enabled: false,
					text: 'HD 온실환경 '
				},
				xAxis: {
					title: {
						enabled: true,
						text: 'g/㎥'
					},
					startOnTick: true,
					endOnTick: true,
					showLastLabel: true,
					min:0,
					max:30,
					step: 1,
					tickInterval: 5
				},
				yAxis: {
					title: {
						text: 'Temperature  ℃'
					},
					min:0,
					max:35,
					step: 1,
					tickInterval: 5
				},
				legend: {
					layout: 'horizontal',
					align: 'right',
					y: 680,
					verticalAlign: 'top',
					floating: true,
					backgroundColor: '#FFFFFF',
					borderWidth: 1,
					squareSymbol: false,
					symbolWidth: 0
				},
				plotOptions: {
					scatter: {
						tooltip: {
							headerFormat: '<b>{series.name}</b><br>'
							,pointFormat: '{point.y} ℃, {point.x} g/㎥'
						}
					}
				},
				series: []
			},
			hoverData: null
		};
	}

	setHoverData = (e) => {
		// The chart is not updated because `chartOptions` has not changed.
		this.setState({ hoverData: e.target.category })
	}

	updateSeries = () => {
		// The chart is updated only with new options.
		this.setState({});
	}

	afterChartCreated = (chart) => {
		this.internalChart = chart;
	}

	componentDidMount() {
		this.updateSeries();
	}

	updateSeries = () => {
		console.info('There data updateSeries');
		// GET request using fetch with error handling
		let obj = {
			userId : this.props.userId,
			farmId : this.props.farmId
		};

		let _this = this;
		API.hdChartDataSensors(obj).then(  response => {
			const data =  response;
			console.info('There data', data);
			this.setChart(data, _this);

		}).catch( error => console.log(error));

		//일정시간 간격으로 업데이트
		_this.interval = setInterval(function() {
			API.hdChartDataSensors(obj).then(  response => {
				const data =  response;
				console.info('There data', data);
				this.setChart(data, _this);

			}).catch( error => console.log(error));

		}, 1000 * 60 * 5); // setInterval
	}

	setChart(data, _this) {
		const array = [];
		let i = 0;
		let result = data[0].containers.map((container) => {

			let img_nm = "0";
			let hd = container.sensors[6].value;

			//차트 마커 아이콘 종류 결정
			if (hd <= 4.5) {
				img_nm = 2;
			}
			if (hd > 4.7 && hd <= 6.5) {
				img_nm = 0;
			}
			if (hd > 6.7) {
				img_nm = 1;
			}

			array.push({
				name: container.containerName + i++ + "( " + hd + " g/㎥)",
				marker: {symbol: 'url(/' + img_nm + '.png)'},
				data: [[container.sensors[4].value, container.sensors[0].value]]
			});
			return array;
		});
		_this.setState({
			chartOptions: {
				series: array
			}
		})
	}

	render() {
		const { chartOptions, hoverData } = this.state;

		return (
			<div>
				<HighchartsReact
                    options = { this.state.chartOptions }
				/>
			</div>
		)
	}
}

export default HDChartsData;