import React, { useEffect, useState } from "react";

import _ from "lodash";
import styled from "styled-components";

import FarmSelect from "components/dataLookUp/farmSelect";
import SettingSensor from "components/notificationSetting/settingSensor.jsx";
import SensorStatus from "components/notificationSetting/sensorStatus";

import { API } from "service";
import { useInput } from "hooks/commonHooks";
const Wrapper = styled.div`
  width: 100%;

  padding: 49px 14% 230px 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
`;

const arr2str = (arr) => {
  let str = "";
  for (let i of arr) {
    str = str + i + ",";
  }
  str = str.slice(0, -1);
  return str;
};

function Main() {
  const [farm_data, set_farm] = useState([]);

  const [sensor, set_sensor] = useState([]);
  const [equip, set_equip] = useState([]);

  const [selectedSensor, set_selectedSensor] = useState([]);
  const [tag2name, set_tag2name] = useState({});

  const [min, min_handdle, set_min] = useInput(``);
  const [max, max_handdle, set_max] = useInput(``);

  const [preProcessed, set_preProcessed] = useState([]);

  const [unit, set_unit] = useState("");

  const apeendTag = (tag, id) => {
    tag2name[tag] = id;
  };

  useEffect(() => {
    API.getContainers().then((val) => {
      set_farm(val);
    });
  }, []);

  const reload = () => {
    API.getContainers().then((val) => {
      set_farm(val);
    });
  };
  const settingBtn = () => {
    let data = [];
    console.log(min === "");
    if (min === "") {
      alert("최소값을 설정해주세요");
    } else if (max === "") {
      alert("최대값을 설정해주세요");
    } else if (max < min) {
      alert("설정이 잘못되었습니다.");
    } else if (selectedSensor.length == 0) {
      alert("센서를 설정해주십시오.");
    } else {
      for (let i of selectedSensor) {
        data.push({
          tagId: i,
          alarmUpperLimit: max,
          alarmLowerLimit: min,
        });
      }

      if (window.confirm("센서의 정상범위를 변경하시겠습니까?"))
        API.settingSensorNotification(data)
          .then((val) => {
            alert("요청 성공");
            // reload()
          })
          .catch((err) => {
            alert("요청 실패");
          });
    }
  };

  return (
    <Wrapper>
      <FarmSelect
        setTime={() => {
          set_selectedSensor([]);
        }}
        farm={farm_data}
        set_sensor={set_sensor}
        set_equip={set_equip}
      />
      <SettingSensor
        set_min={set_min}
        set_max={set_max}
        min={min}
        max={max}
        min_handdle={min_handdle}
        max_handdle={max_handdle}
        set_unit={set_unit}
        unit={unit}
        tag2name={tag2name}
        set_tag2name={apeendTag}
        selectedSensor={selectedSensor}
        set_selectedSensor={set_selectedSensor}
        set_preProcessed={set_preProcessed}
        sensor={sensor}
        settingBtn={settingBtn}
        reload={reload}
      />
      <SensorStatus preProcessed={preProcessed} />
    </Wrapper>
  );
}

export default Main;
