import React, { Component } from 'react';
import { injectIntl } from 'react-intl'
import { nbind } from 'js/common'
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import _ from 'lodash'
import cookie from 'react-cookies'
import * as common from "js/common";
import * as actions from 'reducers/actions';
import { API } from 'service.js';
import Modal from 'react-modal';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import styled,{css} from 'styled-components'

const Header=styled.header`
position: fixed;
height: 97px;
padding-top: 22px;
left: 0px;
right: 0px;
top: 0px;
bottom: 0px;
display: flex;
border: 1px solid #c9c9c9;
z-index: 9999;
background-color: #f5f5f5;
display: flex;
align-items: center;


`
const Ul=styled.ul`
display: flex;
height: 100% ;
@media only screen and (max-width: 1400px) {
    display: none;
  }
`
const Li=styled.li`

align-items: center;
padding: 0px 1.7vw;
display: flex;

border-bottom: 5px solid #f5f5f5;
${props=>props.huge&&
css`
	border-bottom: 5px solid #17B9B6;

	& a{
	color: #17B9B6;

	}


`
}

`

const CustomLink=styled(Link)`

font-family: "Spoqa Han Sans Neo";
font-style: normal;
font-weight: 500;
font-size: 18px;

color: #656565;



`

const Logo=styled(Link)`


margin-left: 5%;
margin-right: 70px;
display: flex;
font-family: "BMJUA";
font-style: normal;
font-weight: normal;
font-size: 32px;
line-height: 33px;
text-align: center;
align-items: center;

color: #17B9B6;

text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.15);
`
class NewHeader extends Component {
    constructor(props) {
        super(props);
		this.state = {
			lang: '',
			isLogin: '',
			confirmLogout: false,
			isNewMsg: false,
			isHovering: false,
			role : 'ROLE_FARMER',
			reload:0,
		}
		
		nbind(this);
		this.currentPathname = null;
		this.currentSearch = null;
		this.handleMouseHover = this.handleMouseHover.bind(this);
		this.handleMouseHover2 = this.handleMouseHover2.bind(this);
	}
	reload(){
		this.setState({
			reload:this.state.reload+1
		})
	}
	handleMouseHover() {
		this.setState(this.toggleHoverState);
		if(this.state.isHovering){
			
		}
	}
	handleMouseHover2(){
		this.setState({isHovering : false})
	}
	toggleHoverState(state) {
		return {
			isHovering: !state.isHovering,
		};
	}
	
	componentDidMount () {
		if(this.props.user.realm_access){
			let t = this.props.user.realm_access.roles
			let role ='' 
			for(var i in t){
				if(t[i].indexOf('ROLE_CPR') > -1){
					role = 'ROLE_CPR'
				}else if(t[i].indexOf('ROLE_FARMER') > -1){
					role = 'ROLE_FARMER'
					
				}
			}
			this.setState({role : role})
			this.ahthCheck(role)
		}
	}
	componentDidUpdate(prevProps) {
		// 전형적인 사용 사례 (props 비교를 잊지 마세요)
		if (this.props.user !== prevProps.user) {
			if(this.props.user.realm_access.roles){
				this.setState({role : this.props.user.realm_access.roles[0]})
				this.ahthCheck(this.props.user.realm_access.roles[0])
			}
		}
	}
	
	getCookieData() {
		let cookieData = {
			token: cookie.load('userToken'), 
			id: cookie.load('userId'), 
			lang: cookie.load('lang'),
		};
		// cookieData.token = _.get(cookieData, 'lang', '');
		return cookieData
	}
	// 로그인 필수 페이지 로그인 체크(안했을 경우, 로그인 페이지로 이동) + 새로운 메시지 수신 체크
	ahthCheck(auth){
		let authCheck = [
			'/company',
			'/protocol',
			'/cctv',
			'/menu',
			'/farm',
			'/gateway',
			'/noticeregister',
			'/faqregister'
		]
		if(auth  == 'ROLE_FARMER'){
			for(var i in authCheck){
				if(this.props.match.path.indexOf(authCheck[i]) > -1){
					this.props.handleAlert('dddd')
					this.props.history.push('/mainUser')
				}
			}
		}else{
			
		}
		
	}
	goLoginPageNotLogin(path) {
		// 로그아웃일 때 
		let cookieData = {
			token: cookie.load('userToken'),
			id: cookie.load('userId'),
			lang: cookie.load('lang'),
		};
		let loginPage = [
			
		];
		if (!cookieData.token || cookieData.token == "") {
			alert('로그인 후 이용이 가능한 서비스입니다.');
			this.props.history.push('/login')
			return false;
		}else{
			// 로그인 상태일 때만 토큰체크
			if (cookieData.token !== '') {
				let obj = {
					userToken: cookieData.token,
				};
				
				// API.userInfo(obj).then(result => {
				// 	let code = result.responseCode === "SUCCESS";
				// 	let isLogin = this.state.isLogin;
				// 	let isNewMsg = this.state.isNewMsg;
				
				// 	if (code ) {
				// 		isLogin = true;
						
				// 		this.setState({
				// 			isLogin, isNewMsg
				// 		});
				// 		this.props.handleUserInfo({userData:result.data,userToken: cookieData.token});
				// 	}else{
				// 		loginPage.some((item, index) => {
				// 			if (this.props.location.pathname.indexOf(item) > 0) {
				// 				this.alert('로그인 후 이용이 가능한 서비스입니다.', `msg.youNeedLogin`);
				// 				this.actionLogout('/login');
				// 				this.props.history.push('')
				// 				return false
				// 			}
				// 		})
				// 		isLogin = false;
				// 		this.setState({ isLogin });
				// 	}
				// }).catch(error => console.log(error));
			}
		}
		
	}
	// 메뉴 클릭시 이동 전, 로그인 체크
	checkLogin = (e) => {
		e.preventDefault();
		let cookieData = this.getCookieData();
		let isLogout = !common.isValid(cookieData.token);
		if ( isLogout ) {
			this.alert('로그인 후 이용이 가능한 서비스입니다.', `msg.youNeedLogin`)
			setTimeout(() => {
				this.props.history.push('/login');
			}, 0);
			return false;
		} else {
			let href = '';
			if ( e.target.tagName === 'P' ) {
				href = e.target.parentElement.getAttribute('href');
			} else if ( e.target.tagName === 'A' ) {
				href = e.target.getAttribute('href');
			}
			this.props.history.push(href);
		}
		return false;
	};
	// 실제 프론트에서의 로그아웃 처리
	actionLogout(path) {
		cookie.remove('userId', { path: '/' });
		cookie.remove('userToken', { path: '/' });
		this.props.handleUserInfo({ userId: '', userToken: '' });
		this.resetConfirm();
		this.setState({ isLogin: false,menuOpen:false });
		this.props.history.push('/');
	}
	openMenu(el){
		el.classList.toggle('open');
	}
	tryLogout() {
		let obj = {
			userToken: this.props.user.userInfo.userToken,
		}
		// API.logout(obj).then(result => {
		// 	let code = result.status;
		// 	if (result.result) {
		// 		if ( code === 100 ) {
		// 			this.alert('로그아웃 되었습니다.', 'msg.logoutSuccess');
		// 			this.actionLogout('/');
		// 		}
		// 	} else {
		// 		switch ( code ) {
		// 			case -2000 :
		// 			case -2100:
		// 			case -2200: {
		// 				this.alert('로그인 상태가 아닙니다.', 'msg.notLogin');
		// 				this.actionLogout('/');
		// 				break;
		// 			}
		// 			default: this.alert('로그아웃이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.', `msg.logoutFail`); 
		// 		}
		// 	}
		// }).catch(error => console.log(error));
	}
	changeLang(lang) {
		this.props.handleLang(lang);
		cookie.save('lang', lang, { path: '/' });
		this.setState({lang});
	}
	resetConfirm() {
		this.setState({ confirmLogout: false })
	}

	setFocus(event){
		
		const child=event.target

		let menu=document.getElementsByClassName("menu")
		
		for(let a=0;a<menu.length;a++){
			console.log(menu[a])
			menu[a].childNodes[0].style.color="#656565"
			menu[a].style.borderBottom="5px solid #f5f5f5"
		}
		child.style.color="#17B9B6"
		child.parentElement.style.borderBottom="5px solid #17B9B6"
		
	}
	
    render() {
	
        return (
			<React.Fragment >
				<Header >
						{/* <h1>
							<Link >
								 FARM IN
							</Link>
						</h1> */}
						<Logo to='/mainUser'>FARM IN</Logo>
						
							<Ul>
								<Li huge className='menu' >
									<CustomLink to='/mainUser'  onClick={this.setFocus}   href="javascript:void(0);"  >
										메인
									</CustomLink>
								</Li>
								
								<Li className='menu' >
										<CustomLink to='/control' onClick={this.setFocus}  href="javascript:void(0);"  >
											관제화면
										</CustomLink>
									</Li>
									<Li className='menu' >
										<CustomLink to='/mainUser' onClick={this.setFocus} href="javascript:void(0);"  >
											농장관리
										</CustomLink>
									</Li>
								
									<Li className='menu' >
									<CustomLink to='/mainUser' onClick={this.setFocus} href="javascript:void(0);"  >
										조회
									</CustomLink>
								</Li>
								<Li className='menu' >
									<CustomLink to='/mainUser' onClick={this.setFocus} href="javascript:void(0);"  >
										통계
									</CustomLink> 
								</Li>
								<Li className='menu' >
									<CustomLink to='/login' onClick={this.setFocus} href="javascript:void(0);"  >
										영농일지
									</CustomLink>
								</Li>
								<Li className='menu' >
									<CustomLink to='/notice' onClick={this.setFocus} href="javascript:void(0);"  >
										게시판
									</CustomLink>
								</Li>
									<Li className='menu' >
										<CustomLink to='/mainUser' onClick={this.setFocus} href="javascript:void(0);"  >
											시스템관리
										</CustomLink>
									</Li>
								
							</Ul>
							
				
						
						
					
				
				</Header>
				
				{/* <SlidingPane
					className="menuBox"
					overlayClassName="some-custom-overlay-class"
					isOpen={menuOpen}
					hideHeader={true}
					width="300px"
					from={'left'}
					onRequestClose={() => {
					// triggered on "<" on left top click or on outside click
					this.setState({ menuOpen: false });
					}}
				>	
				
					<div>
						{
							this.state.isLogin ?
							<React.Fragment>
								<div className="userBox">
									<div>
										<Link to={'/accountChange'} >
											<p>{this.props.user.userInfo['userData']['name']} 님</p>
											<p className="mail">{this.props.user.userInfo['userData']['email']}</p>
										</Link>
										
									</div>
								</div>
							</React.Fragment>
							:
							<React.Fragment>
								<Link to="/login" className="btnLogin">로그인 / 회원가입</Link>
							</React.Fragment>
						}
					<ul className="menuList f_light">
							{/* <li><Link to='/'>seller </Link></li>
							<li><Link to='/'>item </Link></li> */}
							{/* <li><Link to='/'>style </Link></li>
							<li><Link to='/'>favorite </Link></li>
							<li><Link to='/'>shopping bag </Link></li> */}
							
							{/* {
							this.state.isLogin ?
								<li onClick={()=>{this.actionLogout()}} >
									<a >LogOut</a>
								</li>
								:null
							} */}
{/* 							
						</ul>
							
					</div>
				</SlidingPane> */} 
				{/* Confirm ======================= */}
				
				
			</React.Fragment>
		);
    }
}


export default withRouter(injectIntl(connect(
	state => ({
		user: state.user,
		common: state.common,
	}),
	dispatch => ({
		// user
		handleUserInfo: (data) => { dispatch(actions.SET_USER_INFO(data)) },
		// common
		handleLang: (data) => { dispatch(actions.SET_LANG(data)) },
		handleAlert: (data) => { dispatch(actions.SET_ALERT(data)) },
	})
)(NewHeader)));

