import React, { Component } from 'react';
import { injectIntl } from 'react-intl'
import { nbind } from 'js/common'

class Pager extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
			
		};
		nbind(this);
    }
    componentDidMount(){
    }
    
    openClickHandler = (i) => {
        this.props.onChangePage(i);
	}
	calcPage() {
		let pageIndex = this.props.pageIndex;
		const { pageSize } = this.props;
		let startPage, endPage, prevPage, nextPage;
		if ( pageIndex % 10 === 0 ) {
			startPage = pageIndex - 9;
			endPage = pageIndex;
		} else {
			startPage = Math.floor(pageIndex * 0.1) * 10 + 1
			endPage = Math.floor(pageIndex * 0.1) * 10 + 10
		}
		nextPage = pageIndex + 1;
		prevPage = pageIndex - 1;
		if (nextPage > pageSize) {
			nextPage = pageSize
		}
		if (prevPage < 1) {
			prevPage = 1
		}
		let list = [];
		for (var i = startPage; i <= pageSize && i <= endPage; i++) {
			list.push(i)
		}
		let obj = {
			pageIndex: pageIndex,
			pageSize: pageSize,
			prevPage: prevPage,
			nextPage: nextPage,
			list: list
		}
		return obj
	}
    render() {
		const { t } = this.props;
		let page = this.calcPage();
        return (
            <div className="pageNum">
                <ul>
					{/* { page.pageIndex !== page.prevPage && */}
						<li onClick={() => this.openClickHandler(1)}  className="first">
							<button className="icon arrowDoubleLeftSv">
							<svg xmlns="http://www.w3.org/2000/svg" width="18.551" height="16.234" viewBox="0 0 18.551 16.234">
							<g id="그룹_215" data-name="그룹 215" transform="translate(1939.275 1209.963) rotate(180)">
								<line id="선_15" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
								<line id="선_16" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
							</g>
							<g id="그룹_216" data-name="그룹 216" transform="translate(1948.275 1209.963) rotate(180)">
								<line id="선_15-2" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
								<line id="선_16-2" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
							</g>
							</svg>
	
							</button>
						</li>
					{/* } */}
					{/* { page.pageIndex > 10 && */}
                    	<li onClick={() => this.openClickHandler(page.prevPage)}  className="prev">
							<button className="icon arrowLeftSv">{this.iText('이전',`pager.prev`)}
							<svg xmlns="http://www.w3.org/2000/svg" width="9.551" height="16.234" viewBox="0 0 9.551 16.234">
							<g id="그룹_217" data-name="그룹 217" transform="translate(1939.275 1209.963) rotate(180)">
								<line id="선_15" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
								<line id="선_16" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
							</g>
							</svg>

							</button>
						</li>
					{/* } */}
					{/* <li>
						{this.props.pageIndex} / {this.props.pageSize}
					</li> */}
                    <React.Fragment>
                    {
                        page.list.map((item,index) => {
                            return (
                                <li className={this.props.pageIndex === item ? 'on' : null} onClick={() => this.openClickHandler(item)} key={'page'+index}>
                                    <button>{item}</button>
									
                                </li>
                            )
                        })
                    }
                    </React.Fragment>
					{/* { Math.ceil(page.pageIndex * 0.1) * 10 < Math.ceil(page.pageSize * 0.1) * 10 && */}
						<li onClick={() => this.openClickHandler(page.nextPage)} className="next">
							<button className="icon arrowRightSv">
							<svg xmlns="http://www.w3.org/2000/svg" width="9.551" height="16.234" viewBox="0 0 9.551 16.234">
							<g id="그룹_218" data-name="그룹 218" transform="translate(-1929.725 -1193.729)">
								<line id="선_15" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
								<line id="선_16" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
							</g>
							</svg>

							</button>
						</li>
					{/* } */}
					{/* { page.pageIndex !== page.nextPage && */}
						<li onClick={() => this.openClickHandler(page.pageSize)} className="last">
							<button className="icon arrowDoubleRightSv">
							<svg xmlns="http://www.w3.org/2000/svg" width="18.551" height="16.234" viewBox="0 0 18.551 16.234">
							<g id="그룹_219" data-name="그룹 219" transform="translate(-1929.725 -1193.729)">
								<line id="선_15" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
								<line id="선_16" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
							</g>
							<g id="그룹_220" data-name="그룹 220" transform="translate(-1920.725 -1193.729)">
								<line id="선_15-2" data-name="선 15" y1="5.313" x2="6.187" transform="translate(1937.156 1195.844) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
								<line id="선_16-2" data-name="선 16" x1="5.813" y1="5.313" transform="translate(1937.156 1202.031) rotate(90)" fill="none" stroke="#4fe0b6" strokeLinecap="round" strokeWidth="3"/>
							</g>
							</svg>

							</button>
						</li>
					{/* } */}
                </ul>
            </div>
        );
    }
}

export default Pager;