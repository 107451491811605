import React from 'react';
import { nbind,postIndexing, reverseIndexing } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import CKEditor from 'components/layout/include/CKEditor';
import cookie from "react-cookies";
import SearchBox from 'components/common/searchBox';
import { tsThisType } from '@babel/types';


class Farm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			memberId: '',
			mobileNumber: '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 5,
			list : [],
			keyword: '',
			checkList :[],
			pageListSize : 10,
			request : {
				params : {
					size : 10,
					page: 1
				}
			},
			totalElements : 10,
			//deviceList: [],
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);

	}
	//페이징
	onChangePage(index) {
		this.setState({ pageIndex: index },()=>{
			let obj = this.state.request;
			obj.params.page = Number(index) - 1;
			this.callData(obj);
		});
	}

	//검색
	onSearchChange(i, value) {
        this.setState({pageIndex : i, request : value}, () => {this.callData(this.state.request)});
    };


	componentDidMount() {
		let obj = {
			params : {
				size : 10,
				page: Number(this.state.pageIndex) -1
			}
		};
		this.callData(obj);
	}
	callData (obj) {
		if(this.state.keyword != '' ){
			obj['name'] = this.state.keyword
		}
		this.props.setLoading(true);
		API.fetchFarms(obj).then(result => {
			this.setState({
				list : result.content ,
				pageSize : result.totalPages,
				totalElements : result.totalElements,
				checkList : []
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			this.props.setLoading(false)
		});


}
	
	deleteList() {
		if(this.state.checkList.length < 1){
			alert('선택된 목록이 없습니다.')
		}
		let obj = {
			userId : cookie.load("userId"),
			ids: this.state.checkList
		};
		
		this.props.setLoading(true)
		API.fetchFarmsDelete(obj).then(result => {
			this.props.setLoading(false)
			alert("삭제되었습니다.");
			
			let obj = {
				params : {
					size : 10,
					page: Number(this.state.pageIndex) -1
				}
			};

			this.callData(obj)
		}).catch(error => {
			console.log(error);
			this.props.setLoading(false)
			alert("실패 되었습니다.");
		});
	}
	
	handleCheckboxChange = event => {
		let newArray = [...this.state.checkList, event.target.value];
		if (this.state.checkList.includes(event.target.value)) {
		  newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		  checkList: newArray
		});
	};
	
	searchContent = [
        {
            name : "이름",
            classCode : 'name',
            type : 'TEXT'
        }
    ]

	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장</h2>
                        <div>
						<SearchBox 
                            searchContent = {this.searchContent}
                            onSearchChange = {this.onSearchChange }
                            pageIndex = {this.state.pageIndex}
                            pageSize = {this.state.pageSize}
                            pageListSize = {this.state.pageListSize}
                        />
                                
                            <div className="tableBox mt30">
                                <table>
									<colgroup>
										<col width="50"/>
										<col width="50"/>
										<col width="150"/>
										{/* <col width="200"/> */}
										<col width="150"/>
										<col width="150"/>
										<col width="150"/>
										<col width="200"/>
										
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>번호</th>
                                            <th>농장명</th>
                                            {/* <th>전화번호</th> */}
                                            <th>농장주 아이디</th>
                                            <th>핸드폰번호</th>
                                            <th>생산물</th>
                                            <th>상세보기</th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
									{this.state.list.map((item,index)=>{
												return(
													<tr key={index}>
														<td className="textCenter">
															{/* <div className="checkBox"><input type="checkbox" name="type" id="T4"/> <label htmlFor="T4"></label></div> */}
															<div className="checkBox"><input type="checkbox" name="type" id={'t'+item.id} value={item.id} onChange={this.handleCheckboxChange} checked={this.state.checkList.indexOf(String(item.id)) > -1}/> <label htmlFor={'t'+item.id}></label></div>
														</td>
														<td className="textCenter">{reverseIndexing(this.state.totalElements, this.state.pageIndex,index, this.state.pageListSize)}</td>
														<td className="textCenter"><a href="javascript:void(0);" onClick={() => { this.props.history.push({ pathname: `/farm/detail/${item.id}` }) }}>{item.name}</a> </td>
														{/* <td className="textCenter">{item.telephoneNumber}</td> */}
														<td className="textCenter">{item.userId}</td>
														<td className="textCenter">{item.mobileNumber}</td>
														<td className="textCenter">{item.productName}</td>
														<td className="textCenter">
															<button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/farm/${item.id}/info/` }) }}>농장정보</button>
														</td>
													</tr>
													)
											})
										}
									</tbody>
                                </table>
                                <div className="textRight">
								
                                    <Link to="/farm/farmregister" className="btn mr10 mt10">등록</Link>
									<button  className="btn mr10 mt10" onClick = {() => {this.deleteList()} }>삭제</button>
                                </div>
                                <Pager pageIndex={this.state.pageIndex} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Farm;