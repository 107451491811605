
const initialState = {
	'role' : "", 
	'acr': "",
	'allowed-origins': [],
	'aud"': [],
	"azp": "farmin",
	"email_verified": false,
	"exp": null,
	"family_name": "",
	"given_name": "",
	"iat": null,
	"iss": "",
	"jti": "",
	"name": "",
	"preferred_username": "",
	"realm_access": {roles: null},
	"resource_access": {},
	"scope": "openid email profile",
	"session_state": "eb1cb63c-adaf-498f-8e66-a04e43d74ed7",
	"sub": "bd861be1-cd2a-49e3-b95d-80a76c744e59",
	"typ": "Bearer",
	"role" : "",
};

export default function header(state = initialState, action) {

	switch (action.type) {
		case 'USER_INFO':
			let role = '';
			// if(action.data.realm_access) {
				
			// }
			return {
				...action.data
			};
		default:
			return state;
	}
}