import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';

import home from 'img/icon-home.png'
import flower from 'img/icon-flower.png'
import bug from 'img/main_bug.png'

import mapping from 'img/icon-mapping.png'
import { useInput } from 'hooks/commonHooks'
import Modal from './setFarm';

import { connect } from "react-redux";


const mapStateToProps = state => {
  return {
    test: state,
  };
};

const Wrap = styled.div`
width: 32%;

text-align: center;
justify-content: center;
background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:20px;
/* padding-bottom: 10%; */

`
const Section = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
`
const Title = styled.span`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2rem;
color: #2A3A4B;
`
const Button = styled.button`
display: flex;
justify-content: center;
align-items: center;

width: 10.7rem;
height: 41px;

background: #17B9B6;
border-radius: 5px;

font-family: "Spoqa Han Sans Neo";
font-style: normal;
font-weight: 400;
font-size: 18px;

color: #FFFFFF;
`

const Card = styled.div`
display: flex;
flex-direction: column;
width: 100%;

margin-top: 16px;
padding: 6% 5.8% 0px 9.7%;
background: #F5F5F5;
border-radius: 5px;
padding-bottom: 0px;
`

const Line = styled.div`
display: flex;
width: 100%;
padding-bottom: 8%;
align-items: center;
`

const Img = styled.img`
width: 25px;
height: 25px;
`
const Sub = styled.span`
font-family: Spoqa Han Sans Neo;
font-size: 16px;
text-align: left;
width: 45px;

margin: 0px 7px;

color: #A4A4A4;
`
const Text = styled.span`
flex:1;
text-align: left;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 16px;
margin-left: 8px;
line-height: 2rem;
color: #2A3A4B;
`



const init = (obj, idx) => {
    let informations = {
        "name": '',
        "child_num":0,
        "best":'',
        "productCode":'VEGETABLES',
        address:''

    }
    if (obj == undefined || obj.length==0) {
        return informations
    }
    
    const list=obj[idx]
    informations.name=list.name
    informations.child_num=list.children.length
    informations.best=list.productCodeInfo.name
    informations.productCode=list.productCode
    informations.address=list.roadNameAddress
    informations.productCode=list.productCode.slice(0,6)
    return informations


}



function FarmInformation(props) {
    const [information,info_handdle,set_info]=useInput({})
    const [modal_visible,set_modal_visible]=useState(false)
    useEffect(() => {
        set_info(init(props.farm,props.idx))
    },[props])
    useEffect(()=>{
        // console.log(props.farm)
    },[information])


    return (
        <Wrap>
            <Section>
                <Title>
                    {information.name}

                </Title>
                
            <Modal idx={props.idx} set_idx={props.set_idx} farm={props.farm} onClose={()=>set_modal_visible(false)} visible={modal_visible}/>

                <Button onClick={()=>set_modal_visible(true)}>
                    농장선택
                </Button>
            </Section>

            <Card>
                <Line>
                    <Img src={home} alt="" />
                    <Sub>                    
                        {information.productCode=="INSECT"?"곤충동":"시설동"}
</Sub>
                    <Text>{information.child_num}개</Text>
                </Line>

                <Line>
                    <Img src={information.productCode=="INSECT"?bug:flower} alt="" />
                    <Sub>
                        {information.productCode=="INSECT"?"곤충":"작물"}
                        
                        
                        
                        </Sub>
                    <Text>{information.best}</Text>
                </Line>

                <Line>
                    <Img src={mapping} alt="" />
                    <Sub>주소</Sub>
                    <Text>{information.address}</Text>
                </Line>
            </Card>
        </Wrap>

    );
}

export default connect(mapStateToProps)(FarmInformation);


