import React,{useState,useEffect,useCallback,useRef} from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import cookie from 'react-cookies';
import _ from 'lodash'
import {useInput} from '@/hooks/commonHooks';
import { mobileCheck,emailCheck } from 'js/vaildChecker';
const FindUserId = () =>{

	const mobileRef = useRef(null),
		  emailRef = useRef(null);

	const [name, onChangeUserName] = useInput('');
	const [mobilePhoneNumber, onChangeMobileNumber] = useInput('');
	const [email,onChangeEmail] = useInput('');

	const fetchFindId = useCallback(e =>{
		e.preventDefault();
		let updateResult;
		let mobileValid = mobileCheck(mobilePhoneNumber);
        if(!mobileValid){
            alert('전화번호는 -을 포함한 형식으로 입력 해 주세요.');
            mobileRef.current.focus();
            return;
        }
        let emailValid = emailCheck(email);
        if(!emailValid){
            alert('올바른 이메일 형식이 아닙니다.');
            emailRef.current.focus();
            return;
        }		

		const getUserInfo = API.findUserId({name,mobilePhoneNumber,email});
		getUserInfo.then(res=>{
			if(res.length > 0){
				updateResult = window.confirm(`입력하신 정보와 일치하는 아이디는" ${res[0].userId} "입니다\n로그인 화면으로 이동하시겠습니까?`);
			}else{
				alert(`입력하신 정보와 일치하는 아이디가 존재하지 않습니다`);
			}
		}).
		catch(err =>{
			console.log(err);
		}).finally(()=>{
			if(updateResult) window.history.back();
		})


	},[name,mobilePhoneNumber,email]);

	return(
		<div id="contents">
			<div className="contentInner">
				<div className="userIdContentWrapper">
					<div className="titleContentWrapper">
						아이디 찾기
					</div>
					<div className="innerContentWrapper">
						<div className='inputWrapper'>
							<div>이름</div>
							<input placeholder='가입하신 유저의 성함을 입력 해 주세요' onChange={e=>onChangeUserName(e)}/>
						</div>
						<div className='inputWrapper'>
							<div>전화번호</div>
							<input ref={mobileRef} placeholder='가입하신 유저의 전화번호를 입력 해 주세요' onChange={e=>onChangeMobileNumber(e)}/>
						</div>
						<div className='inputWrapper'>
							<div>이메일</div>
							<input ref={emailRef} placeholder='가입하신 유저의 이메일을 입력 해 주세요'onChange={e=>onChangeEmail(e)} onKeyUp={e=>e.key === 'Enter' ? fetchFindId(e) : null}/>
						</div>
						<button className='submitBnt' type='submit' onClick={fetchFindId}>아이디 찾기</button>
					</div>
				</div>
			</div>
		</div>
	)

}
export default FindUserId;