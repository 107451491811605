import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Header from './include/Header';
import Loader from './include/Loader';
const Layout3 = ({ component: Component, ...rest }) => {
	return (
		<Route  {...rest} render={matchProps => (
			<div id='wrap' style={{display:'block'}}>
				<section id='container' className="layout3">
					<Component {...matchProps} />
				</section>
				<Loader />
			</div>
		)} />
	)
};

export default withRouter(Layout3);