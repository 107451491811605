import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import leftArrow from "img/icon_leftArrow.png";
import rightArrow from "img/icon_rightArrow.png";
import clicked_rightArrow from "img/button_arrow_clicked.png";

import { useInput } from "hooks/commonHooks";
import Box from "components/mainPage/material/box";
import Modal from "./modalMapping";
import { useInterval } from "components/utils/api";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  min-width: 1300px;
  background-color: ${(props) => props.background};

  /* @media (max-width: 1300px) {
min-width:1100px;
  
} */
`;

const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  margin-top: 2rem;
  margin-bottom: 3rem;
`;
const Title = styled.span`
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 46px;

  color: #656565;
`;

const List_wrapper = styled.div`
  display: flex;
`;

const Button_wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const List = styled.div`
  width: 1.5rem;
  height: 1rem;
  margin: 0px 4px;

  background: #d9d9d9;
  border-radius: 9px;

  cursor: pointer;
`;

const Arrow = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;

  &:hover {
    content: url(${clicked_rightArrow});
  }
`;
const Left_Arrow = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  &:hover {
    content: url(${clicked_rightArrow});
    transform: rotate(180deg);
  }
`;

const style_init = (num) => {
  let lists = document.getElementsByClassName("Arrow");

  for (let i of lists) {
    i.style.width = "1.5rem";
    i.style.background = "#D9D9D9";
  }
  let list = document.getElementsByClassName("List" + num);

  if (list == undefined) {
    return;
  } else {
    list = list[0];
  }
  if (list == undefined) {
    return;
  }

  list.style.width = "2.5rem";
  list.style.background = "#17B9B6";
  list.style.borderRadius = "9px";
};

const init = (obj, idx) => {
  let informations = {
    children: [],
    child_num: 0,
    productCode: "",
  };

  if (obj == undefined || obj.length == 0) {
    return informations;
  }
  const list = obj[idx];

  informations.child_num = list.children.length - 1;
  informations.children = list.children;
  informations.productCode = list.productCode.slice(0, 6);

  return informations;
};

let boxs = [];

function Main(props) {
  const [num, set_num] = useState(0);
  const [arrow_position, set_arr] = useState(0);
  const [container, set_con] = useState([]);
  const [max_idx, set_max] = useState(0);
  const [modal_visible, set_modal_visible] = useState(false);
  const [modal_id, set_modal_id] = useState(0);

  const [information, set_information] = useState({});
  let informations = {};

  useInterval(props.start, modal_visible ? null : 16000);

  const return_box = (container, idx) => {
    boxs = [];
    if (container.length <= 0) {
      return;
    }

    push_child(container[idx], 0, container[idx].name);
  };

  const modalClick = (id) => {
    set_modal_visible(true);
    set_modal_id(id);
  };

  const push_child = (obj, depth, name) => {
    let is_check = true,
      comfort = false,
      heat = false,
      humidity = false,
      water = false,
      cool = false,
      is_hd = false;
    // HD가 존재하는지 체크하는 부분
    obj.equips.find((val) => {
      val.sensors.map((data) => {
        if (data.tagClass.code == "HD") {
          is_hd = true;

          if (is_check) {
            comfort = false;
            is_check = false;
            let hd_data = data.value;
            if (hd_data <= 4.5) {
              heat = true;
              humidity = true;
            } else if (hd_data <= 6.5) {
              heat = true;
              water = true;
            } else {
              cool = true;
              water = true;
            }
          }
        }
      });
    });

    if (!is_hd) {
      comfort = false;
    }

    boxs.push(
      <Box
        comfort={comfort}
        heat={heat}
        cool={cool}
        humidity={humidity}
        water={water}
        product={information.productCode}
        num={num}
        data={obj}
        onClick={modalClick}
        depth={depth}
        name={name}
      />
    );

    obj = obj.children;
    if (obj.length != 0) {
      obj.map((val) => {
        push_child(val, depth + 1, `${name} > ${val.name}`);
      });
    }
  };

  useEffect(() => {
    dataSet();
  }, [num, props, container]);

  const dataSet = () => {
    try {
      informations = init(props.farm, props.idx);
      set_max(informations.child_num);
      set_con(informations.children);
      set_information(informations);
      style_init(num);
    } catch (error) {}
  };

  const push_list = () => {
    let start = num - arrow_position;
    let last = num + 2 - arrow_position;

    let arrows = [];
    if (start <= 0) {
      start = 0;
    }
    if (last >= max_idx) {
      last = max_idx;
    }

    for (let i = start; i <= last; i++) {
      arrows.push(
        <List
          onClick={() => {
            set_num(i);
            set_arr(i - start);
          }}
          className={`Arrow List${i}`}
        />
      );
    }
    if (max_idx == 0) return;
    return arrows.map((val) => {
      return val;
    });
  };

  const arrow_onclick = (direction) => {
    if (direction === "left") {
      set_num(num - 1);
      if (arrow_position >= 1) {
        set_arr(arrow_position - 1);
      }
    } else {
      set_num(num + 1);

      if (arrow_position < 2) {
        set_arr(arrow_position + 1);
      }
    }
  };
  return (
    <Wrapper>
      {modal_visible && (
        <Modal
          start={props.start}
          stop={props.stop}
          modal_id={modal_id}
          onClick={set_modal_visible}
          visible={modal_visible}
        />
      )}
      <Top>
        <Title>
          {information.productCode == "INSECT" ? "곤충" : "시설"}동 환경정보
        </Title>
        <Button_wrapper>
          {num <= 0 || (
            <Left_Arrow
              onClick={() => arrow_onclick("left")}
              style={{ marginRight: "12px" }}
              src={leftArrow}
            />
          )}

          <List_wrapper>{push_list()}</List_wrapper>

          {num >= max_idx ? (
            <Arrow
              style={{ opacity: "0%", cursor: "auto", marginLeft: "12px" }}
            />
          ) : (
            <Arrow
              style={{ marginLeft: "12px" }}
              src={rightArrow}
              onClick={() => arrow_onclick("right")}
            />
          )}
        </Button_wrapper>
      </Top>

      {return_box(container, num)}

      {boxs.map((val) => {
        return val;
      })}
    </Wrapper>
  );
}

export default Main;
