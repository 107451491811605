import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import * as common from "js/common";


class userDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
    		controlAuthorYn : "N",
   			userId: null,
			farmId: this.props.match.params.id,
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}
	onChangePage(index) {
		this.setState({ pageIndex: index }, () => alert('페이지변경'));
	}
	componentDidMount() {
		this.apply();
	}
	apply () {
		let obj = {
			id : this.props.match.params.id,
		};
		
		this.props.setLoading(true)
		API.farMemberaccess(obj).then(result => {
			this.setState({
             	result
			})
			this.props.setLoading(false);
			console.log(result);
			console.log("dsadsfsda");
		}).catch(error => {
            console.log(error);
            this.props.setLoading(false);
        });
		
	}


	update () {
		let obj = {
			id : this.props.match.params.id,
			controlAuthorYn : this.state.controlAuthorYn,
		};
		this.props.setLoading(true)
        
        API.farmMemberUpdate(obj).then(result => {
            //this.apply();
			this.setState({
				
			});
			this.props.setLoading(false);
            
			let confirm = window.confirm('수정 되었습니다.');
            if(confirm) window.history.back();
			
		}).catch(error => {
            console.log(error);
            this.props.setLoading(false);
        });
		

	}
	
	render() {
		return (
			<React.Fragment>
			<div className="contentInner">
				<div className="contentArea">
					<h2 className="pageTitle textCenter">농장 접근권한 수정</h2>
					<div className="noticeDetail">
						
						<div className="tableBox detail mt30">
							<table>
								<tbody>
									<tr>
										<th>ID</th>
										<td><input type="text" value={this.state.userId} onChange={(e) => {
													let data = this.state; 
													data.userId = e.target.value
													this.setState({ data })
												}}/>
										</td>
										<th>제어권한</th>
										<td>
											<div className="selectBox w100">
												<select name="" id="" value={this.state.controlAuthorYn} placeholder="제목 + 내용"
												onChange={(e) => {
													let data = this.state; 
												data.controlAuthorYn = e.target.value
												this.setState({ data })
													
													}}>
											
													<option value="Y">허용</option>
													<option value="N">허용안함</option>
												</select>

											</div>
										</td>
									</tr>
									 {/* <th>농장</th>
										<td><input type="text" value={this.state.userId} onChange={(e) => {
													let data = this.state; 
													data.userId = e.target.value
													this.setState({ data })
												}} />
										</td> */}
								  
								</tbody>
							</table>
						</div>
					  
						<div className="textCenter mt30"> 
						
							<button className="btn mr5 mt10"  onClick={()=>{this.update()}}>수정</button>
						
							<button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
		);
	}
}
export default userDetail;