import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { isCompositeComponent } from 'react-dom/test-utils';
import * as common from "js/common";

class userRegister extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
    		controlAuthorYn : "Y",
   			userId: null,
            confirm : false,
            startDate: null,
            displayMemberId:'',
            memberId: '',
            userCheck:false,
            farmId: this.props.match.params.id,
            
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}
	onChangePage(index) {
		this.setState({ pageIndex: index }, () => alert('페이지변경'));
	}

    idSearch(){
        if(this.state.userId == '') return alert('농장주 아이디를 입력 해 주세요');
        API.farmMemberExists({id:this.state.userId}).then(res => {
            if(res.length > 0){
                this.setState({
                    userChck : true,
                    ...this.state,
                    id : res[0].id,
                

                });
                alert('유저 조회가 완료 되었습니다.');
                this.setState({confirm : true});
            }else{
                alert('검색된 유저가 존재하지 않습니다.');
                this.setState({id : null, confirm : false});
            }
          
        }).catch(err =>{
            alert(`서버와의 통신이 원활하지 않습니다.\n${err.message}`);
        })

    }


	insert(){
        this.props.setLoading(true);
        console.log(this.state);
        API.farmMebers(this.state).then(result => {
            console.log(result);
            this.props.setLoading(false);
            let confirm = window.confirm('등록이 완료 되었습니다. \n리스트 화면으로 이동 하시겠습니까?');
            if(confirm) window.history.back();
        }).catch(error => {
            console.log(error)
            this.props.setLoading(false);
        });
    }
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장 접근권한 등록</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
											<th>ID</th>
                                            <td><input type="text" value={this.state.userId} onChange={(e) => {
                                                        let data = this.state; 
                                                        data.userId = e.target.value
                                                        this.setState({ data })
													}}
                                                        readOnly={this.state.userCheck}
                                                    />
                                            </td>
                                            <td colSpan="2"><button onClick={()=>{this.idSearch()}} className="btn">ID검색</button></td>
                                            <th>제어권한</th>
                                            <td>
                                                <div className="selectBox w100">
                                                    <select name="" id="" value={this.state.controlAuthorYn} placeholder="제목 + 내용"
													onChange={(e) => {
														let data = this.state; 
													data.controlAuthorYn = e.target.value
													this.setState({ data })
														
														}}>
												
														<option value="Y">허용</option>
                                                        <option value="N">허용안함</option>
                                                    </select>

                                                </div>
                                            </td>
                                           
                                        </tr>
                                         {/* <th>농장</th>
                                            <td><input type="text" value={this.state.userId} onChange={(e) => {
                                                        let data = this.state; 
                                                        data.userId = e.target.value
                                                        this.setState({ data })
													}} />
                                            </td> */}
                                        
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                {
                                    this.state.confirm ? <button className="btn mr5 mt10" onClick={()=>{this.insert()}}>등록</button> : <button className="btn gray ml30" disabled>등록</button>
                                }
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default userRegister;