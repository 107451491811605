import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import sunny from 'img/icon-sunny.png'
import cloud from 'img/icon-cloud.png'
import rain from 'img/rain.png'
import evening from 'img/evening.png'
import graycloud from 'img/graycloud.png'

import img_sunrise from 'img/icon_sunrise.png'
import img_sunset from 'img/icon_sunset.png'
import { useInput } from 'hooks/commonHooks'
import moment from 'moment'
import getLunar from 'components/utils/getLunar';
import { API } from 'service';
const Wrap = styled.div`
width: 32%;

text-align: center;
justify-content: center;
background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:20px;
`
const Section = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
/* padding: 4px 0px; */
`
const Title = styled.span`
display: flex;
font-family: Spoqa Han Sans Neo;
font-weight: 500;

text-align: left;
align-items: center;
font-size: 2rem;
color: #2A3A4B;
height: 44px;



`

const Middle_title = styled.span`
display: flex;
font-family: Spoqa Han Sans Neo;
font-weight: bold;

text-align: left;
align-items: center;
font-size: 22px;
color: #2A3A4B;
height: 44px;
`

const Button = styled.button`
display: flex;
justify-content: center;
align-items: center;

width: 14.4rem;
height: 41px;

background: #17B9B6;
border-radius: 5px;

font-family: "Spoqa Han Sans Neo";
font-style: normal;
font-weight: 400;
font-size: 18px;

color: #FFFFFF;
`

const Card = styled.div`
display: flex;
width: 100%;
height: 47px;
align-items: center;
justify-content: space-evenly;
padding: 0px 15%;
margin-top: 16px;

background: #F5F5F5;
border-radius: 5px;
`
const Days = styled.div`
display: flex;

`

const Sub = styled.div`
display: flex;
font-family: Spoqa Han Sans Neo;
font-size: 16px;
margin-right: 8px;
color: #A4A4A4;
`

const Text = styled.div`
display: flex;
font-family: Spoqa Han Sans Neo;
font-size: 16px;

color: #666F82;
`

const Foot = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 8px;
`

const Temp_wrap = styled.div`
display: flex;
flex-direction: column;
align-items: center;

`
const Text_wrap = styled.div`
display: flex;
align-items: center;

`
const Img = styled.img`
width: 88px;
height: 88px;
`
const Front = styled.span`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 16px;
margin-right: 3px;
color: #666F82;
`

const Tem = styled.span`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;

color: #2A3A4B;
`
const C = styled.span`
display: flex;
height: 100%;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 17px;
/* identical to box height */


color: #2A3A4B;

`
const Sun_icon = styled.img`
width: 15px;
height: 15px;
margin-right: 5px;
`

const Sun_text = styled.span`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 16px;

color: #A4A4A4;
`
const Sun_wrap = styled.span``
const Sun_wrapper = styled.div`
display: flex;
justify-content: space-around;
margin-top: 6px;
`

const Time_wrap = styled.div`
display: flex;
flex-direction: column;
margin-top: 20px;
`
const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토']
let fullDate = moment(new Date()).format('YYYY년 MM월 DD일')
let day_date = WEEKDAY[new Date().getDay()]

const getTime = () => {
    let text = '';
    let now = new Date()
    let nowHour = now.getHours();
    if (nowHour <= 12) {
        text = '오전 '
    } else {
        text = '오후 '
    }
    let time = moment().format('hh시 mm분')
    return text + time
}



function Main(props) {
    const [time, set_time] = useState("0")
    const [lunar, set_lunar] = useState([])
    const [sunrise, set_sunrise] = useState(0)
    const [sunset, set_sunset] = useState(0)
    const [solarterm, set_solarterm] = useState({ 'dDay': '', 'title': '' })
    const [dayMaxTemp, set_dayMaxTemp] = useState("0")
    const [dayMinTemp, set_dayMinTemp] = useState("0")

    const [isSensor, set_isSensor] = useState(false)

    const [amIcon, set_amIcon] = useState(sunny)
    const [pmIcon, set_pmIcon] = useState(sunny)
    const openPopup = () => {
        sessionStorage.setItem("farm", JSON.stringify(props.farm[props.idx]));
        window.open(`popUp?outdoor=true`, '_blank', 'width=1400, height=800, resizable=no, menubar=no, status=no, toolbar=no')
    }


    useEffect(() => {
        dataSet();
        if (props.farm != undefined && props.farm.length != 0) {
            set_isSensor(false)
            API.getWeather({ "longitude": props.farm[props.idx].longitude, "latitude": props.farm[props.idx].latitude }).then(data => {
                set_sunrise(data.sunrise)
                set_sunset(data.sunset)
                set_solarterm(data.solarterm)
                set_dayMaxTemp(data.dayMaxTemp)
                set_dayMinTemp(data.dayMinTemp)

                let am_icon = ''
                if (data.amPTY == 0)
                    switch (data.amSKY) {
                        case '1': am_icon = sunny; break;//맑음
                        case '3': am_icon = cloud; break;//구름 많음
                        case '4': am_icon = graycloud; break;//흐림
                    }
                else
                    switch (data.amPTY) {
                        case '1': am_icon = rain; break;//비
                        case '2': am_icon = rain; break;//비,눈
                        case '3': am_icon = rain; break;//눈
                        case '4': am_icon = rain; break;//소나기
                    }

                let pm_icon = ''
                if (data.pmPTY == 0)
                    switch (data.pmSKY) {
                        case '1': pm_icon = sunny; break;//맑음
                        case '3': pm_icon = cloud; break;//구름 많음
                        case '4': pm_icon = graycloud; break;//흐림
                    }
                else
                    switch (data.pmPTY) {
                        case '1': pm_icon = rain; break;//비
                        case '2': pm_icon = rain; break;//비,눈
                        case '3': pm_icon = rain; break;//눈
                        case '4': pm_icon = rain; break;//소나기
                    }
                set_amIcon(am_icon)
                set_pmIcon(pm_icon)




            }).catch(data => {
                // console.log(props.farm[props.idx].longitude)
                // alert('asd')
            })

            props.farm[props.idx].equips.map(val => {

                if (val.equipClass.enableControlYn == "N") {
                    set_isSensor(true)

                }
            })


        }
        let lunar = getLunar()
        set_lunar([lunar.lunMonth, lunar.lunDay])
        set_time(getTime())

        setInterval(() => {
            set_time(getTime())

        }, 1000)
    }, [props])


    const dataSet = () => {
        try {
        } catch (error) {
        }
    }

    return (

        <Wrap>
            <Section>
                <Title>
                    {fullDate} {day_date}요일
                </Title>
                {false/* isSensor */ &&
                    <Button onClick={openPopup} >

                        외기센서 확인
                    </Button>
                }
            </Section>

            <Card>
                <Days>
                    <Sub>음력</Sub>
                    <Text>{lunar[0]}월 {lunar[1]}일</Text>
                </Days>

                <Days>
                    <Sub>절기</Sub>
                    <Text>{solarterm.title} + {solarterm.dDay}일</Text>
                </Days>
            </Card>

            <Foot>
                <Temp_wrap>
                    <Img src={amIcon || sunny} />
                    <Text_wrap>

                        <Front>
                            {dayMaxTemp == null ? 'ERROR' : '최고'}
                        </Front>
                        <Tem>
                            {dayMaxTemp}
                        </Tem>
                        <C>
                            {dayMaxTemp && '℃'}

                        </C>
                    </Text_wrap>

                </Temp_wrap>

                <Time_wrap>
                    <Middle_title>
                        {time}
                    </Middle_title>
                    <Sun_wrapper>
                        <Sun_wrap>
                            <Sun_icon src={img_sunrise} />
                            <Sun_text>{sunrise}</Sun_text>
                        </Sun_wrap>

                        <Sun_wrap>
                            <Sun_icon src={img_sunset} />
                            <Sun_text>{sunset}</Sun_text>
                        </Sun_wrap>
                    </Sun_wrapper>
                </Time_wrap>
                <Temp_wrap>
                    <Img src={pmIcon || sunny} />
                    <Text_wrap>
                        <Front>

                            {dayMinTemp == null ? 'ERROR' : '최저'}
                        </Front>
                        <Tem>
                            {dayMinTemp}
                        </Tem>
                        <C>
                            {dayMinTemp && '℃'}


                        </C>
                    </Text_wrap>

                </Temp_wrap>

            </Foot>
        </Wrap>

    );
}

export default Main;