import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class GatewayDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 1,
			list : []
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}
	onChangePage(index) {
		this.setState({ pageIndex: index }, () => alert('페이지변경'));
	}
	componentDidMount() {
		
	}
	apply () {
		let obj = {
			userToken: this.props.user.userInfo.userToken,
			contents : this.state.content,
			cellphone : '',
			email : this.state.email,
			name: this.state.name
		};
		
		if(this.state.content === ""){
			this.alert('문의내용을 입력해주세요.'); 
			return false
		}

		API.inquiries(obj).then(result => {
			
			if(result.responseCode == "SUCCESS"){
                this.alert('문의내용이 접수되었습니다.')
                this.historyBack()
			}else{
				
				this.alert('등록에 실패하였습니다.')
			}
		}).catch(error => console.log(error));
		
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">장치, 태그 목록</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td><input type="text"/></td>
                                            <th>농장</th>
                                            <td><input type="text"/></td>
                                            <th>농장</th>
                                            <td><input type="text"/></td>
                                            <th>농장</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>농장</th>
                                            <td><input type="text"/></td>
                                            <th>농장</th>
                                            <td><input type="text"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button  className="btn mr5 mt10">수정</button>
                                <Link to="/gateway" className="btn gray ml30">취소</Link>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default GatewayDetail;