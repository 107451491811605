import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import CKEditor from 'components/layout/include/CKEditor';
import cookie from 'react-cookies'
import * as common from "js/common";

class Gateway extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            pageIndex:1,
			list : [],

			searchStartDate :  new Date((new Date).setDate((new Date).getDate() - 6 )),
			searchEndDate: new Date()

		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	onChangePage(index) {
		this.setState({ pageIndex: index });
	}

	componentDidMount() {
		this.callData()
	}
	
	callData () {
		let startDate = this.state.searchStartDate;
		let endDate = this.state.searchEndDate;
		console.log(startDate)
		console.log(endDate)

		console.log(common.toLocalDate(new Date(startDate).setDate(new Date(startDate).getDate() - 1)))
		console.log(common.toLocalDate(new Date(endDate).setDate(new Date(endDate).getDate() + 1)))

		

		let obj = {
			params : {}
		};
		
		if(startDate){
			obj.params['startDate'] = common.toLocalDate(new Date(startDate).setDate(new Date(startDate).getDate() - 1))			
		}		
		if(endDate){
			obj.params['endDate'] = common.toLocalDate(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
		}

		this.props.setLoading(true)
		API.loginLogList(obj).then(result => {

			let searchStartDate = common.toLocalDate(new Date(startDate))
			let searchEndDate = common.toLocalDate((new Date(endDate)).setDate(endDate.getDate() + 1))

			console.log(common.toDateString(searchStartDate, `time`))
			console.log(common.toDateString(searchEndDate, `time`))

			result = result.filter(one => 
			    one.type === 'LOGIN' || one.type === 'LOGIN_ERROR'
			).filter(one => 								
				new Date(common.toLocalDate(one.time, 'time')) >= new Date(common.toDateString(searchStartDate, `time`))
				&&
				new Date(common.toLocalDate(one.time, 'time')) < new Date(common.toDateString(searchEndDate, `time`))
			)

			this.setState({
				list : result,
				pageIndex : 1			
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			alert('데이터를 불러오지 못했습니다')
			this.props.setLoading(false)
		});
		
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">로그인 내역</h2>
                        <div>
                            <div className="whBox">
                                <div className="searchBox">
									<div className="datePickerDuration">
										<DatePicker
											className="dfhadjklfhls"
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={new Date(this.state.searchStartDate)}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { 
												this.setState({ searchStartDate : new Date(common.toLocalDate(date))});
												
											}}
										/>
										<span className="dash">~</span>
										<DatePicker
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={new Date(this.state.searchEndDate)}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { 												
												this.setState({ searchEndDate :  new Date(common.toLocalDate(date))});
											}}
										/>
									</div>
                                    <button type="button" onClick={() => {this.callData()}}>검색</button>
                                </div>
                            </div>
                            <div className="tableBox mt30">
                                <table>
									<colgroup>
										<col width="50"/>
										<col width="100"/>
										<col width="100"/>
										<col width="100"/>
										<col width="50"/>
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>발생</th>
                                            <th>IP</th>
                                            <th>시각</th>
                                            <th></th>                                            
                                        </tr>
                                    </thead>
                                    <tbody>
										{
											this.state.list.length === 0 ?
												<tr style={{ cursor: 'default' }}>
													<td colSpan='100' className="textCenter">로그인 내역이 없습니다.</td>
												</tr>
											:
													this.state.list.slice(this.state.pageIndex * 10 - 10, this.state.pageIndex * 10).map((item,index)=>{
													return(
														<tr >
															<td className="textCenter"></td>															
															<td className="textCenter">{item.type}</td>
															<td className="textCenter">{item.ipAddress}</td>
															<td className="textCenter">{common.toLocalDate(item.time, `time`)}</td>
															<td className="textCenter"></td>
														</tr>
													)
													}
										)}
                                    </tbody>
                                </table>
                                <Pager pageIndex={this.state.pageIndex} pageSize={Math.ceil(this.state.list.length / 10) } onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Gateway;