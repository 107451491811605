import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import { Line, Bar, Pie } from 'react-chartjs-2'
import { isArray } from 'highcharts';
import moment from 'moment'

import pin from "img/map_pin.png";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { 
  Navigation, 
  Pagination, 
  Scrollbar, 
  A11y 
} from "swiper"; //*

//style
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss"; // *
import "swiper/components/pagination/pagination.scss"; // *
import "swiper/components/scrollbar/scrollbar.scss";
import cookie from "react-cookies"; // *

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]); // *

const { kakao } = window;


const sliderSetting = {
    dots: true,
    infinite: false,
    // speed: 500,
    // slidesToShow: 1,
    
    centerPadding:10,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 4,
    
    // className: 'sample',
};

let today = new Date().toLocaleString()
let fullDate = moment(new Date()).format('YYYY-MM-DD')
let fullDate2 = moment(new Date()).format('YYYYMMDD')

class Notice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			weatherPop : false,
            alramPop : false,
            hdPop : false,
            //
            localList : [],
            treeNodeId : null,
            farmList : [],
            selectFarm : null,
            
            productList : [
                {
                    productName :'22',
                     
                },
                {
                    productName :'22',
                     
                },
                {
                    productName :'22',
                     
                },

            ],
            totalApply :{
                labels: ['심각', '많음', '보통', '적합', '적음', '아주적음'],
                datasets: [
                    {
                        label: "Stock A",
                        backgroundColor: ["#E16A6A", "#E9B260", "#EAD047", "#69B250", "#23B2AA", "#3E54D3"],
                        borderColor: ["#E16A6A", "#E9B260", "#EAD047", "#69B250", "#23B2AA", "#3E54D3"],
                        borderWidth: 1,
                        hoverBackgroundColor: ["#E16A6A", "#E9B260", "#EAD047", "#69B250", "#23B2AA", "#3E54D3"],
                        hoverBorderColor: ["#E16A6A", "#E9B260", "#EAD047", "#69B250", "#23B2AA", "#3E54D3"],
                        borderSkipped : 'top',
                        data: [100,100,100,100,100,100],
                        borderRadius: Number.MAX_VALUE 
                    }
                ]
            },
            applyTime:{
                labels: ['1', '2', '3', '4'],
                datasets: [
                    {
                        label: "Stock B",
                        borderColor: ["#3E54D3"],
                        borderWidth: 2,
                        borderSkipped : 'top',
                        fill: false,
                        tension: 0.1,
                        data: [1,3,25,6]
                        
                    },
                    {
                        borderColor: ["#E16A6A"],
                        borderWidth: 2,
                        borderSkipped : 'top',
                        fill: false,
                        tension: 0.1,
                        data: [3,2,5,1]
                    }
                ]
            },
            containerList : [],
            containerInfo : {
                sensors : [],
            },
            containerHD : {
                value:''
            },
            containerId : null,
            sensorInfo :null ,
            alramList :[],
            eventCount : 0 ,
            eventWeeksCount : 0,
            moveStatus : false,
            weatherData : null,
            todayInfo:{ 
                lunarDate: "0000-00-00",
                solarTerm: "-",
                sunRise: "00:00:00",
                sunSet: "00:00:00" ,
            },
            intervalPlay: true,
            date : '0000-00-00',
            curTime : '오전 00:00:00'
		};
		nbind(this);
	}
    map;
    maker;
    iwRemoveable;
    infowindow;
    MapTypeControl;
    zoomControl;
    iwContent;
    infowindow;
    markers = [];

    oldMarkers = [];

    
    interval;
    timeoutl;
    nowTime;
	componentDidMount() {
        setInterval(() => {
			let text = '';
			let now = new Date()
			let nowHour = now.getHours();
			if ( nowHour <= 12 ) {
				text = '오전 '
			} else{
				text = '오후 '
			}
			let time = moment().format('hh : mm : ss')
			this.setState({
			  curTime : text + time,
              date : moment().format('yyyy-MM-DD')
			})
		}, 1000)
       
        // this.secsorTotal()
        // this.alramList();
        this.mapscript()
       
    }
    componentWillUnmount(){
        clearInterval(this.interval)
        clearTimeout(this.timeout)
       
    }
    mapscript(){
	    console.log("mapscript start!!!")
        kakao.maps.load(() => {
            this.localList()
            this.productsList()
            let container = document.getElementById("map");
            let options = {
                center: new window.kakao.maps.LatLng(37.62197524055062,
                    127.16017523675508),
                level: 4,
                draggable :true,
                scrollwheel : true
            };
            let map
            this.map = map = new window.kakao.maps.Map(container, options);
            // this.clusterer = new window.kakao.maps.MarkerClusterer({
            //     map: this.map, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
            //     averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
            //     minLevel: 10 // 클러스터 할 최소 지도 레벨 
            // });
            let markerPosition = new window.kakao.maps.LatLng(
                37.62197524055062,
                127.16017523675508
            );
          
            kakao.maps.event.addListener(map, 'dragstart', ()=> {        
                this.setState({moveStatus :true})
                clearInterval(this.interval)
                clearTimeout(this.timeout)
                if(this.state.intervalPlay){
                    this.timeout = setTimeout(() => {
                        this.intervalSet()
                        this.farmChange(0);
                        this.setState({moveStatus :false,farmId :0});
                    }, 3000);
                }
            });
           // this.mapTypeControl()
            //this.displayMarker({latitude:37.62197524055062,longitude:127.16017523675508});
            // setTimeout(() => {
            //     this.setCenter()
            // }, 2000);
            // 마커가 지도 위에 표시되도록 설정합니다
        });
    }

    // 배열에 추가된 마커들을 지도에 표시하거나 삭제하는 함수입니다
    setMarkers(map) {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(map);
        }
        if(map == null){
            this.markers = []
        }
    }

    setOldMarkers(map) {
        if(map == null){
            this.oldMarkers = []
        }else {
            for (var i = 0; i < this.oldMarkers.length; i++) {
                this.oldMarkers[i].setMap(map);
            }
        }

    }

    displayMarker(props) {
	    console.info("displayMarker start")
        const {latitude,longitude, name } = props;
        
        let imageSrc  = require('../../img/map_pin.png'),
            imageSize = new kakao.maps.Size(31, 39),  // 마커 이미지의 크기
            imgOptions =  {
                offset: new kakao.maps.Point(11, 28) // 마커 좌표에 일치시킬 이미지 내에서의 좌표
            },
            markerImage = new kakao.maps.MarkerImage(pin, imageSize, imgOptions);

        let position = new kakao.maps.LatLng(latitude, longitude)

        let marker = new kakao.maps.Marker({
            position: position,
            image: markerImage ,
            title : name ? name : ''
        });

        
        let position2= new kakao.maps.LatLng((latitude - (0.003)), longitude)
        this.setMarkers(null);
        marker.setMap(this.map);
        this.markers.push(marker);
        this.map.setLevel(10);

        setTimeout(() => {
            this.map.panTo(position2);
        }, 0);
        // 마커에 클릭이벤트를 등록
        kakao.maps.event.addListener(marker, 'click', function() {
            // 마커를 클릭하면 장소명이 인포윈도우에 표출
            // infowindow.setContent('<div style="padding:5px;font-size:12px;">' + place.place_name + '</div>');
            // infowindow.open(map, marker);
            this.farmChange(0);
        });
    }
    makeOverListener = (map, marker, infowindow)  => {
        return function () {
            infowindow.open(map, marker);
        };
    }

    makeOutListener = (infowindow) => {
        return function () {
            infowindow.close();
        }
    }
    mapTypeControl = () => {
        this.MapTypeControl = new window.kakao.maps.MapTypeControl();
        this.map.addControl(this.MapTypeControl, window.kakao.maps.ControlPosition.TOPRIGHT);
        this.zoomControl = new window.kakao.maps.ZoomControl();
        this.map.addControl(this.zoomControl, window.kakao.maps.ControlPosition.RIGHT);
    }
    weatherToday(data){
        const {latitude,longitude} = data;
        
        let obj = {
            latitude : latitude,
            longitude : longitude
		};
		API.weatherToday(obj).then(result => {
            this.setState({weatherData  : result})
		}).catch(error => console.log('ㅇㅇ',error));
        API.todayInfo(obj).then(result => {
            this.setState({todayInfo  : result})
		}).catch(error => console.log('ㅇㅇ',error));
        
    }


    secsorTotal(){
        let obj = {
			userId: cookie.load("userId")//this.props.user.userInfo.userToken,
		};
        
		API.sensorsTotal(obj).then(result => {
            console.log('sensor',result)
           
		}).catch(error => console.log('ㅇㅇ',error));
    }
    productsList(){
        let obj = {
			userId: cookie.load("userId")//this.props.user.userInfo.userToken,
		};
        
		API.productsList(obj).then(result => {
            
            this.setState({productList: result})
		}).catch(error => console.log(error));
    }
    localList(){
        let obj = {
			userId: cookie.load("userId")//this.props.user.userInfo.userToken,
		};
        
		API.localList(obj).then(result => {
            this.setState({localList : result,treeNodeId : result[0] ? result[0].id : null})
            if(result[0]){
                this.localFarmList(result[0].id)
            }else{

            }
		}).catch(error => console.log(error));
    }

    //선택한 지역의 전체 마커를 보여준다.
    localMarkers(result){
	    console.log("localMarkers start");
        this.oldMarkers.map(function (m) {
            console.log("old marker !!");
            m.setMap(null);
        });

        var imageSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";
        let imageSize = new kakao.maps.Size(31, 39),  // 마커 이미지의 크기
            imgOptions = {
                offset: new kakao.maps.Point(11, 28) // 마커 좌표에 일치시킬 이미지 내에서의 좌표
            },
            markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imgOptions);
        let lmap = this.map;
        let oldMarkers = this.oldMarkers;
        var markers = result.map(function (i, position) {
            var marker2 = new window.kakao.maps.Marker({
                map: lmap, // 마커를 표시할 지도
                image: markerImage ,
                title: i.name+" 농장",
                clickable: true, // 마커를 클릭했을 때 지도의 클릭 이벤트가 발생하지 않도록 설정합니다
                position: new window.kakao.maps.LatLng(i.latitude, i.longitude)
            });
            oldMarkers.push(marker2);
            return marker2;
        });


    }

    localFarmList (id){
        if(id){
            let obj = {
                userId: cookie.load("userId"),//this.props.user.userInfo.userToken,
                treeNodeId : id//id
            };

            API.localFarmList(obj).then(result => {
                this.setState({farmList :result, selectFarm : result[0]? result[0] : null ,farmIndex : 0})
                this.weatherToday(result[0])
                this.localMarkers(result)
                this.displayMarker(result[0])
                this.containerList(result[0].id)
                
                if(result.length > 1){
                   this.intervalSet()
                }
                
            }).catch(error => console.log(error));
        }else{
            this.setState({farmList :[], selectFarm :  null })
        }   
        
        
    }
    intervalSet(){
        if(this.interval){
            this.setState({farmIndex : 0})
            clearInterval(this.interval)
        }
        
        this.interval = setInterval((e) => {

            let increaseIndex = this.state.farmIndex + 1
            if(increaseIndex > (this.state.farmList.length - 1)){
                increaseIndex = 0
            }
            //console.log(increaseIndex,this.state.farmList)
            this.farmChange(increaseIndex)
            this.setState({farmIndex : increaseIndex, selectFarm : this.state.farmList[increaseIndex]})
            // console.log('interval',increaseIndex)
        }, 3000);
    }
    farmChange(index){
        this.setState({farmIndex : index, selectFarm : this.state.farmList[index]})
        let target = this.state.farmList[index]
        //console.log("container id >>> "+target.id)
        this.containerList(target.id)
        this.weatherToday(target)
        this.displayMarker(target)
    }

    containerList(id){
        let obj = {
			userId: cookie.load("userId"),//this.props.user.userInfo.userToken,
            farmId : id
		};
		API.containerList(obj).then(result => {
            
            this.setState({containerList : result , containerId : id})
            this.changeContainer(result[0].id)
		}).catch(error => console.log('ㅇㅇ',error));
    }

    alramList (){
        let obj = {
			userId: cookie.load("userId"),//this.props.user.userInfo.userToken,
		};
		API.alramList(obj).then(result => {
            console.log('assdasd',result )
            if(Array.isArray(result) ){
                this.setState({alramList : result})
            }
            //
		}).catch(error => console.log('ㅇㅇ',error));
    }
    sensorInfo(id){
        let obj = {
			userId: cookie.load("userId"),//this.props.user.userInfo.userToken,
            containerId : id,
		};
		API.sensorInfo(obj).then(result => {
            this.setState({containerInfo : result[0].containers[0]})
            //console.log('containerInfo', this.state.containerInfo)
		}).catch(error => console.log('ㅇㅇ',error));
        API.containerHD(obj).then(result => {
            this.setState({containerHD : result[0].containers[0].sensors[0]})
		}).catch(error => console.log('ㅇㅇ',error));
        

    }
    eventCount(id){
        
        let obj = {
			userId: cookie.load("userId"),//this.props.user.userInfo.userToken,
            containerId : id,
            startDate : fullDate,
            endDate : fullDate
		};
		API.eventCount(obj).then(result => {
            
            this.setState({eventCount : result.length})
            this.weekCount(id)
		}).catch(error => console.log('ㅇㅇ',error));
    }
    weekCount(id){
        let now = new Date();
        
        let start = moment(now).subtract(6, 'days').format('YYYY-MM-DD');
        let end = moment(now).format('YYYY-MM-DD');
        
        let obj = {
			userId: cookie.load("userId"),//this.props.user.userInfo.userToken,
            containerId : id,
            startDate : start,
            endDate : end
		};
        API.eventCount(obj).then(result => {
            
            this.setState({eventWeeksCount : result.length})
		}).catch(error => console.log('ㅇㅇ',error));
    }
    changeContainer(id){
        this.sensorInfo(id)
        this.eventCount(id)
    }
    convertLocal(props){
        const {latitude,longitude} = props;
        let Re = 6371.00877, // 지구 반경
        
            grid = 5.0, // 격자 반경(km)
        
            slat1 = 30.0,
            slat2 = 60.0,
        
            olon = 126.0,
            olat = 38.0,
            XO = 43,
            YO = 136; // 기준점 좌표    
        let DEGRAD = Math.PI / 180.0,
            RADDEG = 180.0 / Math.PI,
            re = Re / grid;
            slat1 = slat1 * DEGRAD;
            slat2 = slat2 * DEGRAD;
            olon = olon * DEGRAD;
            olat = olat * DEGRAD;
        let sn = Math.tan(Math.PI * 0.25 + slat2 * 0.5) / Math.tan(Math.PI * 0.25 + slat1 * 0.5);
            sn = Math.log(Math.cos(slat1) / Math.cos(slat2) / Math.log(sn));
        let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
            sf = Math.pow(sf, sn) * Math.cos(slat1) / sn;
        let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
            ro = re * sf / Math.pow(ro, sn);
        let arr = {};
        let ra = Math.tan(Math.PI * 0.25 + latitude * DEGRAD * 0.5);
            ra = re * sf / Math.pow(ra, sn);
        let theta = longitude * DEGRAD - olon;
            if (theta > Math.PI) theta -= 2.0 * Math.PI;
            if (theta < -Math.PI) theta += 2.0 * Math.PI;
            theta *= sn;
            arr.nx = Math.floor(ra * Math.sin(theta) + XO + 1);
            arr.ny = Math.floor(ro - ra * Math.cos(theta) + YO);
        return arr;
    }
    playChange(data){
        console.log(data+"/"+this.state.intervalPlay)
        if(data && !this.state.intervalPlay){
            this.intervalSet()
            this.farmChange(this.state.farmIndex);
            this.setState({moveStatus :false,farmId :0});
        }else{
            clearInterval(this.interval)
            clearTimeout(this.timeout)
        }
        this.setState({intervalPlay : data})
    }
	render() {
        const options = {
            legend: {
                display: false, // label 숨기기
            },
            scales: {
                xAxes: [{
                    //display:false,
                    //stacked: true,
                    gridLines: {
                        display: false,
                    },
                }],
                yAxes: [{
                    display:false,
                    ticks: {
                        beginAtZero:true
                    },
                    scaleLabel: {
                         display: true,
                         labelString: 'Moola',
                         fontSize: 10 
                      }
                }]
                
            },
            borderRadius: 60,
            maintainAspectRatio: false // false로 설정 시 사용자 정의 크기에 따라 그래프 크기가 결정됨.
        }
        const applyTimeOptions = {
            legend: {
                display: false, // label 숨기기
            },
            scales: {
                tickWidth : 20,
                drawTicks : false,
                lineWidth:2,
                xAxes: [{
                    stacked: true,
                    gridLines: {
                        display: true,
                    },
                    ticks: {
                        beginAtZero: true,
                        display : false
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        beginAtZero: true,
                        display : true
                    },
                    type: 'linear',
                }]
                
            },
            
            maintainAspectRatio: false // false로 설정 시 사용자 정의 크기에 따라 그래프 크기가 결정됨.
        }
		return (
			<React.Fragment>
				<div className="contentInner mapBox">
                    <div className="">
                        <div className="mapCont">
                            <div id="map">
                            </div>
                            <div className="locationSelect">
                                <div className="selectBox">
                                    <select name="" id="" placeholder="지역" onChange={(e)=>this.localFarmList(e.target.value)}>
                                    <option value={''}>지역을 선택해주세요.</option>
                                        {this.state.localList.map((item,index)=>{
                                            return(
                                                <option selected={this.state.treeNodeId ==  item.id} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="selectBox">
                                    <select name="" id="" placeholder="농장" onChange={(e)=>this.farmChange(e.target.value)}>
                                        {this.state.farmList.map((item,index)=>{
                                            return(
                                                <option selected={this.state.selectFarm.id ==  item.id} value={index}>{item.name} 농장 </option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            {/* 1-1 */}
                            {!this.state.moveStatus  ? 
                             <React.Fragment>
                                 <div className="mapContBox col1">
                                <div className=" row1">
                                    <strong>작목 통계목</strong>
                                    <div>
                                        <ul>
                                            
                                            {this.state.productList.map((item,index)=>{
                                                return (
                                                    <li><strong>{item.productName}</strong> <span>{item.count? item.count : 0}</span></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                {/* 1-2
                                <div className=" row2">
                                    <strong>전체 증산 현황</strong>
                                    <div>
                                        <div className="chartBox">
                                            <Bar 
                                                data={this.state.totalApply}
                                                width={168}
                                                height={250}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                </div>
                                */}
                            </div>
                            <div className="mapContBox col2">
                                {/* 2-1 */}
                                <div className=" row1">
                                    {this.state.selectFarm ?
                                        <React.Fragment>
                                            <strong>
                                                {this.state.selectFarm.name } 농장
                                                <span>({this.state.selectFarm.name } )</span>
                                            </strong>
                                            <div>
                                                <dl>
                                                                                                  <dt>재배품목</dt>
                                                    <dd>{this.state.selectFarm.productCode}</dd>
                                                </dl>
                                                <dl>
                                                    <dt>농장 면적</dt>
                                                    <dd>{this.state.selectFarm.area ? this.state.selectFarm.area : 0}㎥</dd>
                                                    <dt>재배 시작일</dt>
                                                    <dd>{this.state.selectFarm.startdate ? this.state.selectFarm.startdate : '0000-00-00' }</dd>
                                                </dl>
                                                <dl>
                                                    <dt>농장 주소</dt>
                                                    <dd>{this.state.selectFarm.roadNameAddress}</dd>
                                                </dl>
                                            </div>
                                        </React.Fragment>     
                                        :
                                        null
                                    }
                                    
                                </div>
                                {/* 2-2 */}
                                <div className=" row2">
                                <strong className="between">
                                        <p>
                                            <span>{this.state.date}</span>
                                            <br/>
                                            {this.state.curTime}
                                        </p>
                                        <span>
                                            ( 음력 ) {this.state.todayInfo.lunarDate} <br/>
                                            ( 절기 ) {this.state.todayInfo.solarTerm}
                                        </span>
                                        
                                    </strong>
                                    <div>
                                        <div className="weatherBox">

                                            <div className="btnBox textLeft ml10 ">
                                                <dl>&nbsp;</dl>
                                                {/*  <button onClick={()=>{this.setState({weatherPop : true})}}>세부정보 더 보기</button> */ }
                                            </div>

                                            <div className="weatherSlider">
                                                <div>
                                                    <div>
                                                          {/* amMaxTemp: 19
                                                            amMinTemp: 12
                                                            amPty: "0"
                                                            amSky: "4"
                                                            pmMaxTemp: 22
                                                            pmMinTemp: 17
                                                            pmPty: "0"
                                                            pmSky: "4" */}
                                                        {
                                                            this.state.weatherData ?
                                                            <React.Fragment>
                                                                {
                                                                    Number(this.state.weatherData.amPty) == 0 ?
                                                                    <i className={['','sun','cloudSmall','cloudMany','cloud'][Number(this.state.weatherData.amSky)]}></i>
                                                                    :
                                                                    <i className={
                                                                        {
                                                                            '1':'rain', 
                                                                            '2':'rain', 
                                                                            '3':'snow', 
                                                                            '4':'rain', 
                                                                            '5':'rain', 
                                                                            '6':'rain', 
                                                                            '7':'rain', 
                                                                        }[Number(this.state.weatherData.amSky)]}>
                                                                    </i>
                                                                }
                                                            </React.Fragment>
                                                            : <i className='sun'></i>
                                                            
                                                        }
                                                        
                                                        <p>
                                                            <strong>오전</strong>
                                                            <span className="high">최고 : {this.state.weatherData ? this.state.weatherData.amMaxTemp : '-' }℃</span> <br/>
                                                            <span className="low">최저 : {this.state.weatherData ? this.state.weatherData.amMinTemp : '-' }℃</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        {
                                                            this.state.weatherData ?
                                                            <React.Fragment>
                                                                {
                                                                    Number(this.state.weatherData.pmPty) == 0 ?
                                                                    <i className={['','sun','cloudSmall','cloudMany','cloud'][Number(this.state.weatherData.pmSky)]}></i>
                                                                    :
                                                                    <i className={
                                                                        {
                                                                            '1':'rain', 
                                                                            '2':'rain', 
                                                                            '3':'snow', 
                                                                            '4':'rain', 
                                                                            '5':'rain', 
                                                                            '6':'rain', 
                                                                            '7':'rain', 
                                                                        }[Number(this.state.weatherData.pmSky)]}>
                                                                    </i>
                                                                }
                                                            </React.Fragment>
                                                            : <i className='sun'></i>
                                                        }
                                                        <p>
                                                            <strong>오후</strong>
                                                            <span className="high">최고 : {this.state.weatherData ? this.state.weatherData.pmMaxTemp : '-' }℃</span> <br/>
                                                            <span className="low">최저 : {this.state.weatherData ? this.state.weatherData.pmMinTemp : '-' }℃</span>
                                                        </p>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                {/* 2-3*/}
                                <div className="row3">
                                    
                                    <div>
                                        <div className="mb10 flex itemCenter mb10">
                                            <strong>시설별 환경정보</strong>
                                            <div className="selectBox ml10">
                                                <select name="" id="" placeholder="지역" onChange={(e)=>this.changeContainer(e.target.value)}>

                                                    {this.state.containerList.map((item,index)=>{
                                                        return(
                                                            <option selected={this.state.containerId ==  item.id} value={item.id}>{item.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                
                                        <div className="topBox">
                                            <div> 
                                                <div className="title">
                                                    <strong>오늘 알람 발생</strong>
                                                    {/* <button onClick={()=>{this.setState({alramPop : true})}}>알람이력</button> */}
                                                </div>
                                                <div className="info first">
                                                    <div>
                                                        <p>{this.state.eventCount}<span>건</span></p>
                                                    </div>
                                                    <div>
                                                        <p>금주 <span>{this.state.eventCount}</span>건</p>
                                                        <p>오늘 <span>{this.state.eventCount}</span>건</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="title">
                                                    <strong>현재 HD 정보</strong>
                                                    {/* <button onClick={()=>{this.setState({hdPop : true})}}>HD 차트</button> */}
                                                </div>
                                                <div className="info second">
                                                
                                                    <div
                                                    className={(() => {
                                                        let hd_val =  this.state.containerHD.value != null ? this.state.containerHD.value : 0
                                                        if (hd_val <= 1.5 ) {
                                                            return "yellow";
                                                        }else if (hd_val > 1.5 && hd_val <= 3.5 ) {
                                                            return "yellow";
                                                        }else if (hd_val > 3.5 && hd_val <= 4.5 ) {
                                                            return "green"
                                                        }else if (hd_val > 4.5 && hd_val <= 7.5 ) {
                                                            return "green";
                                                        }else if (hd_val > 7.5 && hd_val <= 9.5 ) {
                                                            return "red";
                                                        }else{
                                                            return "red";
                                                        }
                                                    })()}
                                                    >
                                                        <strong>
                                                        {(() => {
														let hd_val = this.state.containerHD.value != null ? this.state.containerHD.value : 0
                                                                if (hd_val <= 1.5 ) {
                                                                    return "심각 증산";
                                                                }else if (hd_val > 1.5 && hd_val <= 3.5 ) {
                                                                    return "조금적은증산";
                                                                }else if (hd_val > 3.5 && hd_val <= 4.5 ) {
                                                                    return "적은 증산"
                                                                }else if (hd_val > 4.5 && hd_val <= 7.5 ) {
                                                                    return "정적 증산";
                                                                }else if (hd_val > 7.5 && hd_val <= 9.5 ) {
                                                                    return "많은 증산";
                                                                }else{
                                                                    return "매우많은증산";
                                                                }
                                                            })()}
                                                        </strong>
                                                        <p>{this.state.containerHD.value ? this.state.containerHD.value : 0} <span>g/m3</span></p>
                                                    </div>

                                                    <div>
                                                        <p className="red">난방 <span>필요</span></p>
                                                        <p className="blue">가습 <span>필요</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Swiper  
                                            className="mt10"
                                            spaceBetween={10}
                                            slidesPerView={4}
                                            loop={false}
                                            autoplay={false}
                                            
                                        >
                                            {this.state.containerInfo.sensors.map((item, index) => {
                                                return (
                                                <SwiperSlide>
                                                    <strong>{item.name}</strong>
                                                    <p className="number">{item.value}<span className={item.classCode == 'HUM' || item.classCode == 'TEMP' || item.classCode == 'WETTEMP'?"top" :null}>{item.unit}</span></p>
                                                    <p className="guideText">정상 <span>{item.alarmLowerLimit} ~ {item.alarmUpperLimit}</span>{item.unit}</p>
                                                </SwiperSlide>
                                                )
                                            })}
                                        </Swiper>
                                        {/* <div className="bottomBox">
                                            {this.state.containerInfo.sensors.map((item, index) => {
                                                return (
                                                <div>
                                                    <strong>온도</strong>
                                                    <p className="number">19<span className="top">℃</span></p>
                                                    <p className="guideText">정상 <span>18 ~ 20</span>℃</p>
                                                </div>
                                                )
                                            })}
                                            </div> */}
                                        {/* <div className="bottomBox">
                                            <div>
                                                <strong>온도</strong>
                                                <p className="number">19<span className="top">℃</span></p>
                                                <p className="guideText">정상 <span>18 ~ 20</span>℃</p>
                                            </div>
                                            <div>
                                                <strong>습도</strong>
                                                <p className="number">19<span className="top">%</span></p>
                                                <p className="guideText">정상 <span>18 ~ 20</span>%</p>
                                            </div>
                                            <div>
                                                <strong>Co2</strong>
                                                <p className="number">1070<span className="bottom">ppm</span></p>
                                                <p className="guideText">정상 <span>18 ~ 20</span>ppm</p>
                                            </div>
                                            <div>
                                                <strong>상대습도</strong>
                                                <p className="number">19<span className="top">% </span></p>
                                                <p className="guideText">정상 <span>18 ~ 20</span>% </p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        
                             </React.Fragment>
                             :null
                            }
                            <div className="playBox">
                                <button className={this.state.intervalPlay ? 'on' : null} onClick={()=>{this.playChange(true)}}>
                                    <svg width="27" height="30" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.7392 19.8696C16.4217 19.8696 20.2175 16.0738 20.2175 11.3913C20.2175 6.70893 16.4217 2.91309 11.7392 2.91309C7.05683 2.91309 3.26099 6.70893 3.26099 11.3913C3.26099 16.0738 7.05683 19.8696 11.7392 19.8696Z" stroke="white" stroke-width="1.41304" stroke-miterlimit="10"/>
                                    <path d="M14.5653 11.3913L10.3262 8.56519V14.2174L14.5653 11.3913Z" stroke="white" stroke-width="1.41304" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    시작하기
                                </button>
                                <button className={!this.state.intervalPlay ? 'on' : null} onClick={()=>{this.playChange(false)}}>
                                    <svg width="30" height="34" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g filter="url(#filter0_d)">
                                        <path d="M13.5217 20.7392C18.2041 20.7392 22 16.9434 22 12.261C22 7.57856 18.2041 3.78271 13.5217 3.78271C8.8393 3.78271 5.04346 7.57856 5.04346 12.261C5.04346 16.9434 8.8393 20.7392 13.5217 20.7392Z" stroke="white" stroke-width="1.41304" stroke-miterlimit="10"/>
                                        <path d="M11.4021 9.43481V15.087" stroke="white" stroke-width="1.41304" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M15.6414 9.43481V15.087" stroke="white" stroke-width="1.41304" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                        <filter id="filter0_d" x="0.336914" y="3.07617" width="26.3696" height="26.3696" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                        <feOffset dy="4"/>
                                        <feGaussianBlur stdDeviation="2"/>
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                        </filter>
                                        </defs>
                                    </svg>
                                    정지
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* 기상 상세 팝업 */}
					<Modal
						isOpen={this.state.weatherPop}
                        onRequestClose={()=>{this.setState({weatherPop : false})}}
                        shouldCloseOnOverlayClick={true}
						className="modalBox modalSizeS"
						style={{ content: { minWidth: 1000 } }}
					>
                        <div className="modalWrap">
							<div className="modalContent">
                                <ul className="weatherList">
                                    <li>
                                        <strong>시각</strong>
                                        <strong>날씨</strong>
                                        <strong>기온</strong>
                                        <strong>강수량 <br/>(mm)</strong>
                                        <strong>강수확률</strong>
                                        <strong>바람<br/>(m/s)</strong>
                                        <strong>습도</strong>
                                    </li>    
                                </ul>
								<div className="scrollBox">
                                    <ul className="weatherList" style={{width:1200}}>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                            <div className="badge"><span className="badgeBox">22일(월)</span> 최저 <span className="low">-</span> / 최고 <span className="high">11℃</span></div>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                        <li>
                                            <p>14시</p>
                                            <p><i className="sun"></i></p>
                                            <p>11℃</p>
                                            <p>-</p>
                                            <p>0%</p>
                                            <p><i className="angle ang30"></i>4</p>
                                            <p>30%</p>
                                        </li>
                                    </ul>
                                </div>
							</div>
						</div>
					</Modal>
                    {/* HD chart 팝업 */}
                    <Modal
						isOpen={this.state.hdPop}
                        onRequestClose={()=>{this.setState({hdPop : false})}}
                        shouldCloseOnOverlayClick={true}
						className="modalBox modalSizeS"
						style={{ content: { minWidth: 1000 } }}
					>
                        <div className="modalWrap">
                            <div className="modalHeader"><strong>HD 그래프</strong></div>
							<div className="modalContent">
                                <div>
                                    <div className="selectBox small">
                                        <select name="" id="" placeholder="농장">
                                            <option  value={'ㅇ'}>습도</option>
                                            <option  value={'ㅇ'}>습도</option>
                                            <option  value={'ㅇ'}>습도</option>
                                        </select>
                                    </div>
                                    <div className="checkBox ml20"><input type="checkbox" name="type" id="T1"/> <label htmlFor="T1">온도</label></div>
                                    <div className="checkBox ml10"><input type="checkbox" name="type" id="T2"/> <label htmlFor="T2">습도</label></div>
                                    <div className="hdChartBox">
                                        
                                    </div>
                                </div>
							</div>
						</div>
					</Modal>
                    {/* 알람팝업 */}
                    <Modal
						isOpen={this.state.alramPop}
                        onRequestClose={()=>{this.setState({alramPop : false})}}
                        shouldCloseOnOverlayClick={true}
						className="modalBox modalSizeS"
						style={{ content: { minWidth: 600 } }}
					>
                        <div className="modalWrap">
                            <div className="modalHeader"><strong>알람 이력</strong></div>
							<div className="modalContent">
                                
							</div>
						</div>
					</Modal>
                </div>
			</React.Fragment>
		);
	}
}
export default Notice;