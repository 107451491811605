import React, { Component, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useInput } from "hooks/commonHooks";
import { API } from "service";
import api from "components/utils/api";
import SamplePriceCompo from "./material/samplePrice";
import PestInformationCard from "./material/pestInformationCard";
const Wrap = styled.div`
  width: 32%;

  text-align: center;
  /* justify-content: center; */
  background: #ffffff;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 0px;
  /* padding-bottom: 3%; */
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.span`
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 1.7rem;
  padding-bottom: 10px;
  color: #c9c9c9;
  cursor: pointer;
  margin-right: 5%;
  &:after {
    display: block;
    content: "";

    border-bottom: solid 3px #17b9b6;
    transform: scaleX(0);
    transition: all 250ms ease-in-out;
    padding-bottom: 0.3rem;
    transform-origin: 0% 50%;
  }

  &:hover {
    color: #17b9b6;
  }
  &:hover:after {
    transform: scaleX(1);
  }

  ${(props) =>
    props.cliked &&
    css`
      color: #17b9b6;
      /* border-bottom: 3px solid #17B9B6; */
      font-weight: 500;
      &:after {
        transform: scaleX(1);
      }
    `};
`;
const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 10.7rem;
  height: 41px;

  background: #17b9b6;
  border-radius: 5px;

  font-family: "Spoqa Han Sans Neo";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  color: #ffffff;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  height: 70%;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 14px 23px;
  background: #f5f5f5;
  border-radius: 5px;
`;

const Line = styled(Link)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-bottom: 12px; */
`;
const Content = styled.span`
  width: 70%;
  text-align: left;
  align-items: center;
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 2rem;
  color: #747474;

  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  /* overflow-x: hidden;
overflow-y: auto; */
  /* white-space:nowrap; */
`;

const Date = styled.div`
  flex: 1;
  text-align: right;

  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #a6a6a6;
`;

const Texts = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  width: ${(props) => props.width};
  width: 100%;
`;

function Main(props) {
  const { farmData, targetFarmIdx } = props;

  const [contents, contents_handdle, set_contents] = useInput([]);

  const [displayTab, setDisplayTab] = useState("announce");
  const [samplePriceFarmFlag, setSamplePriceFarmFlag] = useState(false);

  const [tabWidth, setTabWidth] = useState("auto");

  const wholeBntRef = useRef(null),
    sectionRef = useRef(null),
    innerTabWidthRef = useRef(0),
    tabMenuSizeRef = useRef(0);

  useEffect(() => {
    if (farmData.length < 1) return;
    const targetSample = farmData.findIndex((e) => e.placeId == 211);

    if (targetSample != undefined && targetFarmIdx == targetSample) {
      setSamplePriceFarmFlag(true);
      // setSamplePriceFarmFlag(false);
    } else {
      setSamplePriceFarmFlag(false);
    }
  }, [targetFarmIdx, farmData]);

  useEffect(() => {
    samplePriceControl();
    titleSizeCalcControl();
  }, [samplePriceFarmFlag]);

  const samplePriceControl = () => {
    if (samplePriceFarmFlag) {
      setDisplayTab("samplePrice");
    } else {
      setDisplayTab("announce");
    }
  };

  const titleSizeCalcControl = () => {
    try {
      const outerWrapperWidth = sectionRef.current.clientWidth;

      if (samplePriceFarmFlag) {
        setTabWidth(outerWrapperWidth + "px");
      } else {
        // setTabWidth('auto')
      }
    } catch (error) {
      console.error(error);
    }
  };

  const Lists = (val) => {
    // console.log(val)
    return (
      <Line
        key={displayTab === "announce" ? val.boardNoticeId : val.boardFaqId}
        to={
          displayTab === "announce"
            ? `/notice/detail/${val.boardNoticeId}`
            : `/faq/detail/${val.boardFaqId}`
        }
      >
        <Content>{val.title}</Content>
        <Date>{val.regDate.slice(0, 10)}</Date>
      </Line>
    );
  };

  useEffect(() => {
    contentControl();
  }, [displayTab]);

  const contentControl = () => {
    if (displayTab != "samplePrice" && displayTab !== "pestInformation") {
      let data = { size: 5, page: 0 };
      let api;
      switch (displayTab) {
        case "announce":
          api = API.getNotice;
          break;
        case "FAQ":
          api = API.getFaQ;
          break;
        default:
          api = API.getNotice;
      }
      api(data)
        .then((val) => {
          set_contents(val.results);
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  const tabControl = (target) => {
    try {
      setDisplayTab(target);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <Wrap>
      <Section ref={sectionRef}>
        <Texts ref={innerTabWidthRef} width={tabWidth}>
          {samplePriceFarmFlag && (
            <Title
              onClick={() => tabControl("samplePrice")}
              cliked={displayTab == "samplePrice"}
            >
              농산물 가격 정보
            </Title>
          )}
          {/*   {samplePriceFarmFlag &&
                        <Title onClick={() => tabControl('pestInformation')} cliked={displayTab == 'pestInformation'}>
                            병해충 발생 정보
                        </Title>
                    } */}
          {/* {!samplePriceFarmFlag && */}
          <>
            <Title
              onClick={() => tabControl("announce")}
              cliked={displayTab == "announce"}
            >
              공지사항
            </Title>
            <Title
              onClick={() => tabControl("FAQ")}
              cliked={displayTab == "FAQ"}
            >
              {/* Q&A */}
              FAQ
            </Title>
          </>
          {/* } */}
        </Texts>
        {displayTab != "samplePrice" && displayTab != "pestInformation" && (
          <Button
            to={displayTab == "announce" ? "/notice" : "/faq"}
            ref={wholeBntRef}
          >
            {/* announce */}
            전체보기
          </Button>
        )}
      </Section>

      {displayTab !== "samplePrice" && displayTab !== "pestInformation" ? (
        <Card>
          {contents.map((val) => {
            // return Lists(val[0],val[1])
            return Lists(val);
          })}
        </Card>
      ) : displayTab == "samplePrice" ? (
        <SamplePriceCompo />
      ) : (
        <PestInformationCard />
      )}
    </Wrap>
  );
}

export default Main;
