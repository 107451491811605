import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import * as common from "js/common";


class GatewayDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gatewayInfo :{
                "id":1,
                "memberId":1,
                "cprId":1,
                "treeNodeId":1,
                "name":"",
                "productCode":"",
                "telephoneNumber":null,
                "mobileNumber":null,
                "faxNumber":null,
                "email":null,
                "roadNameAddress":"",
                "addrDetail":"",
                "zipCode":"",
                "latitude":"",
                "longitude":"",
                "employeeNumber":null,
                "openDate":"2",
                "contractDate":"",
                "note":null,
                "delYn":"N",
                "area":100
            },
            deviceTypeList : [],
            tagTypeList : [],
            protocolList : [],

            gatewayContainerList : [],
            gatewayDeviceList : [] ,

            deviceList : {
                list : [],
                pageIndex:1,
                pageSize : 0,
                classCode : null,
                useYn : null,
                containerId : null,
                name : null,
            },
            tagList : {
                list : [],
                pageIndex:1,
                pageSize : 0,
                classCode : null,
                useYn : null,
                equipId : null, 
                name : null,
                containerId: null,
            },

            checkListDevice : [],
				checkListDeviceAll : false,
            checkListTag : [],
				checkListTagAll : false,
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
		this.onChangePage2= this.onChangePage2.bind(this);
	}
	onChangePage(index) {
		let  set = {
			...this.state.deviceList,
			pageIndex : index
		}
		this.setState({ deviceList: set }, () => {this.deviceList()});
	}
	onChangePage2(index) {
		let  set = {
			...this.state.tagList,
			pageIndex : index
		}
		this.setState({ tagList: set }, () => {this.tagList()});
	}
	componentDidMount() {
		this.dataCall()
        this.deviceList()
        this.tagList()
	}	
	dataCall () {
		let obj = {
			id : this.props.match.params.id
		};
		
		API.farmgatewayDetail(obj).then(result => {
            this.setState({gatewayInfo : result})
		}).catch(error => console.log(error));
        API.deviceTypeList(obj).then(result => {
            this.setState({deviceTypeList : result})
		}).catch(error => console.log(error));
        API.tagTypeList(obj).then(result => {
            this.setState({tagTypeList : result})
		}).catch(error => console.log(error));

        API.gatewayContainerList(obj).then(result => {
            this.setState({gatewayContainerList : result})
		}).catch(error => console.log(error));
        API.gatewayDeviceList(obj).then(result => {
            this.setState({gatewayDeviceList : result})
		}).catch(error => console.log(error));
        
        API.protocol().then(result => {
			
            this.setState({
                protocolList : result
           })
		}).catch(error => console.log(error));
	}
	deviceList(){
		let obj = {
			params :{
					farmGatewayId : this.props.match.params.id,
					size : 5,
					page : Number(this.state.deviceList.pageIndex) -1
			}
		};

		if(this.state.deviceList.classCode){
			obj.params['classCode'] = this.state.deviceList.classCode
		}
		if(this.state.deviceList.containerId){
			obj.params['containerId'] = this.state.deviceList.containerId
		}
		if(this.state.deviceList.name){
			obj.params['name'] = this.state.deviceList.name
		}
		if(this.state.deviceList.useYn){
			obj.params['useYn'] = this.state.deviceList.useYn
		}
		console.log(this.state.checkListDevice);
		this.props.setLoading(true)
		API.gatewayDevice(obj).then(result => {
			this.props.setLoading(false)
			this.setState({
					deviceList : {
						...this.state.deviceList,
						list : result.content,
						pageSize : result.totalPages,
						
					},
					checkListDevice : [],
					checkListDeviceAll :false,
			})
		}).catch(error => console.log(error));
    }
    tagList(){
        let obj = {
			params :{
                farmGatewayId : this.props.match.params.id,
                size : 5,
                page : Number(this.state.tagList.pageIndex) -1
            }
		};

		if(this.state.tagList.classCode){
			obj.params['classCode'] = this.state.tagList.classCode
		}
		if(this.state.tagList.equipId){
			obj.params['equipId'] = this.state.tagList.equipId
		}
		if(this.state.tagList.name){
			obj.params['name'] = this.state.tagList.name
		}
		if(this.state.tagList.useYn){
			obj.params['useYn'] = this.state.tagList.useYn
		}
		this.props.setLoading(true)
		API.gatewayTag(obj).then(result => {
			this.props.setLoading(false)
			this.setState({
					tagList : {
						...this.state.tagList,
						list : result.content,
						pageSize : result.totalPages,
						
					},
					checkListTag : [],
					checkListTagAll : false,
			})
		}).catch(error => console.log(error));
    }
    handleCheckboxChange = event => {
		let newArray = [...this.state.checkListDevice, event.target.value];
		if (this.state.checkListDevice.includes(event.target.value)) {
		   newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		   checkListDevice: newArray
		});
	};
	handleCheckboxChangeAll = e =>{
		
		let list = []

		if(e.target.checked ){
			for (let i in this.state.deviceList.list){
				list.push(String(this.state.deviceList.list[i].id))		
			}
		}
		console.log(list)
		this.setState({
			checkListDeviceAll : e.target.checked,
			checkListDevice : list
		})
	}
	deleteDevice() {
		if(this.state.checkListDevice.length < 1){
			alert('선택된 목록이 없습니다.')
		}
		let obj = {
			ids: this.state.checkListDevice
		};

		
		if(window.confirm('선택하신 장치를 삭제하시겠습니까?')){
			this.props.setLoading(true)
			API.deviceDelete(obj).then(result => {
				this.props.setLoading(false)
				this.deviceList()
			}).catch(error => console.log(error));
		}
		
		
	}
    handleCheckboxChangeTag = event => {
		let newArray = [...this.state.checkListTag, event.target.value];
		if (this.state.checkListTag.includes(event.target.value)) {
		  newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		  checkListTag: newArray
		});
	};
	handleCheckboxChangeTagAll = e =>{
		
		let list = []

		if(e.target.checked ){
			for (let i in this.state.tagList.list){
				list.push(String(this.state.tagList.list[i].id))		
			}
		}
		console.log(list)
		this.setState({
			checkListTagAll : e.target.checked,
			checkListTag : list
		})
	}
	deleteTag() {
		if(this.state.checkListTag.length < 1){
			alert('선택된 목록이 없습니다.')
		}
		let obj = {
			ids: this.state.checkListTag
		};
		if(window.confirm('선택하신 태그를 삭제하시겠습니까?')){
			this.props.setLoading(true)
			API.tagDelete(obj).then(result => {
				this.props.setLoading(false)
				this.tagList()
			}).catch(error => console.log(error));
		}
	}
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">장치, 태그 목록</h2>
                        <div className="">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td><input value={this.state.gatewayInfo.name} readOnly type="text" /></td>
                                            <th>농장</th>
                                            <td><input value={this.state.gatewayInfo.farmName} readOnly type="text"/></td>
                                            <th>IP</th>
                                            <td><input value={this.state.gatewayInfo.ip} readOnly type="text"/></td>
                                            <th>PORT</th>
                                            <td><input value={this.state.gatewayInfo.port} readOnly type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>종류</th>
                                            <td>
                                                <div className="selectBox">
                                                <select name="" id="" disabled value={this.state.gatewayInfo.protocolId}>
                                                    <option value="">선택</option>
                                                    {this.state.protocolList.map((item,index)=>{
                                                        return(
                                                            <option value={item.id}>{item.name}</option>
                                                        )
                                                    })}
                                                    
                                                </select>
                                                </div>
                                            </td>
                                            <th>설치 날짜</th>
                                            <td><input value={common.toLocalDate(this.state.gatewayInfo.installDate)} readOnly type="text"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="whBox mt20">
                                <div className="searchBox">
                                    <div className="selectBox mr10">
													<select name="" id="" 
													 onChange={(e) => {
														// classCode : null,
														// useYn : null,
														// containerId : null,
														// name : null,
														let data = this.state.deviceList; 
														data.classCode = e.target.value///////////
														this.setState({ params: data })
													}}>
                                            <option value="">장치종류</option>
                                            {this.state.deviceTypeList.map((item,index)=>{
                                                return(
                                                    <option value={item.code}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="selectBox mr10">
                                        <select name="" id="" onChange={(e) => {
														// classCode : null,
														// useYn : null,
														// containerId : null,
														// name : null,
														let data = this.state.deviceList; 
														data.containerId = e.target.value///////////
														this.setState({ params: data })
													}}>
                                            <option value="">시설동</option>
                                            {this.state.gatewayContainerList.map((item,index)=>{
                                                return(
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="selectBox mr10">
                                        <select name="" id="" onChange={(e) => {
														// classCode : null,
														// useYn : null,
														// containerId : null,
														// name : null,
														let data = this.state.deviceList; 
														data.useYn = e.target.value///////////
														this.setState({ params: data })
													}}>
                                            <option value="">사용여부</option>
                                            <option value="Y">사용</option>
                                            <option value="N">미사용</option>
                                        </select>
                                    </div>
                                    <div className="selectBox">
                                        <select name="" id="">
                                            <option value="">이름</option>
                                        </select>
                                    </div>
                                    <div className="inputBox ml10">
                                        <input type="text" 
													 onKeyPress={event => {
														if (event.key === 'Enter') {
														  this.onChangePage(1)
														}
													 }}
													 onChange={(e) => {
														let data = this.state.deviceList; 
														data.name = e.target.value///////////
														this.setState({ params: data })
													}}></input>
                                        <button onClick={()=>{this.onChangePage(1)}} type="button">검색</button>
                                    </div>
                                </div>
                            </div>
                            <div className="tableBox mt30">
                                <table>
											<colgroup>
												<col width="50"/>
												<col width="50"/>
												<col width="300"/>
												<col width="150"/>
												<col width="250"/>
												<col width="150"/>
												
											</colgroup>
											<thead>
												<tr>
													<th><div className="checkBox"><input type="checkbox" name="type" id="all" onChange={this.handleCheckboxChangeAll} checked={this.state.checkListDeviceAll}/> <label htmlFor="all"></label></div></th>
													<th>번호</th>
													<th>이름</th>
													<th>장치종류</th>
													<th>시설동</th>
													<th>사용여부</th>
												</tr>
													
											</thead>
											<tbody>
											{/* <tr >
												<td className="textCenter">
														<div className="checkBox"><input type="checkbox" name="type" id="T4"/> <label htmlFor="T4"></label></div>
													</td>
													<td className="textCenter">1</td>
													<td className="textCenter"><a href="javascript:void(0);" onClick={() => { this.props.history.push({ pathname: `/gateway/devicetag/${3}/deviceDetail/${3}` }) }}>에어컨1</a> </td>
													<td className="textCenter">에어컨</td>
													<td className="textCenter">서우동</td>
													<td className="textCenter">사용</td>
												</tr> */}
											{	this.state.deviceList.list.length === 0 ?
											<tr style={{ cursor: 'default' }}>
												<td colSpan='100' className="textCenter">등록된 장치가 없습니다.</td>
											</tr>
											:
											this.state.deviceList.list.map((item, index) => {
												return (
													// classCode: "1"
													// classValue: 1
													// containerId: 1
													// containerName: "테스트시설동#1"
													// delYn: "N"
													// farmGatewayId: 1
													// farmGatewayName: "farm gateway#1"
													// id: 1
													// name: "장치#1"
													// powerOnYn: "Y"
													// useYn: "Y"
													<tr key={index} >
														<td className="textCenter">
															<div className="checkBox"><input type="checkbox" name="type" id={'d'+item.id} value={item.id} onChange={this.handleCheckboxChange} checked={this.state.checkListDevice.indexOf(String(item.id)) > -1} /> <label htmlFor={'d'+item.id}></label></div>
														</td>
														<td className="bold textCenter">{((this.state.deviceList.pageIndex - 1) * 5 ) +(index +1)}</td>
														<td className="textCenter"><a href="javascript:void(0);" onClick={() => { this.props.history.push({ pathname: `/gateway/devicetag/${this.state.gatewayInfo.id}/deviceDetail/${item.id}` }) }}>{item.name}</a> </td>
														<td className="textCenter">
															<span className="selectBox textRender">
																	<select value={item.classCode} disabled >
																		<option value="">장치종류</option>
																		{this.state.deviceTypeList.map((item,index)=>{
																			return(
																					<option value={item.code}>{item.name}</option>
																			)
																		})}
																	</select>
															</span>
														</td>
														<td className="textCenter">{item.containerName}</td>
														<td className="textCenter">{item.useYn}</td>
													</tr>
												)
											})
										}
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to={`/gateway/devicetag/${this.props.match.params.id}/deviceRegister`} className="btn mr10 mt10">등록</Link>
											<button onClick={() =>{ this.deleteDevice()}} className="btn mr10 mt10">삭제</button>
                                </div>
                                <Pager pageIndex={this.state.deviceList.pageIndex} pageSize={this.state.deviceList.pageSize} onChangePage={this.onChangePage} />
                            </div>



                            <div className="whBox mt20">
                                <div className="searchBox">
                                    <div className="selectBox mr10">
                                        <select name="" id="" onChange={(e) => {
														// classCode : null,
														// useYn : null,
														// containerId : null,
														// name : null,
														//equipId 
															let data = this.state.tagList; 
															data.classCode = e.target.value///////////
															this.setState({ params: data })
														}}>
                                            <option value="">통계 카테고리</option>
                                            {this.state.tagTypeList.map((item,index)=>{
                                                return(
                                                    <option value={item.code}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="selectBox mr10">
                                        <select name="" id="" onChange={(e) => {
														// classCode : null,
														// useYn : null,
														// containerId : null,
														// name : null,
														//equipId 
															let data = this.state.tagList; 
															data.equipId = e.target.value///////////
															this.setState({ params: data })
														}}>
                                            <option value="">장치종류</option>
                                            {this.state.gatewayDeviceList.map((item,index)=>{
                                                return(
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="selectBox mr10">
                                        <select name="" id="" onChange={(e) => {
														// classCode : null,
														// useYn : null,
														// containerId : null,
														// name : null,
														//equipId 
															let data = this.state.tagList; 
															data.useYn = e.target.value///////////
															this.setState({ params: data })
														}}>
                                            <option value="">사용여부</option>
                                            <option value="Y">Y</option>
                                            <option value="N">N</option>
                                        </select>
                                    </div>
                                    <div className="selectBox">
                                        <select name="" id="">
                                            <option value="">이름</option>
                                        </select>
                                    </div>
                                    <div className="inputBox ml10">
													<input type="text" 
													 onKeyPress={event => {
														if (event.key === 'Enter') {
														  this.onChangePage2(1)
														}
													 }}
													 onChange={(e) => {
														let data = this.state.tagList; 
														data.name = e.target.value///////////
														this.setState({ params: data })
													}}></input>
                                        <button onClick={()=>{this.onChangePage2(1)}} type="button">검색</button>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="tableBox mt30">
                                <table>
												<colgroup>
													<col width="50"/>
													<col width="50"/>
													<col width="300"/>
													<col width="150"/>
													<col width="250"/>
													<col width="150"/>
													<col width="150"/>
												</colgroup>
                                    <thead>
                                        <tr>
                                            <th><div className="checkBox"><input type="checkbox" name="type" id="all2" onChange={this.handleCheckboxChangeTagAll} checked={this.state.checkListTagAll}/> <label htmlFor="all2"></label></div></th>
                                            <th>번호</th>
                                            <th>태그이름</th>
                                            <th>통계 카테고리</th>
                                            <th>소속 장치</th>
                                            <th>사용여부</th>
                                            <th>정상여부</th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
										
													{	this.state.deviceList.list.length === 0 ?
													<tr style={{ cursor: 'default' }}>
														<td colSpan='100' className="textCenter">등록된 장치가 없습니다.</td>
													</tr>
													:
													this.state.tagList.list.map((item, index) => {
														return (
																			// alarmSendYn: "Y"
																			// classCode: "DRYTEMP"
																			// delYn: "N"
																			// diaryDisplayYn: "Y"
																			// displayYn: "Y"
																			// equipName: "장치#2"
																			// id: 98
																			// monitoringYn: "Y"
																			// name: "건구"
																			// normalYn: "Y"
																			// protocolMap: null
																			// saveTerm: 60
																			// saveYn: "Y"
																			// seq: 1
																			// status: null
																			// unit: "℃"
																			// useYn: "Y"
																			// value: 49
															<tr key={index} >
																<td className="textCenter">
																	<div className="checkBox"><input type="checkbox" name="type" id={'t'+item.id} value={item.id} onChange={this.handleCheckboxChangeTag} checked={this.state.checkListTag.indexOf(String(item.id)) > -1}/> <label htmlFor={'t'+item.id}></label></div>
																</td>
																<td className="bold textCenter">{((this.state.tagList.pageIndex - 1) * 5 ) +(index +1)}</td>
																<td className="textCenter"><a href="javascript:void(0);" onClick={() => { this.props.history.push({ pathname: `/gateway/devicetag/${this.state.gatewayInfo.id}/tagDetail/${item.id}` }) }}>{item.name}</a> </td>
																<td className="textCenter">
																	<span className="selectBox textRender">
																			<select value={item.classCode} disabled >
																				<option value="">통계카테고리 미매칭</option>
																				{this.state.tagTypeList.map((item,index)=>{
																					return(
																							<option value={item.code}>{item.name}</option>
																					)
																				})}
																			</select>
																	</span>
																</td>
																<td className="textCenter">{item.equipName}</td>
																<td className="textCenter">{item.useYn}</td>
																<td className="textCenter">{item.normalYn}</td>
															</tr>
														)
													})
													}
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to={`/gateway/devicetag/${this.props.match.params.id}/tagRegister`} className="btn mr10 mt10">등록</Link>
									
                                    <button onClick={() =>{ this.deleteTag()}} className="btn mr10 mt10">삭제</button>
                                </div>
                                <Pager pageIndex={this.state.tagList.pageIndex} pageSize={this.state.tagList.pageSize} onChangePage={this.onChangePage2} />
                            </div>
                            <div className="textCenter mt30">
                                <button  className="btn mr5 mt10">수정</button>
                                <Link to="/gateway" className="btn gray ml30">취소</Link>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default GatewayDetail;