
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Switch, withRouter } from 'react-router-dom';
import Layout1 from "./layout/Layout1";
import Layout2 from "./layout/Layout2";
import Layout3 from "./layout/Layout3";
import Layout4 from "./layout/Layout4";
import PopUp from "./layout/popUp";
import cookie from 'react-cookies'
import Modal from 'react-modal';
import { routes, routes2,routes3 ,routes4,route_popUp} from '../router';
import _ from 'lodash'
import * as actions from 'reducers/actions';
import { IntlProvider } from "react-intl";
import ko from "lang/ko";
import en from "lang/en";
import { nbind } from '../js/common';
import Alert from './layout/include/Alert';
import jwt_decode from "jwt-decode";
 

const messages = {
	'ko': ko,
	'en': en
};

class App extends Component {
	constructor(props) {
		super(props);
		Modal.setAppElement('#root');
		nbind(this);
		this.is_ie = true;

		
	}
	state = {
		location: this.props.location,
	}
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	componentDidMount() {

		

		this.props.handleLang('ko');
		if(cookie.load('userToken') == undefined) return this.props.history.push('/login');
		let cookieData = this.getCookieData();
		// 쿠키에 설정된 없어 없으면 기본값 한국어로 저장
		// if (cookieData.lang === '' || cookieData.lang === undefined || cookieData.lang === null
		// ) {
		// 	cookie.save('lang', 'ko', { path: '/' })
		// } else {
		// 	this.props.handleLang(cookie.load('lang'));
		// }
		// 쿠키 불러오기
		cookieData = {
			token: cookie.load('userToken'),
		}
		cookieData['id'] = _.get(cookieData, 'id', '');
		cookieData['token'] = _.get(cookieData, 'token', '')
		if(cookieData['token']){
			var decoded = jwt_decode(cookieData.token);
		}
		
		// let userInfo = {
		// 	userId: cookieData.id,
		// 	userToken: cookieData.token
		// };
		this.props.handleUserInfo(decoded);
		

		let ua = navigator.userAgent;
		/* MSIE used to detect old browsers and Trident used to newer ones*/
		// this.is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
		// if(this.is_ie){
		// 	this.props.history.push('/')
		// }
		//console.log(this.is_ie);
		const { history } = this.props;
		history.listen((newLocation, action) => {
			this.currentPathname = newLocation.pathname ;
			if(action!=='PUSH'){
				if( this.currentPathname === null||this.currentPathname === '/login'){
					history.go(0)
					this.currentPathname = null
					
				}
			}
			return false;
		});
	}
	getCookieData() {

		let cookieData = {
			id: cookie.load('userId'),
			token: cookie.load('userToken'),
			lang: cookie.load('lang'),
		}
		cookieData.id = _.get(cookieData, 'id', '');
		cookieData.token = _.get(cookieData, 'token', '');
		cookieData.lang = _.get(cookieData, 'lang', '');
		return cookieData
	}
	
  	render() {
		return (
			<React.Fragment>
				{/* {this.is_ie ?
					<Modal
						isOpen={true}
						className="popupBox black iePoroutespup"
					>
						<p>구글</p>
					</Modal>
					: null
				} */}
				{ this.props.common.lang ?
					<IntlProvider locale={this.props.common.lang} messages={messages[this.props.common.lang]} >
						{
							(!_.isNil(this.props.user)) &&
							<Switch>
								
								{
								routes.map((route, index) => (
									
								 <Layout1 path={route.path} component={route.components} key={'layou1_'+index} exact={true}/>
								))
								}

								

								{
									route_popUp.map((route, index) => (
								

										<PopUp path={route.path} component={route.components} key={'popUp' + index} exact={true}/>
									))
								}

								{
								routes2.map((route, index) => (
								
									<Layout2 path={route.path} component={route.components} key={'layou2_' + index} exact={true}/>
								))
								}
								{
								routes3.map((route, index) => (
								

									<Layout3 path={route.path} component={route.components} key={'layou2_' + index} exact={true}/>
								))
								}
								
								
							</Switch>
						}
						<Alert />
					</IntlProvider>
					:
					null
				}
				
			</React.Fragment>
    );
  }
}



export default withRouter(connect(
state => ({
	user: state.user,
	common: state.common,
}),
dispatch => ({
	// user
	handleUserInfo: (data) => {
		dispatch(actions.SET_USER_INFO(data))
	},
	// common
	handleLang: (data) => {
		dispatch(actions.SET_LANG(data))
	},
})
)(App));
