import React, { Component } from 'react';
import { injectIntl } from 'react-intl'
import { nbind } from 'js/common'
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import _ from 'lodash'
import cookie from 'react-cookies'
import * as common from "js/common";
import * as actions from 'reducers/actions';
import { API } from 'service.js';
import Modal from 'react-modal';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: '',
			isLogin: '',
			confirmLogout: false,
			isNewMsg: false,
			isHovering: false,
			role: 'ROLE_FARMER',
		}

		nbind(this);
		this.currentPathname = null;
		this.currentSearch = null;
		this.handleMouseHover = this.handleMouseHover.bind(this);
		this.handleMouseHover2 = this.handleMouseHover2.bind(this);
	}
	handleMouseHover() {
		this.setState(this.toggleHoverState);
		if (this.state.isHovering) {

		}
	}
	handleMouseHover2() {
		this.setState({ isHovering: false })
	}
	toggleHoverState(state) {
		return {
			isHovering: !state.isHovering,
		};
	}
	cssInit() {
		let dir = this.props.match.path.split('/')[1]
		// console.log(dir)
		let target = document.getElementsByClassName(dir)[0]

		if (target != undefined) {
			target.style.color = "#17B9B6"
			target.style.fontWeight = 500
			target.parentElement.style.borderBottom = "5px solid #17B9B6"
		}
	}

	componentDidMount() {

		if (this.props.user.realm_access) {
			let t = this.props.user.realm_access.roles
			let role = ''
			for (var i in t) {
				if (t[i].indexOf('ROLE_CPR') > -1) {
					role = 'ROLE_CPR'
				} else if (t[i].indexOf('ROLE_FARMER') > -1) {
					role = 'ROLE_FARMER'

				}
			}
			this.setState({ role: role })
			this.ahthCheck(role)
		}
		// console.log('tt', this.props)

		this.cssInit()


	}
	componentDidUpdate(prevProps) {
		// 전형적인 사용 사례 (props 비교를 잊지 마세요)

		if (this.props.user !== prevProps.user) {
			if (this.props.user.realm_access.roles) {
				this.setState({ role: this.props.user.realm_access.roles[0] })
				this.ahthCheck(this.props.user.realm_access.roles[0])
			}
		}
	}

	getCookieData() {
		let cookieData = {
			token: cookie.load('userToken'),
			id: cookie.load('userId'),
			lang: cookie.load('lang'),
		};
		// cookieData.token = _.get(cookieData, 'lang', '');
		return cookieData
	}
	// 로그인 필수 페이지 로그인 체크(안했을 경우, 로그인 페이지로 이동) + 새로운 메시지 수신 체크
	ahthCheck(auth) {
		let authCheck = [
			'/company',
			'/protocol',
			'/cctv',
			'/menu',
			'/farm',
			'/gateway',
			'/noticeregister',
			'/faqregister',
			'/faq/:id/edit',
			'/notice/:id/edit'
		]
		if (auth == 'ROLE_FARMER') {
			for (var i in authCheck) {
				if (this.props.match.path.indexOf(authCheck[i]) > -1) {
					this.props.handleAlert('dddd')
					this.props.history.push('/mainUser')
				}
			}
		} else {

		}

	}
	goLoginPageNotLogin(path) {
		// 로그아웃일 때 
		let cookieData = {
			token: cookie.load('userToken'),
			id: cookie.load('userId'),
			lang: cookie.load('lang'),
		};
		let loginPage = [

		];
		if (!cookieData.token || cookieData.token == "") {
			alert('로그인 후 이용이 가능한 서비스입니다.');
			this.props.history.push('/login')
			return false;
		} else {
			// 로그인 상태일 때만 토큰체크
			if (cookieData.token !== '') {
				let obj = {
					userToken: cookieData.token,
				};

				// API.userInfo(obj).then(result => {
				// 	let code = result.responseCode === "SUCCESS";
				// 	let isLogin = this.state.isLogin;
				// 	let isNewMsg = this.state.isNewMsg;

				// 	if (code ) {
				// 		isLogin = true;

				// 		this.setState({
				// 			isLogin, isNewMsg
				// 		});
				// 		this.props.handleUserInfo({userData:result.data,userToken: cookieData.token});
				// 	}else{
				// 		loginPage.some((item, index) => {
				// 			if (this.props.location.pathname.indexOf(item) > 0) {
				// 				this.alert('로그인 후 이용이 가능한 서비스입니다.', `msg.youNeedLogin`);
				// 				this.actionLogout('/login');
				// 				this.props.history.push('')
				// 				return false
				// 			}
				// 		})
				// 		isLogin = false;
				// 		this.setState({ isLogin });
				// 	}
				// }).catch(error => console.log(error));
			}
		}

	}
	// 메뉴 클릭시 이동 전, 로그인 체크
	checkLogin = (e) => {
		e.preventDefault();
		let cookieData = this.getCookieData();
		let isLogout = !common.isValid(cookieData.token);
		if (isLogout) {
			this.alert('로그인 후 이용이 가능한 서비스입니다.', `msg.youNeedLogin`)
			setTimeout(() => {
				this.props.history.push('/login');
			}, 0);
			return false;
		} else {
			let href = '';
			if (e.target.tagName === 'P') {
				href = e.target.parentElement.getAttribute('href');
			} else if (e.target.tagName === 'A') {
				href = e.target.getAttribute('href');
			}
			this.props.history.push(href);
		}
		return false;
	};
	// 실제 프론트에서의 로그아웃 처리
	actionLogout(path) {
		cookie.remove('userId', { path: '/' });
		cookie.remove('userToken', { path: '/' });
		this.props.handleUserInfo({ userId: '', userToken: '' });
		this.resetConfirm();
		this.setState({ isLogin: false, menuOpen: false });
		this.props.history.push('/');
	}
	openMenu(el) {
		el.classList.toggle('open');
	}
	tryLogout() {
		let obj = {
			userToken: this.props.user.userInfo.userToken,
		}
		// API.logout(obj).then(result => {
		// 	let code = result.status;
		// 	if (result.result) {
		// 		if ( code === 100 ) {
		// 			this.alert('로그아웃 되었습니다.', 'msg.logoutSuccess');
		// 			this.actionLogout('/');
		// 		}
		// 	} else {
		// 		switch ( code ) {
		// 			case -2000 :
		// 			case -2100:
		// 			case -2200: {
		// 				this.alert('로그인 상태가 아닙니다.', 'msg.notLogin');
		// 				this.actionLogout('/');
		// 				break;
		// 			}
		// 			default: this.alert('로그아웃이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.', `msg.logoutFail`); 
		// 		}
		// 	}
		// }).catch(error => console.log(error));
	}
	changeLang(lang) {
		this.props.handleLang(lang);
		cookie.save('lang', lang, { path: '/' });
		this.setState({ lang });
	}
	resetConfirm() {
		this.setState({ confirmLogout: false })
	}


	render() {

		return (
			<React.Fragment>
				<header id='header'>
					<div className='headerInner'>
						<h1>
							<Link to="/mainUser">
								FARM IN
							</Link>
						</h1>
						<div className={"menuBox " + (this.state.isHovering ? 'on' : '')}
							onMouseEnter={this.handleMouseHover}
							onMouseLeave={this.handleMouseHover2}
						>
							<ul>
								<li>
									<Link to="/mainUser" href="javascript:void(0);" className={'mainUser menu'}>
										메인
									</Link>
								</li>
								<li>
									<Link to='/lookUp' href="javascript:void(0);" className={'lookUp menu'}>
										데이터 조회
									</Link>
								</li>
								{this.state.role != 'ROLE_FARMER' ?
									<li>
										<Link to="/" href="javascript:void(0);" className={'menu2 menu'}>
											관제화면
										</Link>
									</li>
									: null
								}
								{/* {this.state.role != 'ROLE_FARMER' ? */}
								<li>
									<Link to='/notification' href="javascript:void(0);" className={'notification menu'}>
										농장관리
									</Link>
								</li>
								{/* : null
								} */}


								{/* <li>
									<Link href="javascript:void(0);" className={'menu5 menu'}>
										통계
									</Link>
								</li>
								<li>
									<Link href="javascript:void(0);" className={'menu6 menu'}>
										영농일지
									</Link>
								</li> */}
								<li>
									<Link to='/notice' href="javascript:void(0);" className={'notice menu faq'}>
										게시판
									</Link>
								</li>
								{this.state.role != 'ROLE_FARMER' ?
									<li>
										<Link href="javascript:void(0);" className={'menu6 menu'}>
											시스템관리
										</Link>
									</li>
									: null
								}

							</ul>
							<div className="subMenuBox">
								<ul>
									<li>
										<ul>
											<li>
												{/* {
													cookie.load('role') === "corporation" ?
														<Link to="/" href="javascript:void(0);" className={'menu6 '}>
															운영사 메인화면
														</Link> : null
												}
												{
													cookie.load('role') === "farmer" ?
														<Link to="/mainUser" href="javascript:void(0);" className={'menu6 '}>
															농장주 메인화면
														</Link> : null
												} */}

											</li>
										</ul>
									</li>
									{this.state.role != 'ROLE_FARMER' ?
										<li>
											<ul>

												<li>
													{/* <Link to="/control" href="javascript:void(0);" className={'menu6 '}>
														관제화면
													</Link> */}
												</li>

											</ul>
										</li>
										: null
									}
									{this.state.role != 'ROLE_FARMER' ?
										<li>
											<ul>
												{/* <li>
													<Link to="/gateway" href="javascript:void(0);" className={'menu6 '}>
														농장게이트웨이
													</Link>
												</li>
												<li>
													<Link to="/farm" href="javascript:void(0);" className={'menu6 '}>
														농장관리
													</Link>
												</li> */}
												{/*
												<li>
													<Link  href="javascript:void(0);"  className={'menu6 ' }>
														농장접근 권한 관리
													</Link>
												</li>
												*/}
												{/* <li>
													<Link to="/company/member" href="javascript:void(0);" className={'menu6 '}>
														농장 사용자 관리
													</Link>
												</li> */}
											</ul>
										</li>
										: null
									}

									<li>
										<ul>
											{/* <li>
												<Link to="/history/control" href="javascript:void(0);" className={'menu6 '}>
													농장 제어 이력
												</Link>
											</li>
											<li>
												<Link to="/history/event" href="javascript:void(0);" className={'menu6 '}>
													센서 에러 내역
												</Link>
											</li>
											<li>
												<Link to="/history/user" href="javascript:void(0);" className={'menu6 '}>
													로그인 내역
												</Link>
											</li> */}
										</ul>
									</li>
									<li>
										<ul>
											{/* <li>
												<Link to="/data" href="javascript:void(0);" className={'menu6 '}>
													메인 데이터 분석
												</Link>
											</li>
											<li>
												<Link to="/data2" href="javascript:void(0);" className={'menu6 '}>
													HD 데이터 분석
												</Link>
											</li>
											<li>
												<Link to="/data3" href="javascript:void(0);" className={'menu6 '}>
													농장 시설동별 데이터 비교
												</Link>
											</li> */}
										</ul>
									</li>
									<li>
										<ul>
											<li>
												<Link to='/notification' href="javascript:void(0);" className={'menu6 '}>
													센서알림 설정
												</Link>
											</li>

										</ul>
									</li>
									<li>
										<ul>
											<li>
												<Link to="/notice" href="javascript:void(0);" className={'menu6 '}>
													공지사항
												</Link>
											</li>
											<li>
												<Link to="/faq" href="javascript:void(0);" className={'menu6 '}>
													FAQ
												</Link>
											</li>

										</ul>
									</li>

									{/* {this.state.role !='ROLE_FARMER' ? 
										<li>
											<ul>
												<li>
													<Link to="/company" href="javascript:void(0);"  className={'menu6 ' }>
														서비스 운영사 관리
													</Link>
												</li>
												<li>
													<Link to="/protocol" href="javascript:void(0);"  className={'menu6 ' }>
														통신 프로토콜 관리
													</Link>
												</li>
												<li>
													<Link to="/user" href="javascript:void(0);"  className={'menu6 ' }>
														사용자 관리
													</Link>
												</li>
												<li>
													<Link to="/cctv" href="javascript:void(0);"  className={'menu6 ' }>
														CCTV 관리
													</Link>
												</li>
												<li>
													<Link to="/menu" href="javascript:void(0);"  className={'menu6 ' }>
														메뉴 접근권한 관리
													</Link>
												</li>
												<li>
													<Link to="/grade" href="javascript:void(0);"  className={'menu6 ' }>
														등급 권한 관리
													</Link>
												</li>
												<li>
													<Link  to="/auth" href="javascript:void(0);"  className={'menu6 ' }>
														권한 그룹 관리
													</Link>
												</li>
												<li>
													<Link to="/code" href="javascript:void(0);"  className={'menu6 ' }>
														코드 관리
													</Link>
												</li>


											</ul>
										</li>
									: null
									} */}

								</ul>
							</div>
						</div>

						<div className="util">
							<span> {cookie.load('userId')} 님</span>
							<Link to="/mypage/profile">
								{/* 마이페이지 */}
								<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.2" d="M12 2.875C10.2553 2.87502 8.5516 3.40416 7.11386 4.39256C5.67612 5.38096 4.57194 6.78216 3.9471 8.41114C3.32226 10.0401 3.20614 11.8203 3.61406 13.5167C4.02199 15.213 4.93478 16.7458 6.23193 17.9126V17.9125C6.77309 16.8478 7.59844 15.9538 8.61653 15.3294C9.63462 14.705 10.8057 14.3747 12 14.375C11.2892 14.375 10.5944 14.1642 10.0034 13.7693C9.41243 13.3745 8.95181 12.8132 8.67981 12.1565C8.40781 11.4998 8.33664 10.7773 8.4753 10.0801C8.61397 9.38303 8.95624 8.74268 9.45884 8.24009C9.96143 7.73749 10.6018 7.39522 11.2989 7.25655C11.996 7.11789 12.7186 7.18905 13.3753 7.46106C14.0319 7.73306 14.5932 8.19368 14.9881 8.78467C15.383 9.37566 15.5938 10.0705 15.5938 10.7812C15.5937 11.7344 15.2151 12.6485 14.5412 13.3224C13.8672 13.9964 12.9531 14.375 12 14.375C13.1943 14.3747 14.3654 14.705 15.3835 15.3294C16.4016 15.9538 17.2269 16.8478 17.7681 17.9125C19.0652 16.7457 19.978 15.213 20.3859 13.5166C20.7939 11.8203 20.6777 10.0401 20.0529 8.41109C19.4281 6.78211 18.3239 5.38092 16.8861 4.39253C15.4484 3.40413 13.7447 2.875 12 2.875Z" fill="#4F80E2" />
									<path d="M12 20.125C16.7635 20.125 20.625 16.2635 20.625 11.5C20.625 6.73654 16.7635 2.875 12 2.875C7.23654 2.875 3.375 6.73654 3.375 11.5C3.375 16.2635 7.23654 20.125 12 20.125Z" stroke="#4F80E2" strokeMiterlimit="10" />
									<path d="M12 14.375C13.9848 14.375 15.5938 12.766 15.5938 10.7812C15.5938 8.79648 13.9848 7.1875 12 7.1875C10.0152 7.1875 8.40625 8.79648 8.40625 10.7812C8.40625 12.766 10.0152 14.375 12 14.375Z" stroke="#4F80E2" strokeMiterlimit="10" />
									<path d="M6.23145 17.9125C6.77285 16.8481 7.59826 15.9542 8.61628 15.3298C9.63431 14.7055 10.8053 14.375 11.9995 14.375C13.1937 14.375 14.3647 14.7055 15.3827 15.3298C16.4007 15.9542 17.2261 16.848 17.7675 17.9125" stroke="#4F80E2" strokeLinecap="round" strokeLinejoin="round" />
								</svg>


							</Link>
							<Link to="/mainUser">
								{/* 홈 */}
								<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path opacity="0.2" d="M14.1557 18.6876V14.375C14.1557 14.1844 14.08 14.0016 13.9452 13.8668C13.8104 13.732 13.6276 13.6563 13.437 13.6563H10.562C10.3713 13.6563 10.1885 13.732 10.0537 13.8668C9.91895 14.0016 9.84322 14.1844 9.84322 14.375V18.6876C9.84322 18.8782 9.76751 19.061 9.63274 19.1958C9.49797 19.3306 9.31517 19.4063 9.12456 19.4064L4.81259 19.4069C4.71819 19.407 4.62472 19.3884 4.53751 19.3523C4.4503 19.3161 4.37105 19.2632 4.3043 19.1965C4.23755 19.1297 4.1846 19.0505 4.14847 18.9633C4.11234 18.8761 4.09375 18.7826 4.09375 18.6882V10.3812C4.09375 10.2811 4.11467 10.182 4.15518 10.0905C4.19569 9.99888 4.25488 9.91678 4.32897 9.84941L11.516 3.31465C11.6483 3.19435 11.8207 3.12769 11.9995 3.12769C12.1783 3.12768 12.3507 3.19433 12.483 3.31461L19.671 9.84941C19.7451 9.91677 19.8043 9.99888 19.8448 10.0905C19.8853 10.182 19.9062 10.2811 19.9062 10.3812V18.6882C19.9062 18.7826 19.8877 18.8761 19.8515 18.9633C19.8154 19.0505 19.7625 19.1297 19.6957 19.1965C19.6289 19.2632 19.5497 19.3161 19.4625 19.3523C19.3753 19.3884 19.2818 19.407 19.1874 19.4069L14.8744 19.4064C14.6838 19.4064 14.501 19.3306 14.3662 19.1958C14.2314 19.061 14.1557 18.8782 14.1557 18.6876Z" fill="#656565" />
									<path d="M14.1557 18.6876V14.375C14.1557 14.1844 14.08 14.0016 13.9452 13.8668C13.8104 13.732 13.6276 13.6563 13.437 13.6563H10.562C10.3713 13.6563 10.1885 13.732 10.0537 13.8668C9.91895 14.0016 9.84322 14.1844 9.84322 14.375V18.6876C9.84322 18.8782 9.76751 19.061 9.63274 19.1958C9.49797 19.3306 9.31517 19.4063 9.12456 19.4064L4.81259 19.4069C4.71819 19.407 4.62472 19.3884 4.53751 19.3523C4.4503 19.3161 4.37105 19.2632 4.3043 19.1965C4.23755 19.1297 4.1846 19.0505 4.14847 18.9633C4.11234 18.8761 4.09375 18.7826 4.09375 18.6882V10.3812C4.09375 10.2811 4.11467 10.182 4.15518 10.0905C4.19569 9.99888 4.25488 9.91678 4.32897 9.84941L11.516 3.31465C11.6483 3.19435 11.8207 3.12769 11.9995 3.12769C12.1783 3.12768 12.3507 3.19433 12.483 3.31461L19.671 9.84941C19.7451 9.91677 19.8043 9.99888 19.8448 10.0905C19.8853 10.182 19.9063 10.2811 19.9062 10.3812V18.6882C19.9063 18.7826 19.8877 18.8761 19.8515 18.9633C19.8154 19.0505 19.7625 19.1297 19.6957 19.1965C19.6289 19.2632 19.5497 19.3161 19.4625 19.3523C19.3753 19.3884 19.2818 19.407 19.1874 19.4069L14.8744 19.4064C14.6838 19.4064 14.501 19.3306 14.3662 19.1958C14.2314 19.061 14.1557 18.8782 14.1557 18.6876V18.6876Z" stroke="#656565" strokeLinecap="round" strokeLinejoin="round" />
								</svg>

							</Link>
							<Link to="/login" className="btn_logout">
								<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M16.1338 7.72656L19.9062 11.5L16.1338 15.2734" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M9.84375 11.5H19.9036" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M9.84375 19.4062H4.8125C4.62188 19.4062 4.43906 19.3305 4.30427 19.1957C4.16948 19.0609 4.09375 18.8781 4.09375 18.6875V4.3125C4.09375 4.12188 4.16948 3.93906 4.30427 3.80427C4.43906 3.66948 4.62188 3.59375 4.8125 3.59375H9.84375" stroke="#656565" strokeWidth="1.4375" strokeLinecap="round" strokeLinejoin="round" />
								</svg>



							</Link>
						</div>
						{/* <button className="btn_search"></button>
						<button className="btn_favorit"></button> */}
					</div>


				</header>

				{/* <SlidingPane
					className="menuBox"
					overlayClassName="some-custom-overlay-class"
					isOpen={menuOpen}
					hideHeader={true}
					width="300px"
					from={'left'}
					onRequestClose={() => {
					// triggered on "<" on left top click or on outside click
					this.setState({ menuOpen: false });
					}}
				>	
				
					<div>
						{
							this.state.isLogin ?
							<React.Fragment>
								<div className="userBox">
									<div>
										<Link to={'/accountChange'} >
											<p>{this.props.user.userInfo['userData']['name']} 님</p>
											<p className="mail">{this.props.user.userInfo['userData']['email']}</p>
										</Link>
										
									</div>
								</div>
							</React.Fragment>
							:
							<React.Fragment>
								<Link to="/login" className="btnLogin">로그인 / 회원가입</Link>
							</React.Fragment>
						}
					<ul className="menuList f_light">
							{/* <li><Link to='/'>seller </Link></li>
							<li><Link to='/'>item </Link></li> */}
				{/* <li><Link to='/'>style </Link></li>
							<li><Link to='/'>favorite </Link></li>
							<li><Link to='/'>shopping bag </Link></li> */}

				{/* {
							this.state.isLogin ?
								<li onClick={()=>{this.actionLogout()}} >
									<a >LogOut</a>
								</li>
								:null
							} */}
				{/* 							
						</ul>
							
					</div>
				</SlidingPane> */}
				{/* Confirm ======================= */}


			</React.Fragment>
		);
	}
}


export default withRouter(injectIntl(connect(
	state => ({
		user: state.user,
		common: state.common,
	}),
	dispatch => ({
		// user
		handleUserInfo: (data) => { dispatch(actions.SET_USER_INFO(data)) },
		// common
		handleLang: (data) => { dispatch(actions.SET_LANG(data)) },
		handleAlert: (data) => { dispatch(actions.SET_ALERT(data)) },
	})
)(Header)));

