import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { image_mapping, offImage_mapping } from 'components/utils/mapping'

import Line from "./include/leftdown"
import Temparature from "img/icons_item/temperature.png"
import Swich from './include/switch'
import Message from './include/message'
import Input from './include/input'
import { useInput } from 'hooks/commonHooks'
import {CARBONHEATER} from 'components/utils/equips'








function Modal(props) {
    const [data, set_data] = useState([])
    const [sensors, set_sensors] = useState([])

    const [isOn, set_isOn] = useState(false)
    const [actionTemp, actionTemp_handdle, set_actionTemp] = useInput('')
    const [actionWater, actionWater_handdle, set_actionWater] = useInput('')
    const [stopTemp, stopTemp_handdle, set_stopTemp] = useInput('')
    const [stopWater, stopWater_handdle, set_stopWater] = useInput('')

    // title unit data datahandle

    const handleChange = (e) => {
        set_isOn(e.target.checked);
    };

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            props.onClose(e)
        }
    }

    const close = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const array_find = (arr, code) => {
//TEMP
        let return_arr = {}
        return_arr = arr.filter(val => {
            if (val.tagClass.code == code) {
                return true
            }
            return false
        })

        if (return_arr.length == 0)
            return_arr = [{ 'value': 'err' }]
        return return_arr[0]
    }
    //TEMP:온도
    //CARBONHEATONTEMP:동작온도
    //CARBONHEATOFFTEMP:정지온도
    //CARBONHEATONHUM:동작습도
    //CARBONHEATOFFHUM:정지습도
    useEffect(() => {
        if (props.length != 0) {
            set_sensors(props.sensors)
            set_data(props.data)
            set_actionTemp(array_find(props.sensors, "CARBONHEATONTEMP")["value"])
            set_actionWater(array_find(props.sensors, "CARBONHEATOFFTEMP")["value"])
            set_stopTemp(array_find(props.sensors, "CARBONHEATONHUM")["value"])
            set_stopWater(array_find(props.sensors, "CARBONHEATOFFHUM")["value"])

            if (props.status.value == 0)
                set_isOn(false)
            else if (props.status.value == 1)
                set_isOn(true)
        }
    }, [props])

    return (
        <>
            {data.length == 0 ||
                <>
                    <ModalOverlay visible={props.visible} />
                    <ModalWrapper onClick={onMaskClick} visible={props.visible}>
                        <ModalInner>
                            <Top>
                                <Title>{data.name}</Title>
                                <CloseButton onClick={close}>닫기</CloseButton>
                            </Top>

                            <Wrapper>
                                <Wrap>
                                    {isOn == true ?
                                        <>
                                            <Img src={image_mapping[data.equipClass.equipClassId]} />
                                            <TempWrap>
                                                <TempImg src={Temparature} />
                                                <Temp>{array_find(sensors, "TEMP")["value"]}</Temp>
                                                <Unit>℃</Unit>
                                            </TempWrap>
                                        </>

                                        :

                                        <>
                                            <Img src={offImage_mapping[data.equipClass.equipClassId]} />
                                            <TempWrap>
                                                <TempImg src={Temparature} />
                                                <Temp style={{ color: "#898C8F" }}>{array_find(sensors, "TEMP")["value"]}</Temp>
                                                <Unit style={{ color: "#898C8F" }}>℃</Unit>

                                            </TempWrap>
                                        </>
                                    }

                                    <Swich isOn={isOn} handleChange={handleChange} />

                                    <Message success={true} />

                                    {isOn == true ? <>
                                        <Line title="동작 온도" titleColor="#17B9B6" backgroundColor="#AAEAE9" color="#17B9B6" unit="℃" int={array_find(sensors, "CARBONHEATONTEMP")["value"]} />
                                        <Line title="정지 온도" titleColor="#FFFFFF" backgroundColor="#B5B5B5" color="#656565" unit="℃" int={array_find(sensors, "CARBONHEATOFFTEMP")["value"]} />
                                        <Line title="동작 습도" titleColor="#4F80E2" backgroundColor="#CEE5FF" color="#4F80E2" unit="%" int={array_find(sensors, "CARBONHEATONHUM")["value"]} />
                                        <Line title="정지 습도" titleColor="#FFFFFF" backgroundColor="#B5B5B5" color="#656565" unit="%" int={array_find(sensors, "CARBONHEATOFFHUM")["value"]} />
                                    </>
                                        :
                                        <>
                                            <Line title="동작 온도" titleColor="#F5F5F5" backgroundColor="#B5B5B5" color="#898C8F" unit="℃" int={array_find(sensors, "CARBONHEATONTEMP")["value"]} />
                                            <Line title="정지 온도" titleColor="#F5F5F5" backgroundColor="#B5B5B5" color="#898C8F" unit="℃" int={array_find(sensors, "CARBONHEATOFFTEMP")["value"]} />
                                            <Line title="동작 습도" titleColor="#F5F5F5" backgroundColor="#B5B5B5" color="#898C8F" unit="%" int={array_find(sensors, "CARBONHEATONHUM")["value"]} />
                                            <Line title="정지 습도" titleColor="#F5F5F5" backgroundColor="#B5B5B5" color="#898C8F" unit="%" int={array_find(sensors, "CARBONHEATOFFHUM")["value"]} />
                                        </>
                                    }
                                </Wrap>


                                <Wrap second>
                                    <Dongzak>동작설정</Dongzak>

                                  {/* title titleColor color unit data handleChange */}
                                    

                                    <Input titleColor="#17B9B6" color="#17B9B6" title="동작 온도" data={actionTemp} handleChange={actionTemp_handdle} unit="℃" />
                                    <Input titleColor="#747474" color="#2A3A4B" title="정지 온도" data={stopTemp} handleChange={stopTemp_handdle} unit="℃" />
                                    <Input titleColor="#4F80E2" color="#4F80E2" title="동작 습도" data={actionWater} handleChange={actionWater_handdle} unit="%" />
                                    <Input titleColor="#747474" color="#2A3A4B" title="정지 습도" data={stopWater} handleChange={stopWater_handdle} unit="%" />
                                    <Message success={true} />

                                    <Confirm>동작설정</Confirm>
                                </Wrap>
                            </Wrapper>
                        </ModalInner>
                    </ModalWrapper>
                </>
            }

        </>
    )
}

const Dongzak = styled.div`
display:flex ;
width:100% ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;

color: #747474;
`

const Confirm = styled.button`
display:flex ;
width:100% ;
background: #4F80E2;
border-radius: 5px;
height: 4.5rem;

align-items:center ;
justify-content:center ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;

color: #FFFFFF;
`
const TempWrap = styled.div`
width:100% ;
height: 3.3rem;
display:flex ;
justify-content:center ;
align-items:center ;
margin: 1.2rem 0;
`
const TempImg = styled.img`
width:2rem ;
height:2rem ;
margin-right:3px ;
`
const Temp = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
line-height: 26px;
font-size: 2.6rem;
font-weight: 500;
/* identical to box height */

color: #17B9B6;
`
const Unit = styled.div`
display:flex ;
height:100% ;
justify-content:flex-start ;

font-style: normal;
font-weight: 500;
font-size: Spoqa Han Sans Neo;
font-size: 1.6rem;
line-height: 26px;

color: #17B9B6;
`

const ModalWrapper = styled.div`
  box-sizing: border-box;
 

  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 25%;
  animation: zoomIn 0.2s none;
  margin: 0 auto;
  padding: 3.5rem;

  width: 80rem;
`


const Top = styled.div`
display:flex ;
justify-content:space-between ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 2.6rem;

line-height: 46px;


color: #2A3A4B;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`

const CloseButton = styled.div`
display:flex ;
width: 11.5rem;
height: 4.7rem;

line-height:4.7rem ;
background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;

text-align:center ;
justify-content:center ;

color: #FFFFFF;

cursor:pointer ;
`
const Wrap = styled.div`
display:flex ;
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:2.5rem;
margin-left: 3rem;
`}

`

const Img = styled.img`
width: 130px ;
height: 130px ;
`

export default Modal