import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Chart from 'chart.js';
import DownloadSimple from 'img/DownloadSimple.png'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver';
const Wrapper = styled.div`
width: 100%;
min-width: 1300px;

margin-top: 16px;
background: ${props => props.backgroundColor};
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;

padding: 3rem;
`


let color_mapping = {
    0: '#ffffff',
    1: '#E7E7E7',
    2: '#CACACA',
    3: '#949494',
    4: '#757575',
    5: '#575757',

}
let btn_color_mapping = {
    0: '#D3D3D3',
    1: '#D3D3D3',
    2: '#A9A9A9',
    3: '#757575',
    4: '#949494',
    5: '#757575',

}



function Main(props) {
    const canvasDom = useRef(null)
    const [depth, set_depth] = useState(0)
    const [standardTime, set_standardTime] = useState('')
    const [mychart, set_mychart] = useState('')

    const [ctx, set_ctx] = useState('')
    useEffect(() => {

        let [hour, minutes] = props.standardTime.split(':')
        hour = parseInt(hour)
        minutes = parseInt(minutes)
        if (hour / 10 < 1)
            hour = `0${hour}`
        if (minutes / 10 < 1)
            minutes = `0${minutes}`
        console.log(hour + ':' + minutes)
        set_standardTime(hour + ':' + minutes)

    }, [props.standardTime])







    useEffect(() => {


        if (props.length != 0) {
            const ctx = canvasDom.current.getContext("2d");
            set_ctx(ctx)
            if (mychart != '')
                mychart.destroy()

            const chart = new Chart(ctx, props.graph)
            set_mychart(chart)
            // chart.options.scales = '';
            // chart.update()

        }
        if (props.depth != undefined) {
            set_depth(props.depth)
        }
    }, [props])

    const startDayChange = (e) => {
        let startDay = e.target.value

        let start = new Date(e.target.value)
        let end = new Date(props.endDay)

        if (startDay == props.endDay && props.searchType !== 'hourly') {
            alert("시작일과 종료일이 일치합니다.")
        } else if (end < start) {
            alert('시작일이 종료일보다 큽니다.')
        } else {
            props.set_startDay(startDay)
        }

    }

    const endDayChange = (e) => {
        let endDay = e.target.value

        let start = new Date(props.startDay)
        let end = new Date(e.target.value)

        if (endDay == props.startDay && props.searchType !== 'hourly') {
            alert("시작일과 종료일이 일치합니다.")
        } else if (end < start) {
            alert('시작일이 종료일보다 큽니다.')
        } else {
            props.set_endDay(endDay)
        }

    }

    return (
        <Wrapper backgroundColor={color_mapping[depth]}>
            <Switch_Label>
                <Label>
                    <Switch_input checked={props.searchType == 'hourly'} name="test" type="radio" value="hourly" onClick={props.searchTypeHanddle} />
                    <Switch backgroundColor={btn_color_mapping[depth]}>시간별</Switch>
                </Label>
                <Label>
                    <Switch_input checked={props.searchType == 'daily'} value="daily" onClick={props.searchTypeHanddle} name="test" type="radio" />
                    <Switch backgroundColor={btn_color_mapping[depth]} >일별</Switch>
                </Label>
                <Label>
                    <Switch_input checked={props.searchType == 'weekly'} name="test" type="radio" value="weekly" onClick={props.searchTypeHanddle} />
                    <Switch backgroundColor={btn_color_mapping[depth]}>주별</Switch>
                </Label>

                <Label>
                    <Switch_input checked={props.searchType == 'monthly'} name="test" value="monthly" onClick={props.searchTypeHanddle} type="radio" />
                    <Switch backgroundColor={btn_color_mapping[depth]}>월별</Switch>
                </Label>
                {props.isLookUp &&
                    <Download_Button onClick={props.download}> <Download_icon src={DownloadSimple} /> 데이터 다운로드 </Download_Button>
                }
            </Switch_Label>
            <Middle>
                <span>
                    <Title>
                        시작일
                        <Calendar value={props.startDay} onChange={startDayChange} type="date" />
                    </Title>

                    <Title>
                        종료일
                        <Calendar value={props.endDay} onChange={endDayChange} type="date" />
                    </Title>
                    {
                        props.searchType == 'hourly' ||
                        <Title>
                            기준시간
                            <Calendar value={standardTime} onChange={props.standardTimeHanddle} type="time" />
                        </Title>
                    }
                </span>

                <Btn onClick={props.search}>
                    검색
                </Btn>
            </Middle>

            <Graph id="chartCanvas" ref={canvasDom}></Graph>
            <a id="download" style={{ display: "hidden" }} />
        </Wrapper>
    );
}

const Download_icon = styled.img`
width: 2.2rem;
height: 2.2rem;
margin-right: 0.5rem;
`

const Download_Button = styled.button`
width: 191px;
height: 52px;

background: #4F80E2;
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 23px;
/* identical to box height */

display: flex;
align-items: center;
margin-left: 0.8rem;
color: #FFFFFF;
`


const Graph = styled.canvas`
display: flex;
width: 100%;
height: 582px;
background: #F5F5F5;
border-radius: 8px;
margin-top: 25px;
`


const Middle = styled.div`
display: flex;

align-items: center;
justify-content: space-between;
margin:1.4rem 0rem;
`
const Title = styled.label`
margin-left: 23px;
font-size: 1.6rem;
`
const Btn = styled.button`
display: flex;
width: 17.3rem;
height: 5.2rem;
float: right;
align-items: center;
justify-content: center;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 2rem;


display: flex;

color: #FFFFFF;

background: #17B9B6;
border-radius: 5px;
`

const Calendar = styled.input`
width: 190px;
height: 58px;

margin-left: 19px;
background: #F1F1F1;
border-radius: 5px;

padding: 0px 12px;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;

color: #2A3A4B;

&:before {
    position: absolute;
    top: 3px; left: 3px;
    
    display: inline-block;
    color: black;
}
`

const Switch_Label = styled.div`
display: flex;
justify-content: flex-end;
`

const Label = styled.label`
  cursor: pointer;
  margin-left: 0.8rem;
`;


const Switch = styled.div`
width: 9.4rem;
    height: 5.2rem;
    display: flex;
    
    align-items: center;
    justify-content: center;
    background: ${props => props.backgroundColor};
    border-radius: 5px;
    font-family: 'Spoqa Han Sans Neo';

    font-weight: 500;
    font-size: 1.8rem;

    color: #FFFFFF;


`;
const Switch_input = styled.input`
  opacity: 0;

  &:checked  {
      &+ ${Switch}{
        background-color:#17B9B6 ;
      }
    
  }
`;

export default Main;