import React, { useState, useEffect } from 'react';
import { API, equipControlFetch } from 'service';
import { code_mapping, image_mapping } from 'components/utils/mapping';
import GansuMoter from 'components/modal/gansuMoter';
import styled, { css } from 'styled-components';
import { HVACTEMPHUM } from 'constants/equipInfo';
import { getEquipSensorInnerTagObj, getEquipSensorInnerTagValue, integerCheck } from 'components/utils/util';
import dp from 'img/tools_item/fan_on.png'
import hangon from 'img/tools_item/thermo-hygrostat.png'
import Line from "./include/leftdown"
import { useInput } from 'hooks/commonHooks';

import Message from './include/message'
import Input from './include/input'
export const HvacTempHumControlModal = ({ data }) => {

    const [tempValue, onChangeTempValue] = useInput(getEquipSensorInnerTagValue(data, HVACTEMPHUM.envState.setTemp));
    const [humValue, onChangeHumValue] = useInput(getEquipSensorInnerTagValue(data, HVACTEMPHUM.envState.setTemp));

    const [fetchResult, setFetchResult] = useState(null);

    const controlfetch = (e) => {

        try {
            e.preventDefault();
            console.log(tempValue, humValue);

            if (!integerCheck(tempValue)) {
                alert('온도 설정은 정수로 입력 해 주세요.')
                return;
            }

            if (!integerCheck(humValue)) {
                alert('습도 설정은 정수로 입력 해 주세요.')
                return;
            }

            if (tempValue < 0 || tempValue > 100) {
                alert('온도 설정은 0 ~ 99이내로 입력 해 주세요.')
                return;
            }

            if (humValue < 0 || humValue > 100) {
                alert('습도 설정은 0 ~ 99이내로 입력 해 주세요.')
                return;
            }

            const targetConfigTemp = getEquipSensorInnerTagObj(data, HVACTEMPHUM.envState.setTemp),
                targetConfigHum = getEquipSensorInnerTagObj(data, HVACTEMPHUM.envState.setHum);


            const { equipClass: { code: equipClassCode, equipProtocolMap: equipClassValue }, equipId, seq } = data;

            const { tagClass: { code: configHumClassCode, encoding: configHumEncoding }, tagId: configHumActId, gatewayMap: { map: configHumAddress } } = targetConfigHum,
                { tagClass: { code: tempStepClassCode, encoding: tempStepEncoding }, tagId: tempStepActId, gatewayMap: { map: tempStepAddress } } = targetConfigTemp;

            let fetchData = {
                equipClassCode,
                equipId,
                equipClassValue,
                params: [
                    {
                        id: tempStepActId,
                        address: tempStepAddress,
                        classCode: tempStepClassCode,
                        seq,
                        value: tempValue,
                        encoding: 'FULLBYTE_DECIMAL',
                        bitSeq: null,
                    },
                    {
                        id: configHumActId,
                        address: configHumAddress,
                        classCode: configHumClassCode,
                        seq,
                        value: humValue,
                        encoding: 'FULLBYTE_DECIMAL',
                        bitSeq: null,
                    },
                ]
            }
            new Promise((resolve, reject) => {
                API.equipControl(fetchData)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
                .then(res => {
                    console.log(res);
                    if (res !== 200) {
                        throw Error('contorl Error ' + res)
                    }
                    setFetchResult(true);
                })
                .catch(err => {
                    console.log(err)
                    setFetchResult(false);
                    alert(err.message);
                })

        } catch (error) {
            setFetchResult(false);
            alert(error.message)
        }
    }

    return (
        <Wrapper>
            <Wrap>
                <Img src={hangon} />
                <Line title="현재 온도" titleColor="#FF868B" backgroundColor="#FFE4E3" color="#2A3A4B" unit="℃" int={getEquipSensorInnerTagValue(data, HVACTEMPHUM.envState.curTemp)} />
                <Line title="현재 습도" titleColor="#4F80E2" backgroundColor="#CEE5FF" color="#2A3A4B" unit="%" int={getEquipSensorInnerTagValue(data, HVACTEMPHUM.envState.curHum)} />
                <Line title="설정 온도" titleColor="#FF868B" backgroundColor="#FFE4E3" color="#2A3A4B" unit="℃" int={getEquipSensorInnerTagValue(data, HVACTEMPHUM.envState.setTemp)} />
                <Line title="설정 습도" titleColor="#4F80E2" backgroundColor="#CEE5FF" color="#2A3A4B" unit="%" int={getEquipSensorInnerTagValue(data, HVACTEMPHUM.envState.setHum)} />
            </Wrap>
            <Wrap second>
                <Dongzak>동작설정</Dongzak>
                <Input title="온도 설정(℃)" titleColor="#FF868B" backgroundColor="#FFE4E3" color="#2A3A4B" unit="" handleChange={onChangeTempValue} data={tempValue} />
                <Input title="습도 설정(%)" titleColor="#4F80E2" backgroundColor="#FFE4E3" color="#2A3A4B" unit="" handleChange={onChangeHumValue} data={humValue} />
                {fetchResult !== null &&
                    <Message success={fetchResult} />
                }
                <Confirm onClick={controlfetch}>동작설정</Confirm>
            </Wrap>
        </Wrapper>
    )
}






const FanImage = styled.img`
width: 2.8rem;
height: 2.8rem;
`
const FanText = styled.span`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.6rem;
margin: 0 0.6remx;
/* identical to box height */


color: #17B9B6;

`
const Span = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 2rem 0;
`

const StatusMessage = styled.div`
display: flex;
width: 6.6rem;
height: 3.2rem;
justify-content: center;
align-items: center;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.6rem;
line-height: 20px;
margin-left: 0.6rem;
/* identical to box height */


color: #E16A6A;
background-color: #FFCFCF;
border: 0.1rem solid #E16A6A;
box-sizing: border-box;
border-radius: 18.5px;
`
const Dongzak = styled.div`
display:flex ;
width:100% ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;

color: #747474;
`

const Confirm = styled.button`
display:flex ;
width:100% ;
background: #4F80E2;
border-radius: 5px;
height: 4.5rem;

align-items:center ;
justify-content:center ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;

color: #FFFFFF;
`
const TempWrap = styled.div`
width:100% ;
display:flex ;
justify-content:center ;
align-items:center ;
`
const TempImg = styled.img`
width:2rem ;
height:2rem ;
margin-right:3px ;
`
const Temp = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2.6rem;
line-height: 3.2rem;
/* identical to box height */

color: #17B9B6;
`
const Unit = styled.div`
display:flex ;
height:100% ;
/* justify-content:flex-start ; */
align-items: flex-start;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;

color: #17B9B6;
`

const ModalWrapper = styled.div`
  box-sizing: border-box;
 

  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 5%;
  
  margin: 0 auto;
  padding: 3.5rem;

  width: 80rem;
    animation: zoomIn 0.2s none;
`

const CloseButton = styled.div`
display:flex ;
width: 11.5rem;
height: 4.7rem;

line-height:4.7rem ;
background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;

text-align:center ;
justify-content:center ;

color: #FFFFFF;

cursor:pointer ;
`
const Top = styled.div`
display:flex ;
justify-content:space-between ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 2.6rem;
line-height: 46px;


color: #2A3A4B;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`

const Wrap = styled.div`
display:flex ;
/* height: 34.8rem; */
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:2.5rem;
margin-left: 3rem;
`}

`

const Img = styled.img`
width: 11rem ;
height: 11rem ;
`
