import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import CKEditor from 'components/layout/include/CKEditor';
import cookie from 'react-cookies'
import * as common from "js/common";
class Gateway extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 1,
			list : [],
			keyword : '',

			request:{
				params : {
					size : 10,
					page : 1,		
				},							
				startDate : String(common.toLocalDate((new Date).setDate((new Date).getDate() - 6 ))),
				endDate: String(common.toLocalDate(new Date))				
			}
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	onChangePage(index) {
		this.setState({ pageIndex: index },()=>{
			let obj = this.state.request;
			obj.params.page = Number(index) - 1;
			this.callData(obj);
		});
	}

	componentDidMount() {
		let obj = {
			params : {
				size : 10,
				page: Number(this.state.pageIndex) -1
			}
		};		
		this.callData(obj)
	}

	callData (obj) {
		

		if(this.state.request.startDate){
			obj.params['startDate'] = this.state.request.startDate
		}		
		if(this.state.request.endDate){
			obj.params['endDate'] = this.state.request.endDate
		}

		this.props.setLoading(true)

		let api;
		if(this.props.user.realm_access.roles.includes("ROLE_CPR"))
			api = API.findActuatorLogPagesInCpr(obj)
		else
			api = API.findActuatorLogPages(obj)
		
		
		api.then(result => {
			console.log(result)
			this.setState({
				list : result.content ,
				pageSize : result.totalPages
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			alert('데이터를 불러오지 못했습니다')
			this.props.setLoading(false)
		});
		
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장 제어 이력</h2>
                        <div>
                            <div className="whBox">
                                <div className="searchBox">
									<div className="datePickerDuration">
										<DatePicker
											className="dfhadjklfhls"
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={new Date(this.state.request.startDate)}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { 
												if (date === null) { date = this.state.request.startDate; } 
												let data = this.state.request;
												data.startDate = String(common.toLocalDate(date));
												this.setState({ request : data }) 
											}}
										/>
										<span className="dash">~</span>
										<DatePicker
											dateFormat="yyyy-MM-dd"
											placeholderText="YYYY-MM-DD"
											selected={new Date(this.state.request.endDate)}
											showDisabledMonthNavigation
											onChangeRaw={(e) => { e.preventDefault() }}
											onChange={(date) => { 
												if (date === null) { date = this.state.request.endDate; } 
												let data = this.state.request;
												data.endDate = String(common.toLocalDate(date));
												this.setState({ request : data }) 
											}}
										/>
									</div>                                                                 
                                    <button type="button" class="ml10" onClick={() => {this.onChangePage(1)}}>검색</button>									                                    
                                </div>
                            </div>
                            <div className="tableBox mt30">
                                <table>
									
									<colgroup>
										<col width="70"/>
										<col width="70"/>
										<col width="70"/>
										<col width="70"/>
										<col width="50"/>
										<col width="100"/>
										<col width="50"/>
										<col width="100"/>	
										<col width="30"/>										
									</colgroup>
                                    <thead>
                                        <tr>
											<th></th>
											<th>농장 이름</th>
											<th>시설동 이름</th>
											<th>장치</th>
                                            <th>ID</th>
                                            <th>시각</th>                                      
                                            <th>길이</th>
                                            <th>프로토콜 맵</th>
											<th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
										{
											
											this.state.list.length === 0 ?
												<tr style={{ cursor: 'default' }}>
													<td colSpan='100' className="textCenter">농장 제어 이력이 없습니다.</td>
												</tr>
											:
												this.state.list.map((item,index)=>{
													return(
														<tr >
															<td></td>
															<td className="textCenter">{item.farmName}</td>
															<td className="textCenter">{item.containerName}</td>
															<td className="textCenter" onClick={() => { this.props.history.push({ pathname: `/history/control/detail`, state : {item : item}} ) }}>{item.equipName}</td>
															<td className="textCenter">{item.regId}</td>
															<td className="textCenter">{common.toDateString(item.regDate, 'time')}</td>
															<td className="textCenter">{item.length}</td>
															<td className="textCenter">{item.protocolMap}</td>
															<td></td>
														</tr>
													)
												}
										)}
                                    </tbody>
                                </table>
                                <Pager pageIndex={this.state.pageIndex} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Gateway;
