import React from 'react';
import {BrowserRouter} from 'react-router-dom';

import App from './components/App';
import { createStore, compose } from 'redux';
//import {Link} from "react-router-dom";
import reducers from './reducers';
import { Provider } from 'react-redux';

const store = createStore(
	reducers,
	compose(window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
	);

class Root extends React.Component {
	
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Provider>
		);
	}
}

export default Root;