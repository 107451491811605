import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Section from "./sectionSession"
import { cate_mapping } from 'components/utils/mapping'
import SettingWrap from './settingWrap'
const Wrapper = styled.div`
display: flex;
width: 100%;
flex-direction: column;
margin-top: 16px;
min-width: 1300px;
background-color: ${props => props.background};
`

const Middle = styled.div`
width: 100%;

background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;

`
const Button=styled.button`
height: 5.2rem;
width: 17.3rem;
padding: 1.4rem 6.5rem;
background: #17B9B6;
border-radius: 5px;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 2rem;
margin: 1.6rem 0;
color: #FFFFFF;
float: right;
margin-left: auto;
`

const BelongWrapper = styled.div`
display: flex;
width: 100%;
margin-top: 32px;
`


function Main(props) {
    const [preprocessedData, set_preprocessedData] = useState([])

    
    const preprocess=()=>{
        const label = []
            props.sensor.map(val => {
                val.sensors.map(data => {

                    let cate = label.find(val => { if (val.name == cate_mapping[data.tagClass.code]) return true })
                    
                    if (cate == undefined) {

                        label.push({
                            name: cate_mapping[data.tagClass.code],
                            sensors: [data],
                            unit:data.tagClass.unit
                        })

                    } else {
                        cate.sensors.push(data)
                    }
                })
            })
            set_preprocessedData(label)
            props.set_selectedSensor([])
            props.set_preProcessed(label)
    }

    useEffect(() => {
        if (props.length != 0) {
            preprocess()
        }
    }, [props.sensor])
    useEffect(()=>{},[])
    return (

        <Wrapper>
            <Middle>

                <BelongWrapper>
                
                    <Section min={props.min} max={props.max} set_unit={props.set_unit}set_tag2name={props.set_tag2name} set_lower={props.set_min} set_upper={props.set_max}
                    selected={props.selectedSensor} set_selected={props.set_selectedSensor} category={preprocessedData} />
                    <SettingWrap unit={props.unit} min={props.min} max={props.max} min_handdle={props.min_handdle} max_handdle={props.max_handdle}/>

                </BelongWrapper>
            </Middle>
			<Button onClick={()=>props.settingBtn(preprocess)}>설정</Button>

        </Wrapper>
    );
}


export default Main;