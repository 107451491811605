import React from 'react';
//import QrCodeCreate from "../components/qrCode/qrCodeCreate.jsx";
class Login extends React.Component {

    render() {
        return (
            <div className="page404">
                <strong>404</strong>
               <p>유효하지 않은 URL 입니다.</p>
               <p>요청하신 페이지가 사라졌거나 잘못된 호출입니다.</p>
            </div>
        );
    }
}


export default Login;