import React, { Children } from 'react';
import styled from 'styled-components';
import icon_CheckCircle from "img/icon_CheckCircle.png"
import blue_icon_CheckCircle from "img/deepblue_checkCircle.png.png"


function Main(props) {
    return ( 
        <Label >
        <Switch_input isBlue={props.isBlue} onClick={props.onClick} checked={props.checked} name="test" type="checkbox" />
        
        <Switch >{props.children.map(val=>{return val})}</Switch>
    </Label>
     );
}




const Label = styled.label`
  cursor: pointer;
  margin-left: 0.8rem;
`;


const Switch = styled.div`
display: flex;

    
padding: 10px 20px 10px 10px;
height: 51px;
margin-right: 10px;
background: #FFFFFF;
border: 2px solid #DFDFDF;
box-sizing: border-box;
box-shadow: 0px 3px 6px rgba(125, 140, 154, 0.25);
border:0.1rem solid rgba(125, 140, 154, 0.3);
border-radius: 5px;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;
line-height: 26px;

margin-bottom: 0.8rem;
cursor: pointer;

`;
const Switch_input = styled.input`
  opacity: 0;

  &:checked  {
    &+ ${Switch}{
      
      
        border: 0.1rem solid ${props=>props.isBlue?'#4F80E2':'#17B9B6'};
      }

      &+ ${Switch}>img:nth-child(1){
        
        content: url(${props=>props.isBlue?blue_icon_CheckCircle:icon_CheckCircle});
      }
    
  }
`;

export default Main;
