// import PropTypes from 'prop-types';

// export const euqipClassProps = Object.freeze({
//     name: PropTypes.string,
//     code: PropTypes.string,
//     enableAutoYn: PropTypes.string,
//     enableControlYn: PropTypes.string,
//     equipClassId: PropTypes.number,
//     equipProtocolMap: PropTypes.number,
// });

// export const sensorProps = Object.freeze({
//     bitSeq: PropTypes.any,
//     driveYn: PropTypes.any,
//     modeDate: PropTypes.string,
//     name: PropTypes.string,
//     normalYn: PropTypes.string,
//     regDate: PropTypes.string,
//     gatewayMap: gatewayMapProps,
//     tagClass: tagClassProps,
//     seq: PropTypes.number,
//     tagId: PropTypes.number,
//     value: PropTypes.any
// });

// export const gatewayMapProps = Object.freeze({
//     actionType: PropTypes.any,
//     controlYn: PropTypes.string,
//     gatewayMapId: PropTypes.number,
//     map: PropTypes.string,
//     name: PropTypes.string,
//     note: PropTypes.string,
// });

// export const tagClassProps = Object.freeze({
//     bitCalcYn: PropTypes.string,
//     code: PropTypes.string,
//     encoding: PropTypes.string,
//     isDigital: PropTypes.string,
//     name: PropTypes.string,
//     tagClassActions: PropTypes.array,
//     tagClassId: PropTypes.number,
//     unit: PropTypes.string,
// })

