import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInput } from 'hooks/commonHooks'
const color = {
    "0": "#17B9B6",
    "1": "#4F80E2",
    "2": "#845EC2",
    "3": "#EF7CA5",
}

const Label = styled.label`
/* height: 42px; */
  cursor: pointer;
  margin-left: 0.6rem;
  margin-bottom: 1.2rem;
`;


const Item = styled.div`
display:flex ;
align-items:center ;
justify-content:center ;
padding:1rem 1.6rem ;
cursor: pointer ;


background: #F1F1F1;
border-radius: 24.5px;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 1.6rem;

color: #A4A4A4;
`
const Item_input = styled.input`
  opacity: 0;

  &:checked  {
    &+ ${Item}{
        color:#FFFFFF;
        background-color: ${props => props.color};
      }

    
    
  }
`;

function Main(props) {
    const [checked, checked_hadn, set_checked] = useInput([])


    const clickHandle = (data, idx) => {
        props.onClickFn(data.children)
        props.set_GridName(data.name)
        let sensors = []
        let equips = []
        props.set_reload()

        for (let target of data.equips) {
            if (target.equipClass.code === 'FARMINENVSET') continue;
            if (target.equipClass.enableControlYn == 'Y') {
                equips.push(target)
            } else {
                sensors.push(target)
            }
        }

        // data.equips.forEach(val => {

        //     if (val.equipClass.enableControlYn == 'Y') {
        //         equips.push(val)
        //     }
        //     else {
        //         sensors.push(val)
        //     }
        // })

        props.set_equip(equips)
        props.set_sensor(sensors)

        let arr = []

        for (let i = 0; i < props.farm.length; i++) {
            arr.push(i == idx)
        }
        set_checked(arr)

    }
    useEffect(() => {
        if (props.first) {
            clickHandle(props.farm[0], 0)
        }

    }, [])
    useEffect(() => {
        return () => {
            set_checked([])
        }
    }, [props.reload])
    // useEffect(()=>{
    //     if(props.length!=0){
    //         let arr=[]
    //         for(let i of props.farm){
    //             arr.push(false)
    //         }
    //         set_checked(arr)
    //     }

    // },[props.farm,props.reload])


    return (

        <Wrap>
            <Title>{props.title}</Title>
            <ItemWrap color={color[props.grid]}>

                {props.farm.length == 0 || props.farm.map((val, idx) => {
                    return <Label>
                        {/* <Item_input  checked={checked[idx]}  name={`top${props.title}`} color={color[props.grid]} type="radio"/> */}
                        <Item_input checked={checked[idx]} name={`top` + props.title} color={color[props.grid]} type="radio" />
                        <Item color={color[props.grid]} onClick={() => { clickHandle(val, idx); }}>{val.name}</Item>
                    </Label>
                })}

            </ItemWrap>
        </Wrap>


    );
}



const Wrap = styled.div`
/* display:flex ;
flex:1; */
width:50% ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 2.0rem;
margin-left: 1.6rem;

line-height: 25px;
/* identical to box height */

display: flex;
align-items: center;

color: #2A3A4B;
margin-bottom:20px ;
`
const ItemWrap = styled.div`
display:flex ;
/* align-items: center; */
max-height: 12rem;
flex-wrap:wrap ;
overflow-y:auto ;
line-height: 2.4rem;

::-webkit-scrollbar {
  width: 5px;
border-radius: 4px;
}
 
::-webkit-scrollbar-track {
  background: #D3D3D3;
border-radius: 4px;

}
 
::-webkit-scrollbar-thumb {
  background: ${props => props.color || "#17B9B6"};
  border-radius: 4px;

}
`

export default Main;