import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import icon_nonCheckCircle from "img/icon_nonCheckCircle.png"
import icon_arrow from "img/icon_arrow.png"
import Button from "./button"


const BelongWrap = styled.div`

width: 100%;
margin: 24px 37px;
margin-top: 0px;
`

const Belong = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2rem;

display: flex;
align-items: center;

color: #2A3A4B;

margin-bottom: 19px;

`
const CategoryWrap = styled.div`
display: flex;
width: 100%;
max-height: 185px;
margin-bottom: 34px;

flex-wrap: wrap;
overflow-y: auto;
overflow-x: none;


::-webkit-scrollbar {
  width: 5px;
border-radius: 4px;

}
 
::-webkit-scrollbar-track {
  background: #D3D3D3;
border-radius: 4px;

}
 
::-webkit-scrollbar-thumb {
  background: #17B9B6;
  border-radius: 4px;

}

`



const CheckImg = styled.img`
width: 27px;
height: 27px;

margin-right: 12px;

`
const Titles = styled.div`
width: 100%;
display: flex;

padding: 16px;
height: 62px;

background: #F1F1F1;
border-radius: 5px;

margin-bottom:24px;

`

const Title = styled.span`
flex:0 0 auto;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2rem;
padding: 0.3rem 0rem;
margin-right: 10px;


border-bottom: 3px solid #F1F1F1;
color: #A4A4A4;
transition: all 250ms ease-in-out;


&:after {
  display:block;
  content: '';
  border-bottom: solid 3px ${props=>props.isBlue==true? '#4F80E2':'#17B9B6' };
  transform: scaleX(0);  
  transition: all 250ms ease-in-out;
  padding-bottom: 0.3rem;
  /* transform-origin:  0% 50%; */
}
&:hover:after {
    transform: scaleX(1); 

}


&:hover{
    color: ${props=>props.isBlue==true? '#4F80E2':'#17B9B6' };
    
}

${props=>props.focus==true&&css`
    color: ${props.isBlue==true? '#4F80E2':'#17B9B6' };

    &:after{
        transform: scaleX(1); 
        border-bottom: 3px solid ${props.isBlue==true? '#4F80E2':'#17B9B6' } ;
    }

`}

cursor: pointer;

`
const Img = styled.img`
width: 30px;
height: 30px;

cursor: pointer;

${props=>props.reverse&&
css`
transform:rotate(180deg) ;
`}

`

const TitleWrap = styled.div`
flex:1;

align-items: center;

display: flex; 
flex-wrap: nowrap; 
overflow: hidden;

text-overflow:ellipsis;
scroll-behavior: smooth;
`



function Main(props) {

    const [category,  set_cate] = useState([])

    const [scroll,set_scroll]=useState(false)
    const [index,set_idx]=useState(0)
    
    useEffect(() => {
        // props.set_unit(obj.unit)
        set_idx(0)
        if(props.length!=0){
            if(props.category.length!=0)
                props.set_unit(props.category[0].unit)
        }
        set_cate(props.category)
    }, [props.category])
    useEffect(()=>{
        
    })
    

    const cateOnClickEvent = (id,name,obj) => {
        let arr=props.selected.slice()

        if(arr.length!=0){
            props.set_lower('')
            props.set_upper('')
        }
        else{
        props.set_lower(obj.tagRange.alarmLowerLimit)
        props.set_upper(obj.tagRange.alarmUpperLimit)}
        
        props.set_tag2name(id,name)
        
        let idx=arr.indexOf(id)
        
        if(idx==-1){
            arr.push(id)
        }else{
            arr.splice(idx,1)
        }
        
        props.set_selected(arr)
    }

    // {props.checked}
    const spread_cate = (data) => {
        return data.map((obj,idx) => {
            return <Button checked={props.selected.indexOf(obj.tagId)==-1?false:true} onClick={()=>cateOnClickEvent(obj.tagId,obj.name,obj)} id={`category${idx}`} ><CheckImg src={icon_nonCheckCircle} />{obj.name}</Button>
        })
    }
//
    const spread_title = (data) => {
        return data.map((obj,map_idx) => {
                return <Title focus={map_idx==index}  onClick={(e)=>{props.set_unit(obj.unit);set_idx(map_idx);}}>{obj.name}</Title>
        })
    }


    const rightButton=(e)=>{
        let target=e.currentTarget.parentNode.getElementsByTagName("div")[0]
        var _scrollX = target.scrollLeft;
        target.scrollLeft=_scrollX + 100
        if(target.scrollLeft>0)
            set_scroll(true)
      
    }

    const leftButton=(e)=>{
        let target=e.currentTarget.parentNode.getElementsByTagName("div")[0]
        var _scrollX = target.scrollLeft;
        target.scrollLeft=_scrollX - 100

        if(_scrollX-100<=0){
            set_scroll(false)
        target.scrollLeft=0

        }
        else{
        target.scrollLeft=_scrollX - 100

        }
    }

    return (

        <BelongWrap>

            <Belong>센서</Belong>

            <Titles>
            {scroll&&<Img reverse onClick={leftButton} src={icon_arrow} />}

                <TitleWrap>   
                    {spread_title(category)}
           
                </TitleWrap>

                <Img onClick={rightButton} src={icon_arrow} />
            </Titles>

            <CategoryWrap>
                {category[index]==null||spread_cate(category[index].sensors)}

            </CategoryWrap>


        </BelongWrap>


    );
}

export default Main;