import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { image_mapping, offImage_mapping } from 'components/utils/mapping'
import Switch from './include/switch'
import Message from './include/message'
// import CloseButton from './CloseButton'
import common from 'img/tools_item/common.png'


const Top = styled.div`
display:flex ;
justify-content:space-between ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2.6rem;



color: #2A3A4B;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`

const Wrap = styled.div`
display:flex ;
flex: 1;
padding:24px 31px;
align-items:center ;

flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
`}

`

const Img = styled.img`
width: 130px ;
height: 130px ;
`



function Modal({ onClose, visible, data, status, sensors }) {

    const [isOn, set_isOn] = useState(false)
    const handleChange = (e) => {
        set_isOn(e.target.checked);
    };
    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose(e)
        }
    }

    const close = (e) => {
        if (onClose) {
            onClose(e)
        }
    }


    useEffect(() => {
        if (status.value == 0)
            set_isOn(false)
        else if (status.value == 1)
            set_isOn(true)
    }, [status])

    return (
        <>
            <ModalOverlay visible={visible} />
            {data.length == 0 ||

                <ModalWrapper className={visible && 'open'} onClick={onMaskClick} visible={visible}>

                    <ModalInner>
                        <Top>
                            <Title>{data.name}</Title>
                            <CloseButton onClick={close}>닫기</CloseButton>
                        </Top>

                        <Wrapper>

                            <Wrap><Img src={(isOn ? image_mapping[data.equipClass.equipClassId] : offImage_mapping[data.equipClass.equipClassId]) || common} /></Wrap>

                        </Wrapper>
                        <Switch isOn={isOn} handleChange={handleChange} />


                        <Message />

                    </ModalInner>
                </ModalWrapper>
            }
        </>
    )
}




const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  outline: 0;
  &.open {
      position: fixed;
      background-color: rgba(0,0,0,0.5);
      & > div {
            
		}
	}
/* ${props => props.visible &&
        css`
    position: fixed;
		width: 100%;
		height: 100vh;
		background-color: rgba(0,0,0,0.5);
		transition: background-color 0.25s;
		
		& > div {
			transform: scale(1.0);
		}
        
        `
    } */
      
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 25%;
  margin: 0 auto;
  padding: 3.5rem;

  width: 50rem;
  
    animation: zoomIn 0.2s none;
  
        
`

const CloseButton = styled.div`
display:flex ;
width: 131px;
height: 53px;

line-height:51px ;
background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;

text-align:center ;
justify-content:center ;

color: #FFFFFF;

cursor:pointer ;
`
export default Modal