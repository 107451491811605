
import gansu from 'img/tools_item/watermoter_on.png'
import left from 'img/tools_item/03_window_left.png'
import right from 'img/tools_item/01_window_right.png'
import top from 'img/tools_item/05_window_top.png'
import nanbang from 'img/tools_item/heating.png'
import zunyal from 'img/tools_item/01_exchanger_ strong.png'
import dp from 'img/tools_item/fan_on.png'
import aircon from 'img/tools_item/air_on.png'
import hangon from 'img/tools_item/thermo-hygrostat.png'
import light from 'img/tools_item/light_on.png'
import water from 'img/tools_item/water_on.png'
import moter from 'img/tools_item/gear_on.png'

import gansu_off from 'img/tools_item/watermoter_off.png'
import left_off from 'img/tools_item/04_window_left_off.png'
import right_off from 'img/tools_item/02_window_right_off.png'
import top_off from 'img/tools_item/06_window_top_off.png'
import nanbang_off from 'img/tools_item/heating_off.png'
import zunyal_off from 'img/tools_item/04_exchanger_ off.png'
import dp_off from 'img/tools_item/fan_off.png'
import aircon_off from 'img/tools_item/air_off.png'
import hangon_off from 'img/tools_item/thermo-hygrostat.png'
import light_off from 'img/tools_item/light_off.png'
import water_off from 'img/tools_item/water_off.png'
import moter_off from 'img/tools_item/gear_off.png'

export const imageTable = Object.freeze({
    LEFTSIDEWINDOWIMG: {
        on: left,
        off: left_off,
    },
    RIGHTSIDEWINDOWIMG: {
        on: right,
        off: right_off,
    },
    TOPWINDOWIMG: {
        on: top,
        off: top_off,
    },
    NANBANGIMG: {
        on: nanbang,
        off: nanbang_off,
    },
    LIGHTIMG: {
        on: light,
        off: light_off
    },
    FANIMG: {
        on: dp,
        off: dp_off
    },
    HVACIMG: {
        on: hangon,
        off: hangon_off,
    },
    GEARIMG: {
        on: moter,
        off: moter_off,
    },
    WATERMOTORIMG: {
        on: water,
        off: water_off,
    },
    GWANSUIMG: {
        on: gansu,
        off: gansu_off,
    },
})

export const cate_mapping = {
    HUM: '습도',
    TEMP: '온도',
    HD: 'HD',
    CO2: 'CO₂',
    SOILEC: '토양EC',
    POREEC: '공극수EC',
    SOILTEMP: '토양온도',
    HUMPERSOIL: '토양수분함유율',
    RAINCHECK: '강우',
    WINDSPEED: '풍속',
    WINDDIR: '풍향',
    SOLARDAYAMOUNT: '일사량',
    NUTRIENTTEMP: '양액온도',
    NUTRIENTPH: '양액PH',
    NUTRIENTEC: '양액EC',
    FERTIGATIONTEMP: '관비온도',
    FERTIGATIONPH: '관비PH',
    FERTIGATIONEC: '관비EC'
}

export const code_mapping = {
    'RIGHTSIDEWINDOW': 'SIDEVALUE',
    'LEFTSIDEWINDOW': 'SIDEVALUE',
    'TOPWINDOW': 'SIDEVALUE',
    'DIFFISUER': 'DIFFIUSERVALUE',
    'CARBONHEATER': 'CARBONHEATSTATUS',
    'HEATEXCHANGER': 'HEATEXCONTROL',
    'HEATEXCHANGERCO2': 'HEATEXSTATCOMMON',
    'LIGHT': 'LIGHTSTATUS',
    'IRRIGATIONMOTOR': 'IRRIGATIONMOTORSTATUS',
    'HVAC': 'HVACCURTEMP',
    'AIRCON': 'AIRCONSTATUS',
    'HEATEXCHANGERCO2HUM': 'HEATEXSTATCOMMON',
    'HUMIDIFIER': 'HUMIDIFIERHUMSTAT',
    'CARBONHEATERNONECONTROL': 'CARBONHEATSTATBIT',
    'HEATMOTOR': 'MOTORSTATUS',
    'HEATFAN': 'FANSTATUS',
    'LEFTAST': 'SIDEVALUE',
    'HEATMOTORTEMP': 'CARBONHEATSTATBIT',
    'CARBONHEATERPOWER': 'CARBONHEATSTATBIT',
    'NUTRIENTEQUIP': 'NUTRIENTEQUIPSTATUS',
    'CIRCULATIONFAN': 'CIRCULATIONFANSTATUS',
    'CARBONHEATERPOWER': 'CARBONHEATSTATBITREVERS',

};



// 3 좌측 측창 
// 5 우측 측창 
// 6 천창 
// 7 만지작 하드웨어 
// 8 만지작 토양 하드웨어 
// 9 탄소난방 
// 10 전열교환기(제주도용)
// 11 디피저 
// 14 co2  설정 전열교환기
// 15 조명 
// 17 관수모터 
// 18 항온항습기 
// 20 일반 센서 그룹 
// 22 에어컨 
// 23 전열교환기(CO2, 가습)
// 24 가습기 
// 25 탄소난방(제어X)
// 26 난방 모터 
// 27 난방 순환 팬 
// 28 백엽상 
// 29 AST 
// 30 일사량 
// 31 양액 
// 32 관비 
// 33 히터 


export const equipClassId2name = {
    3: '좌측 측창',
    5: '우측 측창',
    6: '천창',
    7: '만지작 하드웨어',
    8: '만지작 토양 하드웨어',
    9: '탄소난방',
    10: '전열교환기',
    11: '디피저 ',
    14: 'co2  설정 전열교환기',
    15: '조명 ',
    17: '관수모터 ',
    18: '항온항습기 ',
    20: '일반 센서 그룹 ',
    22: '에어컨 ',
    23: '전열교환기(CO2, 가습)',
    24: '가습기 ',
    25: '탄소난방(제어X)',
    26: '난방 모터 ',
    27: '난방 순환 팬 ',
    28: '백엽상 ',
    29: 'AST ',
    30: '일사량 ',
    31: '양액 ',
    32: '관비 ',
    33: '히터 ',
}


let toyang = ''
let common = ''
let pan = ''
let back = ''
let ast = ''
let ilsa = ''
let yangac = ''
let ganbi = ''
let manzizac = ''


export const image_mapping = {
    '3': left,
    '5': right,
    '6': top,
    '7': manzizac,
    '8': toyang,
    '9': nanbang,
    '10': zunyal,
    '11': dp,
    '14': zunyal,
    '15': light,
    '17': gansu,
    '18': hangon,
    '45': hangon,
    '20': common,
    '22': aircon,
    '23': zunyal,
    '24': water,
    '25': nanbang,
    '26': moter,
    '27': dp,
    '28': back,
    '29': top,
    '30': ilsa,
    '31': yangac,
    '32': ganbi,
    '33': nanbang,
}

export const offImage_mapping = {
    '3': left_off,
    '5': right_off,
    '6': top_off,
    '7': manzizac,
    '8': toyang,
    '9': nanbang_off,
    '10': zunyal_off,
    '11': dp_off,
    '14': zunyal_off,
    '15': light_off,
    '17': gansu_off,
    '18': hangon_off,
    '45': hangon_off,
    '20': common,
    '22': aircon_off,
    '23': zunyal_off,
    '24': water_off,
    '25': nanbang_off,
    '26': moter_off,
    '27': dp_off,
    '28': back,
    '29': top_off,
    '30': ilsa,
    '31': yangac,
    '32': ganbi,
    '33': nanbang_off,
}

export const broad_tag = {
    ta: '기온',
    rn: '강수량',
    ws: '풍속',
    wd: '풍향',
    hm: '습도',
    pv: '증기압',
    td: '이슬점온도',
    pa: '현지기압',
    ps: '해면기압',
    ss: '일조',
    icsr: '일사',
    dsnw: '적설',
    ts: '지면온도,'
}


