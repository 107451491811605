import React from 'react';
import { nbind,postIndexing, reverseIndexing } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import cookie from 'react-cookies'
import { getOptions } from 'highcharts';
import SearchBox from 'components/common/searchBox';

class Gateway extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 5,
			list : [],
			keyword : '',
			checkList :[],
			className:'',
			ip:'',
			pageListSize : 10,
			request : {
				params : {
					size : 10,
					page: 1
				}
			}
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	//페이징
	onChangePage(index) {
		this.setState({ pageIndex: index },()=>{
			let obj = this.state.request;
			obj.params.page = Number(index) - 1;
			this.callData(obj);
		});
	}

	componentDidMount() {
		let obj = {
			params : {
				userId : cookie.load("userId"),
				size : 10,
				page: Number(this.state.pageIndex) -1
			}
		};
		this.callData(obj)
	}
	callData (obj) {
		

		if(this.state.keyword != '' ){
			obj.params['name'] = this.state.keyword
		}
		
		this.props.setLoading(true)
		API.farmgatewayList(obj).then(result => {
			console.log(result);
			this.setState({
				list : result.content ,
				pageSize : result.totalPages,
				totalElements : result.totalElements,
				checkList : []
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			this.props.setLoading(false)
		});
		
	}
	deleteList() {
		let refresh = {
			params : {
				size : 10,
				page: Number(this.state.pageIndex) -1
			}
		};
		if(this.state.checkList.length < 1){
			alert('선택된 목록이 없습니다.')
		}
		let obj = {
			userId : cookie.load("userId"),
			ids: this.state.checkList
		};
		console.log(obj);
		this.props.setLoading(true)
		API.farmgatewayDelete(obj).then(result => {
			alert('삭제가 완료 되었습니다.');
			this.callData(refresh)
		}).catch(error => console.log(error)).
		finally(()=>{
			this.props.setLoading(false);
		});
		
	}
	handleCheckboxChange = event => {
		let newArray = [...this.state.checkList, event.target.value];
		if (this.state.checkList.includes(event.target.value)) {
		  newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		  checkList: newArray
		});
	};
	
	searchContent = [
        {
            name : "이름",
            classCode : 'name',
            type : 'TEXT'
        }
    ]

	onSearchChange(i, value) {
        this.setState({pageIndex : i, request : value}, () => {this.callData(value)});
    };

	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장 게이트 웨이</h2>
                        <div>
                            {/* <div className="whBox">
                                <div className="searchBox">
                                    <div className="selectBox mr10">
                                        <select name="" id="" placeholder="제목 + 내용">
                                            <option value="">종류</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>

                                    </div>
									<div className="selectBox">
                                        <select name="" id="" placeholder="제목 + 내용">
                                            <option value="">이름</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>

                                    </div>
                                    <div className="inputBox  ml10">
                                        <input type="text"/>
                                        <button type="button">검색</button>
                                    </div>
                                </div>
                            </div> */}
							<SearchBox 
								searchContent = {this.searchContent}
								onSearchChange = {this.onSearchChange }
								pageIndex = {this.state.pageIndex}
								pageSize = {this.state.pageSize}
								pageListSize = {this.state.pageListSize}
                        	/>
                            <div className="tableBox mt30">
                                <table>
									<colgroup>
										<col width="50"/>
										<col width="50"/>
										
										{/* <col width="300"/>
										<col width="150"/>
										<col width="250"/>
										<col width="150"/>
										<col width="150"/> */}
										
										<col width="250"/>
										<col width="200"/>
										<col width="300"/>
										<col width="200"/>
										
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>번호</th>
                                            <th>게이트웨이명</th>
                                            <th>종류</th>
                                            <th>url</th>
                                            <th>장치{'&'}태그 이동</th>
                                            {/* {<th>로직 조건이동</th>} */}
                                        </tr>
                                        
                                    </thead>
                                    <tbody>
										{/* <tr >
                                            <td className="textCenter">
												<div className="checkBox"><input type="checkbox" name="type" id="T4"/> <label htmlFor="T4"></label></div>
											</td>
                                            <td className="textCenter">1</td>
                                            <td className="textCenter"><a href="javascripot:void(0);" onClick={() => { this.props.history.push({ pathname: `/gateway/detail/${3}` }) }}>팜인박스1</a> </td>
                                            <td className="textCenter">팜인박스</td>
                                            <td className="textCenter">url</td>
                                            <td className="textCenter">
												<button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/gateway/devicetag/${3}` }) }}>장치{'&'}태그</button>
											</td>
                                            <td className="textCenter">
												<button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/gateway/logic/${3}` }) }}>로직 조건</button>
											</td>
                                        </tr> */}
										{this.state.list.map((item,index)=>{ 
											return(
												<tr key={index}>
													<td className="textCenter">
														<div className="checkBox"><input type="checkbox" name="type" id={'t'+item.id} value={item.id} onChange={this.handleCheckboxChange} checked={this.state.checkList.indexOf(String(item.id)) > -1} /> <label htmlFor={'t'+item.id}></label></div>
													</td>
													<td className="textCenter">{reverseIndexing(this.state.totalElements, this.state.pageIndex,index, this.state.pageListSize)}</td>
													<td className="textCenter"><a href="javascripot:void(0);" onClick={() => { this.props.history.push({ pathname: `/gateway/detail/${item.id}` }) }}>{item.name}</a> </td>
													<td className="textCenter">{item.className}</td>
													<td className="textCenter">{item.ip}</td>
													<td className="textCenter">
														<button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/gateway/devicetag/${item.id}` }) }}>장치{'&'}태그</button>
													</td>
													{/* {<td className="textCenter">
														<button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/gateway/logic/${item.id}` }) }}>로직 조건</button>
													</td>} */}
												</tr>
											)
										})}
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to="/gateway/gatewayregister" className="btn mr10 mt10">등록</Link>
									<button onClick={() =>{ this.deleteList()}} className="btn mr10 mt10">삭제</button>
                                </div>
                                <Pager pageIndex={this.state.pageIndex} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Gateway;