import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighStock from "highcharts/highstock";
import { API } from 'service.js';

class ContainerCharts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            farmId : props.farmId,
            classCode : props.classCode,
            containerId : props.containerId,
            updateSeries : props.updateSeries,
            userId : props.userId,
            sensorLists: [],
            chartOptions: {
                chart: {
                    type: 'line',
                    height: '40%',
                    zoomType: 'y',
                },
                rangeSelector: {
                    buttons: [{
                        count: 30,
                        type: 'minute',
                        text: '30M'
                    }, {
                        count: 12,
                        type: 'hour',
                        text: '12H'
                    }, {
                        count: 1,
                        type: 'day',
                        text: 'D'
                    }, {
                        count: 1,
                        type: 'week',
                        text: 'W'
                    }, {
                        count: 1,
                        type: 'month',
                        text: 'M'
                    }, {
                        count: 1,
                        type: 'year',
                        text: 'Y'
                    }, {
                        type: 'all',
                        text: 'All'
                    }],
                    inputEnabled: true,
                    selected: 1
                },
                title: {
                    text: ''
                },
                plotOptions: {
                    line: {
                        gapSize:5
                        //color: '#d62020'
                        //  },
                        //  bar: {
                        //color: '#d52020'
                        //  },
                        //  column: {
                    },
                    series: {
                        marker: {
                            radius: 2
                        },
                        animation: true,
                        step: false,
                        turboThrehold:1000,
                        borderWidth: 0
                    }
                },
                tooltip: {
                    valueDecimals: 1,
                    valueSuffix: '℃',
                    xDateFormat:'%Y-%m-%d<br/>%H:%M:%S %p'
                    // reformat the tooltips so that local times are displayed
                    //formatter: function() {
                    //var d = new Date(this.x + (myOffset*60000));
                    //var n = (this.point.name === undefined) ? '' : '<br/>' + this.point.name;
                    //return this.series.name + ':<b>' + this.y + '</b>' + n + '<br/>' + d.toDateString() + '<br/>' + d.toTimeString().replace(/\(.*\)/, "");
                    //}
                },
                xAxis: {
                    type: 'datetime',
                    ordinal:false,
                    //min: Date.UTC(2013,02,28),
                    dateTimeLabelFormats : {
                        hour: '%l %p',
                        minute: '%l:%M %p'
                    },
                    title: {
                        text: '시간'
                    }
                },
                yAxis: [{
                    title: {
                        text: 'Temperature  ℃'
                    },
                    id: 'T'
                }, {
                    title: {
                        text: 'Other'
                    },
                    opposite: true,
                    id: 'O'
                }],
                exporting: {
                    enabled: true,
                    csv: {
                        dateFormat: '%d/%m/%Y %I:%M:%S %p'
                    }
                },
                legend: {
                    enabled: true
                },
                navigator: {
                    baseSeries: 0,  //select which series to show in history navigator, First series is 0
                    series: {
                        includeInCSVExport: false
                    }
                },
                series: []
            },
            hoverData: null
        };
    }

    setHoverData = (e) => {
        // The chart is not updated because `chartOptions` has not changed.
        this.setState({ hoverData: e.target.category })
    }

    updateSeries = () => {
        console.info('There data updateSeries');
        this.setState({ classCode: this.props.classCode })
        let obj = {
            farmId : this.props.farmId,
            userId : this.props.userId,
            classCode : this.props.classCode
        };
        let _this = this;

       // console.info("updateSeries::>>>",obj);


        //최초 설정
        API.farmChartSensors(obj).then(  response => {
            const data =  response;

            const arraySeries = [];
            data[0].containerList.map((containerList) => {
                containerList.sensorList.map((sensors) => {
                    //console.info('There data sensors', sensors);
                    const array = [];
                    sensors.valueList.map((values) => {
                        //let date = new Date(values.time)
                        //console.info('There data date::', new Date(values.time + 9 * 60 * 60 * 1000).getTime());
                        array.push( [new Date(values.time).getTime() + 9 * 60 * 60 * 1000, values.value] );
                        return array;
                    });
                    arraySeries.push( { name: containerList.containerName+'('+containerList.containerId+')' , data: array, tooltip: { valueDecimals: 2 },} );
                });
                //console.info('There data array', arraySeries);
            });
            _this.setState({
                chartOptions: {
                    series: arraySeries
                }
            })

        }).catch( error => console.log(error));
    }

    afterChartCreated = (chart) => {
        this.internalChart = chart;
    }

    componentDidMount() {
        this.updateSeries();
    }

    render() {
        const { chartOptions, hoverData } = this.state;

        return (

            <div className="chartBox">
                <div>
                    <div className="App">
                        {
                            <HighchartsReact
                                highcharts={HighStock}
                                constructorType={"stockChart"}
                                options = { this.state.chartOptions }
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ContainerCharts;