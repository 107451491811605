import _ from 'lodash';

const moment = require('moment-timezone');


// export const nbind = (component, option) => {
// 	component.iText = (des, id, values) => {
// 		if ( id === "" ) { return id; }
// 		if (_.get(component, 'props.intl', undefined)) {	
// 			return component.props.intl.formatMessage({
// 				id
// 			}, values);
// 		}
// 	};
// 	component.alert = (des, id, values) => {
// 		console.log(component.props.common.alert.stopTimer);
// 		if ( !component.props.common.alert.visible ) {
// 			component.props.common.alert.stopTimer();
// 			if ( id === "" ) { return id; }
// 			if (_.get(component, 'props.intl', undefined) && _.get(component, 'props.dispatch', undefined)) {
// 				let timer = '';
// 				let obj = {
// 					visible: true,
// 					id: id,
// 					startTimer: function() {
// 						this.timer = setTimeout(() => {
// 							component.props.dispatch(actions.SET_ALERT({
// 								visible: false,
// 								id: '',
// 								stopTimer: function () {},
// 								startTimer: function () {}
// 							}));
// 						}, 2500);
// 						console.log('sdfsdf');
// 					},
// 					stopTimer: function() {
// 						clearTimeout(this.timer);
// 					}
// 				}
// 				// Promise.resolve(obj)
// 				function updateMsg() {
// 					return new Promise ((resolve, reject) => {
// 						component.props.dispatch(actions.SET_ALERT(obj))
// 						component.props.common.alert.startTimer();
// 					})
// 				};
// 				updateMsg().then((data)=>{
// 					console.log('updateMsg');
// 				});
// 				console.log(obj);
// 			}	
// 		}
// 	};
// }
let timer = function () {};
export const nbind = (component, option) => {
	component.nullCheck = (data) => {
		return (data != null && data != '')
	};
	component.historyBack = () => {
		console.log(component,option)
		component.props.history.goBack();
	};
	component.iText = (des, id, values) => {
		if ( id === "" || !id ) { 
			return des;
			// return des;
		}else{
			if (_.get(component, 'props.intl', undefined)) {
				return component.props.intl.formatHTMLMessage({
					id
				}, values);
			}else{

			}
		}
		
	};
	
	component.alert = (des, id, values) => {
		clearTimeout(timer);
		
		// if ( id === "" ) { values = des; }
		values = des
		if (_.get(component, 'props.intl', undefined) && _.get(component, 'props.handleAlert', undefined)) {
			clearTimeout(timer);
			component.props.handleAlert({
				visible: true,
				id: id,
				value: values
			});
			timer = setTimeout(() => {
				component.props.handleAlert({
					visible: false,
					id: '',
					value: values
				});
			}, 1500);
		}
	};
	component.confirm = (des, id, values) => {
		if ( id === "" ) { id = des; }
		if (_.get(component, 'props.intl', undefined) && _.get(component, 'props.handleConfirm', undefined)) {
			clearTimeout(timer);
			component.props.handleConfirm({
				visible: true,
				id: id,
			});
		}
	};
}

export function toLocalDate(date, format) {
	if (!isValid(date)) {
		return ""
	}
	// let timezone = 'Asia/Seoul';
	date = moment.utc(date).local();
	if (format === 'time') {
		return date.format('YYYY-MM-DD HH:mm:ss');
	} else if (format === 'onlyTime') {
		return date.format('HH:mm:ss');
	}else{
		return date.format('YYYY-MM-DD');
	}
}

export function toDateString(date, format) {
	if (!isValid(date)) {
		return ""
	}
	// let timezone = 'Asia/Seoul';
	date = moment.utc(date);
	if (format === 'time') {
		return date.format('YYYY-MM-DD HH:mm:ss');
	} else if (format === 'onlyTime') {
		return date.format('HH:mm:ss');
	}else{
		return date.format('YYYY-MM-DD');
	}
}

// value와 일치하는 셀렉트 값 반환
export function matchSel(array, val) {
	let obj = {};
	let result = false;
	if ( val === undefined || val === null) {
		val = '';
	}
	array.some((item) => {
		if ( String(item.value) === String(val) ) {
			obj = item
			result = true;
		}
		return result;
	})
	if ( !result ) {
		val = '';
		array.some((item) => {
			if (String(item.value) === String(val)) {
				obj = item
				result = true;
			}
			return result;
		})
	}
	return obj
}

// 값 빈값 체크
// ( string empty "" , undefined, null => false )
export function isValid(val) {
	if (val === "" || val === undefined || val === null) {
		return false
	} else {
		return true
	}
}
// 인덱스 넘버링
export function idxNumbering(pageIndex, index) {
	return Number(((pageIndex - 1) * 10) + (index + 1)).toLocaleString('en-US');
}
// 소수점 스트링 절사
// decimalFixed (값, 고정할 소수점 자리수)
export function decimalFixed(data, fixedNum) {
	// 유효값 체크
	if ( !isValid(data) ) {
		data = '0';
		for (let i = 0; i <= fixedNum; i++) {
			data += '0';
		}
		return data;
	}
	// fixedNum 기본값 설정
	if (!isValid(fixedNum)) {
		fixedNum = 2;
	}
	data = String(data);
	// 소수일 때
	if (/[.]/g.test(data)) {
		let integer = '';
		let decimal = '';
		integer = data.split('.')[0]
		decimal = data.split('.')[1];
		if (decimal.length > fixedNum) {
			decimal = decimal.slice(0, fixedNum);
		} else if (decimal.length < fixedNum) {
			for (let i = decimal.length; i <= fixedNum; i++) {
				decimal += '0';
			}
		} else {}
		data = `${integer}.${decimal}`;
	// 정수일 때
	} else {
		let decimal = '';
		for (let i = 0; i < fixedNum; i++) {
			decimal += '0';
		}
		data = `${data}.${decimal}`
	}
	return data;
}

//  ======================================================

// input autocomplete = off
function offAutoComplete() {
	let input = document.getElementsByTagName('input');
	for (let i = 0; i < input.length; i++) {
		input[i].setAttribute('autocomplete', 'off');
	}
}
// function inputWidthAuto() {
// 	function paddingWidth(ele) {
// 		return ele.offsetWidth;
// 	}
// 	function onlyWidth(ele) {
// 		ele = window.getComputedStyle(ele, null);
// 		return parseInt(ele.getPropertyValue('width'));
// 	}
// 	let box = document.querySelectorAll('.inputBox');
// 	for (let i = 0; i < box.length; i++) {
// 		let ele = window.getComputedStyle(box[i], null);
// 		let totalWidth = onlyWidth(box[i]);
// 		totalWidth -= parseInt(ele.getPropertyValue('padding-left')) - parseInt(ele.getPropertyValue('padding-right'));
// 		let input = '';
// 		let isReadOnly, isSpan = false;
// 		let buttonWidth = 0;
// 		for (let j = 0; j < box[i].children.length; j++) {
// 			let child = box[i].children[j];
// 			if (child.tagName === 'SPAN') {
// 				isSpan = true;
// 			}
// 			if (child.tagName === 'UL') {
// 				buttonWidth = paddingWidth(child);
// 			}
// 			if (child.tagName === 'INPUT') {
// 				input = child;
// 				isReadOnly = child.readOnly;
// 			} else {
// 				totalWidth -= paddingWidth(child);
// 			}
// 		}
// 		totalWidth -= 5;
// 		if (input !== '') {
// 			input.style.width = `${totalWidth}px`;
			
// 			let paddingRight = box[i].style.paddingRight;
// 			box[i].style.paddingRight = `${paddingRight + buttonWidth }px`;
// 		}
// 		if ( isSpan ) {
// 			input.style.textAlign = 'right';
// 		}
// 		if ( isReadOnly ) {
// 			box[i].style.backgroundColor = '#eaeaea';
// 		}
// 	}
// }
// function addChangeModal () {
// 	const modal = document.querySelectorAll(".ReactModalPortal");
// 	for (let i = 0; i < modal.length; i++) {
// 		modal[i].addEventListener("DOMSubtreeModified", function () {
// 			inputWidthAuto();
// 		});
// 	}
// }
window.onload = function () {
	offAutoComplete();
	// inputWidthAuto();
	// addChangeModal();
}
document.body.addEventListener('DOMSubtreeModified', () => {
	offAutoComplete();
	// inputWidthAuto();
	// addChangeModal();
})


export const postIndexing = (index,q) =>{
	let first = index - 1,
		second = q+1;

	if(second == 10){
		first += 1;
		second = 0;
	}
	return `${first}${second}`;
}


export const reverseIndexing = (total,curPage,index, pageSize) => (total-((curPage -1)*pageSize))-index;
