import React, { Suspense } from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import DaumPostcode from 'react-daum-postcode';
import Modal from 'react-modal';
import { emailCheck, mobileCheck } from 'js/vaildChecker';

class farmRegister extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            params:{ 
                user : '',
                email :  '',
                name: '',
                farmer: '',
                faxNumber: '',
                telephoneNumber: '',
                mobileNumber: '',
                roadNameAddress: '',            
                addrDetail: '',
                productCode: '',
                zipCode: '',
                employeeNumber: '',
                openDate: null,
                contractDate: null,
                note: '',
                area: '',
                cprId: null,
                memberId: '',
                containerId : this.props.match.params.containerId,
				productCode : '',
				averageOutput : null,
				productionCycle : '',
                displayMemberId:'',
                latitude: "",
                longitude: ""
            },
            list : [],
            majorClassCode : '',
			productMajorClassList : [],
			productMinorClassList : new Map(),
            userCheck:false,
            zipPop : false,
            validCheckList : Object.freeze(['name','roadNameAddress','productCode']),
		};
        this.name = React.createRef();
        this.roadNameAddress = React.createRef();
        this.productCode = React.createRef();
        this.mobileNumber = React.createRef();
        this.email = React.createRef();
		nbind(this);
	}
    
	componentDidMount() {
        this.productClassList();
	}                   
    //등록하는 함수

    farmRegiste () {
        if(!this.state.userCheck){
            document.getElementById('displayUserCheck').focus();
            alert('유저 조회가 완료되지 않았습니다.');
            return;
        }
        
        for(let i = 0; i < this.state.validCheckList.length; i++){
            let target = this.state.validCheckList[i];
            if(!this.state.params[target] || !this.state.params[target].trim()){
                this[target].current.focus();
                return alert('유효한 입력이 아니거나 필수값이 입력되지 않았습니다\n*항목은 필수 입력값 입니다.');
            }
        }

        if(!mobileCheck(this.state.params.mobileNumber)){
            this.mobileNumber.current.focus();
            return alert('유효한 전화번호 형식이 아닙니다.')
        }
        if(!this.state.params.email){
            const flag = emailCheck(this.state.params.email);
            if(!flag){
                this.email.current.focus();
                return alert('유효한 이메일 형식이 아닙니다.')
            }
        }
		this.props.setLoading(true)
        //여기에 if문 넣기
        // if(!this.nullCheck(t.name) || !this.nullCheck(t.equipId) || !this.nullCheck(t.classCode) ){
        //     window.alert('이름,분류,소속장치를 모두 입력해주세요.')
        //     this.props.setLoading(false)
        //     return false
        // }
        this.setState({params : {memberId : this.state.params.displayMemberId}});

        API.fetchFarmsRegiste(this.state.params).then(result => {
           
			this.setState({
                params : result
           })
			this.props.setLoading(false)
			let confirm = window.confirm('농장 등록이 완료 되었습니다. \n리스트 화면으로 이동 하시겠습니까?');
            if(confirm) window.history.back();
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
    }
    
    productClassList(){
        API.productClassList().then(result => {
			let majorCategory = []
			let minorCategory = new Map()

			result[0].children.map(code1 => {
				majorCategory.push(code1);
				minorCategory.set(code1.minorCode, code1.children)
			})
			this.setState({			
				productMinorClassList : minorCategory,				
				productMajorClassList : majorCategory,
				majorClassCode : ''								
			});
		}).catch(error=>{console.log(error)})
	}

    checkUser(){
        if(this.state.params.displayMemberId == '') return alert('농장주 아이디를 입력 해 주세요');
        API.farmerInfo({userId:this.state.params.displayMemberId}).then(res =>{
            if(res.length > 0){
                this.setState({
                    userCheck : true,
                    params:{...this.state.params,
                        memberId : res[0].id,
                        mobileNumber : res[0].mobilePhoneNumber,
                        email : res[0].email
                    }
                });
                alert('유저 조회가 완료 되었습니다.');
            }else{
                alert('검색된 유저가 존재하지 않습니다.');
            }

        }).
        catch(err =>{
            alert(`서버와의 통신이 원활하지 않습니다.\n${err.message}`);
        })
    }
    handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        let zonecode = data.zonecode
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        // console.log(data);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
        // console.log(fullAddress);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
        // console.log(this.state.params)
        
        let t = this
        var callback = function(result, status) {
            if (status === "OK") {
                // console.log(result);
                // result[0]
                // x: "126.519268181444"
                // y: "34.369640578845"
                let params = {
                    ...t.state.params,
                    roadNameAddress: fullAddress,
                    zipCode: zonecode,
                    latitude : result[0].x,
                    longitude : result[0].y
                }
                t.setState({
                    zipPop:false,
                    params : params 
                })
            }
        };

        var geocoder = new window.kakao.maps.services.Geocoder();

        

        geocoder.addressSearch(fullAddress, callback);

       
    }

	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장 등록</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox tableFixed detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>농장명*</th>
                                            <td><input type="text"  value={this.state.params.name} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.name = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }
                                                    ref={this.name}
                                                    /></td>
                                            <th>농장주 아이디*</th>
                                            <td><input type="text" value={this.state.params.displayMemberId} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.displayMemberId = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={this.state.userCheck}
                                                    id='displayUserCheck'
                                            /></td>
                                            <td><button className="btn ml20" onClick={()=>{this.checkUser()}} type="button">유저 조회</button></td>
                                        </tr>
                                        <tr>
                                            <th>팩스번호</th>
                                            <td><input type="text" value={this.state.params.faxNumber} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.faxNumber = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }
                                            /></td>
                                            <th>전화번호</th>
                                            <td><input type="text" value={this.state.params.telephoneNumber} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.telephoneNumber = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                            /></td>
                                        </tr>
                                        <tr>
                                            <th>이메일</th>
                                            <td><input type="text" value={this.state.params.email} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.email = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }
                                                    ref={this.email}
                                            /></td>
                                            <th>핸드폰번호*</th>
                                            <td><input type="text" value={this.state.params.mobileNumber} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.mobileNumber = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }
                                                    ref={this.mobileNumber}
                                            /></td>
                                        </tr>
                                        <tr>
                                            <th>주소*</th>
                                            <td colSpan="2"><input type="text" value={this.state.params.roadNameAddress} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.roadNameAddress = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    ref={this.roadNameAddress} 
                                            /></td>
                                            <td><button className="btn ml20" onClick={()=>this.setState({zipPop : true})} >주소 검색</button></td>
                                        </tr>
                                        
                                        <tr>
                                            <th>생산물 카테고리*</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.params.majorClassCode} placeholder="농작물 종류"
														onChange={(e) => {
                                                            this.setState( {majorClassCode: e.target.value, params : {...this.state.params, productCode : ''}})}    
                                                                                                                
                                                        }>
														<option value="">선택</option>
														{   
															this.state.productMajorClassList.map((item,index)=>{
																return(
																	<option value={item.minorCode}>{item.name}</option>
																)
															})															
														}
                                                    </select>
                                                </div>
                                            </td>			
                                            <th>생산물*</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.params.productCode} placeholder="농작물 종류"
														onChange={(e) => {this.setState({ params : {...this.state.params, productCode: e.target.value}})}}
                                                        ref={this.productCode}>
														<option value="">선택</option>
														{   
															this.state.majorClassCode != '' && this.state.productMinorClassList.get(this.state.majorClassCode).map((item,index)=>{
																return(
																	<option value={item.minorCode}>{item.name}</option>
																)
															})
														}												
                                                    </select>
                                                </div>
                                            </td>
                                            
                                        </tr>       
                                        <tr>
                                            <th>상세주소</th>
                                            <td><input type="text" value={this.state.params.addrDetail} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.addrDetail = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }  
                                            /></td>
                                            <th>우편번호</th>
                                            <td><input type="text" value={this.state.params.zipCode} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.zipCode = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                            
                                            /></td>
                                        </tr>
                                        <tr>
                                            <th>위도</th>
                                            <td><input type="text" value={this.state.params.latitude} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.latitude = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly
                                                    />
                                            </td>
                                            <th>경도</th>
                                            <td><input type="text" value={this.state.params.longitude} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.longitude = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly
                                                    />
                                            </td>
                       
                                        </tr>
                                        <tr>
                                            <th>직원수</th>
                                            <td><input type="text" value={this.state.params.employeeNumber} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.employeeNumber = e.target.value
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                             /></td>
                                            <th>설립일</th>
                                            <td>
                                            <DatePicker
                                                    className="datePickerLib"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date()}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.openDate; } 
                                                        let data = this.state.params; 
                                                        let strDate = date.toISOString();
                                                        data.openDate = strDate.substring(0,strDate.lastIndexOf('T')).trim();
                                                        this.setState({ params: data }) 
                                                    }}
             
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>가동일자</th>
                                            <td>
                                                <DatePicker
                                                    className="datePickerLib"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date()}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.contractDate; } 
                                                        let data = this.state.params; 
                                                        let strDate = date.toISOString();
                                                        data.contractDate = strDate.substring(0,strDate.lastIndexOf('T')).trim();
                                                       
                                                        this.setState({ params: data }) 
                                                    }}
                                                />
                                            </td>
                                            <th>면적</th>
                                            <td><input type="number"  value={this.state.params.area} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.area = e.target.value
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                             /></td>
                                        </tr>
                                        <tr>
                                            <th>비고</th>
                                            <td colSpan="3"><input type="text"  value={this.state.params.note} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.note = e.target.value
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                            
                                            /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                            {/* onClick={() => {this.apply()}} */}
                                <button onClick={()=>{this.farmRegiste()}} className="btn mr5 mt10"  >등록</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
                    <Modal
						isOpen={this.state.zipPop}
						onRequestClose={()=>{this.setState({zipPop : false})}}
						shouldCloseOnOverlayClick={true}
						className="modalBox modalSizeS"
						style={{ content: { minWidth: 600, minHeight:500 } }}
					>
						<DaumPostcode
                            style={{height:500}}
                            onComplete={this.handleComplete}
                                                
                        />
					</Modal>
				</div>
			</React.Fragment>
		);
	}
}
export default farmRegister;