import React,{useEffect,useState,useCallback} from 'react';
import { Link } from "react-router-dom";
/*styles*/
const MyPageWorkSpace = ({children,props}) => {


    return (

        <div className='wholeWapper'>
            <div className='leftMenuWrapper'>
                <div className='leftMenuTitle'>
                    <div>계정 관리</div>
                </div>
                <div className="leftMenu">
                    <Link to='/mypage/profile' className='link'>프로필 관리</Link>
                    <Link to='/mypage/another' className='link'>another</Link>
                </div>
            </div>
            <div className='contentWrapper'>
                {children}
            </div>
        </div>

    )

}

export default MyPageWorkSpace;