import React from 'react';
import { useHistory } from 'react-router-dom';

const CompanyMemberAlwns = () => {
    const history = useHistory();
    return(
        <>
            <div className="contentInner">
                <div className="contentArea">
                    <h2 className="pageTitle textCenter">농장 사용자 등록</h2>
                    <div className="noticeDetail">
                        <div className="tableBox tableFixed detail mt30">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>농장주 아이디* </th>
                                        <td><input type="text"  onChange={(e) => {

                                                    }
                                                }
                                                id='displayUserCheck'
                                        /></td>
                                        <td><button className="btn ml20" onClick={()=>{}} type="button">유저 조회</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                      
                        <div className="textCenter mt30">
                        {/* onClick={() => {this.apply()}} */}
                            <button onClick={()=>{}} className="btn mr5 mt10"  >등록</button>
                            <button onClick={()=>{history.goBack()}} className="btn gray ml30">취소</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyMemberAlwns;