import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { nbind } from '../../../js/common'
import Ckeditor from 'ckeditor4-react';

class CKEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
		nbind(this);
	}
	componentDidMount(){
	}
	render() {
		return (
			<React.Fragment>
				<Ckeditor
					onBeforeLoad={(CKEDITOR) => {
						CKEDITOR.disableAutoInline = true
					}}
					data={this.props.data}
					onChange={(e) => { this.setState({ content: e.editor.getData() }); this.props.editorData(e.editor.getData()); }}
					config={{
						// filebrowserBrowseUrl: './browser/browse.php?type=Files',
						// filebrowserUploadUrl: './uploader/upload.php?type=Files',
						customConfig: '',
						contentsCss: '* { margin:0; padding:10 }',
						height: 400,
						fontSize_defaultLabel:'18px',
						extraPlugins: ['font'],
						// toolbar: 'Full',
						//removeButtons: 'Image',
						// allowedContent: true,
						// language: this.iText( `selLang.${this.props.common.lang}`),
					}}
				/>
			</React.Fragment>
		);
	}
}

export default injectIntl(connect(
	state => ({
		user: state.user,
		common: state.common,
	})
)(CKEditor));
// export default CKEditor