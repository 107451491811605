import React,{useState,useEffect,useCallback,useRef} from 'react';
import { API } from 'service.js';
import _ from 'lodash'
import {useInput} from '@/hooks/commonHooks';
import { mobileCheck,emailCheck } from 'js/vaildChecker';
const ReplaceUserPw = () =>{

	const mobileRef = useRef(null),
		  emailRef = useRef(null),
		  idRef = useRef(null);

	const [userId,onChangeUserId] = useInput('');
	const [name, onChangeUserName] = useInput('');
	const [mobilePhoneNumber, onChangeMobileNumber] = useInput('');
	const [email,onChangeEmail] = useInput('');
	const [userVerify,setUserVerify] = useState(false);
	const [password,onChangePassword] = useInput('');
	const [passwordValid,onChangePasswordValid] = useInput('');
	const [passwordMessage, setPasswordMessage] = useState({flag:false,message:''}); 

	const verifyPassword = useCallback(e =>{
		onChangePasswordValid(e);
		if(password != '' && password == e.target.value){
			setPasswordMessage({flag:true,message:'입력하신 비밀번호가 일치합니다.'});
		}else{
			setPasswordMessage({flag:false,message:'입력하신 비밀번호가 일치하지 않습니다.'})
		}
	},[password,passwordValid,passwordMessage]);

	const fetchReplacePassword = useCallback(e =>{
		e.preventDefault();
		if(password != passwordValid) return alert('입력하신 비밀번호가 다릅니다.');
		if(password == '') return alert('비밀번호를 입력 해 주세요.');
		let fetchResult;
		const fetchPassword = API.replacePassword({userId,password});
		fetchPassword.then(res=>{
			fetchResult = window.confirm('비밀번호 재 설정이 완료되었습니다.\n로그인 화면으로 이동 하시겠습니까?')
		}).
		catch(err =>{
			console.log(err);
		}).
		finally(()=>{
			if(fetchResult) window.history.back();
		});

	},[password,passwordValid]);

	const fetchUserCheck = useCallback(e =>{
		e.preventDefault();
		setUserVerify(false);

		if(userId == undefined || userId == ''){
			alert('유저 아이디를 입력 해 주세요.');
			idRef.current.focus();
			return;
		} 

		let mobileValid = mobileCheck(mobilePhoneNumber);
        if(!mobileValid){
            alert('전화번호는 -을 포함한 형식으로 입력 해 주세요.');
            mobileRef.current.focus();
            return;
        }
        let emailValid = emailCheck(email);
        if(!emailValid){
            alert('올바른 이메일 형식이 아닙니다.');
            emailRef.current.focus();
            return;
        }		

		const getUserInfo = API.findUserId({userId,name,mobilePhoneNumber,email});
		getUserInfo.then(res=>{
			if(res.length > 0){
				alert(`변경하실 비밀번호를 입력 해 주세요.`);
				setUserVerify(true);
			}else{
				alert(`입력하신 정보와 일치하는 유저가 존재하지 않습니다`);
			}
		}).
		catch(err =>{
			console.log(err);
		})
	},[userId,name,mobilePhoneNumber,email]);

	return(
		<div id="contents">
			<div className="contentInner">
				<div className="userIdContentWrapper">
					<div className="titleContentWrapper">
						비밀번호 재 설정
					</div>
					<div className="innerContentWrapper" style={{height:'65%'}}>
						<div className='inputWrapper'>
							<div>아이디</div>
							<input ref={idRef} placeholder='비밀번호를 재설정 하고자 하는 아이디를 입력 해주세요.' onChange={e=>onChangeUserId(e)}/>
						</div>
						<div className='inputWrapper'>
							<div>이름</div>
							<input placeholder='가입하신 유저의 성함을 입력 해 주세요' onChange={e=>onChangeUserName(e)}/>
						</div>
						<div className='inputWrapper'>
							<div>전화번호</div>
							<input ref={mobileRef} placeholder='가입하신 유저의 전화번호를 입력 해 주세요' onChange={e=>onChangeMobileNumber(e)}/>
						</div>
						<div className='inputWrapper'>
							<div>이메일</div>
							<input ref={emailRef} placeholder='가입하신 유저의 이메일을 입력 해 주세요' onChange={e=>onChangeEmail(e)} onKeyUp={e=>e.key === 'Enter' ? fetchUserCheck(e) : null}/>
						</div>
						<button className='submitBnt' type='submit' onClick={fetchUserCheck}>사용자 검색</button>
						{userVerify &&
						<>
							<div className='inputWrapper'>
								<div>비밀번호</div>
								<input placeholder='변경하실 비밀번호를 입력 해 주세요.' type='password'  onChange={e=>onChangePassword(e)}/>
							</div>
							<div className='inputWrapper'>
								<div>비밀번호 재 확인</div>
								<input placeholder='변경하실 비밀번호를 다시 입력 해 주세요.' type='password'  onChange={e=>verifyPassword(e)} onKeyUp={e=>e.key === 'Enter' ? fetchReplacePassword(e) : null}/>
							</div>						
							<button className='submitBnt' type='submit' onClick={fetchReplacePassword}>비밀번호 재 설정</button>
							<div style={{color:passwordMessage.flag?'blue':'red'}}>{passwordMessage.message}</div>
						</>
						}
					</div>
				</div>
			</div>
		</div>
	)

}

export default ReplaceUserPw;