import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { getEquipSensorInnerTagObj } from 'components/utils/util';
import { LIGHT, NUTRIENTEQUIP } from 'constants/equipInfo';
import Switch from '../include/switch';
import Message from '../include/message';
import common from 'img/tools_item/common.png'
import { imageTable } from 'components/utils/mapping';
import { API } from 'service';

const NutrientControlModal = ({ data, fetchRes, fetchResControl }) => {

    const [targetData, setTargetData] = useState(data);

    const [driveYn, setDriveYn] = useState(false);

    useEffect(() => {
        setTargetData(data);
    }, [data])

    useEffect(() => {
        initData();
    }, [targetData]);

    const initData = () => {

        try {

            const targetObj = getEquipSensorInnerTagObj(targetData, NUTRIENTEQUIP.status);
            if (targetObj === undefined) throw Error('can\'t find status tag');
            const curStatus = targetObj.tagClass.tagClassActions.find(e => e.value == targetObj.value);
            if (curStatus.name === 'ON') {
                setDriveYn(true)
            }
        } catch (error) {
            alert(error.message);
        }

    }


    const delay = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve()
            }, ms)
        })
    }
    const fetchEquipControl = async () => {

        try {

            setDriveYn(!driveYn)
            fetchResControl(null);
            const { equipClass: { code: equipClassCode, equipProtocolMap: equipClassValue }, equipId, seq } = targetData;
            const actuators = targetData.actuators.find(e => e.tagClass.code === NUTRIENTEQUIP.controlValue);
            if (actuators === undefined) {
                throw Error('actuator is not found');
            }
            const { tagClass: { code: classCode, encoding }, tagId: actId, gatewayMap: { map: address }, bitSeq } = actuators;

            let fetchData = {
                equipClassCode,
                equipId,
                equipClassValue,
                params: [
                    {
                        id: actId,
                        address,
                        classCode,
                        seq,
                        value: driveYn ? 0 : 1,
                        encoding,
                        bitSeq
                    }
                ]
            }

            const fetchResult = await API.equipControl(fetchData);
            if (fetchResult !== 200) {
                throw Error('control Error' + fetchResult);
            }
            fetchResControl(true);
            alert('제어 요청이 완료 되었습니다.');

        } catch (error) {
            alert(error.message)
            setDriveYn(driveYn)
            fetchResControl(false)
        }


    }

    return (
        <>
            <Wrapper>
                <Wrap><Img src={driveYn ? imageTable.NANBANGIMG.on : imageTable.NANBANGIMG.off} /></Wrap>
            </Wrapper>
            <Switch isOn={driveYn} handleChange={fetchEquipControl} />
            {fetchRes !== null &&
                <Message success={fetchRes} />
            }
        </>
    )

}

export default NutrientControlModal;

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`

const Wrap = styled.div`
display:flex ;
flex: 1;
padding:24px 31px;
align-items:center ;

flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
`}

`

const Img = styled.img`
width: 130px ;
height: 130px ;
`
