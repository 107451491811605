import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import cookie from 'react-cookies';
import decode from 'jwt-decode';
import _ from 'lodash'
import login_logo from 'img/login_logo.png'
import Email from 'img/email.png'
import Lock from 'img/LockKey.png'
import styled from 'styled-components'
import Logo from 'img/loginimage/logo.png'

import img1 from 'img/loginimage/com_01.png'
import img2 from 'img/loginimage/com_02.png'
import img3 from 'img/loginimage/com_03.png'
import img4 from 'img/loginimage/com_04.png'

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: '',
			userPw1: '',
			userPw2: '',
			idSave: false,

			loginFailCnt: '', // 로그인 실패 횟수
			loginLockSec: '', // 로그인 잠금 남은 시간

			recaptcha: false,

			// modal
			modalFindPwShow: false,
			modalIdChange: false,
			modalUserId: '',
			modalCertifyCode: '',
			modalEmailCertifyTimer: '',
			modalEmailCertifySec: 0,
			modalEmailCertifyGoalSec: 180,
		};
		nbind(this);
	}
	componentDidMount() {
		cookie.remove('userToken', { path: '/' });
		cookie.remove('userId', { path: '/' });
		sessionStorage.clear();

		let id = cookie.load('autoSaveId');
		if (id) {
			this.setState({
				idSave: true,
				userId: id
			});
			document.getElementById('userId').value = id;
			document.getElementById('userPw1').autofocus = true;
			document.getElementById('idSave').checked = true;
		}
	}
	changeRecaptcha(value) {
		this.setState({ recaptcha: true });
	}
	expiresRecaptcha(e) {
		this.setState({ recaptcha: false });
	}

	tryLogin() {
		let s = this.state;
		let essential = [
			{ message: '아이디를 입력해 주세요.', value: s.userId, ele: document.getElementById('userId') },
			{ message: '비밀번호를 입력해 주세요.', value: s.userPw1, ele: document.getElementById('userPw1') },

		];
		let errorEssential = essential.some((item) => {
			if (item.value === '' || !item.value) {
				this.alert(item.message, '');
				item.ele.focus();
				return true;
			}
			return false;
		});
		// 필수값 체크
		if (errorEssential) {
			return false;
		}
		// if (!this.state.recaptcha ){
		// 	this.alert('reCAPTCHA를 체크해주세요.', 'msg.recaptcha');
		// 	return false;
		// }
		// username:'test1', 
		// password:'test1', 
		// client_id:'farmin', 
		// grant_type:'password',
		// client_secret:'cf4468af-590b-44cd-bbd4-d7bb87acccca',
		// scope:'openid'
		let obj = {
			// usercode:'dd'
			"username": this.state.userId,
			"password": this.state.userPw1,
			"client_id": 'farmin',
			"grant_type": 'password',
			//"client_secret": '0f550e9e-c8fd-4648-9948-89a531efe966',
			"scope": 'openid'
		};
		API.login(obj).then((result) => {
			console.log(result)
			cookie.save('userId', String(s.userId), { path: '/' })
			cookie.save('userToken', String(result.access_token), { path: '/' })

			if (s.idSave) {
				var expires = new Date();
				expires.setDate(expires.getDate() + 365);
				cookie.remove('autoSaveId', { path: '/' });
				cookie.save('autoSaveId', String(s.userId), { path: '/', expires: expires })
			} else {
				cookie.remove('autoSaveId', { path: '/' });
			}


			//jwt 에서 권한 조회
			var token = decode(result.access_token);
			console.log(token.realm_access.roles);

			this.props.handleUserInfo(token);
			if (token.realm_access.roles.includes('ROLE_FARMER')) {
				cookie.save('role', 'farmer', { path: '/' })
				this.props.history.push('/mainUser');
			} else if (token.realm_access.roles.includes('ROLE_CPR')) {
				cookie.save('role', 'corporation', { path: '/' })
				this.props.history.push('/');
			}
			console.log("role==>" + cookie.load('role'));

		}).catch(error => {
			console.log(error);
			console.log(error.message);
			alert('로그인 실패했습니다.\n잠시 후, 아이디와 패스워드를 확인해주세요.',);
		});
	}
	findId() {

	}

	render() {
		return (
			<div style={{ overflow: 'auto' }} id="contents">
				<div className="contentInner">
					<form name="login_frm" className="centerVertical" method="GET" style={{ height: "100%", textAlign: 'center' }} onSubmit={e => e.preventDefault()}>
						<div className="loginWrap">
							<div className='backgroundBox'>
								<div className="verticalBox">
									<h1>
										<img className='logoImg' src={login_logo} />
									</h1>
									<div className="borderInputBox">
										<label className='loginLabel' for="userId"><img src={Email} className='loginIcon' />이메일</label>
										<input
											type="text" id="userId" autoFocus
											onChange={(e) => { this.setState({ userId: e.target.value }); }}
											onKeyPress={(e) => { if (e.key === "Enter") { this.tryLogin() } }}
										/>
									</div>
									<div className="borderInputBox">
										<label className='loginLabel' for="userPw1"><img src={Lock} className='loginIcon' />비밀번호</label>
										<input
											type="password" id="userPw1"
											onChange={(e) => { this.setState({ userPw1: e.target.value }); }}
											onKeyPress={(e) => { if (e.key === "Enter") { this.tryLogin() } }}
										/>
									</div>
									{/* <div className="textRight">
									<div className="checkBox"><input className="login" type="checkbox" name="idSave" id="idSave"/> <label htmlFor="idSave">ID 기억하기</label></div>
								</div> */}
									{/* 								
								<div className="textCenter mt20" style={{color:'#507DE2'}}>
								<Link  style={{color:'#507DE2'}} to="/finduserid">아이디 찾기</Link>
								  	<span className="ml10 mr10">|</span> 
									  <Link  style={{color:'#507DE2'}} to="/findPw">비밀번호 재발급</Link>
									</div> */}

									<button type="button" className="btn btn_login" onClick={() => { this.tryLogin(); }}>로그인</button>
								</div>
							</div>


						</div>
					</form >
					<div className='footer'>
						<div className='wrap'>
							<img className='Logo' style={{ marginRight: '2.2rem' }} src={Logo} />

							<div className='line_wrap'>
								<div className='line'>

									<div className='title'>전주 본사</div>
									<div className='text'> 전주시 덕진구 유상로 67 408호</div>

									<div className='title'>서울 연구소</div>
									<div className='text'>서울특별시 영등포구 문래동3가 55-20 에이스하이테크시티 제 2동 1710호</div>
								</div>
								<div className='line'>
									<div className='title'>문의처</div>
									<div className='text'>063-211-4522</div>

									<div className='title'>팩스</div>
									<div className='text'>063-211-4922</div>

									<div className='title'>메일</div>
									<div className='text'>swms@suhwooms.com</div>


								</div>
							</div>


						</div>
						<div className='wrap'>
							<img style={{ width: '7.2rem', height: '5.2rem' }} src={img1} />
							<img src={img2} />
							<img src={img3} />
							<img src={img4} />
						</div>
					</div>

				</div>
			</div>
		);
	}
}

const Wrap = styled.div`
display: flex;
`

const Title = styled.div``





export default Login;
