import React from 'react';
import { Route, withRouter } from 'react-router-dom';

const Layout2 = ({ component: Component, ...rest }) => {
	return (
		<Route  {...rest} render={matchProps => (
			<div id='wrap'>
				<section id='container' className="layout2">
					<Component {...matchProps} />
				</section>
			</div>
		)} />
	)
};

// class Layout2 extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 		}
// 	}
// 	componentDidMount() {
// 	}
// 	componentWillUnmount() {
// 	}
// 	render() {
// 		const { component: Component, ...rest } = this.props
// 		return (
// 			<Route  {...rest} render={matchProps => (
// 				<div id='wrap'>
// 					<section id='container' className="layout2">
// 						<Component {...matchProps} />
// 					</section>
// 				</div>
// 			)} />
// 		)
// 	}
// }

export default withRouter(Layout2);