import {
  CARBONHEATERPOWER,
  CIRCULATIONFAN,
  HEATEXCHANGER,
  HEATEXCHANGERCO2,
  HVAC,
  HVACTEMP,
  HVACTEMPHUM,
  IRRIGATIONMOTOR,
  LEFTSIDEWINDOW,
  LIGHT,
  NUTRIENTEQUIP,
  PERIODIRRIGATIONMOTOR,
  PERIODSTEPMOTOR,
  RIGHTSIDEWINDOW,
  SECONDLEFTAST,
  TIMECONTROLIRRIGATIONMOTOR,
  TIMECONTROLLIGHT,
} from "constants/equipInfo";

export const convetLocalToUtcDate = (param) => {
  let date = new Date(param);
  let utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(utcDate).toISOString();
};

export const convertUtcToLocalDate = (param) => {
  let date = new Date(param);
  let localDate = new Date();
};

export const getEquipSensorInnerTagValue = (equip, code) => {
  try {
    const target = equip.sensors.find((e) => e.tagClass.code == code);

    if (target === undefined) {
      return "";
    }

    return target.value.toString();
  } catch (error) {
    console.error(error);
    return "";
  }
};

export const getEquipSensorInnerTagObj = (equip, code) => {
  try {
    console.log("equip:", equip);
    console.log("code:", code);
    const target = equip.sensors.find((e) => e.tagClass.code == code);

    if (target === undefined) {
      //   throw Error("innerTag noneTarget");
    }

    return target;
  } catch (error) {
    console.error(error);
    throw Error(error.message);
  }
};

export const integerCheck = (data) => /^[0-9]+$/g.test(data);

export const statusTagControl = (data, classCode) => {
  let tag;
  switch (classCode) {
    case HVACTEMPHUM.equipClass:
      tag = getEquipSensorInnerTagObj(data, HVACTEMPHUM.status);
      break;
    case HVAC.equipClass:
      tag = getEquipSensorInnerTagObj(data, HVAC.status);
      break;
    case TIMECONTROLIRRIGATIONMOTOR.equipClass:
      tag = getEquipSensorInnerTagObj(data, TIMECONTROLIRRIGATIONMOTOR.status);
      break;
    case HEATEXCHANGER.equipClass:
      tag = getEquipSensorInnerTagObj(data, HEATEXCHANGER.status);
      break;
    case HEATEXCHANGERCO2.equipClass:
      tag = getEquipSensorInnerTagObj(data, HEATEXCHANGERCO2.status);
      break;
    case LEFTSIDEWINDOW.equipClass:
    case RIGHTSIDEWINDOW.equipClass:
    case SECONDLEFTAST.equipClass:
      tag = getEquipSensorInnerTagObj(data, LEFTSIDEWINDOW.status);
      break;
    case CARBONHEATERPOWER.equipClass:
      tag = getEquipSensorInnerTagObj(data, CARBONHEATERPOWER.status);
      break;
    case NUTRIENTEQUIP.equipClass:
      tag = getEquipSensorInnerTagObj(data, NUTRIENTEQUIP.status);
      break;
    case CIRCULATIONFAN.equipClass:
      tag = getEquipSensorInnerTagObj(data, CIRCULATIONFAN.status);
      break;
    case LIGHT.equipClass:
      tag = getEquipSensorInnerTagObj(data, LIGHT.status);
      break;
    case TIMECONTROLLIGHT.equipClass:
      tag = getEquipSensorInnerTagObj(data, TIMECONTROLLIGHT.status);
      break;
    case IRRIGATIONMOTOR.equipClass:
      tag = getEquipSensorInnerTagObj(data, IRRIGATIONMOTOR.status);
      break;
    case PERIODSTEPMOTOR.equipClass:
      tag = getEquipSensorInnerTagObj(data, PERIODSTEPMOTOR.status);
      break;
    case PERIODIRRIGATIONMOTOR.equipClass:
      tag = getEquipSensorInnerTagObj(data, PERIODIRRIGATIONMOTOR.status);
      break;
    case HVACTEMP.equipClass:
      tag = getEquipSensorInnerTagObj(data, HVACTEMP.status);
      break;
    default:
      tag = false;
  }

  return tag;
};
