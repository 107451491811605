import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import CKEditor from 'components/layout/include/CKEditor';
import cookie from 'react-cookies'
class Gateway extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email :  '',
			content : '',
			user : '',
            pageIndex:1,
            pageSize : 1,
			list : [],
			keyword : '',
			checkList :[]
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	onChangePage(index) {
		this.setState({ pageIndex: index }, () => alert('페이지변경'));
		this.callData()
	}

	componentDidMount() {
		this.callData()
	}
	searchData (){
		this.setState({pageIndex : 1});
		this.callData()
	}
	callData () {
		let obj = {
			params : {
				userId : cookie.load("userId"),
				size : 10,
				page: this.state.pageIndex -1
			}
		};

		if(this.state.keyword != '' ){
			obj.params['name'] = this.state.keyword
		}
		this.props.setLoading(true)
		API.farmgatewayList(obj).then(result => {
			this.setState({
				list : result.content ,
				pageSize : result.totalPages
			})
			this.props.setLoading(false)
			
		}).catch(error => {
			this.props.setLoading(false)
		});
		
	}
	deleteList() {
		if(this.state.checkList.length < 1){
			alert('선택된 목록이 없습니다.')
		}
		let obj = {
			params : {
				userId : cookie.load("userId"),
				size : 10,
				ids: this.state.checkList
			}
		};

		this.props.setLoading(true)
		API.farmgatewayDelete(obj).then(result => {
			
			this.callData()
			
		}).catch(error => console.log(error));
		
	}
	handleCheckboxChange = event => {
		let newArray = [...this.state.checkList, event.target.value];
		if (this.state.checkList.includes(event.target.value)) {
		  newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		  checkList: newArray
		});
	};
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">프로토콜 종류</h2>
                        <div>
                            <div className="whBox">
                                <div className="searchBox">
									<div className="selectBox">
                                        <select name="" id="" placeholder="제목 + 내용">
                                            <option value="">이름</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>

                                    </div>
                                    <div className="inputBox  ml10">
                                        <input type="text"/>
                                        <button type="button">검색</button>
                                    </div>
                                </div>
                            </div>
                            <div className="tableBox mt30">
                                <table>
									<colgroup>"
										<col width="50"/>
										<col width="50"/>
										<col width="300"/>
										<col width="150"/>
									
										
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>번호</th>
                                            <th>이름</th>
                                            <th>등록일</th>
                                        </tr>
                                    </thead>
                                    <tbody>
										{/* <tr >
                                            <td className="textCenter">
												<div className="checkBox"><input type="checkbox" name="type" id="T4"/> <label htmlFor="T4"></label></div>
											</td>
                                            <td className="textCenter">1</td>
                                            <td className="textCenter"><a href="javascripot:void(0);" onClick={() => { this.props.history.push({ pathname: `/gateway/detail/${3}` }) }}>팜인박스1</a> </td>
                                            <td className="textCenter">팜인박스</td>
                                            <td className="textCenter">url</td>
                                            <td className="textCenter">
												<button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/gateway/devicetag/${3}` }) }}>장치{'&'}태그</button>
											</td>
                                            <td className="textCenter">
												<button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/gateway/logic/${3}` }) }}>로직 조건</button>
											</td>
                                        </tr> */}
										{this.state.list.map((item,index)=>{
											// classCode: "MZZ"
											// delYn: "N"
											// farmId: 2105
											// id: 6
											// installCost: 1
											// installDate: "2021-03-31T15:00:00.000+00:00"
											// ip: "localhost"
											// manager: null
											// modDate: null
											// modId: null
											// name: "강경철"
											// note: null
											// port: 0
											// protocolId: 1
											// readTerm: 60
											// regDate: null
											// regId: null
											// statusChangedYn: "N"
											// useYn: "Y"
											// warrantyEndDate: "2031-03-03T15:00:00.000+00:00"
											return(
												<tr >
													<td className="textCenter">
														<div className="checkBox"><input type="checkbox" name="type" id="T4" value={item.id} onChange={this.handleCheckboxChange} /> <label htmlFor="T4"></label></div>
													</td>
													<td className="textCenter">1</td>
													<td className="textCenter"><a href="javascripot:void(0);" onClick={() => { this.props.history.push({ pathname: `/protocol/detail/${item.id}` }) }}>{item.name}</a> </td>
													<td className="textCenter">2020-12-22</td>
												</tr>
											)
										})}
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to="/protocol/register" className="btn mr10 mt10">등록</Link>
									<button onClick={() =>{ this.deleteList()}} className="btn mr10 mt10">삭제</button>
                                </div>
                                <Pager pageIndex={this.state.pageIndex} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Gateway;