import React, { useEffect, useState } from 'react';

import _ from 'lodash'
import styled from 'styled-components';

import FarmSelect from 'components/dataLookUp/farmSelect'
import OutSideSensor from 'components/popUp/outSideSensor'
import Graph from 'components/popUp/graphComponent'

import { API } from 'service';
import { data2graph, getTimeStamp } from 'components/utils/api'
import { useInput } from 'hooks/commonHooks';
import { saveAs } from 'file-saver';

import Excel from 'exceljs'
import moment from 'moment-timezone';
import { convetLocalToUtcDate } from 'components/utils/util';

const Wrapper = styled.div`
width: 100%;

padding:49px 14% 230px 14%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background-color: #f5f5f5;
`



function Main() {
	const [farm_data, set_farm] = useState([])

	const [sensor, set_sensor] = useState([])
	const [equip, set_equip] = useState([])

	const [graph, set_graph] = useState({})

	const [selectedSensor, set_selectedSensor] = useState([])
	const [selectedEquip, set_selectedEquip] = useState([])
	const [tag2name, set_tag2name] = useState({})

	const [startDay, startDayHanddle, set_startDay] = useInput(getTimeStamp(-30))
	const [endDay, endDayHanddle, set_endDay] = useInput(getTimeStamp())

	const [standardTime, standardTimeHanddle, set_standardTime] = useInput(new Date().getHours() + ':' + new Date().getMinutes())
	const [searchType, searchTypeHanddle, set_searchType] = useInput('daily')
	const [updateTime, set_updateTime] = useState('')

	const [tag2unit, set_tag2unit] = useState({})

	const [nodeName, set_nodeName] = useState('')

	const [tagNodeName, set_tagNodeName] = useState({})
	useEffect(() => {
		API.getContainers().then(val => {
			set_farm(val)
		})
	}, [])

	useEffect(() => {
		if (selectedSensor.length == 0 && selectedEquip.length == 0) {
			set_graph('')
		} else {
			search()
		}
	}, [selectedSensor, selectedEquip, startDay, endDay, standardTime, searchType, updateTime])

	const search = () => {

		let param = makeParam();
		console.log(param);
		if (param)
			API.getSensorData(param).then(val => {
				console.log(val);
				// if(searchType!='hourly'){
				// 	val.map(data=>{data.time= data.time.slice(0,10)})
				// }else{
				// 	val.map(data=>{data.time=data.time.slice(0,10) +' '+data.time.slice(11,-6)+'00'})

				// }
				parseResult(val)
				if (searchType == 'hourly') {
					val.pop();
				}
				// val.map(data => { data.time = data.time.slice(0, 10) + ' ' + data.time.slice(11, -4) })
				set_graph(data2graph(val, selectedSensor, selectedEquip, tag2name, tag2unit))
			}).catch(err => {
				alert('검색 실패. 옵션을 재설정 해주십시오.\n' + err.message)
			})

	}

	const parseResult = (param) => {

		try {
			if (searchType != 'hourly') {
				for (let data of param) {
					data.time = moment(new Date(data.time).toString()).format('YYYY-MM-DD');
				}
			} else {
				for (let data of param) {
					data.time = moment(new Date(data.time).toString()).format('YYYY-MM-DD HH:mm');
				}
			}
			return param;
		} catch (error) {
			throw Error('resultParseError\n' + error.message)
		}

	}

	const setTime = time => {
		// set_selectedSensor([])
		// set_selectedEquip([])
		try {
			let ti = time.slice(0, 10) + " " + time.slice(11, 19)
			set_updateTime(ti)

		} catch (error) {

		}
	}

	const apeendTag = (tag, id) => {
		tag2name[tag] = id
	}
	const apeendTag2unit = (tag, unit) => {
		tag2unit[tag] = unit
	}
	const makeParam = () => {
		let str = ''
		for (let i of selectedSensor) {
			str = str + i + ','
		}
		for (let i of selectedEquip) {
			str = str + i + ','
		}
		str = str.slice(0, -1)

		if (selectedSensor.length == 0 && selectedEquip.length == 0)
			return false

		if (searchType == '') {
			alert('검색타입을 선택해주십시오')
			return false
		}
		else if (startDay == '') {
			alert('시작일을 선택해주십시오')
			return false
		}
		else if (endDay == '') {
			alert('종료일을 선택해주십시오')
			return false
		}
		else if (standardTime == '' && searchType != 'hourly') {
			alert('기준시간을 선택해주십시오')
			return false
		}

		let endDateTime = endDay + 'T00:00:00'
		if (searchType == 'hourly') {
			endDateTime = endDay + 'T23:59:59'
		}
		let stdDate = convetLocalToUtcDate(startDay + 'T00:00:00');
		let endDate = convetLocalToUtcDate(endDateTime);
		let param = {
			tagIds: str,
			startDateTime: stdDate,
			endDateTime: endDate,
			type: searchType,
			stdTime: standardTime
		}

		return param
	}
	//센서/장비 이름 가져오는 함수
	const getNames = (tagId) => {
		let name = ''
		sensor.map(val => {
			val.sensors.map(data => {
				if (data.tagId == tagId) {
					name = val.name
				}
			})
		})

		equip.map(val => {
			val.sensors.map(data => {
				if (data.tagId == tagId) {
					name = val.equipClass.name
				}
			})
		})

		return name
	}
	//엑셀 파일 만드는 함수
	const saveAsExcel = data => {
		const workbook = new Excel.Workbook();
		const worksheet = workbook.addWorksheet("My Sheet");

		worksheet.columns = [
			{ header: 'No', key: 'no', width: 10 },
			{ header: 'NodeName', key: 'nodename', width: 35 },
			{ header: 'Name', key: 'name', width: 15 },
			{ header: 'Date', key: 'date', width: 35 },
			{ header: 'Value', key: 'value', width: 15 },
			{ header: 'Unit', key: 'unit', width: 15 },
		];

		let number = 1
		let beforeTagId = -1

		data.map(val => {
			let tagId = -1
			if (beforeTagId == val.tagId)
				tagId = beforeTagId

			else {
				tagId = val.tagId
				beforeTagId = tagId
				number++
			}

			// let name = getNames(tagId) + ' > ' + tag2name[tagId]
			worksheet.addRow({ no: number, nodename: tagNodeName[tagId], name: tag2name[tagId], date: val.time, value: val.value, unit: tag2unit[tagId] });

		})


		workbook.xlsx.writeBuffer().then(function (data) {
			let blob = new Blob([data], { type: "application/vnd.ms-excel;charset=utf-8" });
			saveAs(blob, "farmIn data.xlsx");

		}).catch(err => console.log(err));

	};

	const graphDownload = () => {


		let param = makeParam()
		if (param)

			API.getSensorData(makeParam()).then(val => {
				if (searchType == 'hourly') {
					val.pop();
				}
				parseResult(val);
				saveAsExcel(val)
			}).catch(err => {
				console.log(err)
				alert('검색 실패. 옵션을 재설정 해주십시오.')
			})

	}

	return (
		<Wrapper >
			<FarmSelect farm={farm_data} set_sensor={set_sensor} set_equip={set_equip} setTime={setTime} set_nodeName={set_nodeName} />

			<OutSideSensor set_tag2name={apeendTag} selectedSensor={selectedSensor} updateTime={updateTime}
				selectedEquip={selectedEquip} set_selectedSensor={set_selectedSensor} isLookUp={true} apeendTag2unit={apeendTag2unit}
				set_selectedEquip={set_selectedEquip} equip={equip} sensor={sensor} tag2name={tag2name} set_tagNodeName={(tagId) => { tagNodeName[tagId] = nodeName }}
			/>

			<Graph download={graphDownload} search={search} graph={graph} startDayHanddle={startDayHanddle} searchTypeHanddle={searchTypeHanddle} set_startDay={set_startDay} set_endDay={set_endDay} isLookUp={true}
				startDay={startDay} endDay={endDay} searchType={searchType} standardTime={standardTime} standardTimeHanddle={standardTimeHanddle} />
		</Wrapper>
	)
}


export default Main;

