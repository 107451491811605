import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { image_mapping, offImage_mapping } from 'components/utils/mapping'


import Swich from './include/switch'
import Message from './include/message'
import { useInput } from 'hooks/commonHooks'







const Straight = styled.div`
display: flex;
`

function Modal(props) {
    const [data, set_data] = useState([])

    const [isOn, set_isOn] = useState(false)
    const [temp, temp_handdle, set_temp] = useInput('')

    const [actionRate, set_actionRate] = useState(0)
    const [settingTemp, settingTemp_handdle, set_settingTemp] = useInput('')

    // title unit data datahandle

    const handleChange = (e) => {
        set_isOn(e.target.checked);
    };

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            props.onClose(e)
        }
    }

    const close = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const array_find = (arr, keyword) => {

        let return_arr = {}
        return_arr = arr.filter(val => {
            if (val.name == keyword) {
                return true
            }
            return false
        })

        if (return_arr.length == 0)
            return_arr = [{ 'value': 'err' }]
        return return_arr[0]
    }

    useEffect(() => {
        if (props.length != 0) {
            set_data(props.data)

            props.sensors.map(val => { console.log(val.name + ':' + val.tagClass.code) })
            if (props.status != 0)
                set_actionRate(props.status.value)



            if (props.status.value == 0)
                set_isOn(false)
            else if (props.status.value == 1)
                set_isOn(true)
        }
    }, [props])

    return (
        <>
            {data.length == 0 ||
                <>
                    <ModalOverlay visible={props.visible} />
                    <ModalWrapper onClick={onMaskClick} visible={props.visible}>
                        <ModalInner>
                            <Top>
                                <Title>{data.name}</Title>
                                <CloseButton onClick={close}>닫기</CloseButton>
                            </Top>

                            <Wrapper>
                                <Wrap>
                                    {actionRate != 0 ?
                                        <>
                                            <Img src={image_mapping[data.equipClass.equipClassId]} />
                                            <TempWrap>
                                                <Status>작동 중 </Status>
                                                <InerPercent> ({actionRate} <Unit>%</Unit> )</InerPercent>

                                            </TempWrap>
                                        </>
                                        :
                                        <>
                                            <Img src={offImage_mapping[data.equipClass.equipClassId]} />
                                            <TempWrap>
                                                <Status style={{ color: "#898C8F" }}>작동정지 </Status>

                                                <InerPercent style={{ color: "#2A3A4B" }}>({actionRate} <Unit>%</Unit>)</InerPercent>
                                            </TempWrap>
                                        </>
                                    }
                                    <Straight>
                                        <button style={{ lineHeight:'4.5rem', minWidth:'11.6rem',height:'4.5rem',backgroundColor: 'white', color: '#17B9B6', border: '1px solid #17B9B6' }} className='btn white mr10'>전체닫기</button>
                                        <button style={{ lineHeight:'4.5rem', minWidth:'11.6rem',height:'4.5rem'}} className='btn'>전체열기</button>
                                    </Straight>
                                    <Message success={true} />


                                </Wrap>


                                <Wrap second>
                                    <Dongzak>동작설정</Dongzak>

                                    {/* title titleColor color unit data handleChange */}


                                    <Spbe>
                                        <Input style={{color:'#2A3A4B',height:'45px',fontSize:'2rem'}} type="number"/>
                                        <Span>
                                            <Text>초 동안</Text>
                                        </Span>
                                    </Spbe>


                                    

                                    <Message success={true} />
                                    <ConfirmWrap>
                                    <Confirm style={{backgroundColor:"#F5F5F5",color:"#4F80E2"}}>닫기</Confirm>
                                    <Confirm>열기</Confirm>
                                    </ConfirmWrap>
                                </Wrap>
                            </Wrapper>
                        </ModalInner>
                    </ModalWrapper>
                </>
            }

        </>
    )
}

const ConfirmWrap=styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
`

const Span = styled.div`

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 22px;

color: #2A3A4B;
height: 100%;
display: flex;
align-items: flex-end;
`
const Spbe = styled.div`
width: 100%;
margin-top: 1rem;
display: flex;
justify-content: space-between;
align-items: flex-end;
padding-top: 3.6rem;
`
const Text = styled.span`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 2rem;
margin: auto;

color: #2A3A4B;
margin-right:1.5rem;
`
const Input = styled.input`
width: 14.6rem;
height: 45px;

background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 5px;

text-align:right ;
padding:5px 12px;

font-family: Spoqa Han Sans Neo;

font-size: 2rem;

/* identical to box height */

`
const Dongzak = styled.div`
display:flex ;
width:100% ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;

color: #747474;
`

const Confirm = styled.button`
display:flex ;
width:15.6rem ;
height: 4.5rem;

background: #4F80E2;
border: 1px solid #4F80E2;
box-sizing: border-box;
border-radius: 5px;

align-items:center ;
justify-content:center ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;

color: #FFFFFF;
`
const TempWrap = styled.div`
width:100% ;
height: 4rem;
display:flex ;
justify-content:center ;
align-items:center ;
margin-bottom:20px ;
`

const InerPercent = styled.div`
display:flex ;

font-family: Spoqa Han Sans Neo;
align-items:flex-end ;
justify-content:flex-end ;
font-weight: 500;
font-size: 2.6rem;
/* line-height: 46px; */
/* identical to box height */


color: #2A3A4B;
`
const Status = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2.6rem;
/* line-height: 46px; */
/* identical to box height */

color: #17B9B6;
`
const Unit = styled.div`
display:flex ;

align-items:flex-end ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #2A3A4B;
`

const ModalWrapper = styled.div`
  box-sizing: border-box;
 

  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const CloseButton = styled.div`
display:flex ;
width: 11.5rem;
height: 4.7rem;

line-height:4.7rem ;
background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;

text-align:center ;
justify-content:center ;

color: #FFFFFF;

cursor:pointer ;
`
const Wrap = styled.div`
display:flex ;
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:2.5rem;
margin-left: 3rem;
`}

`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 25%;
  margin: 0 auto;
  padding: 3.5rem;

  width: 80rem;
    animation: zoomIn 0.2s none;
`


const Top = styled.div`
display:flex ;
justify-content:space-between ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 2.6rem;

line-height: 46px;


color: #2A3A4B;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`


const Img = styled.img`
width: 130px ;
height: 130px ;
`

export default Modal