import React,{useEffect,useState,useCallback,useRef} from 'react';
import { API } from 'service';
export const FarmSearchModal = ({el,apiName,mappingData,modalHandler,keyword,loading}) =>{

    const [searchKeyword , setSearchKeyword] = useState('');
    const [fetchResult,setFetchResult] = useState([]);
    const [searchMessage,setSearchMessage] = useState('');
    const searchRef = useRef(null);

    const searchFetch = useCallback(() =>{
        setSearchMessage('');
        API[apiName]({[keyword] : searchKeyword}).then(res =>{
            setFetchResult(res.content);
            if(res.content.length < 1){
                setSearchMessage('검색 된 농장이 없습니다.');
            }
        })

    },[searchKeyword])

    const mappingController = useCallback(item =>{
        mappingData(item);
        modalHandler();
    },[fetchResult])

    useEffect(()=>{
        searchRef.current.focus();
    },[]);

    return (
        <div id='modal' style={{width:el.getBoundingClientRect().width,height:el.getBoundingClientRect().height,background:'rgba(0,0,0,0.3)',position:'absolute',zIndex:999,justifyContent:'center',alignItems:'center',display:'flex'}}>
            <div style={{display:'flex',flexDirection:'column',background:'#FFFFFF',width:window.innerWidth/2.3,height:window.innerHeight/2.3,padding:10}}>
                <div style={{flex:1,display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div>키워드</div>
                    <input ref={searchRef} 
                            onKeyUp={e => e.key === 'Enter'?searchFetch() : null } 
                            type='text' 
                            onChange={e=>setSearchKeyword(e.target.value)} 
                            value={searchKeyword}
                            style={{width:'70%'}}
                    />
                    <button onClick={searchFetch} style={{marginRight:'30px'}}>검색</button>
                </div>
                <div style={{flex:3,overflow:'auto'}}>
                    {fetchResult.map((item,index) =>{
                        return(
                            <div onClick={()=>mappingController(item)} key={index}
                                 style={{border:'1px solid gray',marginTop:10,padding:10}}
                            >     
                                이름 : {item.name} <br/>
                                주소 : {item.roadNameAddress}
                            </div>
                        )
                    })}
                    {searchMessage != '' ?
                        <div>{searchMessage}</div>
                        :
                        null
                    }
                </div>
                <div style={{flex:1,display:'flex',justifyContent:'center',alignItems:'center',border:'1px solid gray'}}
                    onClick={modalHandler}
                >
                    닫기
                </div>
            </div>
        </div>
    )

}