import React from 'react';
import styled from 'styled-components';
import icon_success from'img/success.png'
import icon_fail from'img/fail.png'


function Main(props) {
    return (
        <MessageWrap>
            <MessageImg src={props.success?icon_success:icon_fail}/>
            <Message success={props.success}>요청 {props.success?'성공':'실패'}</Message>
        </MessageWrap>

    );
}

// E56F6F
const MessageWrap = styled.div`
display:flex ;
align-items:center ;
justify-content:center ;
margin:1.6rem 0;
`
const MessageImg = styled.img`
width:1.8rem ;
height:1.8rem ;
margin-right:3px ;
`
const Message = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 23px;


color: ${props=>props.success?'#2A3A4B':'#E56F6F'};
`
export default Main;