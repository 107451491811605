import axios from 'axios';
import _ from 'lodash';
import cookie from 'react-cookies'
const AbortController = window.AbortController;
const nodeFetch = require('node-fetch');
const qs = require('qs')
const fetchWithTimeout = (uri, options = {}, time = 10000) => {
	// Lets set up our `AbortController`, and create a request options object
	// that includes the controller's `signal` to pass to `fetch`.
	const controller = new AbortController()
	const config = { ...options, signal: controller.signal }
	// Set a timeout limit for the request using `setTimeout`. If the body of this
	// timeout is reached before the request is completed, it will be cancelled.
	//   const timeout = setTimeout(() => {
	//     controller.abort()
	//   }, time)337777y7y,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,	uu,7,
	//console.log('uri',options)

	return fetch(uri, config)
		.then(response => {
			// Because _any_ response is considered a success to `fetch`,
			// we need to manually check that the response is in the 200 range.
			// This is typically how I handle that.
			if (!response.ok) {
				throw new Error(`${response.status}: ${response.statusText}`)
			}
			return response
		})
		.catch(error => {
			//console.log('console',JSON.stringify(error.message))
			if (error.message == '401: ') {
			}
			// When we abort our `fetch`, the controller conveniently throws a named
			// error, allowing us to handle them separately from other errors.
			if (error.name === 'AbortError') {
				throw new Error('Response timed out')
			}
			throw new Error(error.message)
		})
}


//import { APIURI } from './constants';
//var tempurl = window.location.protocol + "//" + window.location.host;
var tempurl = 'https://www.farminsf.com';
// var tempurl = 'http://59.15.133.179:23000';
// var tempurl = 'http://192.168.10.50:8080';
// if(window.location.host == 'localhost:3000') {
// 	//tempurl = 'http://localhost:8080';
// tempurl = 'https://www.farminsf.com';
// 	// var tempurl = 'http://192.168.10.50:8080';
// }
const APIURL = tempurl;
const formAPI = (path, data) => {
	const payload = _.get(data, 'payload', data);
	const userToken = _.get(payload, 'userToken', '');

	return new Promise(
		(resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open('POST', `${APIURL}${path}`);
			xhr.setRequestHeader('oauthtoken', userToken);
			const data = new FormData();
			for (let [key, value] of Object.entries(payload)) {
				if (key !== "userToken") {
					data.append(key, value);
				}
			}
			xhr.send(data);
			xhr.addEventListener('load', () => {
				const response = JSON.parse(xhr.responseText);
				resolve(response);
			});
			xhr.addEventListener('error', () => {
				const error = JSON.parse(xhr.responseText);
				reject(error);
			});
		}
	);
};

const noneTokenAPI = (path, data, method) => {
	console.log(data);
	console.log(JSON.stringify(data));
	const body = method === 'POST' || method === 'PUT' || method === 'DELETE' ? JSON.stringify(data) : undefined;

	return fetchWithTimeout(`${APIURL}${path}`, {
		//crossDomain:true,
		//credentials: 'include',
		//mode: 'no-cors',
		method,
		headers: {
			// 'Access-Control-Allow-Origin': '*',
			// 'Access-Control-Allow-Headers': 'application/json',
			"Content-Type": "application/json; charset=UTF-8",
			// 'JWT-TOKEN': userToken,
		},
		body,
	}, 10000000)
		.then(response => {
			// token = response.headers.get('x-favorit-auth-token')
			let result = response.json()

			// result.then(function (data) {
			// 	let code = data.status;
			// 	switch ( code ) {
			// 		case -1000: { common.nbind.alert('이미 존재하는 아이디입니다.\n변경후, 다시 시도해 주십시오.', ``); break; }
			// 		case -1100: { common.nbind.alert('인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.', ``); break; }
			// 		default: {goReturn = true; break;}
			// 	}
			// })
			return result
		})
		.then(json => {

			//console.log(json)
			return json
		})
		.catch(e => {
			console.log(e);
			throw new Error(e)
		});

}

const commonAPI = (path, data, method) => {
	const cookieData = {
		id: cookie.load('userId'),
		token: cookie.load('userToken'),
	}
	const payload = _.get(data, 'payload', data);
	const userToken = _.get(cookieData, 'token', '')
	//console.log('userToken',userToken)
	let token = '';

	// api 통신할 때 obj에서 같이 던지면 됨
	// const userCode = _.get(payload, 'userCode', '');
	const body = method === 'POST' || method === 'PUT' || method === 'DELETE' ? JSON.stringify(payload) : undefined;

	// console.log(APIURL)
	return fetchWithTimeout(`${APIURL}${path}`, {
		//crossDomain:true,
		//credentials: 'include',
		//mode: 'no-cors',
		method,
		headers: {
			// 'Access-Control-Allow-Origin': '*',
			// 'Access-Control-Allow-Headers': 'application/json',
			"Content-Type": "application/json; charset=UTF-8",
			// 'JWT-TOKEN': userToken,
			"Authorization": `Bearer ${userToken}`
		},
		body,
	}, 10000000)
		.then(response => {
			// token = response.headers.get('x-favorit-auth-token')
			let result = response.json()

			// result.then(function (data) {
			// 	let code = data.status;
			// 	switch ( code ) {
			// 		case -1000: { common.nbind.alert('이미 존재하는 아이디입니다.\n변경후, 다시 시도해 주십시오.', ``); break; }
			// 		case -1100: { common.nbind.alert('인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.', ``); break; }
			// 		default: {goReturn = true; break;}
			// 	}
			// })
			return result
		})
		.then(json => {

			//console.log(json)
			return json
		})
		.catch(e => {
			console.log(e)
			throw new Error(e)
		});
};

const commonGETAPI = (path, data, method) => {
	const cookieData = {
		id: cookie.load('userId'),
		token: cookie.load('userToken'),
	}
	const payload = _.get(data, 'payload', data);
	const userToken = _.get(cookieData, 'token', '')
	//console.log('userToken',userToken)
	let token = '';

	// api 통신할 때 obj에서 같이 던지면 됨
	// const userCode = _.get(payload, 'userCode', '');
	let uri = new URL(APIURL + path)
	console.log(data)

	if (data.params) {

		Object.keys(data.params).forEach(key => uri.searchParams.append(key, data.params[key]))
		// console.log('uri',data.params)
		// console.log('uri',uri)
	}


	return fetchWithTimeout(uri, {
		//crossDomain:true,
		//credentials: 'include',
		//mode: 'no-cors',
		method,
		headers: {
			// 'Access-Control-Allow-Origin': '*',
			// 'Access-Control-Allow-Headers': 'application/json',
			"Content-Type": "application/json; charset=UTF-8",
			// 'JWT-TOKEN': userToken,
			"Authorization": `Bearer ${userToken}`
		},
	}, 10000000)
		.then(response => {
			// token = response.headers.get('x-favorit-auth-token')
			let result = response.json()

			// result.then(function (data) {
			// 	let code = data.status;
			// 	switch ( code ) {
			// 		case -1000: { common.nbind.alert('이미 존재하는 아이디입니다.\n변경후, 다시 시도해 주십시오.', ``); break; }
			// 		case -1100: { common.nbind.alert('인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.', ``); break; }
			// 		default: {goReturn = true; break;}
			// 	}
			// })
			return result
		})
		.then(json => {

			//console.log(json)
			return json
		})
		.catch(e => {

			throw new Error(e)
		});
};

const loginAPI = (data) => {
	const URL = APIURL + '/auth/realms/farmin/protocol/openid-connect/token';
	//const URL = 'https://www.farminsf.com/auth/realms/farmin/protocol/openid-connect/token';
	const method = 'POST';
	const headers = 'application/x-www-form-urlencoded';
	const body = data
	// 'client_id' : 'farmin',
	// 'grant_type' : 'password',
	// 'client_secret' : 'cf4468af-590b-44cd-bbd4-d7bb87acccca',
	// 'scope' : 'openid',
	// 'username' : 'man1',
	// 'password' : 'man1'
	const options = {
		method: method,
		headers: headers,
		body: qs.stringify(body)
	}

	return fetch(URL, {
		method: method,
		headers: {
			'Content-Type': headers
		},
		body: qs.stringify(body)
	})
		.then(resp => {
			let json = resp.json(); // there's always a body
			if (resp.status >= 200 && resp.status < 300) {
				return json;
			} else {
				return json.then(Promise.reject.bind(Promise));
			}
		})
		.catch(err => {
			console.log(err)
		})
}




const commonGetAPI = (path, data) => {
	return commonAPI(path, data, 'GET');
};
const commonPostAPI = (path, data) => {
	return commonAPI(path, data, 'POST');
};


//Content-Type: multipart/form-data; 용 함수




const formDataCommonAPI = (path, data, method) => {
	const cookieData = {
		id: cookie.load('userId'),
		token: cookie.load('userToken'),
	}

	const payload = _.get(data, 'payload', data);
	const userToken = _.get(cookieData, 'token', '')
	let token = '';

	console.log(payload)
	console.log(JSON.stringify(payload))
	const body = method === 'POST' || method === 'PUT' || method === 'DELETE' ? JSON.stringify(payload) : undefined;



	const api = axios.create({
		baseURL: `${APIURL}`,
		headers: {
			"Content-Type": "multipart/form-data; boundary=something",
			"Authorization": `Bearer ${userToken}`

		}
	})


	if (method === 'PUT') {
		return api.put(`${path}`, data)
	}
	else if (method === 'POST') {
		return api.post(`${path}`, data)

	}



};




const broad = (path, data, method = 'GET') => {


	const cookieData = {
		id: cookie.load('userId'),
		token: cookie.load('userToken'),
	}
	const payload = _.get(data, 'payload', data);
	const userToken = _.get(cookieData, 'token', '')
	//console.log('userToken',userToken)
	let token = '';

	// api 통신할 때 obj에서 같이 던지면 됨
	// const userCode = _.get(payload, 'userCode', '');
	const body = method === 'POST' || method === 'PUT' || method === 'DELETE' ? JSON.stringify(payload) : undefined;

	// console.log(APIURL)
	return fetchWithTimeout(`${APIURL}/api/v2/proxy?url=http://apis.data.go.kr/1360000/AsosHourlyInfoService/getWthrDataList?serviceKey=MpffWbyESXu6xFXDnMnPsb0vw0aVzQSVwGhzUygpquHxSWHaWLwimjzU2JEY3iFq7upV9ZCdVcQQIrpy0Z0jpg%3D%3D&${path}`, {
		//crossDomain:true,
		//credentials: 'include',
		//mode: 'no-cors',
		method,
		headers: {
			// 'Access-Control-Allow-Origin': '*',
			// 'Access-Control-Allow-Headers': 'application/json',
			"Content-Type": "application/json; charset=UTF-8",
			// 'JWT-TOKEN': userToken,
			"Authorization": `Bearer ${userToken}`
		},
		body,
	}, 10000000)
		.then(response => {
			// token = response.headers.get('x-favorit-auth-token')
			let result = response.json()

			// result.then(function (data) {
			// 	let code = data.status;
			// 	switch ( code ) {
			// 		case -1000: { common.nbind.alert('이미 존재하는 아이디입니다.\n변경후, 다시 시도해 주십시오.', ``); break; }
			// 		case -1100: { common.nbind.alert('인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.', ``); break; }
			// 		default: {goReturn = true; break;}
			// 	}
			// })
			return result
		})
		.then(json => {

			//console.log(json)
			return json
		})
		.catch(e => {
			console.log(e)
			throw new Error(e)
		});
};




const getPlayloadResult = (response) => _.get(response, 'payload.value[0].Result', false);
const getPlayloadMessage = (response) => _.get(response, 'payload.value[0].Message', false);
const getPlayloadError = (response) => _.get(response, 'payload.value[0]', {});
const getPlayloadErrorMessage = (response) => _.get(response, 'payload.value[0].Message', 'Unkown Error');
const getMessage = (response) => _.get(response, 'value[0].Message', false);
const getError = (response) => _.get(response, 'value[0].Message', {});
const getErrorMessage = (response) => _.get(response, 'value[0].Message', 'Unkown Error');

export const API = {
	// ********************************************************************************
	// 						API 수정시 systemlog 파일도 반영할 것
	// ********************************************************************************

	userInfo: data => commonGetAPI('/users/me', data), // 토큰 유효성 검사 
	userChange: data => commonAPI('/users/me', data, 'PUT'), // 회원정보 수정
	pwChange: data => commonAPI('/users/me/passwords', data, 'POST'), // 회원정보 수정

	//'
	login: data => loginAPI(data),
	join: data => commonPostAPI('/users', data),  // 회원가입 

	//메인화면

	// 
	getContainers: data => commonGetAPI(`/api/v2/user/places/tags`),
	getWeather: data => commonGetAPI(`/api/v2/dateinfo?longitude=${data.longitude}&latitude=${data.latitude}`),
	getNotice: data => commonGetAPI(`/api/v2/notices/pages?size=${data.size}&page=${data.page}`),
	getQnA: data => commonGetAPI(`/api/v2/qnas/pages?size=${data.size}&page=${data.page}`),
	getFaQ: data => commonGetAPI(`/api/v2/faqs/pages?size=${data.size}&page=${data.page}`),
	getOnlyContainer: data => commonGetAPI(`/api/v2/user/places/${data.id}/tags`),

	getSensorData: data => commonGetAPI(`/api/v2/sensorrecords/pointavg?tagIds=${data.tagIds}&startDateTime=${data.startDateTime}&endDateTime=${data.endDateTime}&type=${data.type}&stdTime=${data.stdTime}`),
	testgetSensorData: data => commonGetAPI(`/api/v2/sensorrecords/pointavg?tagIds=16543,16548&startDateTime=2022-02-10T00:00:00Z&endDateTime=2022-02-26T00:00:00Z&type=daily&stdTime=02:00`),

	settingSensorNotification: data => commonPostAPI(`/api/v2/user/tagranges`, data),
	getNoticePost: data => commonGetAPI(`/api/v2/notices/${data.Id}`),
	//장치 조회 
	getEquip: (data) => commonGetAPI(`/api/v2/user/equips/${data.equipId}/tags`),

	//기상청
	// getBroad:(data)=>broad(`pageNo=1&numOfRows=999&dataType=JSON&dataCd=ASOS&dateCd=HR&startDt=${data.startDt}&startHh=${data.startHh}&endDt=${data.endDt}&endHh=${data.endHh}&stnIds=${data.stnIds}`),
	getBroad: (data) => broad(`pageNo=1&numOfRows=999&dataType=JSON&dataCd=ASOS&dateCd=HR&startDt=${data.startDt}&startHh=${data.startHh}&endDt=${data.endDt}&endHh=${data.endHh}&stnIds=108`),

	//파일




	//관제화면
	localList: data => commonAPI(`/api/v1/treenodes/regions?userId=${data.userId}`, data, 'GET'), // 
	localFarmList: data => commonAPI(`/api/v1/farms?userId=${data.userId}&treeNodeId=${data.treeNodeId}`, data, 'GET'),// 
	farmDetail: data => commonAPI(`/api/v1/farms?userId=${data.userId}&id=${data.farmId}`, data, 'GET'),
	productsList: data => commonAPI(`/api/v1/products/counts?userId=${data.userId}`, data, 'GET'),// 
	sensorsTotal: data => commonAPI(`/api/v1/sensors/hd?userId=${data.userId}`, data, 'GET'),//전체 증산 현황	


	containerList: data => commonAPI(`/api/v1/containers?userId=${data.userId}&farmId=${data.farmId}`, data, 'GET'),//
	sensorInfo: data => commonAPI(`/api/v1/sensors?userId=${data.userId}&containerId=${data.containerId}`, data, 'GET'),//
	containerHD: data => commonAPI(`/api/v1/cpr/sensors?containerId=${data.containerId}&classCode=HD`, data, 'GET'),
	eventCount: data => commonAPI(`/api/v1/eventrecords?userId=${data.userId}&container=${data.containerId}&startTime=${data.startDate}&endTime=${data.endDate}`, data, 'GET'),// 

	hdChartSensors: data => commonAPI(`/api/v1/sensors?userId=${data.userId}`, data, 'GET'),
	hdChartDataSensors: data => commonAPI(`/api/v1/sensors?userId=${data.userId}&farmId=${data.farmId}`, data, 'GET'),
	mainChartSensors: data => commonAPI(`/api/v1/sensors/records?bucketName=farmin&containerId=${data.containerId}`, data, 'GET'),
	farmChartSensors: data => commonAPI(`/api/v1/sensors/records?bucketName=farmin&farmId=${data.farmId}&classCode=${data.classCode} `, data, 'GET'),

	hdTotal: data => commonAPI(`/api/v1/sensors/records?bucketName=farmin&farmId=${data.farmId}&classCode=AHUM&classCode=DRYTEMP`, data, 'GET'),//

	innerSencor: data => commonAPI(`/api/v1/sensors/records/minavgmax?bucketName=farmin&farmId=${data.farmId}&startTime=2021-04-07T13:15:20&endTime=2022-03-29T13:15:20&classCode=DRYTEMP&classCode=RHUM`, data, 'GET'),//

	alramList: data => commonAPI(`/api/v1/eventrecords?userId=${data.userId}&farmId=2105&limitNum=10`, data, 'GET'),// /api/v1/eventrecords?farmId=2105&limitNum=10&userId=test1
	increaseStatus: data => commonAPI(`/api/v1/sensors/records/minavgmax?bucketName=farmin&farmId=${data.farmId}&classCode=HD`, data, 'GET'),
	divisionStatus: data => commonAPI(`/api/v1/sensors/records/minavgmax?bucketName=farmin&farmId=${data.farmId}`, data, 'GET'),

	weatherData: data => commonAPI(`https://www.farminsf.com//apiapi/v1/treenodes/regions?userId=${data.userId}`, data, 'GET'),
	//관제화면 end

	weatherToday: data => commonAPI(`/api/v1/weather/today?latitude=${data.latitude}&longitude=${data.longitude}`, data, 'GET'),
	todayInfo: data => commonAPI(`/api/v1/todayInfo?latitude=${data.latitude}&longitude=${data.longitude}`, data, 'GET'),//https://www.farminsf.com//apiapi/v1/todayInfo?latitude=37.516371&longitude=126.906882

	//메인 서비스 운영사
	getCprId: data => commonAPI(`/api/v1/members/cprs?userId=${data.userId}`, data, 'GET'),


	notice: data => commonAPI(`/api/v1/notices/pages?userId=${data.userId}&size=6`, data, 'GET'),
	qna: data => commonAPI(`/api/v1/qnas/pages?userId=${data.userId}&size=6`, data, 'GET'),

	farmMap: data => commonAPI(`/api/v1/cprs/farms?cprId=${data.cprId}&userId=${data.userId}`, data, 'GET'),


	serviceStatus: data => commonAPI(`/api/v1/cprs/allcounts?cprId=${data.cprId}`, data, 'GET'),
	deviceCount: data => commonAPI(`/api/v1/cprs/allcounts?cprId=${data.cprId}`, data, 'GET'),

	serviceJoinContainer: data => commonAPI(`/api/v1/cprs/containers/counts?cprId=${data.cprId}&regDate=${data.date}T00:00:00`, data, 'GET'),
	serviceJoinMember: data => commonAPI(`/api/v1/members/counts?cprId=${data.cprId}&regDate=${data.date}T00:00:00`, data, 'GET'),

	cprSensor: data => commonAPI(`/api/v1/cpr/sensors?cprId=${data.cprId}&classCode=HD`, data, 'GET'),

	mainAlramList: data => commonAPI(`/api/v1/cprs/eventrecords?cprId=${data.cprId}&solutionYn=N&limitNum=5`, data, 'GET'),
	mainAlramList2: data => commonAPI(`/api/v1/cprs/eventrecords?cprId=${data.cprId}&solutionYn=Y&limitNum=5`, data, 'GET'),

	companyMemberDelete: data => commonAPI(`/api/v1/members/membercpralwnc`, data, 'DELETE'),
	companyMemberEdit: data => commonAPI('/api/v1/members', data, 'PUT'),
	//메인 서비스 운영사 end


	//농장주 


	userFarmList: data => commonAPI(`/api/v1/farms?userId=${data.userId}`, data, 'GET'),
	dateInfo: data => commonAPI(`/api/v1/todayInfo?latitude=${data.latitude}&longitude=${data.longitude}`, data, 'GET'),
	farmHdSensorList: data => commonAPI(`/api/v1/sensors?userId=${data.userId}&farmId=${data.farmId}&classCode=${data.classCode}`, data, 'GET'),//
	farmActuatorList: data => commonAPI(`/api/v1/actuators?userId=${data.userId}&farmId=${data.farmId}`, data, 'GET'),
	eventRecordList: data => commonAPI(`/api/v1/farms/eventrecords?startTime=${data.startTime}&endTime=${data.endTime}&farmId=${data.farmId}`, data, 'GET'),
	eventRecordYorNList: data => commonAPI(`/api/v1/farms/eventrecords?startTime=${data.startTime}&endTime=${data.endTime}&farmId=${data.farmId}&solutionYn=${data.solutionYn}`, data, 'GET'),
	actuatorRecordList: data => commonAPI(`/api/v1/actuators/records?bucketName=farmin&startTime=${data.startTime}&endTime=${data.endTime}&farmId=${data.farmId}`, data, 'GET'),
	actuatorList: data => commonAPI(`/api/v1/actuators?userId=${data.userId}&farmId=${data.farmId}`, data, 'GET'),
	amPmWeather: data => commonAPI(`/api/v1/weather/today?latitude=${data.latitude}&longitude=${data.longitude}`, data, 'GET'),
	farmSensorList: data => commonAPI(`/api/v1/sensors?userId=${data.userId}&farmId=${data.farmId}&classCode=${data.classCode[0]}&classCode=${data.classCode[1]}&classCode=${data.classCode[2]}&classCode=${data.classCode[3]}`, data, 'GET'),
	farmUserList: data => commonGETAPI('/api/v1/cprs/members/pages', data, 'GET'),
	farmUserInfo: data => commonGETAPI(`/api/v1/members/${data.id}`, data, 'GET'),

	//게이트 웨이
	farmgatewayList: data => commonGETAPI(`/api/v1/farmgateways/pages`, data, 'GET'),
	farmgatewayRegiste: data => commonAPI(`/api/v1/farmgateways`, data, 'POST'),
	farmgatewayDetail: data => commonAPI(`/api/v1/farmgateways/${data.id}`, data, 'GET'),
	farmgatewayUpdate: data => commonAPI(`/api/v1/farmgateways`, data, 'PUT'),
	farmgatewayDelete: data => commonAPI('/api/v1/farmgateways', data, 'DELETE'),

	faramList: data => commonAPI('/api/v1/protocols', data, 'GET'),
	protocol: data => commonAPI('/api/v1/protocols', data, 'GET'),
	gatewayType: data => commonAPI('/api/v1/codes?majorCode=FARMGATEWAY', data, 'GET'),//https://www.farminsf.com/api/v1/

	// device& tag
	//단일농장정보
	farmDetail: data => commonAPI(`/api/v1/farms/${data.id}`, data, 'GET'), // https://www.farminsf.com/api/v1/farms/1
	deviceTypeList: data => commonAPI(`/api/v1/equipclass/all`, data, 'GET'),
	gatewayDevice: data => commonGETAPI(`/api/v1/cprs/equips/pages`, data, 'GET'), ///api/v1/cprs/equips/pages?farmGatewayId=1
	gatewayTag: data => commonGETAPI(`/api/v1/cprs/tags/pages`, data, 'GET'), ///api/v1/cprs/equips/pages?farmGatewayId=1
	tagTypeList: data => commonAPI(`/api/v1/tagclass/all`, data, 'GET'),

	gatewayContainerList: data => commonAPI(`/api/v1/containers?farmGatewayId=${data.id}`, data, 'GET'),
	gatewayDeviceList: data => commonAPI(`/api/v1/cprs/equips?farmGatewayId=${data.id}`, data, 'GET'),

	deviceDetail: data => commonAPI(`/api/v1/equips/${data.id}`, data, 'GET'),
	deviceRegiste: data => commonAPI('/api/v1/equips', data, 'POST'),
	deviceDelete: data => commonAPI('/api/v1/equips', data, 'DELETE'),
	deviceUpdate: data => commonAPI('/api/v1/equips', data, 'PUT'),

	tagDetail: data => commonAPI(`/api/v1/tags/${data.id}`, data, 'GET'),
	tagRegiste: data => commonAPI('/api/v1/tags', data, 'POST'),
	tagDelete: data => commonAPI('/api/v1/tags', data, 'DELETE'),
	tagUpdate: data => commonAPI('/api/v1/tags', data, 'PUT'),

	//notice
	noticeList: data => commonGETAPI(`/api/v1/notices/pages`, data, 'GET'),
	noticeDetail: data => commonAPI(`/api/v2/notices/${data.id}`, data, 'GET'),
	noticeDelete: data => {
		console.log(data);
		return commonAPI(`/api/v2/notices`, data, 'DELETE')
	},
	noticeUpdate: (data, id) => formDataCommonAPI(`/api/v2/notices/${id}`, data, 'PUT'),
	noticeImage: data => formDataCommonAPI(`api/v2/files/images`, data, 'POST'),

	noticeRegiste: data => formDataCommonAPI(`/api/v2/notices`, data, 'POST'),
	//faq
	faqList: data => commonGETAPI(`/api/v1/faqboards/pages`, data, 'GET'),
	faqDetail: data => commonAPI(`/api/v2/faqs/${data.id}`, data, 'GET'),
	faqDelete: data => {
		return commonAPI(`/api/v2/faqs`, data, 'DELETE')
	},

	faqUpdate: (data, id) => formDataCommonAPI(`/api/v2/faqs/${id}`, data, 'PUT'),
	faqRegiste: data => formDataCommonAPI(`/api/v2/faqs`, data, 'POST'),
	//qna

	qnaList: data => commonGETAPI(`/api/v1/qnas/pages`, data, 'GET'),
	qnaDetail: data => commonAPI(`/api/v1/qnas/${data.id}`, data, 'GET'),
	qnaDelete: data => commonAPI(`/api/v1/qnas`, data, 'DELETE'),
	qnaUpdate: data => commonAPI(`/api/v1/qnas`, data, 'PUT'),
	qnaRegiste: data => commonAPI(`/api/v1/qnas`, data, 'POST'),

	commentRegiste: data => commonAPI(`/api/v1/qnacomments`, data, 'POST'),
	commentDelete: data => commonAPI(`/api/v1/qnacomments`, data, 'DELETE'),

	//container
	containerDetail: data => commonAPI(`/api/v1/containers/${data.id}`, data, 'GET'),
	containerClass: data => commonAPI('/api/v1/codes?majorCode=CONTAINER', data, 'GET'),
	containerUpdate: data => commonAPI(`/api/v1/containers`, data, `PUT`),
	containerInsert: data => commonAPI(`/api/v1/containers`, data, `POST`),

	// product
	productList: data => commonGETAPI(`/api/v1/products/pages`, data, 'GET'),
	productDelete: data => commonAPI(`/api/v1/products`, data, 'DELETE'),
	productDetail: data => commonAPI(`/api/v1/products/${data.id}`, data, 'GET'),
	productClassList: data => commonAPI('/api/v1/codes/3depth?minorCode=PRODUCT', data, 'GET'),
	productUpdate: data => commonAPI(`/api/v1/products`, data, 'PUT'),
	productInsert: data => commonAPI(`/api/v1/products`, data, 'POST'),

	// actuatorLog
	findActuatorLogPagesInCpr: data => commonGETAPI(`/api/v1/cprs/actuatorlogs/pages`, data),
	findActuatorLogPages: data => commonGETAPI(`/api/v1/actuatorlogs/pages`, data),

	// eventRocord
	eventRecordListWithInfoInCpr: data => commonGETAPI(`/api/v1/cprs/eventrecords/pages`, data, 'GET'),
	eventRecordListWithInfo: data => commonGETAPI(`/api/v1/eventrecords/pages`, data, 'GET'),

	// loginLog
	loginLogList: data => commonGETAPI(`/api/v1/members/login/logs`, data, 'GET'),

	// ======================================================

	getResult: response => getPlayloadResult(response),
	getData: response => getPlayloadMessage(response),
	getErr: response => getPlayloadError(response),
	getErrMsg: response => getPlayloadErrorMessage(response),
	parseData: response => getMessage(response),
	parseErr: response => getError(response),
	parseErrMsg: response => getErrorMessage(response),


	// 농장관리 ================================================================================================
	// 농장 리스트

	fetchFarms: data => commonGETAPI('/api/v1/cprs/farms/pages', data, 'GET'),
	fetchFarmName: data => commonAPI(`/api/v1/cprs/farms/pages?name=${data.farmName}`, data, 'GET'),
	// 농장 등록
	fetchFarmsRegiste: data => commonAPI(`/api/v1/farms`, data, 'POST'),
	// 농장수정
	fetchFarmsDetail: data => commonAPI(`/api/v1/farms/${data.id}`, data, 'GET'),
	fetchFarmsUpdate: data => commonAPI(`/api/v1/farms`, data, 'POST'),
	//농장 삭제
	fetchFarmsDelete: data => commonAPI(`/api/v1/farms`, data, 'DELETE'),

	//농장관리 테이블 안 농장정보 버튼 ===========================================================================

	//농장시설동조회
	farmContainerList: data => commonGETAPI(`/api/v1/containers/pages`, data, 'GET'),
	//농장 사용자 접근 조회
	farmUseraccess: data => commonGETAPI(`/api/v1/memberfarmalwncs/pages`, data, 'GET'),

	//농장시설동 삭제
	farmContainerDelete: data => commonAPI(`/api/v1/containers`, data, 'DELETE'),

	//사용자 삭제
	farmUserDelete: data => commonAPI(`/api/v1/memberfarmalwncs`, data, 'DELETE'),


	//농장 접근 사용자 등록
	farmMebers: data => commonAPI(`/api/v1/memberfarmalwncs`, data, 'POST'),
	// ID조회
	farmMemberExists: data => commonAPI(`/api/v1/cprs/members/exist?userId=${data.id}`, data, 'GET'),
	//농장 접근 가능 사용자 단일 조회
	farMemberaccess: data => commonAPI(`/api/v1/memberfarmalwncs/${data.id}`, data, 'GET'),
	//농장 접근 사용자 수정
	farmMemberUpdate: data => commonAPI(`/api/v1/memberfarmalwncs`, data, 'PUT'),


	// userInfo
	farmerInfo: data => commonAPI(`/api/v1/cprs/members/exist?userId=${data.userId}`, null, 'GET'),
	userInfoUpdate: data => commonAPI(`/api/v1/members`, data, 'PUT'),


	findUserId: ({ userId = '', name = '', mobilePhoneNumber = '', email = '' }) => {
		let queryString = '';
		if (userId != '') queryString = `userId=${userId}&`;
		if (name != '') queryString += `name=${name}&`;
		if (mobilePhoneNumber != '') queryString += `mobilePhoneNumber=${mobilePhoneNumber}&`;
		if (email != '') queryString += `email=${email}`;

		return noneTokenAPI(`/api/v1/public/members?${queryString}`, null, "GET");
	},
	replacePassword: data => noneTokenAPI(`/api/v1/public/members/password`, data, 'PUT'),


	// equipControl
	equipControl: fetchData => commonAPI(`/api/v2/control`, fetchData, 'POST'),

	udpateTagValue: (tagId, value) => commonAPI(`/api/v2/admin/tags/${tagId}`, { value }, 'PUT'),

	proxy: (targetUrl) => commonAPI(`/api/v2/proxy?url=${targetUrl}`, null, 'get'),
};
