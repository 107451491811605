import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ItemWrap from './itemWrapper'
const Wrapper=styled.div`
display:flex ;
width:100% ;
min-width:1300px ;
background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
height: 253px;
padding:30px 20px ;
margin-bottom: 1.6rem;
`
const Line =styled.div`
width: 0px;
height: 204px;
border: 0.1px solid rgba(201,201,201,0.2);
transform: translateY(-10px);
margin-left:1.2rem;
/* transform: translateX(-20px); */

`


function Main(props) {
    useEffect(()=>{
        set_firstGrid(props.farm)
    },[props])
    const [firstGrid,set_firstGrid]=useState([])
    const [secondGrid,set_secondGrid]=useState([])
    const [thirdGrid,set_thirdrid]=useState([])
    const [fourthGrid,set_fourthGrid]=useState([])

    const [firstGridName,set_firstGridName]=useState('')
    const [secondGridName,set_secondGridName]=useState('')
    const [thirdGridName,set_thirGdridName]=useState('')
    const [fourthGridName,set_fourthGridName]=useState('')

    const [reload_secondGrid,set_reload_secondGrid]=useState(false)
    const [reload_thirdGrid,set_reload_thirdrid]=useState(false)
    const [reload_fourthGrid,set_reload_fourthGrid]=useState(false)

   

    const firchClick=(val=>{
        set_secondGrid(val)
        set_thirdrid([])
        set_fourthGrid([])
    })

    const secondClick=(val=>{
        set_thirdrid(val)
        set_fourthGrid([])
    })
    // set_nodeName
    useEffect(()=>{
        if(props.set_nodeName!=undefined){
            props.set_nodeName(firstGridName)
        }
    },[firstGridName])

    useEffect(()=>{
        if(props.set_nodeName!=undefined){
            props.set_nodeName(secondGridName)
        }
    },[secondGridName])

    useEffect(()=>{
        if(props.set_nodeName!=undefined){
            props.set_nodeName(thirdGridName)
        }
    },[thirdGridName])

    useEffect(()=>{
        if(props.set_nodeName!=undefined){
            props.set_nodeName(fourthGridName)
        }
    },[fourthGridName])
    


    return (
        <Wrapper>

            {firstGrid.length==0||<>
            <ItemWrap first={true} grid="0" set_reload={()=>set_reload_secondGrid(!reload_secondGrid)} setTime={props.setTime} set_GridName={set_firstGridName}
            title="농장"set_equip={props.set_equip} set_sensor={props.set_sensor}onClickFn={firchClick} farm={firstGrid}/> 
            <Line/>
            </>}

            {secondGrid.length==0?<ItemWrap farm={[]}/>:
            <ItemWrap set_reload={()=>set_reload_thirdrid(!reload_thirdGrid)} reload={reload_secondGrid} grid="1" setTime={props.setTime} set_GridName={set_secondGridName}
            title={`${firstGridName} > 시설동` }set_equip={props.set_equip} set_sensor={props.set_sensor}onClickFn={secondClick} farm={secondGrid}/>}

            {thirdGrid.length==0||<>
            <Line/> 
            <ItemWrap set_reload={()=>set_reload_fourthGrid(!reload_fourthGrid)} reload={reload_thirdGrid} grid="2" setTime={props.setTime} set_GridName={set_thirGdridName}
            title={`${secondGridName} > 외부박스` } set_equip={props.set_equip} set_sensor={props.set_sensor}onClickFn={set_fourthGrid} farm={thirdGrid}/>
            </>}

            {fourthGrid.length==0||<>
            <Line/>
            <ItemWrap set_reload={()=>{}} reload={reload_fourthGrid} grid="3" setTime={props.setTime}  set_GridName={set_fourthGridName}
            title={`${thirdGridName} > 내부박스` }  set_equip={props.set_equip} set_sensor={props.set_sensor}onClickFn={()=>{}} farm={fourthGrid}/>
            </>}

        </Wrapper>


      );
}

export default Main;