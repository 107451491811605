import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class deviceDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			gatewayInfo : {
				name : null
			},
			containerList : [],
			typeList : [],
			
            tagTypeList : [],
			gatewayDeviceList : [],
			list : [
				{
                    "name": "",
                    "classCode": "",
                    "equipId" : null,
                    "protocolMap":null
                }
                    
			],
			count : 1
		};
		nbind(this);
	}
	
	componentDidMount() {
		this.dataList()
	}
	dataList(){		
		let obj = {
			id : this.props.match.params.id
		}
		API.gatewayContainerList(obj).then(result => {
			this.setState({containerList : result})	
		}).catch( error => console.log(error));
        API.tagTypeList(obj).then(result => {
            this.setState({tagTypeList : result})
		}).catch(error => console.log(error));
		
        API.gatewayDeviceList(obj).then(result => {
            this.setState({gatewayDeviceList : result})
		}).catch(error => console.log(error));
	}
	addItem (){
		
		let list = [];
		console.log(this.state.count)
		for( var i = 0;  i < this.state.count; i++ ){
			list.push({
                "name": null,
                "classCode": null,
                "equipId" : null,
                "protocolMap":null
            })
		}
        list = this.state.list.concat(list)
        console.log(list)
        

		this.setState({list})
	}
	save(){
		
        this.props.setLoading(true)
        for(let i in this.state.list){
			let t = this.state.list[i]
			
			
			if(!this.nullCheck(t.name) || !this.nullCheck(t.equipId) || !this.nullCheck(t.classCode) ){
				window.alert('이름,분류,소속장치를 모두 입력해주세요.')
				this.props.setLoading(false)
				return false
			}
		}
        API.tagRegiste(this.state.list).then(result => {
            console.log(result)
			this.setState({
                //params : result
           	})
			this.props.setLoading(false)
			this.props.history.push({ pathname: `/gateway/devicetag/${this.props.match.params.id}` }) 
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
    }
	deleteItem(index){
		let list = this.state.list
		list.splice(index,1)
		this.setState({list})
	}
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">태그 등록하기</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox  mt30">
                                <table>
									<colgroup>
										<col width="50"/>
										<col width="150"/>
										<col width="160"/>
										<col width="150"/>
										<col width="150"/>
										<col width="100"/>
									</colgroup>
                                    <thead>
                                        <tr>
											<th>번호</th>
                                            <th>이름</th>
                                            <th>분류</th>
                                            <th>소속장치</th>
                                            <th>프로토콜 맵</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
										
                                         {	
											this.state.list.map((item, index) => {
												return (

												<tr key={index} >
														
													<td className="bold textCenter">{(index +1)}</td>
													<td>
														<input type="text" value={this.state.list[index].name} onChange={(e) => {
															let data = this.state.list; 
															data[index].name = e.target.value///////////
															this.setState({ list: data })
														}}/>
													</td>
                                                    <td className="textCenter">
														<div className="selectBox">
																<select value={this.state.list[index].classCode} name={index} onChange={(e) => {
																		let data = this.state.list; 
                                                                        console.log(index)
																		data[index].classCode = e.target.value///////////
																		this.setState({ list: data })
                                                                        console.log(data)
																	}} >
																	<option value="">분류 선택</option>
																	{this.state.tagTypeList.map((item,index)=>{
																	return(
																			<option value={item.code}>{item.name}</option>
																	)
																})}
															</select>
														</div>
													</td>
													<td className="textCenter">
														<div className="selectBox">
																<select value={item.equipId} onChange={(e) => {
																		let data = this.state.list; 
																		data[index].equipId = e.target.value///////////
																		this.setState({ list: data })
																	}} >
																	<option value="">장치 선택</option>
																	{this.state.gatewayDeviceList.map((item,index)=>{
																		return(
																				<option value={item.id}>{item.name}</option>
																		)
																	})}
																</select>
														</div>
													</td>
													<td>
                                                        <input type="text" value={item.protocolMap} onChange={(e) => {
															let data = this.state.list; 
															data[index].protocolMap = e.target.value///////////
															this.setState({ list: data })
														}}/>
                                                    </td>
                                                    <td className="textCenter">
														{	this.state.list.length > 1?
															<button onClick={()=>{this.deleteItem(index)}} className="btn gray ml30">삭제</button>
															: null
														}
														
													</td>
												</tr>
												)
											})
										}
                                    </tbody>
                                </table>
								<div className="textCenter mt30">
									행개수
									<input type="number" className="ml10" style={{width:"100px"}} name="" id="" value={this.state.count} onChange={(e)=>{this.setState({count:e.target.value})}} />
									<button onClick={()=>{this.addItem()}} className="btn ml10 mt10">추가</button>
									
                            	</div>
							</div>
                                 
                            <div className="textCenter mt30">
                                <button onClick={()=>{this.save()}} className="btn mr5 mt10">등록</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default deviceDetail;