export default {
	"menu.stockExchange": "거래소2",
	"menu.myInfo": "내 정보2",
	"menu.myInfo.requestWithdrawal": "입출금 신청2",
	"menu.myInfo.tradeResultList": "거래결과 내역확인2",
	"menu.myInfo.profitList": "수익률 내역2",
	"menu.myInfo.changeUserInfo": "회원 정보 수정2",
	"menu.myInfo.account": "계정 정보관리2",
	"menu.myInfo.userCertify": "회원인증2",
	"menu.myInfo.invitedList": "추천인 목록2",
	"menu.myInfo.invitedProfit": "추천 수익2",
	"menu.customerService": "고객센터2",
	"menu.customercommon.notice": "공지사항2",
	"menu.customerService.event": "이벤트2",
	"menu.customerService.guide": "이용방법2",
	"menu.customerService.faq": "FAQ2",
	"menu.customerService.enquiry": "1:1 문의2",
	"menu.login": "로그인2",
	"menu.join": "회원가입2",

	"footer1": "상호 : 주식회사 비트팡 | 사업자등록번호 :123-456-78978 | 대표자:비트팡 개인정보보호담당자: 홍길동2",
	"footer2": "서울시 서울동 서울로 123-45 3층 | Tel. 02-1235-4568 | E-mail ceo@bitpang.kr | 이용약관 | 개인정보취금방침2",
	"footer3": "copyriygt (c) 2020 bitpang ALLRIGHT RESERVED2",

	"main.stockExchangeShortcut": "거래소 바로가기2",
	'main.noticeExplain': '차세대 트레이딩 플랫폼,\n비트팡의 공지사항을 안내해드립니다.2',
	"main.guideExplain": "안전하고 편리한 비트팡을 경험해보세요.\n비트팡의 사용방법을 안내해드립니다.2",
	"main.question": "문의사항2",
	"main.questionExplain": "궁금하신 것은 언제든지 문의주세요.2",
	"main.otimizedForChorme": "본 서비스는 Chrome에 최적화 되어있습니다.2",

	"join.allAgree": "모두 동의2",
	"join.termsAgree": "이용 약관 동의 [ 필수 ]2",
	"join.privacyPolicyAgree": "개인정보 처리방침 동의 [ 필수 ]2",
	"join.marketingAgree": "마케팅 정보 수신동의 [ 선택 ]2",
	"join.recommender": "추천인2",
	"join.recommenderPh": "추천인 코드 입력2",
	"join.id": "ID (E-mail)2",
	"join.idPh": "이메일 주소 입력2",
	"join.duplicateCheck": "중복체크2",
	"join.authenticationCode": "인증코드2",
	"join.authenticationCodePh": "인증코드 입력2",
	"join.sendCode": "코드발송2",
	"join.certifyCode": "코드인증2",
	"join.pw": "비밀번호2",
	"join.pwPh": "비밀번호 입력2",
	"join.pwConfirm": "비밀번호 확인2",
	"join.pwConfirmPh": "비밀번호 재입력2",
	"join.pwPolicy": "영문+숫자+특수문자($@$!%*#?^&) 조합 9~20자를 입력해 주시기 바랍니다.​2",
	"join.secondaryPw": "2차 비밀번호2",
	"join.secondaryPwPh": "2차 비밀번호 입력2",
	"join.secondaryPwConfirm": "2차 비밀번호 확인2",
	"join.secondaryPwConfirmPh": "2차 비밀번호 재입력2",
	"join.secondaryPwPolicy": "2차 비밀번호는 숫자 6자리로 구성됩니다.2",
	"msg.errorCode": "인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.2",
	"join.successJoin": "회원가입이 완료되었습니다.2",

	"login.saveId": "아이디 저장2",
	"login.findId": "아이디 찾기2",
	"login.findPw": "비밀번호 찾기2",
	"login.findPw2": "2차 비밀번호 찾기2",
	"login.nowPw": "현재 비밀번호2",
	"login.nowPw2": "현재 2차 비밀번호2",
	"login.changePw": "변경할 비밀번호2",
	"login.changePw2": "변경할 2차 비밀번호2",
	"login.changePwCheck": "변경할 비밀번호 확인2",
	"login.changePw2Check": "변경할 2차 비밀번호 확인2",

	"myInfo.deposit": "입금하기2",
	"myInfo.withdraw": "출금하기2",
	"myInfo.withdrawal": "입출금 거래내역2",
	"myInfo.wallet": "고객 전용 Wallet 주소2",
	"myInfo.howDepositRequest": "입금 신청 방법2",
	"myInfo.howDepositRequestDesKRW1": "1. 고객 전용 Wallet 주소를 확인하시고 해당 주소로 입금하실 금액을 이체2",
	"myInfo.howDepositRequestDesKRW2": "2. 시스템 운영자의 확인 후, 자산내역에 반영됨​2",
	"myInfo.depositWarningBTC1": "1. 본인의 보유액을 다른 회원에게 양도 및 양수할 수 없습니다.2",
	"myInfo.depositWarningBTC2": "2. 고객 ID, 고객명은 수정할 수 없습니다.​2",
	"myInfo.depositWarningBTC3": "3. 고객의 과실에 대한 타 계좌로의 입금은 당사에서 책임지지 않습니다.입금 시, 꼭 지갑 주소를 정확히 확인해 주시기 바랍니다.​2",
	"myInfo.depositWarningBTC4": "4. 입 / 출금 관련 문의는 평일 11: 00~19: 00 가능합니다(주말 및 공휴일 휴무)​2",
	"myInfo.depositWarningBTC5": "5. 1 일 산정 기준은 매일 00: 00~23: 59(Local Time) 입니다.​2",
	"myInfo.withdrawAddress": "출금 지갑 주소2",
	"myInfo.withdrawRequestAmount": "출금 신청액2",
	"myInfo.requestDateTime": "신청 일시2",
	"myInfo.price": "금액2",
	"myInfo.targetWallet": "대상 지갑주소2",
	"myInfo.confirmDateTime": "승인일시2",
	"myInfo.startTime": "시작시간2",
	"myInfo.categorize": "구분2",
	"myInfo.status": "상태2",
	"myInfo.request": "요청2",
	"myInfo.confirm": "승인2",
	"myInfo.reject": "반려2",
	"myInfo.sending": "완료2",
	"myInfo.success": "성공2",
	"myInfo.fail": "요청2",
	"myInfo.baseIndex": "기준지수2",
	"myInfo.endDate": "종료시간2",
	"myInfo.contractPrice": "거래대금2",
	"myInfo.result": "결과2",
	"myInfo.totalRegUser": "총 가입회원2",
	"myInfo.totalInvitedFee": "누적 추천 수익2",
	"myInfo.regDate": "가입일2",
	"myInfo.userId": "회원 아이디2",
	"myInfo.noInvitedList": "추천인 목록이 없습니다.2",
	"myInfo.periodTotalInvitedFee": "기간별 총 누적 추천 수익2",
	"myInfo.periodInvitedFee": "기간별 누적 추천 수익2",
	"myInfo.rejectReason": "반려 사유2",
	"myInfo.userOut": "회원 탈퇴2",
	"myInfo.kycReset": "KYC 인증 초기화2",
	"myInfo.phoneChange": "휴대전화 변경2",
	"myInfo.phoneCertify": "휴대전화 인증2",
	"myInfo.countryCode": "국가번호2",
	"myInfo.phone": "휴대전화2",
	"myInfo.phoneNum": "휴대전화 번호2",
	"myInfo.codeCreate": "인증번호 발급2",
	"myInfo.code": "인증번호2",
	"myInfo.grossTransactionAmount": "총 거래금액2",
	"myInfo.grossTransactionProfit": "총 거래수익2",
	"myInfo.grossYield": "총 수익률2",
	"myInfo.tradingDay": "거래일2",
	"myInfo.dailyTurnover": "일별 거래액2",
	"myInfo.dailyGainsAndLosses": "일별 손익액2",
	"myInfo.dailyTransactionFee": "일별 거래 수수료2",
	"myInfo.totalRateOfReturn": "총 수익률2",
	"myInfo.myCode": "고객 코드2",
	"myInfo.userName": "회원 이름2",
	"myInfo.name": "이름2",
	"myInfo.firstName": "이름2",
	"myInfo.lastName": "성2",
	"myInfo.birthday": "생년월일2",
	"myInfo.depositWallet": "입금 지갑주소2",
	"myInfo.letsCertify": "인증하기2",
	"myInfo.sendCode": "인증번호 받기2",
	"myInfo.howWithdrawRequest": "출금 신청 방법2",
	"myInfo.howWithdrawRequestDesBTC1": "1. “출금 지갑 주소”에 출금하실 대상 지갑 주소를 입력2",
	"myInfo.howWithdrawRequestDesBTC2": "2. “출금 신청액”에 출금하실 금액 입력2",
	"myInfo.howWithdrawRequestDesBTC3": "3. “출금 신청” 버튼 클릭​2",
	"myInfo.withdrawWarningBTC1": "1. 본인의 보유액을 다른 회원에게 양도 및 양수할 수 없습니다.2",
	"myInfo.withdrawWarningBTC2": "2. 고객 ID, 고객명은 수정할 수 없으며, 출금을 원하시는 금액을 ‘출금 신청액’ 항목에 입력하시면 됩니다.​2",
	"myInfo.withdrawWarningBTC3": "3. 고객의 과실에 대한 타 계좌로의 입금은 당사에서 책임지지 않습니다. 출금 시, 꼭 지갑 주소를 정확히 확인해 주시기 바랍니다.​2",
	"myInfo.withdrawWarningBTC4": "4. 1일 출금 한도는 2BTC이며, 1회 출금 한도는 0.5 BTC 입니다.​2",
	"myInfo.withdrawWarningBTC5": "5. 출금 시에는 출금 수수료가 발생합니다(0.001 BTC). 또한, 출금 신청금액이 2 BTC 이상일 시 고객센터로 연락주시기 바랍니다.​2",
	"myInfo.withdrawWarningBTC6": "6. 입/출금 관련 문의는 평일 11:00~19:00 가능합니다(주말 및 공휴일 휴무)​2",
	"myInfo.withdrawWarningBTC7": "7. 1일 산정 기준은 매일 00:00~23:59(Local Time)입니다.​2",
	"myInfo.withdrawRequest": "출금 신청2",
	"myInfo.withdrawAfterPhoneKycCertify": "출금은 휴대전화와 KYC 인증을 한 후, 이용할 수 있습니다.2",
	"myInfo.inputWithdrawAddressBTC": "출금 지갑 주소를 입력해 주세요.2",
	"myInfo.inputWithdrawAmount": "출금 신청액을 입력해 주세요.2",
	"myInfo.errorMaxDayAmountBTC": "출금 신청액이 1일 출금 최대 금액을 초과했습니다.\n(1일 출금 최대 금액: {max} BTC, 오늘 누적 출금 신청금액: {today} BTC)2",
	"myInfo.errorMaxOnceAmountBTC": "출금 신청액이 1회 출금 최대 금액을 초과했습니다.\n(1회 출금 최대 금액: {max} BTC)2",
	"myInfo.errorTotalAmountBTC": "총 출금 신청액(출금 신청액+수수료)이 보유금액을 초과했습니다.\n(수수료: {fee}) BTC2",
	"myInfo.errorMinOnceAmountBTC": "출금 신청액이 1회 출금 최소 금액을 초과했습니다.\n(1회 출금 최소 금액: {min} BTC)2",
	"myInfo.inputWithdrawCode": "인증번호를 입력해 주세요.2",
	"myInfo.withdrawReqeustSuccess": "출금 신청이 완료되었습니다.2",
	"myInfo.withdrawReqeustFail": "출금 신청이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"myInfo.kycCertify": "KYC 인증2",
	"myInfo.certify": "인증2",
	"myInfo.uncertify": "미인증2",
	"myInfo.certifySuccess": "인증완료2",
	"myInfo.audit": "심사중2",
	"myInfo.unrequest": "미신청2",
	"myInfo.reset": "초기화2",
	"myInfo.country": "국적2",
	"myInfo.gender": "성별2",
	"myInfo.passportNum": "여권번호2",
	"myInfo.passportImg": "여권사진2",
	"myInfo.frontImg": "여권을 든 정면사진2",
	"myInfo.certifyRequest": "인증신청2",
	"myInfo.pictureInfo": "촬영안내2",
	"myInfo.pictureExplain1": "1. 신분증에 성명, 촬영일을 메모해서 붙여주세요.​2",
	"myInfo.pictureExplain2": "2. 신분증에 노출되는 주민등록번호 뒤 7자리와 여권번호는 가려주세요.​2",
	"myInfo.pictureExplain3": "3. 신분증과 얼굴이 잘 보이도록 촬영해 주세요.​2",
	"myInfo.warning": "주의사항2",
	"myInfo.warningExplain1": "1. 신분증의 복사본 또는 화면 캡쳐본은 인정되지 않습니다.​2",
	"myInfo.warningExplain2": "2. 촬영일을 기준으로 제출일 이전에 찍은 사진은 반려됩니다.​2",
	"myInfo.warningExplain3": "3. 메모가 부탁되지 않았거나 주민등록번호, 여권번호가 모두 노출된 경우 반려됩니다.​2",
	"myInfo.warningExplain4": "4. 제출된 사진은 비대면 실명확인 용도로만 사용되며, 시스템에 의해 확인 후 자동 파기됩니다.​2",
	"myInfo.warningExplain5": "5. 이미지 파일은 jpg, png, jpeg 의 형식이며, 파일 사이즈는 최대 10mb 미만만 업로드 가능합니다.​2",
	"myInfo.userOutWarning1": "탈퇴 후, 귀하는 귀하의 계정에 액세스 및 거래를 수행하거나 어떠한 Bit Pang 서비스도 이용할 수 없게 됩니다.2",
	"myInfo.userOutWarning2": " 이 요청을 제출하기 전에 계정에서 사용 가능한 자금을 인출하는 것이 좋습니다.2",
	"myInfo.userOutWarning3": "계약 및 관련 법에 따라 당사는 귀하의 개인 데이터 삭제를 진행할 수 있으며 동시에 당사 서비스를 귀하에게 제공할 수 없습니다.2",

	"service.title": "제목2",
	"service.content": "내용2",
	"service.file": "첨부파일2",
	"service.regDate": "작성시간2",
	"service.viewCount": "조회수2",
	"service.answerYn": "답변여부2",
	"service.answerContent": "답변내용2",
	"service.categorize": "분류2",
	"myInfo.saveMoney": "보유금액2",
	"service.commentChecking": "미답변2",
	"service.commentSuccess": "답변완료2",
	"service.fileFormExplain": "파일명, 지원형식은 jpg / png / jpeg 이며, 크기는 5 mb를 초과할 수 없습니다.2",
	"service.enquiryCategorize1": "비트팡 거래소 관련2",
	"service.enquiry.exchangeQ1": "Q. 매수, 매도란 무엇인가요?2",
	"service.enquiry.exchangeA1": "A. 매수: \n\r시작가(n)에 n Point를 증가한 n 이상의 값에 도달했을 때, 실현(매도 실격) ​되는 것을 말합니다.​ 매도: 시작가(n) 에 n Point를 감소한 n 이하의 값에 도달했을 때, 실현(매수 실격)​ 되는 것을 말합니다.2",
	"service.enquiry.exchangeQ2": "Q. 질문22",
	"service.enquiry.exchangeA2": "A. 답변22",
	"service.enquiry.exchangeQ3": "Q. 질문32",
	"service.enquiry.exchangeA3": "A. 답변32",
	"service.enquiry.exchangeQ4": "Q. 질문42",
	"service.enquiry.exchangeA4": "A. 답변42",
	"service.enquiry.exchangeQ5": "Q. 질문52",
	"service.enquiry.exchangeA5": "A. 답변52",
	"service.enquiry.exchangeQ6": "Q. 질문62",
	"service.enquiry.exchangeA6": "A. 답변62",
	"service.enquiryCategorize2": "입출금 관련2",
	"service.enquiry.withdrawalQ1": "Q. 내 정보에 입력했던 계좌와 다른 계좌번호로 입출금2",
	"service.enquiry.withdrawalA1": "A. 답변12",
	"service.enquiry.withdrawalQ2": "Q. 질문22",
	"service.enquiry.withdrawalA2": "A. 답변22",
	"service.enquiry.withdrawalQ3": "Q. 질문32",
	"service.enquiry.withdrawalA3": "A. 답변32",
	"service.enquiry.withdrawalQ4": "Q. 질문42",
	"service.enquiry.withdrawalA4": "A. 답변42",
	"service.enquiry.withdrawalQ5": "Q. 질문52",
	"service.enquiry.withdrawalA5": "A. 답변52",
	"service.enquiry.withdrawalQ6": "Q. 질문62",
	"service.enquiry.withdrawalA6": "A. 답변62",

	"join.codeCheck": "코드 확인2",

	"login.pwFind": "비밀번호 찾기2",
	"login.id": "ID(이메일)2",
	"login.tempPwCreate": "임시 비밀번호 발급2",
	"login.tempPw2Create": "임시 2차 비밀번호 발급2",

	"msg.myInfoAfterLogin": "내 정보는 로그인 후 확인 가능합니다.2",
	"msg.stockExchangeAfterLogin": "거래소 서비스 이용은 로그인 후 가능합니다2",
	"msg.nowPwInput": "현재 비밀번호를 입력해 주세요.2",
	"msg.nextPwInput": "변경할 비밀번호를 입력해 주세요.2",
	"msg.pwPolicy": "비밀번호는 8~16자 영문, 숫자, 특수문자를 사용해야 합니다.2",
	"msg.pwNotMatch": "비밀번호가 일치하지 않습니다.2",
	"msg.nextPw2Input": "변경할 2차 비밀번호를 입력해 주세요.2",
	"msg.pw2Policy": "2차 비밀번호는 6자리의 숫자를 사용해야 합니다.2",
	"msg.pw2NotMatch": "2차 비밀번호가 일치하지 않습니다.2",
	"msg.pwChangeSuccess": "비밀번호가 변경되었습니다.2",
	"msg.nowPwNotMatch": "현재 비밀번호가 일치하지 않습니다.2",
	"msg.kycResetSuccess": "KYC 인증이 초기화되었습니다.2",
	"msg.kycResetFail": "KYC 인증 초기화가 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.alreadyCodeSend": "이미 인증번호가 전송됐습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.codeSendSuccess": "인증코드를 전송했습니다.2",
	"msg.selectCountryCode": "국가번호를 선택해 주세요.2",
	"msg.inputPhoneNum": "휴대전화 번호를 입력해 주세요.2",
	"msg.inputPhoneNumOnlyNum": "휴대전화 번호는 숫자만 입력해 주세요.2",
	"msg.inputCode": "인증번호를 입력해 주세요.2",
	"msg.phoneCertifySuccess": "휴대전화 인증이 완료되었습니다.2",
	"msg.codeNotMatch": "인증코드가 일치하지 않습니다.\n확인 후, 다시 시도해 주십시오.2",
	"msg.userOutSuccess": "회원 탈퇴가 완료되었습니다.2",
	"msg.userOutFail": "회원 탈퇴에 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.doYouResetKyc": "KYC 인증을 초기화 하시겠습니까?2",
	"msg.doYouOutUser1": "주의: 회원을 탈퇴하시면 보유하신 모든 자산은 삭제됩니다.\n회원 탈퇴를 계속 하시겠습니까?2",
	"msg.doYouOutUser2": "주의: 회원의 계정과 모든 개인 데이터를 삭제합니다. 계속 하시겠습니까?2",
	"msg.imgUploadWarning": "이미지 파일은 jpg, png, jpeg의 형식이며, 파일 사이즈는 최대 10MB 미만만 업로드 가능합니다.2",
	"msg.inputFirstName": "이름을 입력해 주세요.2",
	"msg.inputLastName": "성을 입력해 주세요.2",
	"msg.selectCountry": "국적을 선택해 주세요.2",
	"msg.inputBirthday": "생년월일을 입력해 주세요.2",
	"msg.inputBirthday2": "생년월일(YYMMDD)을 입력해 주세요.2",
	"msg.inputBirthdayOnlyNum": "생년월일은 숫자만 입력해야 합니다.2",
	"msg.inputBirthdayFormat": "생년월일 형식에 맞게 입력해야 합니다.2",
	"msg.selectGender": "성별을 선택해 주세요.2",
	"msg.inputPassportNum": "여권번호 입력해 주세요.2",
	"msg.inputPassportNumOnlyNum": "여권번호는 숫자만 입력해야 합니다.2",
	"msg.selectPassportImg": "여권사진을 선택해 주세요.2",
	"msg.kycRequestSuccess": "KYC 인증을 신청했습니다.2",
	"msg.kycRequestFail": "KYC 인증 신청에 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.doYouRegThisQuestion": "해당 문의사항을 등록하시겠습니까?2",
	"msg.regSuccess": "등록되었습니다2",
	"msg.servicePreparing": "서비스 준비중입니다.2",
	"msg.inputInvitedCode": "추천인 코드를 입력해 주세요.2",
	"msg.invitedCodeCheckSuccess": "유효한 추천인 코드입니다.2",
	"msg.invitedCodeCheckFail": "유효하지 않은 추천인 코드입니다.2",
	"msg.inputId": "아이디를 입력해 주세요.2",
	"msg.notMatchIdForm": "아이디가 이메일 형식에 맞지 않습니다.2",
	"msg.idCheckSuccess": "사용 가능한 아이디입니다.2",
	"msg.idNeedCheck": "아이디 중복체크가 되지 않았습니다.2",
	"msg.codeSendAndInfoCheck": "인증번호를 발송했습니다.\n인증번호가 오지 않으면 입력하신 정보가 회원정보와 일치하는지 확인해 주세요.2",
	"msg.inputPw": "비밀번호를 입력해 주세요.2",
	"msg.inputPw2": "2차 비밀번호를 입력해 주세요.2",
	"msg.invitedCodeNeedCheck": "추천인 코드체크를 해주십시오.2",
	"msg.essentialValueNeedCheck": "필수값이 체크되지 않았습니다.\n체크한 후 다시 시도해 주십시오.2",
	"msg.alreadyIdExist": "이미 존재하는 아이디입니다.\n변경후, 다시 시도해 주십시오.2",
	"msg.userJoinFail": "회원가입이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.emailSend": "이메일이 발송됐습니다.2",
	"msg.dormantAccount": "휴면 계정입니다.2",
	"msg.notJoinUserOrNotMatchPw": "가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.2",
	"msg.loginFail": "로그인이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.emailSendFail": "이메일 발송에 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.tempPwSendSuccess": "임시 비밀번호가 발급됐습니다.2",
	"msg.tempPw2SendSuccess": "임시 2차 비밀번호가 발급됐습니다.2",
	"msg.notJoinUser": "존재하지 않는 아이디입니다.2",
	"msg.tempPwSendFail": "임시 비밀번호가 발급이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.tempPw2SendFail": "임시 2차 비밀번호가 발급이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.youNeedLogin": "로그인 후 이용이 가능한 서비스입니다.2",
	"msg.logoutSuccess": "로그아웃 되었습니다.2",
	"msg.notLogin": "로그인 상태가 아닙니다.2",
	"msg.logoutFail": "로그아웃이 실패했습니다.\n잠시 후, 다시 시도해 주십시오.2",
	"msg.doYouLogout": "로그아웃하시겠습니까?2",

	"common.confirm": "확인2",
	"common.cancel": "취소2",
	"common.login": "로그인2",
	"common.logout": "로그아웃2",
	"common.view": "보기2",
	"common.search": "검색2",
	"common.find": "찾기2",
	"common.enterKeyword": "검색어 입력2",
	"common.list": "목록2",
	"common.title": "제목2",
	"common.register": "등록하기2",
	"common.shortRegister": "등록2",
	"common.change": "변경2",
	"common.close": "닫기2",
	"common.call": "Call2",
	"common.push": "Push2",
	"common.deposit": "입금2",
	"common.withdraw": "출금2",
	"common.callSuccess": "Call 실현2",
	"common.pushSuccess": "Push 실현2",
	"common.callFail": "Call 실격2",
	"common.pushFail": "Push 실격2",
	"common.proceeds": "수익금2",
	"common.time": "시간2",
	"common.number": "No.2",
	"common.warning": "경고2",
	"common.certify": "인증2",
	"common.no": "No.2",
	"common.none": "없음2",
	"common.noneSelect": "- 선택 -2",
	"common.guideDetail": "이용 약관 상세보기2",
	"common.privacyDetail": "개인정보 처리방침 상세보기2",
	"common.marketingDetail": "마케팅 정보 상세보기2",

	"select.searchType.title": "제목2",
	"select.searchType.content": "내용2",
	"select.searchType.titleAndContent": "제목+내용2",
	"select.gender.male": "남성2",
	"select.gender.female": "여성2",
	"select.enquiryCategory1": "비트팡 거래소 관련2",
	"select.enquiryCategory2": "입출금 관련2",
	"select.enquiryCategory3": "거래 및 정산 관련2",
	"select.enquiryCategory4": "게시판 관련2",
	"select.enquiryCategory5": "회원 등록 및 수정, 탈퇴, 기타2",
	"select.enquiryCategory6": "추천인 관련2",
	"select.enquiryCategory7": "KYC 및 AML 인증 관련2",

	"table.none.no": "내역이 없습니다.2",
	"table.none.noMatch": "조건과 일치하는 리스트가 없습니다.2",

	"pager.first": "처음2",
	"pager.prev": "이전2",
	"pager.next": "다음2",
	"pager.last": "마지막2",

	"selLang.ko": "ko2",
	"selLang.en": "en2",

}
