import React from 'react';
import { FormattedMessage } from 'react-intl';

function iText(des, id, values) {
	return <FormattedMessage id={id}/>;
}
// export const selUserSearch = [
// 	{ value: '1', label: '아이디' },
// 	{ value: '2', label: '연락처' },
// ];
// export const selLang = Intl([
// 	{ value: 'ko', label: this.iText('한국어', 'select.lang.ko') },
// 	{ value: 'en', label: this.iText('영어', 'select.lang.en') },
// ]);
export const selUserSearch = [
	{ value: '1', label: '아이디' },
	{ value: '2', label: '연락처' },
];
export const selSearchType = [
	{ value: '1', label: iText('제목', `select.searchType.title`) },
	{ value: '2', label: iText('내용', `select.searchType.content`) },
	{ value: '3', label: iText('제목+내용', `select.searchType.titleAndContent`) },
];
export const selLang = [
	{ value: 'ko', label: iText('한국어', `select.lang.ko`) },
	{ value: 'en', label: iText('영어', `select.lang.en`) },
];
export const selGender = [
	{ value: '', label: iText('- 선택 -', `common.noneSelect`) },
	{ value: '1', label: iText('남성', `select.gender.male`) },
	{ value: '0', label: iText('여셩', `select.gender.female`) },
];
export const selInqueryType = [
	{ value: '', label: iText('- 선택 -', `common.noneSelect`) },
	{ value: '1', label: '입출금 관련' },
	{ value: '2', label: '거래 관련' },
	{ value: '3', label: '보안 관련' },
	{ value: '4', label: '회원정보 관련' },
	{ value: '5', label: '인증 관련' },
	{ value: '6', label: '의견 및 제안' },
];
export const selTimeSorting = [
	{ value: '0', label: '날짜 오름차순' },
	{ value: '1', label: '날짜 내림차순' },
];
export const selAllWithdrawal = [
	{ value: '88', label: iText('전체', `common.all`) },
	{ value: '0', label: iText('입금', `common.deposit`) },
	{ value: '1', label: iText('출금', `common.withdraw`) },
];
export const selNoneMonth = [
	{ value: '', label: iText('- 월 -', `select.noneMonth`)},
	{ value: '01', label: iText('1월', `common.month1`)},
	{ value: '02', label: iText('2월', `common.month2`)},
	{ value: '03', label: iText('3월', `common.month3`)},
	{ value: '04', label: iText('4월', `common.month4`)},
	{ value: '05', label: iText('5월', `common.month5`)},
	{ value: '06', label: iText('6월', `common.month6`)},
	{ value: '07', label: iText('7월', `common.month7`)},
	{ value: '08', label: iText('8월', `common.month8`)},
	{ value: '09', label: iText('9월', `common.month9`)},
	{ value: '10', label: iText('10월', `common.month10`)},
	{ value: '11', label: iText('11월', `common.month11`)},
	{ value: '12', label: iText('12월', `common.month12`)},
];
export const selNoneDay = [
	{ value: '', label: iText('- 일 -', `select.noneDay`)},
	{ value: '01', label: iText('1일', `common.day1`)},
	{ value: '02', label: iText('2일', `common.day2`)},
	{ value: '03', label: iText('3일', `common.day3`)},
	{ value: '04', label: iText('4일', `common.day4`)},
	{ value: '05', label: iText('5일', `common.day5`)},
	{ value: '06', label: iText('6일', `common.day6`)},
	{ value: '07', label: iText('7일', `common.day7`)},
	{ value: '08', label: iText('8일', `common.day8`)},
	{ value: '09', label: iText('9일', `common.day9`)},
	{ value: '10', label: iText('10일', `common.day10`)},
	{ value: '11', label: iText('11일', `common.day11`)},
	{ value: '12', label: iText('12일', `common.day12`)},
	{ value: '13', label: iText('13일', `common.day13`)},
	{ value: '14', label: iText('14일', `common.day14`)},
	{ value: '15', label: iText('15일', `common.day15`)},
	{ value: '16', label: iText('16일', `common.day16`)},
	{ value: '17', label: iText('17일', `common.day17`)},
	{ value: '18', label: iText('18일', `common.day18`)},
	{ value: '19', label: iText('19일', `common.day19`)},
	{ value: '20', label: iText('20일', `common.day20`)},
	{ value: '21', label: iText('21일', `common.day21`)},
	{ value: '22', label: iText('22일', `common.day22`)},
	{ value: '23', label: iText('23일', `common.day23`)},
	{ value: '24', label: iText('24일', `common.day24`)},
	{ value: '25', label: iText('25일', `common.day25`)},
	{ value: '26', label: iText('26일', `common.day26`)},
	{ value: '27', label: iText('27일', `common.day27`)},
	{ value: '28', label: iText('28일', `common.day28`)},
	{ value: '29', label: iText('29일', `common.day29`)},
	{ value: '30', label: iText('30일', `common.day30`)},
	{ value: '31', label: iText('31일', `common.day31`)},
];
export const selNoneBankKO = [
	{ value: '', label: iText('- 선택 -', `common.noneSelect`) },
	{ value: '카카오뱅크', label: '카카오뱅크'},
	{ value: '국민', label: '국민'},
	{ value: '국세', label: '국세'},
	{ value: '기업', label: '기업'},
	{ value: '농협', label: '농협'},
	{ value: '경남', label: '경남'},
	{ value: '광주', label: '광주'},
	{ value: '대구', label: '대구'},
	{ value: '도이치', label: '도이치'},
	{ value: '뱅크오브아메리카', label: '뱅크오브아메리카'},
	{ value: '신한', label: '신한'},
	{ value: '산업', label: '산업'},
	{ value: '산림조합중앙회', label: '산림조합중앙회'},
	{ value: '새마을금고중앙회', label: '새마을금고중앙회'},
	{ value: '수협', label: '수협'},
	{ value: '신협중앙회', label: '신협중앙회'},
	{ value: '외환', label: '외환'},
	{ value: '우리', label: '우리'},
	{ value: '우체국', label: '우체국'},
	{ value: '저축', label: '저축'},
	{ value: '전북', label: '전북'},
	{ value: '제주', label: '제주'},
	{ value: '중국건설', label: '중국건설'},
	{ value: '중국공상', label: '중국공상'},
	{ value: '케이뱅크', label: '케이뱅크'},
	{ value: '하나', label: '하나'},
	{ value: '한국씨티', label: '한국씨티'},
	{ value: 'BOA', label: 'BOA'},
	{ value: 'BNP파리바', label: 'BNP파리바'},
	{ value: 'HSBC', label: 'HSBC'},
	{ value: 'JP모간체이스', label: 'JP모간체이스'},
	{ value: 'SC제일', label: 'SC제일'},
];

export const selCountryCode = [
	{ value: '', label: iText('- 선택 -', `common.noneSelect`) },
	{ value : "AF", label: "Afghanistan" },
	{ value : "AX", label: "Aland Islands" },
	{ value : "AL", label: "Albania" },
	{ value : "DZ", label: "Algeria" },
	{ value : "AS", label: "AmericanSamoa" },
	{ value : "AD", label: "Andorra" },
	{ value : "AO", label: "Angola" },
	{ value : "AI", label: "Anguilla" },
	{ value : "AQ", label: "Antarctica" },
	{ value : "AG", label: "Antigua and Barbuda" },
	{ value : "AR", label: "Argentina" },
	{ value : "AM", label: "Armenia" },
	{ value : "AW", label: "Aruba" },
	{ value : "AU", label: "Australia" },
	{ value : "AT", label: "Austria" },
	{ value : "AZ", label: "Azerbaijan" },
	{ value : "BS", label: "Bahamas" },
	{ value : "BH", label: "Bahrain" },
	{ value : "BD", label: "Bangladesh" },
	{ value : "BB", label: "Barbados" },
	{ value : "BY", label: "Belarus" },
	{ value : "BE", label: "Belgium" },
	{ value : "BZ", label: "Belize" },
	{ value : "BJ", label: "Benin" },
	{ value : "BM", label: "Bermuda" },
	{ value : "BT", label: "Bhutan" },
	{ value : "BO", label: "Bolivia, Plurinational State of" },
	{ value : "BA", label: "Bosnia and Herzegovina" },
	{ value : "BW", label: "Botswana" },
	{ value : "BR", label: "Brazil" },
	{ value : "IO", label: "British Indian Ocean Territory" },
	{ value : "BN", label: "Brunei Darussalam" },
	{ value : "BG", label: "Bulgaria" },
	{ value : "BF", label: "Burkina Faso" },
	{ value : "BI", label: "Burundi" },
	{ value : "KH", label: "Cambodia" },
	{ value : "CM", label: "Cameroon" },
	{ value : "CA", label: "Canada" },
	{ value : "CV", label: "Cape Verde" },
	{ value : "KY", label: "Cayman Islands" },
	{ value : "CF", label: "Central African Republic" },
	{ value : "TD", label: "Chad" },
	{ value : "CL", label: "Chile" },
	{ value : "CN", label: "China" },
	{ value : "CX", label: "Christmas Island" },
	{ value : "CC", label: "Cocos (Keeling) Islands" },
	{ value : "CO", label: "Colombia" },
	{ value : "KM", label: "Comoros" },
	{ value : "CG", label: "Congo" },
	{ value : "CD", label: "Congo, The Democratic Republic of the Congo" },
	{ value : "CK", label: "Cook Islands" },
	{ value : "CR", label: "Costa Rica" },
	{ value : "CI", label: "Cote d'Ivoire" },
	{ value : "HR", label: "Croatia" },
	{ value : "CU", label: "Cuba" },
	{ value : "CY", label: "Cyprus" },
	{ value : "CZ", label: "Czech Republic" },
	{ value : "DK", label: "Denmark" },
	{ value : "DJ", label: "Djibouti" },
	{ value : "DM", label: "Dominica" },
	{ value : "DO", label: "Dominican Republic" },
	{ value : "EC", label: "Ecuador" },
	{ value : "EG", label: "Egypt" },
	{ value : "SV", label: "El Salvador" },
	{ value : "GQ", label: "Equatorial Guinea" },
	{ value : "ER", label: "Eritrea" },
	{ value : "EE", label: "Estonia" },
	{ value : "ET", label: "Ethiopia" },
	{ value : "FK", label: "Falkland Islands (Malvinas)" },
	{ value : "FO", label: "Faroe Islands" },
	{ value : "FJ", label: "Fiji" },
	{ value : "FI", label: "Finland" },
	{ value : "FR", label: "France" },
	{ value : "GF", label: "French Guiana" },
	{ value : "PF", label: "French Polynesia" },
	{ value : "GA", label: "Gabon" },
	{ value : "GM", label: "Gambia" },
	{ value : "GE", label: "Georgia" },
	{ value : "DE", label: "Germany" },
	{ value : "GH", label: "Ghana" },
	{ value : "GI", label: "Gibraltar" },
	{ value : "GR", label: "Greece" },
	{ value : "GL", label: "Greenland" },
	{ value : "GD", label: "Grenada" },
	{ value : "GP", label: "Guadeloupe" },
	{ value : "GU", label: "Guam" },
	{ value : "GT", label: "Guatemala" },
	{ value : "GG", label: "Guernsey" },
	{ value : "GN", label: "Guinea" },
	{ value : "GW", label: "Guinea-Bissau" },
	{ value : "GY", label: "Guyana" },
	{ value : "HT", label: "Haiti" },
	{ value : "VA", label: "Holy See (Vatican City State)" },
	{ value : "HN", label: "Honduras" },
	{ value : "HK", label: "Hong Kong" },
	{ value : "HU", label: "Hungary" },
	{ value : "IS", label: "Iceland" },
	{ value : "IN", label: "India" },
	{ value : "ID", label: "Indonesia" },
	{ value : "IR", label: "Iran, Islamic Republic of Persian Gulf" },
	{ value : "IQ", label: "Iraq" },
	{ value : "IE", label: "Ireland" },
	{ value : "IM", label: "Isle of Man" },
	{ value : "IL", label: "Israel" },
	{ value : "IT", label: "Italy" },
	{ value : "JM", label: "Jamaica" },
	{ value : "JP", label: "Japan" },
	{ value : "JE", label: "Jersey" },
	{ value : "JO", label: "Jordan" },
	{ value : "KZ", label: "Kazakhstan" },
	{ value : "KE", label: "Kenya" },
	{ value : "KI", label: "Kiribati" },
	{ value : "KP", label: "Korea, Democratic People's Republic of Korea" },
	{ value : "KR", label: "Korea, Republic of South Korea" },
	{ value : "KW", label: "Kuwait" },
	{ value : "KG", label: "Kyrgyzstan" },
	{ value : "LA", label: "Laos" },
	{ value : "LV", label: "Latvia" },
	{ value : "LB", label: "Lebanon" },
	{ value : "LS", label: "Lesotho" },
	{ value : "LR", label: "Liberia" },
	{ value : "LY", label: "Libyan Arab Jamahiriya" },
	{ value : "LI", label: "Liechtenstein" },
	{ value : "LT", label: "Lithuania" },
	{ value : "LU", label: "Luxembourg" },
	{ value : "MO", label: "Macao" },
	{ value : "MK", label: "Macedonia" },
	{ value : "MG", label: "Madagascar" },
	{ value : "MW", label: "Malawi" },
	{ value : "MY", label: "Malaysia" },
	{ value : "MV", label: "Maldives" },
	{ value : "ML", label: "Mali" },
	{ value : "MT", label: "Malta" },
	{ value : "MH", label: "Marshall Islands" },
	{ value : "MQ", label: "Martinique" },
	{ value : "MR", label: "Mauritania" },
	{ value : "MU", label: "Mauritius" },
	{ value : "YT", label: "Mayotte" },
	{ value : "MX", label: "Mexico" },
	{ value : "FM", label: "Micronesia, Federated States of Micronesia" },
	{ value : "MD", label: "Moldova" },
	{ value : "MC", label: "Monaco" },
	{ value : "MN", label: "Mongolia" },
	{ value : "ME", label: "Montenegro" },
	{ value : "MS", label: "Montserrat" },
	{ value : "MA", label: "Morocco" },
	{ value : "MZ", label: "Mozambique" },
	{ value : "MM", label: "Myanmar" },
	{ value : "NA", label: "Namibia" },
	{ value : "NR", label: "Nauru" },
	{ value : "NP", label: "Nepal" },
	{ value : "NL", label: "Netherlands" },
	{ value : "AN", label: "Netherlands Antilles" },
	{ value : "NC", label: "New Caledonia" },
	{ value : "NZ", label: "New Zealand" },
	{ value : "NI", label: "Nicaragua" },
	{ value : "NE", label: "Niger" },
	{ value : "NG", label: "Nigeria" },
	{ value : "NU", label: "Niue" },
	{ value : "NF", label: "Norfolk Island" },
	{ value : "MP", label: "Northern Mariana Islands" },
	{ value : "NO", label: "Norway" },
	{ value : "OM", label: "Oman" },
	{ value : "PK", label: "Pakistan" },
	{ value : "PW", label: "Palau" },
	{ value : "PS", label: "Palestinian Territory, Occupied" },
	{ value : "PA", label: "Panama" },
	{ value : "PG", label: "Papua New Guinea" },
	{ value : "PY", label: "Paraguay" },
	{ value : "PE", label: "Peru" },
	{ value : "PH", label: "Philippines" },
	{ value : "PN", label: "Pitcairn" },
	{ value : "PL", label: "Poland" },
	{ value : "PT", label: "Portugal" },
	{ value : "PR", label: "Puerto Rico" },
	{ value : "QA", label: "Qatar" },
	{ value : "RO", label: "Romania" },
	{ value : "RU", label: "Russia" },
	{ value : "RW", label: "Rwanda" },
	{ value : "RE", label: "Reunion" },
	{ value : "BL", label: "Saint Barthelemy" },
	{ value : "SH", label: "Saint Helena, Ascension and Tristan Da Cunha" },
	{ value : "KN", label: "Saint Kitts and Nevis" },
	{ value : "LC", label: "Saint Lucia" },
	{ value : "MF", label: "Saint Martin" },
	{ value : "PM", label: "Saint Pierre and Miquelon" },
	{ value : "VC", label: "Saint Vincent and the Grenadines" },
	{ value : "WS", label: "Samoa" },
	{ value : "SM", label: "San Marino" },
	{ value : "ST", label: "Sao Tome and Principe" },
	{ value : "SA", label: "Saudi Arabia" },
	{ value : "SN", label: "Senegal" },
	{ value : "RS", label: "Serbia" },
	{ value : "SC", label: "Seychelles" },
	{ value : "SL", label: "Sierra Leone" },
	{ value : "SG", label: "Singapore" },
	{ value : "SK", label: "Slovakia" },
	{ value : "SI", label: "Slovenia" },
	{ value : "SB", label: "Solomon Islands" },
	{ value : "SO", label: "Somalia" },
	{ value : "ZA", label: "South Africa" },
	{ value : "GS", label: "South Georgia and the South Sandwich Islands" },
	{ value : "ES", label: "Spain" },
	{ value : "LK", label: "Sri Lanka" },
	{ value : "SD", label: "Sudan" },
	{ value : "SR", label: "Suriname" },
	{ value : "SJ", label: "Svalbard and Jan Mayen" },
	{ value : "SZ", label: "Swaziland" },
	{ value : "SE", label: "Sweden" },
	{ value : "CH", label: "Switzerland" },
	{ value : "SY", label: "Syrian Arab Republic" },
	{ value : "TW", label: "Taiwan" },
	{ value : "TJ", label: "Tajikistan" },
	{ value : "TZ", label: "Tanzania, United Republic of Tanzania" },
	{ value : "TH", label: "Thailand" },
	{ value : "TL", label: "Timor-Leste" },
	{ value : "TG", label: "Togo" },
	{ value : "TK", label: "Tokelau" },
	{ value : "TO", label: "Tonga" },
	{ value : "TT", label: "Trinidad and Tobago" },
	{ value : "TN", label: "Tunisia" },
	{ value : "TR", label: "Turkey" },
	{ value : "TM", label: "Turkmenistan" },
	{ value : "TC", label: "Turks and Caicos Islands" },
	{ value : "TV", label: "Tuvalu" },
	{ value : "UG", label: "Uganda" },
	{ value : "UA", label: "Ukraine" },
	{ value : "AE", label: "United Arab Emirates" },
	{ value : "GB", label: "United Kingdom" },
	{ value : "US", label: "United States" },
	{ value : "UY", label: "Uruguay" },
	{ value : "UZ", label: "Uzbekistan" },
	{ value : "VU", label: "Vanuatu" },
	{ value : "VE", label: "Venezuela, Bolivarian Republic of Venezuela" },
	{ value : "VN", label: "Vietnam" },
	{ value : "VG", label: "Virgin Islands, British" },
	{ value : "VI", label: "Virgin Islands, U.S." },
	{ value : "WF", label: "Wallis and Futuna" },
	{ value : "YE", label: "Yemen" },
	{ value : "ZM", label: "Zambia" },
	{ value : "ZW", label: "Zimbabwe"}
];

export const selCountryCodePhone = [
	{ value: '', label: iText('- 선택 -', `common.noneSelect`) },
	{ value : "93", label: "Afghanistan(+93)" },
	{ value : "358", label: "Aland Islands(+358)" },
	{ value : "355", label: "Albania(+355)" },
	{ value : "213", label: "Algeria(+213)" },
	{ value : "1 684", label: "AmericanSamoa(+1 684)" },
	{ value : "376", label: "Andorra(+376)" },
	{ value : "244", label: "Angola(+244)" },
	{ value : "1 264", label: "Anguilla(+1 264)" },
	{ value : "672", label: "Antarctica(+672)" },
	{ value : "1268", label: "Antigua and Barbuda(+1268)" },
	{ value : "54", label: "Argentina(+54)" },
	{ value : "374", label: "Armenia(+374)" },
	{ value : "297", label: "Aruba(+297)" },
	{ value : "61", label: "Australia(+61)" },
	{ value : "43", label: "Austria(+43)" },
	{ value : "994", label: "Azerbaijan(+994)" },
	{ value : "1 242", label: "Bahamas(+1 242)" },
	{ value : "973", label: "Bahrain(+973)" },
	{ value : "880", label: "Bangladesh(+880)" },
	{ value : "1 246", label: "Barbados(+1 246)" },
	{ value : "375", label: "Belarus(+375)" },
	{ value : "32", label: "Belgium(+32)" },
	{ value : "501", label: "Belize(+501)" },
	{ value : "229", label: "Benin(+229)" },
	{ value : "1 441", label: "Bermuda(+1 441)" },
	{ value : "975", label: "Bhutan(+975)" },
	{ value : "591", label: "Bolivia, Plurinational State of(+591)" },
	{ value : "387", label: "Bosnia and Herzegovina(+387)" },
	{ value : "267", label: "Botswana(+267)" },
	{ value : "55", label: "Brazil(+55)" },
	{ value : "246", label: "British Indian Ocean Territory(+246)" },
	{ value : "673", label: "Brunei Darussalam(+673)" },
	{ value : "359", label: "Bulgaria(+359)" },
	{ value : "226", label: "Burkina Faso(+226)" },
	{ value : "257", label: "Burundi(+257)" },
	{ value : "855", label: "Cambodia(+855)" },
	{ value : "237", label: "Cameroon(+237)" },
	{ value : "1", label: "Canada(+1)" },
	{ value : "238", label: "Cape Verde(+238)" },
	{ value : " 345", label: "Cayman Islands(+ 345)" },
	{ value : "236", label: "Central African Republic(+236)" },
	{ value : "235", label: "Chad(+235)" },
	{ value : "56", label: "Chile(+56)" },
	{ value : "86", label: "China(+86)" },
	{ value : "61", label: "Christmas Island(+61)" },
	{ value : "61", label: "Cocos (Keeling) Islands(+61)" },
	{ value : "57", label: "Colombia(+57)" },
	{ value : "269", label: "Comoros(+269)" },
	{ value : "242", label: "Congo(+242)" },
	{ value : "243", label: "Congo, The Democratic Republic of the Congo(+243)" },
	{ value : "682", label: "Cook Islands(+682)" },
	{ value : "506", label: "Costa Rica(+506)" },
	{ value : "225", label: "Cote d'Ivoire(+225)" },
	{ value : "385", label: "Croatia(+385)" },
	{ value : "53", label: "Cuba(+53)" },
	{ value : "357", label: "Cyprus(+357)" },
	{ value : "420", label: "Czech Republic(+420)" },
	{ value : "45", label: "Denmark(+45)" },
	{ value : "253", label: "Djibouti(+253)" },
	{ value : "1 767", label: "Dominica(+1 767)" },
	{ value : "1 849", label: "Dominican Republic(+1 849)" },
	{ value : "593", label: "Ecuador(+593)" },
	{ value : "20", label: "Egypt(+20)" },
	{ value : "503", label: "El Salvador(+503)" },
	{ value : "240", label: "Equatorial Guinea(+240)" },
	{ value : "291", label: "Eritrea(+291)" },
	{ value : "372", label: "Estonia(+372)" },
	{ value : "251", label: "Ethiopia(+251)" },
	{ value : "500", label: "Falkland Islands (Malvinas)(+500)" },
	{ value : "298", label: "Faroe Islands(+298)" },
	{ value : "679", label: "Fiji(+679)" },
	{ value : "358", label: "Finland(+358)" },
	{ value : "33", label: "France(+33)" },
	{ value : "594", label: "French Guiana(+594)" },
	{ value : "689", label: "French Polynesia(+689)" },
	{ value : "241", label: "Gabon(+241)" },
	{ value : "220", label: "Gambia(+220)" },
	{ value : "995", label: "Georgia(+995)" },
	{ value : "49", label: "Germany(+49)" },
	{ value : "233", label: "Ghana(+233)" },
	{ value : "350", label: "Gibraltar(+350)" },
	{ value : "30", label: "Greece(+30)" },
	{ value : "299", label: "Greenland(+299)" },
	{ value : "1 473", label: "Grenada(+1 473)" },
	{ value : "590", label: "Guadeloupe(+590)" },
	{ value : "1 671", label: "Guam(+1 671)" },
	{ value : "502", label: "Guatemala(+502)" },
	{ value : "44", label: "Guernsey(+44)" },
	{ value : "224", label: "Guinea(+224)" },
	{ value : "245", label: "Guinea-Bissau(+245)" },
	{ value : "595", label: "Guyana(+595)" },
	{ value : "509", label: "Haiti(+509)" },
	{ value : "379", label: "Holy See (Vatican City State)(+379)" },
	{ value : "504", label: "Honduras(+504)" },
	{ value : "852", label: "Hong Kong(+852)" },
	{ value : "36", label: "Hungary(+36)" },
	{ value : "354", label: "Iceland(+354)" },
	{ value : "91", label: "India(+91)" },
	{ value : "62", label: "Indonesia(+62)" },
	{ value : "98", label: "Iran, Islamic Republic of Persian Gulf(+98)" },
	{ value : "964", label: "Iraq(+964)" },
	{ value : "353", label: "Ireland(+353)" },
	{ value : "44", label: "Isle of Man(+44)" },
	{ value : "972", label: "Israel(+972)" },
	{ value : "39", label: "Italy(+39)" },
	{ value : "1 876", label: "Jamaica(+1 876)" },
	{ value : "81", label: "Japan(+81)" },
	{ value : "44", label: "Jersey(+44)" },
	{ value : "962", label: "Jordan(+962)" },
	{ value : "7 7", label: "Kazakhstan(+7 7)" },
	{ value : "254", label: "Kenya(+254)" },
	{ value : "686", label: "Kiribati(+686)" },
	{ value : "850", label: "Korea, Democratic People's Republic of Korea(+850)" },
	{ value : "82", label: "Korea, Republic of South Korea(+82)" },
	{ value : "965", label: "Kuwait(+965)" },
	{ value : "996", label: "Kyrgyzstan(+996)" },
	{ value : "856", label: "Laos(+856)" },
	{ value : "371", label: "Latvia(+371)" },
	{ value : "961", label: "Lebanon(+961)" },
	{ value : "266", label: "Lesotho(+266)" },
	{ value : "231", label: "Liberia(+231)" },
	{ value : "218", label: "Libyan Arab Jamahiriya(+218)" },
	{ value : "423", label: "Liechtenstein(+423)" },
	{ value : "370", label: "Lithuania(+370)" },
	{ value : "352", label: "Luxembourg(+352)" },
	{ value : "853", label: "Macao(+853)" },
	{ value : "389", label: "Macedonia(+389)" },
	{ value : "261", label: "Madagascar(+261)" },
	{ value : "265", label: "Malawi(+265)" },
	{ value : "60", label: "Malaysia(+60)" },
	{ value : "960", label: "Maldives(+960)" },
	{ value : "223", label: "Mali(+223)" },
	{ value : "356", label: "Malta(+356)" },
	{ value : "692", label: "Marshall Islands(+692)" },
	{ value : "596", label: "Martinique(+596)" },
	{ value : "222", label: "Mauritania(+222)" },
	{ value : "230", label: "Mauritius(+230)" },
	{ value : "262", label: "Mayotte(+262)" },
	{ value : "52", label: "Mexico(+52)" },
	{ value : "691", label: "Micronesia, Federated States of Micronesia(+691)" },
	{ value : "373", label: "Moldova(+373)" },
	{ value : "377", label: "Monaco(+377)" },
	{ value : "976", label: "Mongolia(+976)" },
	{ value : "382", label: "Montenegro(+382)" },
	{ value : "1664", label: "Montserrat(+1664)" },
	{ value : "212", label: "Morocco(+212)" },
	{ value : "258", label: "Mozambique(+258)" },
	{ value : "95", label: "Myanmar(+95)" },
	{ value : "264", label: "Namibia(+264)" },
	{ value : "674", label: "Nauru(+674)" },
	{ value : "977", label: "Nepal(+977)" },
	{ value : "31", label: "Netherlands(+31)" },
	{ value : "599", label: "Netherlands Antilles(+599)" },
	{ value : "687", label: "New Caledonia(+687)" },
	{ value : "64", label: "New Zealand(+64)" },
	{ value : "505", label: "Nicaragua(+505)" },
	{ value : "227", label: "Niger(+227)" },
	{ value : "234", label: "Nigeria(+234)" },
	{ value : "683", label: "Niue(+683)" },
	{ value : "672", label: "Norfolk Island(+672)" },
	{ value : "1 670", label: "Northern Mariana Islands(+1 670)" },
	{ value : "47", label: "Norway(+47)" },
	{ value : "968", label: "Oman(+968)" },
	{ value : "92", label: "Pakistan(+92)" },
	{ value : "680", label: "Palau(+680)" },
	{ value : "970", label: "Palestinian Territory, Occupied(+970)" },
	{ value : "507", label: "Panama(+507)" },
	{ value : "675", label: "Papua New Guinea(+675)" },
	{ value : "595", label: "Paraguay(+595)" },
	{ value : "51", label: "Peru(+51)" },
	{ value : "63", label: "Philippines(+63)" },
	{ value : "872", label: "Pitcairn(+872)" },
	{ value : "48", label: "Poland(+48)" },
	{ value : "351", label: "Portugal(+351)" },
	{ value : "1 939", label: "Puerto Rico(+1 939)" },
	{ value : "974", label: "Qatar(+974)" },
	{ value : "40", label: "Romania(+40)" },
	{ value : "7", label: "Russia(+7)" },
	{ value : "250", label: "Rwanda(+250)" },
	{ value : "262", label: "Reunion(+262)" },
	{ value : "590", label: "Saint Barthelemy(+590)" },
	{ value : "290", label: "Saint Helena, Ascension and Tristan Da Cunha(+290)" },
	{ value : "1 869", label: "Saint Kitts and Nevis(+1 869)" },
	{ value : "1 758", label: "Saint Lucia(+1 758)" },
	{ value : "590", label: "Saint Martin(+590)" },
	{ value : "508", label: "Saint Pierre and Miquelon(+508)" },
	{ value : "1 784", label: "Saint Vincent and the Grenadines(+1 784)" },
	{ value : "685", label: "Samoa(+685)" },
	{ value : "378", label: "San Marino(+378)" },
	{ value : "239", label: "Sao Tome and Principe(+239)" },
	{ value : "966", label: "Saudi Arabia(+966)" },
	{ value : "221", label: "Senegal(+221)" },
	{ value : "381", label: "Serbia(+381)" },
	{ value : "248", label: "Seychelles(+248)" },
	{ value : "232", label: "Sierra Leone(+232)" },
	{ value : "65", label: "Singapore(+65)" },
	{ value : "421", label: "Slovakia(+421)" },
	{ value : "386", label: "Slovenia(+386)" },
	{ value : "677", label: "Solomon Islands(+677)" },
	{ value : "252", label: "Somalia(+252)" },
	{ value : "27", label: "South Africa(+27)" },
	{ value : "500", label: "South Georgia and the South Sandwich Islands(+500)" },
	{ value : "34", label: "Spain(+34)" },
	{ value : "94", label: "Sri Lanka(+94)" },
	{ value : "249", label: "Sudan(+249)" },
	{ value : "597", label: "Suriname(+597)" },
	{ value : "47", label: "Svalbard and Jan Mayen(+47)" },
	{ value : "268", label: "Swaziland(+268)" },
	{ value : "46", label: "Sweden(+46)" },
	{ value : "41", label: "Switzerland(+41)" },
	{ value : "963", label: "Syrian Arab Republic(+963)" },
	{ value : "886", label: "Taiwan(+886)" },
	{ value : "992", label: "Tajikistan(+992)" },
	{ value : "255", label: "Tanzania, United Republic of Tanzania(+255)" },
	{ value : "66", label: "Thailand(+66)" },
	{ value : "670", label: "Timor-Leste(+670)" },
	{ value : "228", label: "Togo(+228)" },
	{ value : "690", label: "Tokelau(+690)" },
	{ value : "676", label: "Tonga(+676)" },
	{ value : "1 868", label: "Trinidad and Tobago(+1 868)" },
	{ value : "216", label: "Tunisia(+216)" },
	{ value : "90", label: "Turkey(+90)" },
	{ value : "993", label: "Turkmenistan(+993)" },
	{ value : "1 649", label: "Turks and Caicos Islands(+1 649)" },
	{ value : "688", label: "Tuvalu(+688)" },
	{ value : "256", label: "Uganda(+256)" },
	{ value : "380", label: "Ukraine(+380)" },
	{ value : "971", label: "United Arab Emirates(+971)" },
	{ value : "44", label: "United Kingdom(+44)" },
	{ value : "1", label: "United States(+1)" },
	{ value : "598", label: "Uruguay(+598)" },
	{ value : "998", label: "Uzbekistan(+998)" },
	{ value : "678", label: "Vanuatu(+678)" },
	{ value : "58", label: "Venezuela, Bolivarian Republic of Venezuela(+58)" },
	{ value : "84", label: "Vietnam(+84)" },
	{ value : "1 284", label: "Virgin Islands, British(+1 284)" },
	{ value : "1 340", label: "Virgin Islands, U.S.(+1 340)" },
	{ value : "681", label: "Wallis and Futuna(+681)" },
	{ value : "967", label: "Yemen(+967)" },
	{ value : "260", label: "Zambia(+260)" },
	{ value : "263", label: "Zimbabwe(+263)"}
]

