import React, { Component } from 'react';
import { connect } from 'react-redux'
import { FadeLoader } from 'react-spinners';

const override = {
	display: 'block',
	margin: '0 auto',
};
class Loader extends Component {
	constructor(props) {
		super(props);
		this.state = {}
	}
	render() {
		return (
			<div id="loader" className={this.props.common.loading ? 'on' : null}>
				<FadeLoader
					css={override}
					sizeUnit={"px"}
					size={130}
					color={'white'}
					loading={true}
				/>
			</div>
		)
	}
}

export default connect(
	state => ({
		common: state.common,
	}),
)(Loader);