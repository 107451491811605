import React, { useState, useEffect } from "react";
import Moter from "components/modal/moter";
import Tanso from "components/modal/tansonanbang";
import Hangon from "components/modal/alawaysWater";
import Single from "components/modal/single_modal";
import Aircon from "components/modal/aircon";
import Changer from "components/modal/changer";
import Ceiling from "components/modal/ceiling";
import Heater from "components/modal/heater";
import { API } from "service";
import { code_mapping } from "components/utils/mapping";
import GansuMoter from "components/modal/gansuMoter";
import styled, { css } from "styled-components";
import {
  CARBONHEATERPOWER,
  CIRCULATIONFAN,
  HEATEXCHANGER,
  HVACTEMPHUM,
  LEFTSIDEWINDOW,
  LIGHT,
  NUTRIENTEQUIP,
  SECONDLEFTAST,
} from "constants/equipInfo";
import { HvacTempHumControlModal } from "components/modal/hvac";
import { HeatexChangerControlModal } from "components/modal/heatexChanger";
import AstControlModal from "components/modal/equipControl/ast";
import LightControlModal from "components/modal/equipControl/light";
import NutrientControlModal from "components/modal/equipControl/nutrient";
import { CarbonHeaterPowerControlModal } from "components/modal/equipControl/carbonHeater";
import { CirculatorFanControlModal } from "components/modal/equipControl/circulatorFan";
const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 25%;
  margin: 0 auto;
  padding: 3.5rem;

  max-width: 100rem;

  animation: zoomIn 0.2s none;
`;

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  outline: 0;
  &.open {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    & > div {
    }
  }
  /* ${(props) =>
    props.visible &&
    css`
      position: fixed;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      transition: background-color 0.25s;

      & > div {
        transform: scale(1);
      }
    `} */
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Title = styled.div`
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 600;
  font-size: 2.6rem;

  color: #2a3a4b;
`;

const CloseButton = styled.div`
  display: flex;
  width: 131px;
  height: 53px;

  line-height: 51px;
  background: #17b9b6;
  border-radius: 5px;
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;

  text-align: center;
  justify-content: center;

  color: #ffffff;

  cursor: pointer;
`;

const Main = (props) => {
  const { modal_id, onClick, start, stop, visible } = props;

  const [data, set_data] = useState();
  const [classId, set_classId] = useState(0);
  const [status, set_status] = useState([]);
  const [sensors, set_setnsors] = useState([]);

  const [fetchRes, setFetchRes] = useState(null);

  useEffect(() => {
    cleanUp();
  }, []);

  const cleanUp = () => {
    setFetchRes(null);
  };

  useEffect(() => {
    set_classId(0);

    if (props.visible) {
      document.body.style.cssText = `
				position: fixed; 
				top: -${window.scrollY}px;
				overflow-y: scroll;
				width: 100%;
			`;

      API.getEquip({ equipId: props.modal_id }).then((val) => {
        set_classId(val.equipClass.equipClassId);
        set_data(val);

        let status_code = code_mapping[val.equipClass.code];
        let sensor = [];
        let local_status = [];
        sensor = val.sensors.filter((data) => {
          if (data.tagClass.code == status_code) {
            local_status = data;
            return false;
          } else {
            return true;
          }
        });
        set_status(local_status);
        set_setnsors(sensor);
      });

      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      };
    }
  }, [props]);

  const fetchEquipInfo = (id) => {
    try {
    } catch (error) {}
  };

  const fetchResControl = (flag) => {
    try {
      setFetchRes(flag);
    } catch (error) {
      alert(error.message);
    }
  };

  const modalMappingControl = () => {
    try {
      let Component = null;
      switch (data.equipClass.code) {
        case HVACTEMPHUM.equipClass:
          Component = HvacTempHumControlModal;
          // return <HvacTempHumControlModal data={data} resultControl={fetchEquipInfo} />
          break;
        case HEATEXCHANGER.equipClass:
          Component = HeatexChangerControlModal;
          // return <HeatexChangerControlModal data={data} resultControl={fetchEquipInfo} />
          break;
        case SECONDLEFTAST.equipClass:
          Component = AstControlModal;
          // return <AstControlModal data={data} resultControl={fetchEquipInfo} />
          break;
        case LIGHT.equipClass:
          Component = LightControlModal;
          break;
        case NUTRIENTEQUIP.equipClass:
          Component = NutrientControlModal;
          break;
        case CARBONHEATERPOWER.equipClass:
          Component = CarbonHeaterPowerControlModal;
          break;

        case CIRCULATIONFAN.equipClass:
          Component = CirculatorFanControlModal;
          break;
        default:
          return <div>아직 구현이 되지 않은 페이지 입니다.</div>;
      }
      return React.createElement(Component, {
        data,
        resultControl: fetchEquipInfo,
        fetchRes,
        fetchResControl,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  if (data == undefined) {
    return <></>;
  }

  return (
    <>
      <ModalOverlay visible={visible}>
        <ModalWrapper visible={visible}>
          <ModalInner>
            <Top>
              <Title>{data.name || ""}</Title>
              <CloseButton onClick={() => onClick(false)}>닫기</CloseButton>
            </Top>
            {modalMappingControl()}
          </ModalInner>
        </ModalWrapper>
      </ModalOverlay>
    </>
  );

  // if (classId == 0) {
  // 	return (<></>)
  // }

  // //전열교환기
  // else if (classId == 10 || classId == 14 || classId == 23) {
  // 	return (<Changer
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }

  // //탄소난방
  // else if (classId == 9) {
  // 	return (<Tanso
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }

  // //관수모터
  // else if (classId == 17) {
  // 	return (<GansuMoter
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }

  // //항온항습기
  // else if (classId == 18) {
  // 	return (<Hangon
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }

  // //에어컨
  // else if (classId == 22) {
  // 	return (<Aircon
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }
  // else if (classId == 33) {
  // 	return (<Heater
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }

  // // 29 5 3 6
  // //천창,측창,AST
  // else if (classId == 3 || classId == 5 || classId == 6 || classId == 29) {
  // 	return (<Ceiling
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }
  // // 온/오프만 있는모달
  // // else if (classId == 11 || classId == 15 || classId == 24 || classId == 26 || classId == 27) {
  // //   return (<Single
  // //     visible={props.visible}
  // //     onClose={() => props.onClick(false)}
  // //     data={data}
  // //     status={status}
  // //     sensors={sensors}
  // //   />)
  // // }
  // //그외
  // else {
  // 	return (<Single
  // 		visible={props.visible}
  // 		onClose={() => props.onClick(false)}
  // 		data={data}
  // 		status={status}
  // 		sensors={sensors}
  // 	/>)
  // }
};

export default Main;
