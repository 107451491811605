import React from 'react';

/*components*/
import MyPageWorkSpace from 'components/layout/include/MyPage';

const Another = props =>{

    return (

        <MyPageWorkSpace>
            <div>another</div>
        </MyPageWorkSpace>
    )

}

export default Another;