import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { image_mapping, offImage_mapping } from 'components/utils/mapping'

import Line from "./include/leftdown"
import Temparature from "img/icons_item/temperature.png"
import Swich from './include/switch'
import Message from './include/message'
import Input from './include/input'
import { useInput } from 'hooks/commonHooks'
import waterIcon from 'img/icon_water.png'
// import Fan from ''
import dp from 'img/tools_item/fan_on.png'






const FanImage = styled.img`
width: 2.8rem;
height: 2.8rem;
`
const FanText = styled.span`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.6rem;
margin: 0 0.6remx;
/* identical to box height */


color: #17B9B6;

`
const Span = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 2rem 0;
`

const StatusMessage = styled.div`
display: flex;
width: 6.6rem;
height: 3.2rem;
justify-content: center;
align-items: center;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.6rem;
line-height: 20px;
margin-left: 0.6rem;
/* identical to box height */


color: #E16A6A;
background-color: #FFCFCF;
border: 0.1rem solid #E16A6A;
box-sizing: border-box;
border-radius: 18.5px;
`
function Modal(props) {
    const [data, set_data] = useState([])
    const [sensors, set_sensors] = useState([])

    const [isOn, set_isOn] = useState(false)
    const [temp, set_temp] = useState(false)
    const [nowWater, set_nowWater] = useState(false)


    const [weekTemp, weekTemp_handdle, set_weekTemp] = useInput('')
    const [nightTemp, nightTemp_handdle, set_nightTemp] = useInput('')
    const [water, water_handdle, set_water] = useInput('')
    const [chageTemp, chageTemp_handdle, set_chageTemp] = useInput('')
    const [weekStart, weekStart_handdle, set_weekStart] = useInput('')
    const [weekEnd, weekEnd_handdle, set_weekEnd] = useInput('')

    const [fanStatus, set_fanStatus] = useState(false)
    const [heatingStatus, set_heatingStatus] = useState(false)
    const [coolingStatus, set_coolingStatus] = useState(false)
    const [humStatus, set_humStatus] = useState(false)
    const [waterStatus, set_waterStatus] = useState(false)

    // title unit data datahandle

    const handleChange = (e) => {
        set_isOn(e.target.checked);
    };

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            props.onClose(e)
        }
    }

    const close = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const array_find = (arr, code) => {
        //TEMP
        let return_arr = {}
        return_arr = arr.filter(val => {
            if (val.tagClass.code == code) {
                return true
            }
            return false
        })

        if (return_arr.length == 0)
            return_arr = [{ 'value': 'err' }]
        return return_arr[0]
    }

    // 제습상:HVACDEHUMIDISTATUS
    // 가습상:HVACHUMIDISTATUS
    // 난방상:HVACHEATSTATUS
    // 냉방상:HVACCOOLINGSTATUS
    // 팬상:HVACFANSTATUS
    // 동작상:HVACDRIVE
    // 현재습:HVACCURHUM
    // 설정습:HVACSETHUM
    // 설정온:HVACSETTEMP
    // 주야간 온도 변:HVACTEMPSTEP
    // 주간종료시:HVACENDTIME
    // 주간시작시:HVACSTARTTIME
    // 습도설:HVACHUM
    // 야간온:HVACNIGHTTEMP
    // 주간온:HVACDAYTEMP


    useEffect(() => {
        if (props.length != 0) {

            set_sensors(props.sensors)
            set_data(props.data)

            console.log(props.data)


            set_weekTemp(array_find(props.sensors, "HVACDAYTEMP")["value"].toString())
            set_nightTemp(array_find(props.sensors, "HVACNIGHTTEMP")["value"].toString())
            set_water(array_find(props.sensors, "HVACHUM")["value"].toString())
            set_chageTemp(array_find(props.sensors, "HVACTEMPSTEP")["value"].toString())
            set_weekStart(array_find(props.sensors, "HVACSTARTTIME")["value"].toString())
            set_weekEnd(array_find(props.sensors, "HVACENDTIME")["value"].toString())

            set_nowWater(array_find(props.sensors, "HVACCURHUM")["value"].toString())

            set_fanStatus(array_find(props.sensors, "HVACFANSTATUS")["value"] == 1)//팬상태
            set_heatingStatus(array_find(props.sensors, "HVACHEATSTATUS")["value"] == 1)//난방상태
            set_coolingStatus(array_find(props.sensors, "HVACCOOLINGSTATUS")["value"] == 1)//냉방상태
            set_humStatus(array_find(props.sensors, "HVACDEHUMIDISTATUS")["value"] == 1)//냉방상태
            set_waterStatus(array_find(props.sensors, "HVACHUMIDISTATUS")["value"] == 1)//냉방상태


            if (props.status.length != 0)
                set_temp(props.status.value.toString())

            if (props.status.value == 0)
                set_isOn(false)
            else if (props.status.value == 1)
                set_isOn(true)
        }
    }, [props])

    return (
        <>
            {data.length == 0 ||
                <>
                    {console.log(fanStatus)}
                    {console.log(heatingStatus)}
                    {console.log(coolingStatus)}
                    {console.log(humStatus)}
                    {console.log(waterStatus)}
                    <ModalOverlay visible={props.visible} />
                    <ModalWrapper onClick={onMaskClick} visible={props.visible}>
                        <ModalInner>
                            <Top>
                                <Title>{data.name}</Title>
                                <CloseButton onClick={close}>닫기</CloseButton>
                            </Top>

                            <Wrapper>
                                <Wrap>

                                    <Img src={image_mapping[data.equipClass.equipClassId]} />
                                    <TempWrap>
                                        <TempImg src={Temparature} />
                                        <Temp>{temp}</Temp>
                                        <Unit>℃</Unit>

                                        <TempImg style={{ marginLeft: '12px' }} src={waterIcon} />
                                        <Temp style={{ color: '#4F80E2' }}>{nowWater}</Temp>
                                        <Unit style={{ color: '#4F80E2', alignItems: "flex-end" }}>%</Unit>
                                    </TempWrap>

                                    <Span>
                                        <FanImage src={dp} />
                                        <FanText>FAN ON</FanText>
                                        {heatingStatus && <StatusMessage>난방</StatusMessage>}
                                        {waterStatus && <StatusMessage style={{ color: '#F68F16', backgroundColor: '#FFEFCA', borderColor: '#F68F16' }}>가습</StatusMessage>}
                                        {coolingStatus && <StatusMessage style={{ color: '#4F80E2', backgroundColor: '#CBDAFA', borderColor: '#4F80E2' }}>냉방</StatusMessage>}
                                        {humStatus && <StatusMessage style={{ color: '#845EC2', backgroundColor: '#ECD0FF', borderColor: '#845EC2' }}>제습</StatusMessage>}
                                        {heatingStatus || waterStatus || coolingStatus || humStatus || <StatusMessage style={{ color: '#17B9B6', backgroundColor: '#AAEAE9', borderColor: '#17B9B6' }}>적정</StatusMessage>}


                                    </Span>




                                    <Line title="주간 온도" titleColor="#FF868B" backgroundColor="#FFE4E3" color="#2A3A4B" unit="℃" int={weekTemp.slice()} />
                                    <Line title="야간 온도" titleColor="#F68F16" backgroundColor="#FFEFCA" color="#2A3A4B" unit="℃" int={nightTemp.slice()} />
                                    <Line title="습도 설정" titleColor="#4F80E2" backgroundColor="#CEE5FF" color="#2A3A4B" unit="%" int={water.slice()} />
                                    <Line title="주/야간 온도변화" titleColor="#845EC2" backgroundColor="#F0DCFF" color="#2A3A4B" unit="℃" int={chageTemp.slice()} />
                                    <Line title="주간 시작시간" titleColor="#17B9B6" backgroundColor="#AAEAE9" color="#17B9B6" unit="시" int={weekStart.slice()} />
                                    <Line title="주간 종료시간" titleColor="#FFFFFF" backgroundColor="#B5B5B5" color="#656565" unit="시" int={weekEnd.slice()} />

                                </Wrap>


                                <Wrap second>
                                    <Dongzak>동작설정</Dongzak>

                                    {/* title titleColor color unit data handleChange */}




                                    <Input title="주간 온도" titleColor="#FF868B" backgroundColor="#FFE4E3" color="#2A3A4B" unit="℃" handleChange={weekTemp_handdle} data={weekTemp} />
                                    <Input title="야간 온도" titleColor="#F68F16" backgroundColor="#FFEFCA" color="#2A3A4B" unit="℃" handleChange={nightTemp_handdle} data={nightTemp} />
                                    <Input title="습도 설정" titleColor="#4F80E2" backgroundColor="#CEE5FF" color="#2A3A4B" unit="%" handleChange={water_handdle} data={water} />
                                    <Input title="주/야간 온도변화" titleColor="#845EC2" backgroundColor="#F0DCFF" color="#2A3A4B" unit="℃" handleChange={chageTemp_handdle} data={chageTemp} />
                                    <Input title="주간 시작시간" titleColor="#17B9B6" backgroundColor="#AAEAE9" color="#17B9B6" unit="시" handleChange={weekStart_handdle} data={weekStart} />
                                    <Input title="주간 종료시간" titleColor="#747474" backgroundColor="#B5B5B5" color="#656565" unit="시" handleChange={weekEnd_handdle} data={weekEnd} />
                                    <Message success={true} />

                                    <Confirm>동작설정</Confirm>
                                </Wrap>
                            </Wrapper>
                        </ModalInner>
                    </ModalWrapper>
                </>
            }

        </>
    )
}

const Dongzak = styled.div`
display:flex ;
width:100% ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 32px;

color: #747474;
`

const Confirm = styled.button`
display:flex ;
width:100% ;
background: #4F80E2;
border-radius: 5px;
height: 4.5rem;

align-items:center ;
justify-content:center ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;

color: #FFFFFF;
`
const TempWrap = styled.div`
width:100% ;
display:flex ;
justify-content:center ;
align-items:center ;
`
const TempImg = styled.img`
width:2rem ;
height:2rem ;
margin-right:3px ;
`
const Temp = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2.6rem;
line-height: 3.2rem;
/* identical to box height */

color: #17B9B6;
`
const Unit = styled.div`
display:flex ;
height:100% ;
/* justify-content:flex-start ; */
align-items: flex-start;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;

color: #17B9B6;
`

const ModalWrapper = styled.div`
  box-sizing: border-box;
 

  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 5%;
  
  margin: 0 auto;
  padding: 3.5rem;

  width: 80rem;
    animation: zoomIn 0.2s none;
`

const CloseButton = styled.div`
display:flex ;
width: 11.5rem;
height: 4.7rem;

line-height:4.7rem ;
background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;

text-align:center ;
justify-content:center ;

color: #FFFFFF;

cursor:pointer ;
`
const Top = styled.div`
display:flex ;
justify-content:space-between ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 2.6rem;
line-height: 46px;


color: #2A3A4B;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`

const Wrap = styled.div`
display:flex ;
/* height: 34.8rem; */
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:2.5rem;
margin-left: 3rem;
`}

`

const Img = styled.img`
width: 11rem ;
height: 11rem ;
`

export default Modal