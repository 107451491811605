import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { apply } from 'file-loader';
import { emailCheck, mobileCheck } from 'js/vaildChecker';
import DaumPostcode from 'react-daum-postcode';
import Modal from 'react-modal';

class farmDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params:{ 
                id: null,
                user: '',
                email: '',
                name: '',
                farmer: '',
                faxNumber: '',
                telephoneNumber: '',
                mobileNumber: '',
                roadNameAddress: '',
                addrDetail: '',
                productCode: '',
                productionCycle : '',
                zipCode: '',
                employeeNumber: '',
                openDate: new Date(),
                contractDate: new Date(),
                note: '',
                area: 1,
                cprId: 1,
                memberId: '',
                latitude: "",
                longitude: ""
            },
            list : [],
            majorClassCode : '',
			productMajorClassList : [],
			productMinorClassList : new Map(),
            zipPop : false ,
            doUpdate:false,
            validCheckList : Object.freeze(['name','roadNameAddress','productCode']),
		};
        this.name = React.createRef();
        this.roadNameAddress = React.createRef();
        this.productCode = React.createRef();
        this.mobileNumber = React.createRef();
        this.email = React.createRef();
		nbind(this);
	}
	componentDidMount() {
        this.apply();
        this.fetchProduct();
        
	}
	apply () {
		let obj = {
			id : this.props.match.params.id
		};
		
		this.props.setLoading(true)
		API.fetchFarmsDetail(obj).then(result => {
			this.setState({
                 params : result
			})
			this.props.setLoading(false);
			
		}).catch(error => {
            console.log(error);
            this.props.setLoading(false);
        });
		
	}

    fetchProduct(){
        API.productClassList().then(result => {
            let majorCategory = []
			let minorCategory = new Map()
			let majorCode = 'CROPS';
            
			result[0].children.forEach(code1 => {
				majorCategory.push(code1);
				minorCategory.set(code1.minorCode, code1.children)
				let majorCodeObject = code1.children.find( one =>one.minorCode == this.state.params.productCode);
				if(majorCodeObject){
					majorCode = code1.minorCode
				}
			})

			this.setState({							
				productMinorClassList : minorCategory,				
				productMajorClassList : majorCategory,
				//majorClassCode : majorCategory[0].minorCode										
				majorClassCode : majorCode							
			})
		}).catch(error=>{console.log(error)}) 
        
        
    }

    update(){

        // const mobileValid = mobileCheck(this.state.mobileNumber);
        // if(!mobileValid){
        //     alert('핸드폰 번호는 - 을 포함한 양식으로 입력 해 주세요.');
        //     document.getElementById('mobilePhoneNumber').focus();
        //     return;
        // } 

        // const emailValid = emailCheck(this.state.email);
        // if(emailValid){
        //     alert('유효한 이메일 형식이 아닙니다.');
        //     document.getElementById('email').focus();
        //     return;
        // }
        for(let i = 0; i < this.state.validCheckList.length; i++){
            let target = this.state.validCheckList[i];
            if(!this.state.params[target] || !this.state.params[target].trim()){
                this[target].current.focus();
                return alert('유효한 입력이 아니거나 필수값이 입력되지 않았습니다');
            }
        }
        if(!this.state.params.email){
            const flag = emailCheck(this.state.params.email);
            if(!flag){
                this.email.current.focus();
                return alert('유효한 이메일 형식이 아닙니다.')
            }
        }
        if(!mobileCheck(this.state.params.mobileNumber)){
            this.mobileNumber.current.focus();
            return alert('유효한 전화번호 형식이 아닙니다.')
        }
        this.props.setLoading(true)
        
        API.fetchFarmsUpdate(this.state.params).then(result => {
            this.setState({});
			this.props.setLoading(false);
			alert("수정 되었습니다");
		}).catch(error => {
            console.log(error);
            this.props.setLoading(false);
        });
    }
    handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        let zonecode = data.zonecode
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }

        // console.log(data);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
        // console.log(fullAddress);  // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
        // console.log(this.state.params)
        
        let t = this
        var callback = function(result, status) {
            if (status === "OK") {
                // console.log(result);
                // result[0]
                // x: "126.519268181444"
                // y: "34.369640578845"
                let params = {
                    ...t.state.params,
                    roadNameAddress: fullAddress,
                    zipCode: zonecode,
                    latitude : result[0].x,
                    longitude : result[0].y
                }
                t.setState({
                    zipPop:false,
                    params : params 
                })
            }
        };

        var geocoder = new window.kakao.maps.services.Geocoder();

        

        geocoder.addressSearch(fullAddress, callback);

       
    }
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장 상세정보</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox tableFixed detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>농장명</th>
                                            <td><input type="text"  value={this.state.params.name || ''} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.name = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }
                                                    readOnly={!this.state.doUpdate}
                                                    ref={this.name}
                                                /></td>
                                            <th>농장주 아이디</th>
                                            <td><input type="text" value={this.state.params.userId || ''} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.memberId = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={!this.state.doUpdate}
                                            /></td>
                                        </tr>
                                        <tr>
                                            <th>팩스번호</th>
                                            <td><input type="text" value={this.state.params.faxNumber || ''} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.faxNumber = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }
                                                    readOnly={!this.state.doUpdate}
                                            /></td>
                                            <th>전화번호</th>
                                            <td><input type="text"value={this.state.params.telephoneNumber || ''} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.telephoneNumber = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={!this.state.doUpdate}
                                            /></td>
                                        </tr>
                                        <tr>
                                            <th>이메일</th>
                                            <td><input type="text" id="email" value={this.state.params.email || ''} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.email = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }
                                                    readOnly={!this.state.doUpdate}
                                                    ref={this.email}
                                            /></td>
                                            <th>핸드폰번호</th>
                                            <td><input type="text"  id="mobilePhoneNumber" value={this.state.params.mobileNumber || ''} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.mobileNumber = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={!this.state.doUpdate}
                                                    ref={this.mobileNumber}
                                            /></td>
                                        </tr>
                                        <tr>
                                            <th>주소</th>
                                            <td colSpan="2"><input type="text" className='width100' 
                                                value={this.state.params.roadNameAddress}
                                                readOnly={!this.state.doUpdate}
                                                ref={this.roadNameAddress}
                                                /></td>
                                            {this.state.doUpdate &&
                                            <td><button className="btn ml120" onClick={()=>this.setState({zipPop : true})} >주소 검색</button></td>
                                            }
                                        </tr>
                                        
                                        <tr>
                                            <th>상세주소</th>
                                            <td><input type="text" value={this.state.params.addrDetail} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.roadNameAddress = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    }  
                                                    readOnly={!this.state.doUpdate}
                                            /></td>
                                            <th>우편번호</th>
                                            <td><input type="text" value={this.state.params.zipCode} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.zipCode = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={!this.state.doUpdate}
                                                    />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>위도</th>
                                            <td><input type="text" value={this.state.params.latitude} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.latitude = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly
                                                    />
                                            </td>
                                            <th>경도</th>
                                            <td><input type="text" value={this.state.params.longitude} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.longitude = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly
                                                    />
                                            </td>
                       
                                        </tr>
                                        <tr>
                                            <th>생산물 카테고리</th>
                                            <td> 
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.majorClassCode} placeholder="농작물 종류"
														onChange={(e) => {this.setState( {majorClassCode: e.target.value})}}
                                                    disabled={!this.state.doUpdate}
                                                    >
														{
													        this.state.productMajorClassList.length > 0 && this.state.productMajorClassList.map((item,index)=>{
																return(
																	<option value={item.minorCode} key={index}>{item.name}</option>
																)
															})
														}
                                                    </select>
                                                </div></td>
                                            <th>생산물</th>
                                            <td>
                                            <div className="selectBox">
                                                    <select name="" id="" value={this.state.params.productCode} placeholder="농작물 종류"
														onChange={(e) => {this.setState({ params : {...this.state.params, productCode: e.target.value}})}}
                                                        disabled={!this.state.doUpdate}
                                                        ref={this.productCode}
                                                    >
														<option value="">선택</option>
														{
															(this.state.majorClassCode != undefined && this.state.productMinorClassList.get(this.state.majorClassCode) != undefined) && 
                                                                this.state.productMinorClassList.get(this.state.majorClassCode).map((item,index)=>{
																return(
																	<option value={item.minorCode} key={index}>{item.name}</option>
																)
															})
														}												
                                                    </select>
                                                </div>
                                            </td>
                                            
                                        </tr>       
                                        <tr>
                                            <th>직원수</th>
                                            <td><input type="text" value={this.state.params.employeeNumber} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.employeeNumber = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={!this.state.doUpdate}
                                             /></td>
                                            <th>설립일</th>
                                            <td>
                                            <DatePicker
                                                    className="datePickerLib"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date(this.state.params.openDate)}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.openDate; } 
                                                        let data = this.state.params; 
                                                        data.openDate = String(date.toISOString()); 
                                                        this.setState({ params: data }) 
                                                    }}
                                                    disabled={!this.state.doUpdate}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>가동일자</th>
                                            <td>
                                                <DatePicker
                                                    className="datePickerLib"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date(this.state.params.contractDate)}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.contractDate; } 
                                                        let data = this.state.params; 
                                                        data.contractDate = String(date.toISOString()); 
                                                        this.setState({ params: data }) 
                                                    }}
                                                    disabled={!this.state.doUpdate}
                                                />
                                            </td>
                                            <th>면적</th>
                                            <td><input type="number"  value={this.state.params.area} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.area = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={!this.state.doUpdate}
                                             /></td>
                                        </tr>
                                        <tr>
                                            <th>비고</th>
                                            <td colSpan="3"><input type="text"  value={this.state.params.note} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.note = e.target.value////////
                                                        this.setState({ params: data })
                                                        }
                                                    } 
                                                    readOnly={!this.state.doUpdate}
                                            /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button  className="btn mr5 mt10" onClick={!this.state.doUpdate ?  ()=>this.setState({doUpdate:true}) : ()=>{this.update()}}>
                                    {!this.state.doUpdate ? '수정' : '수정 완료'}
                                </button>
                                <button onClick={this.state.doUpdate ? ()=>this.setState({doUpdate : false}) : ()=>{this.props.history.goBack()}} className="btn gray ml30">
                                    {this.state.doUpdate ? '수정 취소' : '목록'}
                                </button>
                            </div>
                        </div>
					</div>
                    <Modal
						isOpen={this.state.zipPop}
						onRequestClose={()=>{this.setState({zipPop : false})}}
						shouldCloseOnOverlayClick={true}
						className="modalBox modalSizeS"
						style={{ content: { minWidth: 600, minHeight:500 } }}
					>
						<DaumPostcode
                            style={{height:500}}
                            onComplete={this.handleComplete}
                                                
                        />
					</Modal>
				</div>
			</React.Fragment>
		);
	}
}
export default farmDetail;