import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { image_mapping, offImage_mapping } from 'components/utils/mapping'

import Line from "./include/leftdown"
import Temparature from "img/icons_item/temperature.png"
import Swich from './include/switch'
import Message from './include/message'
import Input from './include/input'
import { useInput } from 'hooks/commonHooks'









function Modal(props) {
    const [data, set_data] = useState([])
    const [sensors, set_sensors] = useState([])

    const [isOn, set_isOn] = useState(false)
    const [temp, temp_handdle, set_temp] = useInput('')

    const [settingTemp, settingTemp_handdle, set_settingTemp] = useInput('')

    const [min,min_handdle,set_min]=useInput(0)
    const [max,max_handdle,set_max]=useInput(0)

    // title unit data datahandle

    const handleChange = (e) => {
        set_isOn(e.target.checked);
    };

    const onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            props.onClose(e)
        }
    }

    const close = (e) => {
        if (props.onClose) {
            props.onClose(e)
        }
    }

    const array_find = (arr, keyword) => {

        let return_arr = {}
        return_arr = arr.filter(val => {
            if (val.tagClass.code == keyword) {
                return true
            }
            return false
        })
        
        if (return_arr.length == 0)
            return_arr = [{ 'value': 'err' }]
        return return_arr[0]
    }

    // 온도MIN:AIRCONTEMPMIN
    // 온도MAX:AIRCONTEMPMAX

    useEffect(() => {
        if (props.length != 0) {
            set_sensors(props.sensors)
            set_data(props.data)
            
            props.sensors.map(val=>{console.log(val.name+':'+val.tagClass.code)})
            if(props.data.length!=0)
            set_temp(array_find(props.data.sensors, "CARBONHEATSTATBIT")["value"])

            set_min(array_find(props.sensors, "HEATMOTORTEMPMIN")["value"])
            set_max(array_find(props.sensors, "HEATMOTORTEMPMAX")["value"])
            

            if (props.status.value == 0)
                set_isOn(false)
            else if (props.status.value == 1)
                set_isOn(true)
        }
    }, [props])

    return (
        <>
            {data.length == 0 ||
                <>
                    <ModalOverlay visible={props.visible} />
                    <ModalWrapper onClick={onMaskClick} visible={props.visible}>
                        <ModalInner>
                            <Top>
                                <Title>{data.name}</Title>
                                <CloseButton onClick={close}>닫기</CloseButton>
                            </Top>

                            <Wrapper>
                                <Wrap>
                                    {isOn == true ?
                                        <>
                                            <Img src={image_mapping[data.equipClass.equipClassId]} />
                                            <TempWrap>
                                                <Temp>작동 중</Temp>
                                            </TempWrap>
                                        </>
                                        :
                                        <>
                                            <Img src={offImage_mapping[data.equipClass.equipClassId]} />
                                            <TempWrap>
                                                <Temp style={{ color: "#898C8F" }}>미 작동</Temp>
                                            </TempWrap>
                                            </>
                                    }

                                    <Swich isOn={isOn} handleChange={handleChange} />

                                    <Message success={true} />

                                    
                                </Wrap>


                                <Wrap second>
                                    <Dongzak>동작설정</Dongzak>

                                  {/* title titleColor color unit data handleChange */}
                                    

                                    
                                  <Input titleColor="#747474" color="#2A3A4B" title="MIN" data={min} handleChange={min_handdle} unit="℃" />
                                  <Input titleColor="#17B9B6" color="#17B9B6" title="MAX" data={max} handleChange={max_handdle} unit="℃" />
                                    <Message success={true} />

                                    <Confirm>동작설정</Confirm>
                                </Wrap>
                            </Wrapper>
                        </ModalInner>
                    </ModalWrapper>
                </>
            }

        </>
    )
}

const Dongzak = styled.div`
display:flex ;
width:100% ;
font-family: Spoqa Han Sans Neo;
font-style: normal;

font-size: 2rem;
margin-bottom: 1.6rem;
font-weight: 500;
color: #747474;
`

const Confirm = styled.button`
display:flex ;
width:100% ;
background: #4F80E2;
border-radius: 5px;
height: 4.5rem;

align-items:center ;
justify-content:center ;

font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 32px;

color: #FFFFFF;
`
const TempWrap = styled.div`
width:100% ;
display:flex ;
justify-content:center ;
align-items:center ;
margin-bottom:20px ;
`

const Temp = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 32px;
line-height: 46px;
/* identical to box height */

color: #17B9B6;
`
const Unit = styled.div`
display:flex ;
height:100% ;
justify-content:flex-start ;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 26px;

color: #17B9B6;
`

const ModalWrapper = styled.div`
  box-sizing: border-box;
 

  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`

const ModalInner = styled.div`
  display:flex ;
  flex-direction:column ;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 10px;

  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 3.5rem;

  width: 80rem;
`

const CloseButton = styled.div`
display:flex ;
width: 11.5rem;
height: 4.7rem;

line-height:4.7rem ;
background: #17B9B6;
border-radius: 5px;
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 1.8rem;

text-align:center ;
justify-content:center ;

color: #FFFFFF;

cursor:pointer ;
`
const Top = styled.div`
display:flex ;
justify-content:space-between ;
`
const Title = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: bold;
font-size: 2.6rem;



color: #2A3A4B;
`

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:1.5rem ;
`

const Wrap = styled.div`
display:flex ;
height: 34.8rem;
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:2.5rem;
margin-left: 3rem;
`}

`

const Img = styled.img`
width: 13rem ;
height: 13rem ;
margin-bottom: 1.6rem;
`

export default Modal