import React, { Suspense } from 'react';

import { nbind,reverseIndexing } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import SearchBox from 'components/common/searchBox';
import { Link } from "react-router-dom";
import * as common from "js/common";


class FarmInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            farmInfo :{
                "id":1,
                "memberId":1,
                "cprId":1,
                "treeNodeId":1,
                "name":"",
                "productCode":"",
                "telephoneNumber":null,
                "mobileNumber":null,
                "faxNumber":null,
                "email":null,
                "roadNameAddress":"",
                "addrDetail":"",
                "zipCode":"",
                "latitude":"",
                "longitude":"",
            },
            farmContainerList:[],
            farmUserList: [],
            farmList : {
                list : [],
                pageIndex:1,
                pageSize : 5,
                Id : null,
                name : null,
                pageListSize : 5,
                area: '',
                installDate: null,
                containerId: null,
                keyword: '',
                totalElements : 0,
                request : {
                    params : {
                        size : 5,
                        page: 1,
                    }
                }
                
            },
            userList : {
                list : [],
                pageIndex:1,
                pageSize : 5,
                controlAuthorYn : '',
                Id : null,
                name : null,
                containerId: null,
                pageListSize : 5,
                contractDate: null,
                installDate : null,
                keyword: '',
                totalElements: 0,
                request : {
                    params : {
                        size : 5,
                        page: 1
                    }
                }
            },
            checkListFarm : [],
            checkListfarmAll : false,
            checkListUser : [],
            checkListUserAll : false,

			

		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
        this.onChangePage2= this.onChangePage2.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
        this.onSearchChange2 = this.onSearchChange2.bind(this);

	}
    //페이징
	onChangePage(index) {
       // console.log(index);
		this.setState({
            farmList : {
                ...this.state.farmList,
                pageIndex: index
            }}, ()=>{
			let obj = this.state.farmList.request;
			obj.params.page = Number(index) - 1;
            obj.params.farmId = this.props.match.params.id;
			this.farmList(obj);
		});

	}
    
	onChangePage2(index) {
		this.setState({pageIndex : index},()=>{
			let obj = this.state.userList.request;
			obj.params.page = Number(index) - 1;
            obj.params.farmId = this.props.match.params.id;
			this.userList(obj);
		});
	}
    
    //검색
    onSearchChange(i, value) {
        value.params['farmId'] = this.props.match.params.id;
        this.setState({pageIndex : i, request : value}, () => {this.farmList(value)});
    };
    onSearchChange2(i, value) {
		//console.log(value);
        value.params['farmId'] = this.props.match.params.id;
        this.setState({pageIndex : i, request : value}, () => {this.userList(value)});
    };    


	componentDidMount() {
        let obj = {
			params : {
				size : 5,
				page: 0,
                farmId : this.props.match.params.id,
			}
		};

		this.apply();
        this.farmList(obj);
        this.userList(obj);
       // console.log(this.props);
		// setTimeout(() => {
		// 	let email = this.props.user.userInfo['userData'] ?this.props.user.userInfo['userData']['email'] : '';
		// 	let name = this.props.user.userInfo['userData'] ?this.props.user.userInfo['userData']['name'] : '';
		// 	this.setState({email ,name })
		// }, 300);
	}
	apply () {
		let obj = {
			id : this.props.match.params.id
		};
		API.fetchFarmsDetail(obj).then(result => {
            //console.log(result);
            this.setState({farmInfo : result});
		}).catch(error => console.log(error));
		
	}

    farmList(obj){
        if(this.state.farmList.keyword != '' ){
			obj['name'] = this.state.farmList.keyword
		}
        API.farmContainerList(obj).then(result => {
          
            this.setState({
                farmContainerList : result.content,
                farmList:{
                ...this.state.farmList,
                list:result.content,
                pageSize: result.totalPages,
                pageIndex : (result.pageable.pageNumber)+1,
                totalElements : result.totalElements,
                },
                checkListFarm:[],
                checkListFarmAll :false,
            });
		}).catch(error => console.log(error));
    }

    searchContent = [
        {
            name : "이름",
            classCode : 'name',
            type : 'TEXT'
        
        }
    ];


    userList(obj){
      
        if(this.state.userList.keyword != '' ){
			obj['name'] = this.state.userList.keyword
		}
        API.farmUseraccess(obj).then(result => {
            this.setState({
                farmUserList : result.content,
                userList:{
                ...this.state.userList,
                list:result.content,
                pageSize: result.totalPages,
                pageIndex : result.pageable.pageNumber + 1,
                totalElements : result.totalElements
                },
                checkListUser:[],
                checkListUserAll :false,
            });
		}).catch(error => console.log(error));

		
    }
    
    searchContent2 = [
        {
            name : "이름",
            classCode : 'name',
            type : 'TEXT'
        }
    ];

    handleCheckboxChange = event => {
		let newArray = [...this.state.checkListFarm, event.target.value];
		if (this.state.checkListFarm.includes(event.target.value)) {
		   newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		   checkListFarm: newArray
		});
	};
	handleCheckboxChangeAll = e =>{
		
		let list = []

		if(e.target.checked ){
			for (let i in this.state.farmList.list){
				list.push(String(this.state.farmList.list[i].id))		
			}
		}
		this.setState({
			checkListfarmAll : e.target.checked,
			checkListFarm : list
		})
	}
	deletefarm() {
        
        
		if(this.state.checkListFarm.length < 1){
			
            alert('선택된 목록이 없습니다.');

		}
        
		let obj = {
			ids: this.state.checkListFarm
		};
        

		
		if(window.confirm('선택하신 농장을 삭제하시겠습니까?')){
			this.props.setLoading(true)
			API.farmContainerDelete(obj).then(result => {

                let obj2 = {
                    params : {
                     size : 5,
                     page: 0,
                     farmId : this.props.match.params.id,
                    }
                }
                
				this.props.setLoading(false);
                alert("삭제 되었습니다");
				this.farmList(obj2);
			}).catch(error => console.log(error));
		}
		
		
	}
    handleCheckboxChangeUser = event => {
		let newArray = [...this.state.checkListUser, event.target.value];
		if (this.state.checkListUser.includes(event.target.value)) {
		  newArray = newArray.filter(day => day !== event.target.value);
		} 
		this.setState({
		  checkListUser: newArray
		});
	};
	handleCheckboxChangeUserAll = e =>{
		
		let list = []

		if(e.target.checked ){
			for (let i in this.state.userList.list){
				list.push(String(this.state.userList.list[i].id))		
			}
		}
		this.setState({
			checkListUserAll : e.target.checked,
			checkListUser : list
		})
	}
	deleteUser() {
		if(this.state.checkListUser.length < 1){
			alert('선택된 목록이 없습니다.');
            return;
		}
		let obj = {
			ids: this.state.checkListUser
		};
		if(window.confirm('삭제하시겠습니까?')){
			this.props.setLoading(true);
			API.farmUserDelete(obj).then(result => {
                let obj2 = {
                    params : {
                        size : 5,
                        page: 0,
                        farmId : this.props.match.params.id,
                    }
                }
				this.props.setLoading(false);
				this.userList(obj2);
			}).catch(error => console.log(error));
		}
	}
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">농장 정보</h2>
                        <div className="">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td><input value={this.state.farmInfo.name} readOnly type="text" /></td>
                                            <th>농장주</th>
                                            <td><input value={this.state.farmInfo.userId} readOnly type="text" /></td>
                                            <th>팩스번호</th>
                                            <td><input value={this.state.farmInfo.faxNumber} readOnly type="text" /></td>
                                            <th>우편번호</th>
                                            <td><input value={this.state.farmInfo.zipCode} readOnly type="text" /></td>
                                        </tr>
                                        <tr>
                                            <th>이메일</th>
                                            <td><input value={this.state.farmInfo.email} readOnly type="text" /></td>
                                            <th>전화번호</th>
                                            <td><input value={this.state.farmInfo.mobileNumber} readOnly type="text" /></td>
                                            <th>주소</th>
                                            <td><input value={this.state.farmInfo.roadNameAddress} readOnly type="text" /></td>
                                            <th>상세주소</th>
                                            <td><input value={this.state.farmInfo.addrDetail} readOnly type="text" /></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h3 className="subTitle">시설동</h3>
                            
                                    <SearchBox 
                                        searchContent = {this.searchContent}
                                        onSearchChange = {this.onSearchChange }
                                        pageIndex = {this.state.farmList.pageIndex}
                                        pageSize = {this.state.farmList.pageSize}
                                        pageListSize = {this.state.farmList.pageListSize}
                                    />
                            <div className="tableBox mt30">
                                <table>
									<colgroup>"
										<col width="80"/>
										<col width="50"/>
										<col width="300"/>
										<col width="150"/>
										<col width="250"/>
										<col width="150"/>
										<col width="150"/>
										
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th><div className="checkBox"><input type="checkbox" name="type" id="all" onChange={this.handleCheckboxChangeAll} checked={this.state.checkListfarmAll}/> <label htmlFor="all"></label></div></th>
                                            <th>번호</th>
                                            <th>이름</th>
                                            <th>시설동 종류</th>
                                            <th>면적</th>
                                            <th>설치일</th>
                                            <th></th>
                                        </tr>
                                        
                                    </thead>
                                    <tbody>                                    
											{this.state.farmContainerList.map((item, index) => {
												return (
										<tr key={index}>
                                            <td className="textCenter">
                                            <div className="checkBox"><input type="checkbox" name="type" id={'d'+item.id} value={item.id} onChange={this.handleCheckboxChange} checked={this.state.checkListFarm.indexOf(String(item.id)) > -1} /> <label htmlFor={'d'+item.id}></label></div>
											</td>
                                            <td className="textCenter">{reverseIndexing(this.state.farmList.totalElements, this.state.farmList.pageIndex,index, this.state.farmList.pageListSize)}</td>
                                            <td className="textCenter"><a href="javascript:void(0);" onClick={() => { this.props.history.push({ pathname: `/farm/${item.id}/info/containerDetail/${item.containerId}`}) }}>{item.name}</a> </td>
                                            <td className="textCenter">{item.classCode}</td>
                                            <td className="textCenter">{item.area}</td>
                                            <td className="textCenter">
                                            {common.toDateString(item.installDate)}
                                            </td>
                                            <td className="textCenter">
                                            <button className="btn blue small" onClick={() => { this.props.history.push({ pathname: `/farm/${item.id}/info/${item.containerId}/product`, state: { farmName : "농장#1", containerName : "시설동#1", }  }) }}>생산물</button>
                                            </td>
                                        </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to={`/farm/${this.props.match.params.id}/info/containerRegister`} className="btn mr10 mt10">등록</Link>
									<button  onClick={() =>{ this.deletefarm()}} className="btn mr10 mt10">삭제</button>
                                </div>
                                <Pager pageIndex={this.state.farmList.pageIndex} pageSize={this.state.farmList.pageSize} onChangePage={this.onChangePage} />
                            </div>

                            {/*  */}
                            <h3 className="subTitle">농장 접근 가능 사용자</h3>
                           
                                 <SearchBox 
                                        searchContent = {this.searchContent2}
                                        onSearchChange = {this.onSearchChange2 }
                                        pageIndex = {this.state.userList.pageIndex}
                                        pageSize = {this.state.userList.pageSize}
                                        pageListSize = {this.state.userList.pageListSize}
                                    />
                            
                            <div className="tableBox mt30">
                                <table>
									<colgroup>
                                    <col width="50"/>
										<col width="50"/>
										<col width="300"/>
										<col width="150"/>
										<col width="250"/>
									</colgroup>
                                    <thead>
                                        <tr>
                                            <th><div className="checkBox"><input type="checkbox" name="type" id="all2" onChange={this.handleCheckboxChangeUserAll} checked={this.state.checkListUserAll}/> <label htmlFor="all2"></label></div></th>
                                            <th>번호</th>
                                            <th>ID</th>
                                            <th>이름</th>
                                            <th>제어권한</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.farmUserList.map((item, index) => {
												return (
										<tr key={index}>
                                            <td className="textCenter">
												<div className="checkBox"><input type="checkbox" name="type" id={'t'+item.id} value={item.id} onChange={this.handleCheckboxChangeUser} checked={this.state.checkListUser.indexOf(String(item.id)) > -1}/> <label htmlFor={'t'+item.id}></label></div>
											</td>
                                            <td className="textCenter">{reverseIndexing(this.state.userList.totalElements, this.state.userList.pageIndex,index, this.state.farmList.pageListSize)}</td>
                                            <td className="textCenter"><a href="javascript:void(0);" onClick={() => { this.props.history.push({ pathname: `/farm/${item.farmId}/info/userDetail/${item.id}` }) }}>{item.userId}</a> </td>
                                            <td className="textCenter">{item.userName}</td>
                                            <td className="textCenter">{item.controlAuthorYn}</td>                                            
                                        </tr> )
                                        })                                        
                                    }
                                    </tbody>
                                </table>
                                <div className="textRight">
                                    <Link to={`/farm/${this.props.match.params.id}/info/userRegister`} className="btn mr10 mt10">등록</Link>
									<button  onClick={() =>{ this.deleteUser()}}className="btn mr10 mt10">삭제</button>
                                </div>
                                <Pager pageIndex={this.state.userList.pageIndex} pageSize={this.state.userList.pageSize} onChangePage={this.onChangePage2} />
                            </div>
                            
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default FarmInfo;