import { API } from "service"
import * as zoom from 'chartjs-plugin-zoom'

import { useEffect, useRef } from 'react';

export function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }


const makeColor=(index)=>{
    let colors=''
    switch(index){
        case 0:colors='#ff0000';break;
        case 1:colors='#ff8c00';break;
        case 2:colors='#ffff00';break;
        case 3:colors='#008000';break;
        case 4:colors='#0000ff';break;
        case 5:colors='#4b0082';break;
        case 6:colors='#800080';break;
        default:colors="#"+Math.round(Math.random()*0xffffff).toString(16)
    } 
        
    return colors
}

export function getTimeStamp(integer) {
    var d=new Date()
    if(typeof integer=='number')
        d.setDate(d.getDate()-30)
        
    var s =
        leadingZeros(d.getFullYear(), 4) + '-' +
        leadingZeros(d.getMonth() + 1, 2) + '-' +
        leadingZeros(d.getDate(), 2); 

    return s;
} 

function leadingZeros(n, digits) {
    var zero = '';
    n = n.toString(); 

    if (n.length < digits) {
        for (let i = 0; i < digits - n.length; i++)
            zero += '0';
    }

    return zero + n;
}





export const data2graph=(data,sensors,equips,obj,tag2unit,broad=[],selectbroad=[])=>{
    let label=[]
    let tagIds=[]
    data=data.filter(val=>{
        if (label.indexOf(val.time)==-1)
            label.push(val.time)

        if( obj[val.tagId]==undefined)
            return false
        if(tagIds.indexOf(val.tagId)==-1)
            tagIds.push(val.tagId)
        return true
    })

    let datasets=[]
    selectbroad.map(val=>datasets[val]=[])


    if(broad.length!=0){
      
        
        broad.map(val=>{
            let a = label.filter(t=>{
                if(t==val.tm)
                    return true
            })
                
            if(a.length!=0){
                selectbroad.map(broad_data=>{                
                    datasets[broad_data].push(val[broad_data])
                })
            }
        })}

     
    for(let tagId of tagIds)
        datasets[tagId]=[]
    
    data.map(val=>{
        // if(val.value==null)
        //     datasets[val.tagId].push(0)
        // else
            datasets[val.tagId].push(val.value)
    })

    let x_dataset=[]
    let y_axes=[]
    let index=0


    for(let sensor of sensors){
        let x_data={
            label:obj[sensor]+`(${tag2unit[sensor]||''})`,
            fill:false,
            yAxisID: 'y_label'+index,
            data:datasets[sensor],
            lineTension : 0.3,  // 0이면 꺾은선 그래프, 숫자가 높을수록 둥글해짐
                    pointRadius : 4,    // 각 지점에 포인트 주지 않음
                    borderColor: makeColor(index)
                    
        }

        let local_max=Math.max.apply(null,datasets[sensor])
        let local_min=Math.min.apply(null,datasets[sensor])

        let y_obj={
            id:'y_label'+index,
            ticks: {
                fontColor:'#747474', // y축 폰트 color
                max:local_max>1?local_max:1,
                min:local_min
            },
            display:false,
         }
        x_dataset.push(x_data)
        y_axes.push(y_obj)

        index+=1

    }
    


    for(let equip of equips){

        let x_data={
            label:obj[equip],
            fill:false,
            yAxisID:'y_label'+index,
            data:datasets[equip],
            lineTension : 0.3,  // 0이면 꺾은선 그래프, 숫자가 높을수록 둥글해짐
                    pointRadius : 4,    // 각 지점에 포인트 주지 않음
                    borderColor: makeColor(index)
        }


        let local_max=Math.max.apply(null,datasets[equip])
        let local_min=Math.min.apply(null,datasets[equip])

        let y_obj={
            id:'y_label'+index,
            ticks: {
                fontColor:'#747474', // y축 폰트 color
                max:local_max>1?local_max:1,
                min:local_min
            },
            display:false,
         }

      

        
        x_dataset.push(x_data)
        y_axes.push(y_obj)

        index+=1
        
    }
  

    for(let broadsensor of selectbroad){
        let x_data={
            label:obj[broadsensor]+`(${tag2unit[broadsensor]||''})`,
            fill:false,
            yAxisID:'y_label'+index,
            
            data:datasets[broadsensor],
            lineTension : 0.3,  // 0이면 꺾은선 그래프, 숫자가 높을수록 둥글해짐
                    pointRadius : 4,    // 각 지점에 포인트 주지 않음
                    borderColor: makeColor(index)
        }

        let local_max=Math.max.apply(null,datasets[broadsensor])
        let local_min=Math.min.apply(null,datasets[broadsensor])

        let y_obj={
            id:'y_label'+index,
            ticks: {
                fontColor:'#747474', // y축 폰트 color
                max:local_max>1?local_max:1,
                min:local_min
            },
            display:false,
         }
        x_dataset.push(x_data)
        y_axes.push(y_obj)

        index+=1

    }
    

    

    let graph={
        type:'line',
        data:{
            // labels:label.map(val=>{return val.slice(0,10)}),
            labels:label,

            datasets:x_dataset,
            
        },options: {
            animation: {
                duration: 0
            },
            responsive: true,

            tooltips: { 
                mode: 'point', 
            intersect: false, 
        }, 

            legend: {
                 labels: {
                      fontColor: '#747474' // label color
                     }
                  },
           
            scales: {
               
                yAxes:  y_axes
                ,
                 // x축
                 xAxes: [{
                    ticks: {
                        fontColor:'#747474' // x축 폰트 color
                    }
                 }]
            },

            //Graph zoom in&out code
            // plugins: {
            //     zoom: {
            //         // Container for pan options
            //         pan: {
            //             // Boolean to enable panning
            //             enabled: true,
    
            //             // Panning directions. Remove the appropriate direction to disable 
            //             // Eg. 'y' would only allow panning in the y direction
            //             mode: 'y'
            //         },
    
            //         // Container for zoom options
            //         zoom: {
            //             // Boolean to enable zooming
            //             enabled: true,
    
            //             // Eg. 'y' would only allow zooming in the y direction
            //             mode: 'y',
            //             speed:0.025,
            //             rangeMax: {
            //                 // Format of max zoom range depends on scale type
            //                 y: max+max*0.1
            //             },
            //             rangeMin:{
            //                 y: min-max*0.1
            //             }
            
            //         }
            //     }
            // }


        }
    }

    return graph

    

}



// export default api