import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class deviceDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params :{
				classCode: "1",
				classValue: 1,
				containerId: 1,
				containerName: "테스트#1",
				delYn: "N",
				farmGatewayId: 1,
				farmGatewayName: "farm gateway#1",
				id: 9,
				name: "testnew#1",
				powerOnYn: null,
				useYn: "N",
			},
			containerList : [],
			deviceTypeList : []
		};

		nbind(this);
	}
	
	componentDidMount() {
		this.dataList()
		this.callData();
	}
    
	callData () {
		let obj = {
			id : this.props.match.params.deviceId
		};
		this.props.setLoading(true)
		API.deviceDetail(obj).then(result => {
			this.setState({
				params : result
			})
			this.props.setLoading(false)
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
		
		
	}
    dataList(){		
		let obj = {
			id : this.props.match.params.id
		}
		API.gatewayContainerList(obj).then(result => {
			this.setState({containerList : result})	
			
									
		}).catch( error => console.log(error));
		API.deviceTypeList(obj).then(result => {
            this.setState({deviceTypeList : result})
		}).catch(error => console.log(error));			
	}
   
	update(){
        this.props.setLoading(true)
        
        API.deviceUpdate(this.state.params).then(result => {
			this.callData()
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
    }
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">장치 상세정보</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td>
															  <input type="text" value={this.state.params.name} onChange={(e) => {
                                                        let data = this.state.params; 
                                                        data.name = e.target.value///////////
                                                        this.setState({ params: data })
                                                    }}/>
															</td>
                                            <th>농장</th>
                                            <td><input value={this.state.params.farmGatewayName} readOnly type="text"/></td>
                                        </tr>
                                        <tr>
                                            <th>시설동</th>
                                            <td>
											<div className="selectBox w100">
													<select name="" id="" value={this.state.params.containerId} placeholder="제목 + 내용"
															onChange={(e) => {
															let data = this.state.params; 
															data.containerId = e.target.value///////////
															this.setState({ params: data })
														}}>
															<option value="">선택</option>
															{this.state.containerList.map((item,index)=>{
																return(
																	<option value={item.id}>{item.name}</option>
																)
															})}
															
													</select>
                                                </div>
											</td>
                                            <th>장치종류</th>
                                            <td>
                                                <div className="selectBox w100">
													<select name="" id="" value={this.state.params.classCode} placeholder="제목 + 내용"
															onChange={(e) => {
															let data = this.state.params; 
															data.classCode = e.target.value///////////
															this.setState({ params: data })
														}}>
															<option value="">선택</option>
															{this.state.deviceTypeList.map((item,index)=>{
																return(
																	<option value={item.code}>{item.name}</option>
																)
															})}
															
													</select>
                                                </div>
                                            </td>
                                        </tr>
										<tr>
											<th>사용여부</th>
                                            <td>
											<div className="selectBox">
													<select name="" id="" value={this.state.params.useYn} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.useYn = e.target.value///////////
															this.setState({ params: data })
														}}>
															
															<option value="Y">사용</option>
															<option value="N">사용안함</option>
													</select>
												</div>
                                            </td>
										</tr>
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button onClick={()=>{this.update()}} className="btn mr5 mt10">수정</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default deviceDetail;