import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class containerDetail extends React.Component {
	constructor(props) {
        
		super(props);
		this.state = {
            id : null,
            name : '',
            classCode : null,
            maxLodage : null,
            proprietyLoadage : null,
            rowNumber : null,
            colNumber : null,
            layerNumber : null,
            installDate : new Date(),
            area : null,
            seq : null,
            useYn : '',
            pushYn : '',
            note : '',            

            containerClass : []
		};
		
        nbind(this);
	}
	componentDidMount() {
        this.callData()
        this.getContainerClass()
	}
    callData(){
        this.props.setLoading(true)
            let obj = {id : this.props.match.params.id}
            API.containerDetail(obj).then(result => {
                console.log("result다")
                console.log(result)
                this.setState({
                    ...result
                });
                this.props.setLoading(false)
            }).catch(error => {
                console.log(error)
                this.props.setLoading(false)
            });
    }
    getContainerClass(){
        API.containerClass().then(result => {
            this.setState({
                containerClass : result
            })
        }).catch(error => console.log(error));
    }
    update(){
        this.props.setLoading(true)
        API.containerUpdate(this.state).then(result => {
            console.log(result);
            this.setState({
                result
            })
            this.props.setLoading(false);
        }).catch(error => {
            console.log(error)
            this.props.setLoading(false);
        });
    }

    
	
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">시설동 상세정보</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td><input type="text" value={this.state.name} onChange={(e) => {
                                                    this.setState({name : e.target.value});
                                                }
                                            }/></td>
                                            <th>시설동 종류</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.classCode} placeholder="시설동 종류"
                                                    onChange={(e) => {
                                                        this.setState({classCode: e.target.value})
                                                    }}>
                                                      {this.state.containerClass.map((item,index)=>{
                                                          return(
                                                              <option value={item.minorCode}>{item.name}</option>
                                                          )
                                                      })}
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
										<tr>
                                            <th>최재 적재수</th>
                                            <td><input type="number" value={this.state.maxLodage} onChange={(e) => {
                                                    this.setState({maxLodage : e.target.value});
                                                }
                                            }/></td>
                                            <th>적정 적재수</th>
                                            <td><input type="number" value={this.state.proprietyLoadage} onChange={(e) => {
                                                    this.setState({proprietyLoadage : e.target.value});
                                                }
                                            }/></td>
                                        </tr>
										<tr>
                                            <th>열수</th>
                                            <td><input type="number" value={this.state.colNumber} onChange={(e) => {
                                                    this.setState({colNumber : e.target.value});
                                                }
                                            }/></td>
                                            <th>칸수</th>
                                            <td><input type="number" value={this.state.rowNumber} onChange={(e) => {
                                                    this.setState({rowNumber : e.target.value});
                                                }
                                            }/></td>
                                        </tr>
										<tr>
                                            <th>단수</th>
                                            <td><input type="number" value={this.state.layerNumber} onChange={(e) => {
                                                    this.setState({layerNumber : e.target.value});
                                                }
                                            }/></td>
                                            <th>설치일</th>
                                            <td>
												<DatePicker
                                                    className="dfhadjklfhls"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date(this.state.installDate)}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.installDate; } 
                                                        this.setState({ installDate: String(date.toISOString()) }) 
                                                    }}
                                                />
											</td>
                                        </tr>
										<tr>
                                            <th>면적</th>
                                            <td><input type="number" value={this.state.area} onChange={(e) => {
                                                    this.setState({area : e.target.value});
                                                }
                                            }/></td>
                                            <th>순서</th>
                                            <td><input type="number" value={this.state.seq} onChange={(e) => {
                                                    this.setState({seq : e.target.value});
                                                }
                                            }/></td>
                                        </tr>
                                        <tr>
                                            <th>알람여부</th>
                                            <td>
                                                <div className="selectBox w100">
                                                    <select name="" id="" value={this.state.pushYn} placeholder="제목 + 내용"
                                                        onChange={(e) => {this.setState({...this.state.params, pushYn : e.target.value})}}>
                                                        <option value="Y">사용</option>
                                                        <option value="N">미사용</option>                                                        
                                                    </select>

                                                </div>
                                            </td>
                                        </tr>
										<tr>
                                            <th>비고</th>
                                            <td colSpan="3"><input type="text" value={this.state.note} onChange={(e) => {
                                                    this.setState({note : e.target.value});
                                                }
                                            }/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button  className="btn mr5 mt10" onClick={()=>{this.update()}}>수정</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default containerDetail;