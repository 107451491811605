import React from 'react';
import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import { Link } from "react-router-dom";
import * as common from "js/common";
import cookie from 'react-cookies'


class NoticeDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params : {
				attachFileId: null,
				content: "내용",
				cprId: 1,
				delYn: "N",
				id: 0,
				qnaComments: [],
				regDate: "2021-06-04T18:46:42",
				title: "",
				regId:"",
			},
			comment : null 
		};
		nbind(this);
	}
	componentDidMount() {
		this.callData()
	}
	callData () {
		let obj = {
			id : this.props.match.params.id
		};
		this.props.setLoading(true)
		API.qnaDetail(obj).then(result => {
			console.log(result)
			this.setState({
                 params : result
			})
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
			this.props.history.push('/')
            this.props.setLoading(false)
        });
		
	}
	addComment(){
		let params = {
			"qnaBoardId": this.props.match.params.id,
		    "content" : this.state.comment,
			userId : cookie.load("userId")
		}
		
		API.commentRegiste(params).then(result => {
			this.callData()
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
			this.props.history.push('/')
            this.props.setLoading(false)
        });
	}
	deleteComment(idx){
		let params = {
			ids : [idx]
		}
		if(window.confirm('삭제하시겠습니까?')){
			API.commentDelete(params).then(result => {
			
				this.callData()
				
			}).catch(error => {
				this.callData()
				console.log(error)
				//this.props.history.push('/')
			}).
			finally(()=>{
				this.props.setLoading(false)
			});
		}
		
	}

	deletePost = () =>{
		
		const deleteConfirm = window.confirm('해당 게시물을 삭제하시겠습니까?');

		if(!deleteConfirm) return;
		
		let params = {ids : [this.state.params.id]};

		API.qnaDelete(params).then(res =>{
			alert('삭제가 완료 되었습니다.');
			window.history.back();
		}).
		catch(err =>{
			alert(err.message);
		})

	}
	render() {
		let userId = cookie.load("userId");
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">{'Q&A'} 상세</h2>
                        <div className="noticeDetail">
                            <strong>{this.state.params.title}</strong>
                            {/* <p className="mt10">서우엠에스</p> */}
                            <p>{common.toLocalDate(this.state.params.regDate)}</p>
                            <div className="whBox" dangerouslySetInnerHTML={{ __html: this.state.params.content }}>
                                
                            </div>
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th width="120">첨부파일</th>
                                            <td colSpan="5">
                                                <div className="inputFile">
                                                    <input type="text"/>
                                                    <input type="file" name="" id=""/>
                                                    <button>파일첨부</button>
                                                </div>
                                            </td>
                                        </tr>
										<tr>
                                            <th width="120">댓글</th>
                                            <td colSpan="5">
                                                <div className="inputFile">
                                                    <input style={{background:'#fff'}} onChange={(e)=>{this.setState({comment : e.target.value})}} type="text"/>
                                                    <button onClick={()=>{this.addComment()}}>작성</button>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
							<div className="tableBox mt10">
                                <table>
									<thead>
										<tr>
											<th>ID</th>
											<th>comment</th>
											<th>등록날짜</th>
											<th width='100'></th>
										</tr>
									</thead>
                                    <tbody>
                                        {/* <tr>
                                            <td className="textCenter" width="150">admin1</td>
                                            <td width="auto">admin1</td>
											<td className="textCenter" width="200">2021-05-05</td>
                                        </tr> */}
										{	this.state.params.qnaComments.length === 0 ?
											<tr style={{ cursor: 'default' }}>
												<td colSpan='100' className="textCenter">등록된 리스트가 없습니다.</td>
											</tr>
											:
											this.state.params.qnaComments.map((item, index) => {
												return (
													<tr key={index} >
														<td className="textCenter" width="150">{item.regId}</td>
														<td width="auto">{item.content}</td>
														<td className="textCenter" width="200">{common.toLocalDate(item.regDate)}</td>
														
															<td>
															{this.props.user.role === 'ROLE_CPR' || this.props.user.preferred_username === item.regId ? 
																<button onClick={()=>{this.deleteComment(item.id)}}>삭제</button>
																	:
																null
															}	
															</td>
													</tr>
												)
											})
										}
                                    </tbody>
                                </table>
                            </div>
                            <div className="textRight">
                                <Link to="/qna" className="btn mr5 mt10">목록</Link>
                            </div>
							{this.props.user.role === 'ROLE_CPR' || this.state.params.regId === this.props.user.preferred_username ? 
								<div className="textCenter mt30">
									<Link to={"/qna/" +this.props.match.params.id+ "/edit"} className="btn mr5 mt10">수정</Link>
									<button className="btn red ml30" onClick={this.deletePost}>삭제</button>
								</div>
								:
								null
							}
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default NoticeDetail;