
// user
export function SET_USER_INFO(data) {
	data.role = data.realm_access.roles.find(e=> e === 'ROLE_CPR' || e === 'ROLE_FARMER')
	return {
		type: 'USER_INFO',
		data: data
	};
}
// common
export function SET_LANG(data) {
	return {
		type: 'LANG',
		data: data
	};
}
export function SET_ALERT(data) {
	return {
		type: 'ALERT',
		data: data
	};
}
export function SET_CONFIRM(data) {
	return {
		type: 'CONFIRM',
		data: data
	};
}
export function SET_LOADING(data) {
	return {
		type: 'LOADING',
		data: data
	};
}