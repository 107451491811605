import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class deviceDetail extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params : {
                alarmSendYn: "N",
                classCode: "FPUENDTIME1",
                delYn: "N",
                equipName: "팜인박스 환경 셋팅",
                id: 16493,
                monitoringYn: "Y",
                normalYn: "Y",
                diaryDisplayYn: "Y",
                displayYn: "Y",
                saveYn: "Y",
                useYn: "Y",
                name: "FPU-동작종료시간1",
                protocolMap: 52,
                saveTerm: 60,
                seq: 1,
                status: null,
                unit: null,
                value: 1,
            },
            containerList : [],
            tagTypeList : [],
			gatewayDeviceList : []
		};
		nbind(this);
	}
	componentDidMount() {
		this.dataList()
		this.callData();
	}
    
	callData () {
		let obj = {
			id : this.props.match.params.deviceId
		};
		this.props.setLoading(true)
		API.tagDetail(obj).then(result => {
			this.setState({
				params : result
			})
			this.props.setLoading(false)
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
	}
    dataList(){		
		let obj = {
			id : this.props.match.params.id
		}
		API.gatewayContainerList(obj).then(result => {
			this.setState({containerList : result})	
		}).catch( error => console.log(error));
        API.tagTypeList(obj).then(result => {
            this.setState({tagTypeList : result})
		}).catch(error => console.log(error));
		
        API.gatewayDeviceList(obj).then(result => {
            this.setState({gatewayDeviceList : result})
		}).catch(error => console.log(error));
	}
   
	update(){
        this.props.setLoading(true)
        
        API.tagUpdate(this.state.params).then(result => {
			this.callData()
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
    }
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">태그 상세정보</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox detail mt30">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>이름</th>
                                            <td>
                                                <input type="text" name="" value={this.state.params.name} id="" onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.name = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}/>
                                            </td>
											<th>프로토콜 맵</th>
                                            <td>
                                                <input type="text" name="" value={this.state.params.protocolMap} id="" onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.protocolMap = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}/>
                                            </td>
											
                                        </tr>
										<tr>
                                            
                                            <th>소속장치</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.equipId} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.equipId = e.target.value///////////
															this.setState({ params: data })
														}}>
															
															{this.state.gatewayDeviceList.map((item,index)=>{
                                                                return(
                                                                        <option value={item.id}>{item.name}</option>
                                                                )
                                                            })}
													</select>
												</div>
                                            </td>
											<th>분류</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.classCode} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.classCode = e.target.value///////////
															this.setState({ params: data })
														}}>
															
                                                            <option value="">통계카테고리 미매칭</option>
                                                            {this.state.tagTypeList.map((item,index)=>{
                                                                return(
                                                                        <option value={item.code}>{item.name}</option>
                                                                )
                                                            })}
													</select>
												</div>
											</td>
                                        </tr>
										<tr>
                                            <th>저장 여부</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.saveYn} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.saveYn = e.target.value///////////
															this.setState({ params: data })
														}}>
															
															<option value="Y">사용</option>
															<option value="N">사용안함</option>
													</select>
												</div>
											</td>											
                                            <th>메인화면 노출여부</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.displayYn} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.displayYn = e.target.value
															this.setState({ params: data })
														}}>
															
															<option value="Y">사용</option>
															<option value="N">사용안함</option>
													</select>
												</div>
                                            </td>											
                                        </tr>
                                        <tr>
                                            <th>사용여부</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.useYn} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.useYn = e.target.value///////////
															this.setState({ params: data })
														}}>
															
															<option value="Y">사용</option>
															<option value="N">사용안함</option>
													</select>
												</div>
                                            </td>
                                            <th>모니터링 여부</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.monitoringYn} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.monitoringYn = e.target.value///////////
															this.setState({ params: data })
														}}>
															
															<option value="Y">사용</option>
															<option value="N">사용안함</option>
													</select>
												</div>
											</td>
                                        </tr>
                                        <tr>
                                            <th>영농일지 노출여부</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.diaryDisplayYn} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.diaryDisplayYn = e.target.value///////////
															this.setState({ params: data })
														}}>
															
															<option value="Y">사용</option>
															<option value="N">사용안함</option>
													</select>
												</div>
                                            </td>
											<th>알람 전송</th>
                                            <td>
                                                <div className="selectBox">
													<select name="" id="" value={this.state.params.alarmSendYn} placeholder="제목 + 내용"
														onChange={(e) => {
															let data = this.state.params; 
															data.alarmSendYn = e.target.value///////////
															this.setState({ params: data })
														}}>
															
															<option value="Y">사용</option>
															<option value="N">사용안함</option>
													</select>
												</div>
											</td>		
                                        </tr>
										<tr>
                                            <th>순서</th>
                                            <td>
                                                <input type="text" name="" value={this.state.params.seq} id="" onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.seq = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}/>
                                            </td>
                                            <th>저장 주기</th>
                                            <td>
                                                <input type="text" name="" value={this.state.params.saveTerm} id="" onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.saveTerm = e.target.value///////////
                                                    this.setState({ params: data })
                                                }}/>
											</td>
                                        </tr>
										<tr>
											<th>프로토콜 맵</th>
											<td>
												<input type="number" name="" value={this.state.params.protocolMap} id="" onChange={(e) => {
                                                    let data = this.state.params; 
                                                    data.protocolMap = e.target.value
                                                    this.setState({ params: data })
                                                }}/>
											</td>
										</tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button onClick={()=>{this.update()}} className="btn mr5 mt10">수정</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default deviceDetail;