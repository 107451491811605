import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";


class ProductRegister extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params : {
				containerId : this.props.match.params.containerId,
				productCode : '',
				averageOutput : null,
				productionCycle : '',
				startDate : new Date(),
				endDate : new Date()
			},

			majorClassCode : '',
			productMajorClassList : [],
			productMinorClassList : new Map()
		};
		nbind(this);
	}

	componentDidMount() {
		this.productClassList()
	}

	callData(){
		let obj = { id : this.state.params.id}
		this.props.setLoading(true)
		API.productDetail(obj).then(result => {
			this.setState({
				params : result
			})		
			this.props.setLoading(false)
		}).catch(error => {
			console.log(error)
			this.props.setLoading(false)
		});
		
	}

	productClassList(){
		API.productClassList().then(result => {
			console.log(result[0])
			let majorCategory = []
			let minorCategory = new Map()
			let majorCode = '';
			result[0].commCodesList.map(code1 => {
				majorCategory.push(code1);
				minorCategory.set(code1.minorCode, code1.commCodesList)
			})

			this.setState({			
				
				productMinorClassList : minorCategory,				
				productMajorClassList : majorCategory,
				majorClassCode : majorCategory[0].minorCode										
			})
		}).catch(error=>{console.log(error)})
	}

	save(){
        this.props.setLoading(true)
		API.productInsert(this.state.params).then(result => {
			this.setState({})
			this.props.setLoading(false)
		}).catch(error=>{
			console.log(error)
			this.props.setLoading(false)
		})
	}

	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">생산물 등록</h2>
                        <div className="noticeDetail">
                            
                            <div className="tableBox tableFixed detail mt30">
                                <table>
                                    <tbody>
										<tr>	
											<th>생산 시작 날짜</th>										
                                            <td>
                                                <DatePicker
                                                    className="dfhadjklfhls"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date(this.state.params.startDate)}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.startDate; } 
                                                        let data = this.state.params;
                                                        data.startDate = String(date.toISOString());                                                         
                                                        this.setState({ params: data }) 
                                                    }}
                                                />
                                            </td>
											<th>생산 종료 날짜</th>										
                                            <td>
                                                <DatePicker
                                                    className="dfhadjklfhls"
                                                    dateFormat="yyyy-MM-dd"
                                                    placeholderText="YYYY-MM-DD"
                                                    selected={new Date(this.state.params.endDate)}
                                                    showDisabledMonthNavigation
                                                    onChangeRaw={(e) => { e.preventDefault() }}
                                                    onChange={(date) => { 
                                                        if (date === null) { date = this.state.params.endDate; } 
                                                        let data = this.state.params; 
                                                        data.endDate = String(date.toISOString());                                                         
                                                        this.setState({ params: data }) 
                                                    }}
                                                />
                                            </td>											
										</tr>	
                                        <tr>
                                            <th>생산물 카테고리</th>
											<td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.params.majorClassCode} placeholder="농작물 종류"
														onChange={(e) => { if(e.target.value != ''){this.setState( {majorClassCode: e.target.value})} }}>
														<option value="">선택</option>
														{
															this.state.productMajorClassList.length > 0 && this.state.productMajorClassList.map((item,index)=>{
																return(
																	<option value={item.minorCode}>{item.name}</option>
																)
															})															
														}
                                                    </select>
                                                </div>
                                            </td>				
											<th>생산물</th>
                                            <td>
                                                <div className="selectBox">
                                                    <select name="" id="" value={this.state.productCode} placeholder="농작물 종류"
														onChange={(e) => {this.setState({ params : {...this.state.params, productCode: e.target.value}})}}>
														<option value="">선택</option>
														{
															this.state.productMinorClassList.size > 0 && this.state.productMinorClassList.get(this.state.majorClassCode).map((item,index)=>{
																return(
																	<option value={item.minorCode}>{item.name}</option>
																)
															})
														}												
                                                    </select>
                                                </div>
                                            </td>			
                                        </tr>									
                                        <tr>
                                            <th>수확주기</th>
                                            <td>
												<input type="text" value={this.state.params.productionCycle} onChange={(e) => {
                                                    this.setState( { params:{ ...this.state.params ,productionCycle : e.target.value }});}}/>
											</td>    
                                            <th>평균 생산량</th>
                                            <td>
												<input type="number" value={this.state.params.averageOutput} onChange={(e) => {
                                                    this.setState( { params:{ ...this.state.params ,averageOutput : e.target.value }});}}/>
											</td>			
                                        </tr>
										
                                      
                                    </tbody>
                                </table>
                            </div>
                          
                            <div className="textCenter mt30">
                                <button  className="btn mr5 mt10" onClick={()=> {this.save()}}>등록</button>
                                <button onClick={()=>{this.props.history.goBack()}} to="/gateway" className="btn gray ml30">취소</button>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default ProductRegister;