import { code_mapping, equipClassId2name } from 'components/utils/mapping';
import React, { useEffect, useState } from 'react';
import { API } from 'service';
import styled, { css } from 'styled-components';
import Section from "./sectionSession"


const Wrapper = styled.div`
display: flex;
width: 100%;
flex-direction: column;
min-width: 1300px;
background-color: ${props => props.background};
`

const Top = styled.div`
display: flex;
width: 100%;
justify-content: space-between;


`
const Title = styled.span`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2.6rem;
margin-bottom:20px;

color: #656565;
`

const Middle = styled.div`
width: 100%;

background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;

`

const Time = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 16px;

margin: 28px 27px 0px 0px;
color: #A4A4A4;
`

const Wrap = styled.span`
display: flex;
align-items: center;
`

const UpdateWrapper = styled.div`
display: flex;
width: 100%;
justify-content: space-between;

`
const BelongWrapper = styled.div`
display: flex;
width: 100%;
`


function Main(props) {
    const [isLookUp, set_isLookUp] = useState(true)
    const [preEquip, set_preEquip] = useState([])
    const [updatetime, setUpdatetime] = useState('')

    const make2 = (data) => {
        if (data / 10 < 1) {
            return '0' + data
        } else {
            return data
        }
    }


    useEffect(() => {
        let today = new Date()
        let year = today.getFullYear();
        let month = today.getMonth() + 1
        let date = today.getDate(); // 일
        let hours = today.getHours();
        let minutes = today.getMinutes();
        let seconds = today.getSeconds();

        setUpdatetime(year + '-' + make2(month) + '-' + make2(date) + '  ' + make2(hours) + ':' + make2(minutes) + ':' + make2(seconds))

        if (!props.isLookUp) {
            set_isLookUp(false)
        }
        if (props.equip != undefined) {
            let obj = []
            let equipClassId_arr = []


            props.equip.map(val => {
                equipClassId_arr = obj.find(arr_val => {

                    if (arr_val.equipClassId == val.equipClass.equipClassId)
                        return true
                })
                let status = val.sensors.find(sensor_data => {

                    if (sensor_data.tagClass.code == code_mapping[val.equipClass.code])
                        return true
                })
                if (status == undefined) {
                    val.tagId = 0
                } else {
                    val.tagId = status.tagId

                }

                if (equipClassId_arr == undefined) {



                    obj.push({
                        equipClassId: val.equipClass.equipClassId,
                        name: equipClassId2name[val.equipClass.equipClassId],
                        sensors: [val]
                    })
                }
                else {
                    equipClassId_arr.sensors.push(val)
                }
            })

            set_preEquip(obj)
        }
    }, [props.equip])

    return (

        <Wrapper>
            {isLookUp ||
                <Top>
                    <Title>{props.name} 센서 데이터</Title>
                </Top>
            }

            <Middle>
                <UpdateWrapper>
                    <Wrap>
                    </Wrap>
                    <Wrap>
                        <Time>
                            업데이트 {updatetime}
                        </Time>
                    </Wrap>
                </UpdateWrapper>

                <BelongWrapper>
                    <Section set_tagNodeName={props.set_tagNodeName} apeendTag2unit={props.apeendTag2unit} tag2name={props.tag2name} title="센서" set_tag2name={props.set_tag2name} selected={props.selectedSensor} set_selected={props.set_selectedSensor} category={props.sensor} />

                    {props.outdoor && <Section apeendTag2unit={props.apeendTag2unit} tag2name={props.tag2name} title="기상청" outdoor={true} is_2nd={true} set_tag2name={props.set_tag2name} selected={props.selectedbroadSensor} set_selected={props.set_selectedbroadSensor} category={props.broadSensor} />}
                    {props.equip == undefined || props.equip.length == 0 || props.outdoor ||
                        <Section set_tagNodeName={props.set_tagNodeName} apeendTag2unit={props.apeendTag2unit} tag2name={props.tag2name} title="장비" is_equip={true} set_tag2name={props.set_tag2name} selected={props.selectedEquip} set_selected={props.set_selectedEquip} category={preEquip} />
                    }

                </BelongWrapper>
            </Middle>

        </Wrapper>
    );
}

export default Main;