import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'service';
import styled from 'styled-components';
import paperclip from 'img/paperclip.png'
import cookie from 'react-cookies'

function Main(props) {
	// const [id,set_id]=useState()
	const [date, set_date] = useState('')
	const [title, set_title] = useState('')
	const [content, set_content] = useState('')
	const [id, set_id] = useState('')
	const [file, set_file] = useState([])
	const [byte, set_byte] = useState(0)
	const [isCpr,set_isCpr]=useState(false)
	useState(() => {
		let id = props.match.params.id
		if (id == undefined) {
			window.location.href = '/404'
		}
		set_id(id)

		API.noticeDetail({ id: id }).then(val => {
			set_content(val.content)
			set_title(val.title)
			console.log(val.files)
			set_file(val.files || [])
			let integer = 0
			val.files.map(val =>
				integer += val.byteSize
			)
			set_byte(integer)
			if (val.modDate == null) {
				set_date(val.regDate.slice(0, 10) + '  ' + val.regDate.slice(11, 16))
			} else {
				set_date(val.modDate.slice(0, 10) + '  ' + val.modDate.slice(11, 16))
			}
		}).catch(err => console.log(err))

		console.log(cookie.load('role'))
		set_isCpr(cookie.load('role')=='corporation')

	}, [])



	const deleteFile = () => {
		if(!isCpr){
			alert('권한이 없습니다.')
			return
		}
		if (window.confirm('삭제하시겠습니까?'))
			API.noticeDelete({ 'ids': id }).then(val => {
				alert('삭제성공')
				window.location.href = '/notice'
			})
	}

	return (
		<Wrapper>
			<InnerWrapper>
				<Time>{date}</Time>
				<Wrap>
					<Title>{title}</Title>

					<Button style={{ background: '#4F80E2' }} className='btn blue' to='/notice'>목록</Button>
					{isCpr&&
					<>
					<Button className='btn ' to={`/notice/${id}/edit`}>수정</Button>
					<Button onClick={deleteFile} className='btn gray'>삭제</Button>                   
					</>
}

				</Wrap>
				<Content dangerouslySetInnerHTML={{ __html: content }} />
				{file.length == 0 ||
					<>
						<FileWrapTitle>
							
							<Img src={paperclip} />
							첨부파일
							<Byte>({(byte / 1024).toFixed(1)}KB)</Byte>

						</FileWrapTitle>

						<FileWrap>
							{file.map(val => {
								return <FileItem >{val.originName + '.' + val.extension}  <DownloadButton href={`https://www.farminsf.com/api/v2/files/${val.name}`}>다운로드</DownloadButton> </FileItem>
							})}
						</FileWrap>
					</>
				}
			</InnerWrapper>
		</Wrapper>
	);
}
const Byte = styled.span`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 16px;
/* identical to box height */
margin-left:0.5rem;
display: flex;
align-items: center;

color: #C9C9C9;

`
const FileItem = styled.span`

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
margin-bottom: 0.8rem;
/* identical to box height */

display: flex;
align-items: center;

color: #2A3A4B;
`

const DownloadButton = styled.a`
width: 7rem;
height: 2.7rem;

display: flex;
align-items: center;
justify-content: center;
background: #17B9B6;
border-radius: 3px;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
/* identical to box height */

display: flex;
align-items: center;

color: #FFFFFF;
margin-left: 1.2rem;
`


const Img = styled.img`
width: 2rem;
height: 2rem;
margin-right: 0.5rem;
`
const FileWrap = styled.div`
width: 100%;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 0px 0px 5px 5px;
padding: 1.4rem 1.6rem;
border-top: none;
`
const FileWrapTitle = styled.div`
width: 100%;
background: #F5F5F5;
border-radius: 4px 4px 0px 0px;
height: 3.6rem;
display: flex;
align-items: center;
padding-left: 1rem;
border: 1px solid #C9C9C9;
border-bottom: none;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
/* identical to box height */

display: flex;
align-items: center;

color: #656565;
`


const Content = styled.div`
width: 100%;
min-height: 400px;
padding: 10px;
padding-top: 41px;
`
const Button = styled(Link)`
margin-right: 6px;
`
const Time = styled.div`
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */

display: flex;
align-items: center;

color: #656565;
`

const Wrapper = styled.div`
width: 100%;
background: #F5F5F5;
height: 100vh;
padding-top: 67px;
`

const InnerWrapper = styled.div`
width: 70%;
max-height: 810px;
margin: 0 auto;
background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
min-width: 1300px;


padding: 32px 28px;
`

const Wrap = styled.div`
display: flex;
justify-content: space-around;

border-bottom:0.1rem solid rgba(201,201,201,0.5);
padding: 18px 0px;
`
const Title = styled.div`
width: 100%;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 700;
font-size: 25px;
line-height: 28px;
/* identical to box height */

display: flex;
align-items: center;

color: #2A3A4B;

`
export default Main;