import React, { Suspense } from 'react';

import { nbind } from 'js/common'
import { API } from 'service.js';
import _ from 'lodash'
import Pager from 'components/layout/include/pager';
import CKEditor from 'components/layout/include/CKEditor';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import * as common from "js/common";

class NoticeRegister extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			params : {
				//attachFileId: null,
				bannerYn: "N",
                headlineYn: "N",
				content: "",
				//cprId: null,
				
				//id: '',
				startDate: null,
                endDate: null,
				title: "",
                delYn: "N",
			},
            updateContent : null,
            mode : 'reg',
		};
		nbind(this);
		this.onChangePage = this.onChangePage.bind(this);
        this.updateContent = this.updateContent.bind(this);
	}
    updateContent(e) {
        console.log(e);
		//var content = e.editor.getData();
		this.setState({
            params:{
                ...this.state.params,
                content: e
            }
			
		});
	}
	onChangePage(index) {
		this.setState({ pageIndex: index }, () => alert('페이지변경'));
	}
	componentDidMount() {
		console.log(this)
        if(this.props.match.params.id){
            this.setState({mode:'edit'})
            this.callData();
        }
	}
	callData () {
		let obj = {
			id : this.props.match.params.id
		};

		this.props.setLoading(true)
		API.qnaDetail(obj).then(result => {
			setTimeout(() => {
                this.setState({
                    params : result
               })
               this.props.setLoading(false)
            }, 0);
			
		}).catch(error => {
			this.props.history.push('/qna')
            this.props.setLoading(false)
        });
		
	}
	update(){
        this.props.setLoading(true)
        
        API.qnaUpdate(this.state.params).then(result => {
            console.log(result)
			this.setState({
                //params : result
           })
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
            
            this.props.setLoading(false)
        });
    }
    save(){
        this.props.setLoading(true)
        
        API.qnaRegiste(this.state.params).then(result => {
            console.log(result)
			this.props.history.push('/qna')
			this.props.setLoading(false)
			
		}).catch(error => {
            console.log(error)
            this.props.setLoading(false)
        });
    }
	render() {
		return (
			<React.Fragment>
				<div className="contentInner">
					<div className="contentArea">
						<h2 className="pageTitle textCenter">{'Q&A'} {this.state.mode == 'edit' ? '수정' : '등록'}</h2>
                        <div>
                            
                            <div className="tableBox detail mt30">
                                <table>
                                   
                                    <tbody>
                                        <tr>
                                            <th width="100">제목</th>
                                            <td colSpan="5"><input type="text" value={this.state.params.title} onChange={(e)=> { this.setState({ params:{ ...this.state.params ,title : e.target.value }}) }} /></td>
                                        </tr>
                                        <tr>
                                            <td colSpan="6">
                                                <Suspense fallback={'Loading...'}>
                                                { this.props.common.lang && <CKEditor data={this.state.params.content} editorData={this.updateContent} />
                                                }
                                                </Suspense>
                                            </td>
                                        </tr>
                                       
                                        <tr>
                                            <th>첨부파일</th>
                                            <td colSpan="5">
                                                <div className="inputFile">
                                                    <input type="text"/>
                                                    <input type="file" name="" id=""/>
                                                    <button>파일첨부</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                               <div className="textCenter mt30">
                                    {this.state.mode == 'edit' ? 
                                    <button className="btn " onClick={()=>this.update()}>수정</button> 
                                    :
                                    <button className="btn " onClick={()=>this.save()}>등록</button>
                                    }
                                   
                                   <Link to="/qna" className="btn gray ml30">취소</Link>
                               </div>
                            </div>
                        </div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default NoticeRegister;