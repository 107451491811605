import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { image_mapping, offImage_mapping } from 'components/utils/mapping'

import Message from './include/message'
import Input from './include/input'
import { useInput } from 'hooks/commonHooks'

import off from 'img/tools_item/04_exchanger_ off.png'
import one from 'img/tools_item/03_exchanger_ light.png'
import two from 'img/tools_item/02_exchanger_ middle.png'
import three from 'img/tools_item/01_exchanger_ strong.png'
import { HEATEXCHANGER } from 'constants/equipInfo'
import { getEquipSensorInnerTagObj } from 'components/utils/util'

import { API, equipControlFetch } from 'service';


export const HeatexChangerControlModal = ({ data }) => {

    const initValueControl = () => {

        try {
            const target = data.sensors.find(e => e.tagClass.code == HEATEXCHANGER.status);
            if (target === undefined) throw Error();
            return target.value;
        } catch (error) {
            return 0;

        }
    }

    const [targetData, setTargetData] = useState(data);
    const [controlValue, setControlValue] = useState(initValueControl());
    const [fetchResult, setFetchResult] = useState(null);
    const prevControlValue = useRef(0);

    const fetchEquipInfo = async (id) => {

        try {
            const fetchRes = await API.getEquip({ equipId: id });
            setTargetData(fetchRes)
        } catch (error) {
            alert(error.message)
        }

    }

    const imageControl = () => {
        try {
            const target = getEquipSensorInnerTagObj(targetData, HEATEXCHANGER.status);
            let imgSource;
            if (target == undefined) {
                throw Error('no target')
            }
            const { value, tagClass: { tagClassActions } } = target;

            if (value === 0) {
                imgSource = off;
            }

            const actLevel = tagClassActions.find(e => e.value === value);

            if (actLevel === undefined) {
                throw Error('no ActLevel');
            }
            switch (actLevel.value) {
                case 1:
                    imgSource = one;
                    break;
                case 2:
                    imgSource = two;
                    break;
                case 3:
                    imgSource = three;
                    break;
                default:
                    imgSource = off;
            }

            return <img src={imgSource} />

        } catch (error) {
            alert(error.message)
            setControlValue(0)
            return <img src={off} />
        }
    }

    const controlBuffer = (data) => {


        fetchControl(data);
    }

    const fetchControl = (data) => {

        try {

            setFetchResult(null);

            if (prevControlValue.current === data) return;

            setControlValue(data);
            prevControlValue.current = data;


            const { equipClass: { code: equipClassCode, equipProtocolMap: equipClassValue }, equipId, seq } = targetData;
            const actuators = targetData.actuators.find(e => e.tagClass.code === HEATEXCHANGER.control);
            if (actuators === undefined) {
                throw Error('tag Error')
            }
            const { tagClass: { code: classCode, encoding }, tagId: actId, gatewayMap: { map: address }, bitSeq } = actuators;


            let fetchData = {
                equipClassCode,
                equipId,
                equipClassValue,
                params: [
                    {
                        id: actId,
                        address,
                        classCode,
                        seq,
                        value: data,
                        encoding,
                        bitSeq
                    }
                ]
            }

            // return;
            new Promise((resolve, reject) => {
                API.equipControl(fetchData)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
                .then(res => {
                    if (res !== 200) {
                        throw Error('contorl Error ' + res)
                    }
                    const targetTagId = getEquipSensorInnerTagObj(targetData, HEATEXCHANGER.status);
                    new Promise((resolve, reject) => {
                        API.udpateTagValue(targetTagId.tagId, data)
                            .then(res => resolve(res))
                            .catch(err => reject(err))
                    })
                        .then(res => {
                            fetchEquipInfo(equipId);
                            setFetchResult(true);
                        })
                })
                .catch(err => {
                    setFetchResult(false);
                    alert(err.message);
                })
        } catch (error) {
            console.log(error.message);
            alert(error.message)
        }

    }

    return (
        <Wrapper>
            <Wrap>
                {imageControl()}
                <div>
                    <Label_Wrap>
                        <Label>
                            <Button_input value={1} onClick={() => controlBuffer(1)} checked={controlValue == 1} type="radio" name='changer' />
                            <Button>약</Button>
                        </Label>
                        <Label>
                            <Button_input value={2} onClick={() => controlBuffer(2)} checked={controlValue == 2} type="radio" name='changer' />
                            <Button>중</Button>
                        </Label>
                        <Label>
                            <Button_input value={3} onClick={() => controlBuffer(3)} checked={controlValue == 3} type="radio" name='changer' />
                            <Button>강</Button>
                        </Label>
                    </Label_Wrap>

                    <Label_Wrap>
                        <Label>
                            <Button_input value={0} onClick={() => controlBuffer(0)} checked={controlValue == 0} type="radio" name='changer' />
                            <Button>끄기</Button>
                        </Label>
                    </Label_Wrap>
                </div>
                {fetchResult != null &&
                    <Message success={fetchResult} />
                }
            </Wrap>

        </Wrapper>
    )

}

// const Dongzak = styled.div`
// display:flex ;
// width:100% ;
// font-family: Spoqa Han Sans Neo;
// font-style: normal;
// font-weight: 500;
// font-size: 22px;
// line-height: 32px;

// color: #747474;
// `

// const Confirm = styled.button`
// display:flex ;
// width:100% ;
// background: #4F80E2;
// border-radius: 5px;
// height: 4.5rem;

// align-items:center ;
// justify-content:center ;

// font-family: Spoqa Han Sans Neo;
// font-style: normal;
// font-weight: 400;
// font-size: 22px;
// line-height: 32px;

// color: #FFFFFF;
// `

// const ModalWrapper = styled.div`
//   box-sizing: border-box;


//   display: ${(props) => (props.visible ? 'block' : 'none')};
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1000;
//   overflow: auto;
//   outline: 0;
// `

// const ModalOverlay = styled.div`
//   box-sizing: border-box;
//   display: ${(props) => (props.visible ? 'block' : 'none')};
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   background-color: rgba(0, 0, 0, 0.6);
//   z-index: 999;
// `

// const ModalInner = styled.div`
//   display:flex ;
//   flex-direction:column ;
//   box-sizing: border-box;
//   position: relative;
//   box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
//   background-color: #fff;
//   border-radius: 10px;

//   top: 25%;
//   animation: zoomIn 0.2s none;
//   margin: 0 auto;
//   padding: 3.5rem;

//   width: 80rem;
// `

// const CloseButton = styled.div`
// display:flex ;
// width: 131px;
// height: 53px;

// line-height:51px ;
// background: #17B9B6;
// border-radius: 5px;
// font-family: Spoqa Han Sans Neo;
// font-style: normal;
// font-weight: 500;
// font-size: 18px;

// text-align:center ;
// justify-content:center ;

// color: #FFFFFF;

// cursor:pointer ;
// `
// const Top = styled.div`
// display:flex ;
// justify-content:space-between ;
// `
// const Title = styled.div`
// font-family: Spoqa Han Sans Neo;
// font-style: normal;
// font-weight: bold;
// font-size: 2.6rem;
// line-height: 46px;


// color: #2A3A4B;
// `

const Wrapper = styled.div`
display:flex ;
width:100% ;
flex:1 ;
margin-top:15px ;
`

const Wrap = styled.div`
display:flex ;
flex: 1;
align-items:center ;
flex-direction:column ;

${props => props.second && css`
background: #F5F5F5;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
padding:2.5rem;
margin-left: 3rem;
`}

`

const Img = styled.img`
width: 130px ;
height: 130px ;
margin-bottom: 1.2rem;
`


const Label = styled.label`
  cursor: pointer;
  margin-left: 12px;
  width: 100%;
flex:1;

`;


const Button = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    background: #F5F5F5;

    font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 500;
font-size: 2rem;

border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 5px;


color: #17B9B6;


`;
const Button_input = styled.input`
  opacity: 0;
  display: none;
  &:checked  {
      &+ ${Button}{
        background: #17B9B6;
        color: #FFFFFF;
      }
  }
`;


const Label_Wrap = styled.div`
width: 34.9rem;
height: 4.4rem;
display: flex;
margin-bottom: 6px;
`