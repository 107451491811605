import React from 'react';
import styled from 'styled-components';

const BelongWrap = styled.div`

width: 100%;
margin: 24px 37px;
margin-top: 0px;
`
const Belong = styled.div`
font-family: Spoqa Han Sans Neo;
font-style: normal;
font-weight: 600;
font-size: 2rem;

display: flex;
align-items: center;

color: #2A3A4B;

margin-bottom: 19px;
`
const Wrap = styled.div`
display: flex;
width: 100%;
`
const Title = styled.span`
font-family: 'Spoqa Han Sans Neo';
font-weight: 600;
font-size: 1.6rem;
margin-bottom: 8px;

display: flex;
align-items: center;

color:${props => props.color || '#656565'};
`
const Input = styled.input`
background: #F1F1F1;
border-radius: 5px;
border: 0px;
height: 62px;
text-align: right;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 700;
font-size: 22px;
padding: 14px;
margin-right: 5px;
display: flex;
align-items: center;
color: #000000;
flex:1;
`
const Unit = styled.div`
height: 62px;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;

display: flex;
align-items: ${props=>props.unit=='℃'?'flex-start':'flex-end'};
padding: 8px 0px;
color: #656565;
`

const InputWrap = styled.div`
display: flex;
flex-direction: column;
width: 100%;
flex: 1;
margin-right: 10px;
`

const IntInput=e=>{
    let value=e.target.value
    value=parseInt(value)
    if(isNaN(value))
        e.target.value=''

    else{
        e.target.value=value}
    
}

function Main(props) {
    return (
        <BelongWrap>
            <Belong>정상범위</Belong>

            <Wrap>
                <InputWrap>
                    <Title>MIN</Title>
                    <Wrap>
                        <Input value={props.min} onChange={(e)=>{IntInput(e);props.min_handdle(e)}}/>
                        <Unit unit={props.unit}>{props.unit}</Unit>

                    </Wrap>
                </InputWrap>

                <InputWrap>
                    <Title style={{color:'#17B9B6'}}>MAX</Title>
                    <Wrap>
                        <Input value={props.max}  onChange={(e)=>{IntInput(e);props.max_handdle(e)}} style={{color:'#17B9B6'}} />
                        <Unit unit={props.unit}>{props.unit}</Unit>
                    </Wrap>
                </InputWrap>
            </Wrap>
        </BelongWrap>
    );
}

export default Main;