import React from 'react';
import {  Route, withRouter } from "react-router-dom";
// import { TransitionGroup, CSSTransition } from "react-transition-group";
// import { connect } from 'react-redux'
import Header from './include/Header';
import Footer from './include/Footer';
import Loader from './include/Loader';

// import * as actions from 'reducers/actions';

class Layout1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		}
	}
	componentDidMount() {
		window.scrollTo({
			top: 0,
			// behavior: "smooth"
		});
	}

	render() {
		const { component: Component, ...rest } = this.props
		return (
			<Route {...rest} render={matchProps => (
				// className="colorWrap"
				<div id='wrap'>

					<Header {...matchProps} />
					<section id='container' className="layout1">
						<Component {...matchProps} />	
						{/* <Footer {...matchProps} /> */}
					</section>
					
					<Loader />
				</div>
			)} />
		)
	}
}



export default withRouter(Layout1);