import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import button_arrow from 'img/button_arrow.png'
import MagnifyingGlass from 'img/MagnifyingGlass.png'
import CheckImage from 'img/CheckImage.png'
import CheckOffImage from 'img/CheckOffImage.png'
import { API } from 'service';
import { Link } from 'react-router-dom';
import clicked_rightArrow from 'img/button_arrow_clicked.png'
import { useInput } from 'hooks/commonHooks';
import cookie from 'react-cookies'

const CheckBox = styled.input`

display: none;

& + div {
    cursor: pointer;
    width: 18px;
height: 18px;
color:#000;
/* border: 1.5px solid #B9B9B9; */
background-image: url(${CheckOffImage});
background-size: 20px;

}

&:checked+ div{
    width: 18px;
height: 18px;
    background-image: url(${CheckImage});
    
    background-size: 20px;
}
`
const Label = styled.label`
display: flex;
align-items: center;
justify-content: center;

`



function Main() {
    let [idx, set_idx] = useState(0)
    let [maxIdx, set_maxIdx] = useState(0)
    const [startIdx, set_startIdx] = useState(0)
    const [endIdx, set_endIdx] = useState(5)

    const [arr, set_arr] = useState([])

    const [period, set_period] = useState(0) //0: 전체기간, 1 : 1개월, 2 : 6개월 ,3: 기간입력
    const [start, start_handdle, set_start] = useInput('')
    const [end, end_handdle, set_end] = useInput('')
    const [condition, set_condition] = useState(0) //0: 제목 + 내용 1 : 제목

    const [page, setPage] = useState(0)
    const [selected, set_selected] = useState([])

    const [word, word_handdle, set_word] = useInput('')
    const [filterArr, set_filterArr] = useState(false)

    const [total, set_total] = useState(0)

    const [searching,set_searching]=useState(false)
	const [isCpr,set_isCpr]=useState(false)

    const Init=()=>{
        set_searching(0)
        set_idx(0)
        set_startIdx(0)
        let max = Math.floor(total / 10) + (total % 10 == 0 ? 0 : 1)
            set_maxIdx(max)
            if (max < 5) {
                set_endIdx(max)
            }else{
                set_endIdx(5)
            }
    }
    const search = () => {
        let new_arr = arr.slice()

        let now = new Date()
        if (period == 0 && word == ''){
            Init()
            return
        }else
            set_searching(true)
        if (period == 1) {
            let month = new Date(now.setMonth(now.getMonth() - 1))
            new_arr = new_arr.filter(val => {
                let date = val.modDate != null ? val.modDate : val.regDate
                date = new Date(date)
                return date > month
            })
        }
        else if (period == 2) {

            let month = new Date(now.setMonth(now.getMonth() - 6))

            new_arr = new_arr.filter(val => {
                let date = val.modDate != null ? val.modDate : val.regDate
                date = new Date(date)
                return date > month
            })
        }
        else if (period == 3) {
            let startDate = new Date(start)
            let endDate = new Date(end)

            new_arr = new_arr.filter(val => {
                let date = val.modDate != null ? val.modDate : val.regDate
                date = new Date(date)
                return date >= startDate && date <= endDate

            })
        }

        if (condition == 0) {
            new_arr = new_arr.filter(val => {
                return val.title.includes(word) || val.content.includes(word)
            })
        } else if (condition == 1) {

            new_arr = new_arr.filter(val => {
                return val.title.includes(word)
            })

        }
        set_filterArr(new_arr)
        let max = Math.floor(new_arr.length / 10) + (new_arr.length % 10 == 0 ? 0 : 1)
        if (max < 5 && arr.length <total) {
            API.getFaQ({ "size": 100, "page": page }).then(val => {
                setPage(page + 1)
                let unknown_arr = arr.slice()
                set_arr(unknown_arr.concat(val.results))
            })
        }

        else {
            set_filterArr(new_arr)
            set_idx(0)
            set_startIdx(0)
            set_maxIdx(max)
            // set_searching(false)

            if (max < 5) {
                set_endIdx(max)
            }else{
                set_endIdx(5)
            }
        }
    }

    useEffect(()=>{
        if(searching){
            search()
        }
    },[arr])
    // let 
    useEffect(() => {
        let data = { "size": 100, "page": page }
        setPage(page + 1)
        API.getFaQ(data).then(val => {
			console.log(val)
            set_arr(val.results)
            let max = Math.floor(val.total / 10) + (val.total % 10 == 0 ? 0 : 1)
            set_maxIdx(max)
            if (max < 5) {
                set_endIdx(max)
            }else{
                set_endIdx(5)
            }
            set_total(val.total)
        })


        console.log(cookie.load('role'))
		set_isCpr(cookie.load('role')=='corporation')
    }, [])

    const rightButton = () => {
        if (endIdx == idx + 1) {
        set_endIdx(endIdx + 1)
        set_startIdx(startIdx + 1)

        }
     
            idx_setting(idx + 1)

    }

    const idx_setting = (idx) => {

        
            if (idx / 5 > page - 1 &&!searching) {
                API.getFaQ({ "size": 100, "page": page }).then(val => {
                    setPage(page + 1)
                    let unknown_arr = arr.slice()
                    set_arr(unknown_arr.concat(val.results))

                })
            }
        

        set_idx(idx)

    }


    const leftButton = () => {
        if (idx == startIdx) {
            set_startIdx(startIdx - 1)
            set_endIdx(endIdx - 1)
        }
        idx_setting(idx - 1)
    }



    const spreadButton = () => {
        var arr = []

        if (startIdx > 0) {
            arr.push(<Arrow style={{ transform: 'rotate(180deg)' }} src={button_arrow} onClick={leftButton} />)
        }

        for (let i = startIdx; i < endIdx; i++) {
            arr.push(<Button checked={i == idx} onClick={() => { idx_setting(i) }}>{i + 1}</Button>)
        }
        if (endIdx < maxIdx) {
            arr.push(<Arrow src={button_arrow} onClick={rightButton} />)
        }
        return arr

    }

    const optionClick = (evnet) => {
        let item = evnet.currentTarget
        const label = item.parentNode.previousSibling
        switch (item.textContent) {
            case '전체기간': set_period(0); break;
            case '1개월': set_period(1); break;
            case '6개월': set_period(2); break;
            case '기간입력': set_period(3); break;

            case '제목 + 내용': set_condition(0); break;
            case '제목': set_condition(1); break;

        }
        label.parentNode.classList.remove('active');
        label.innerHTML = item.textContent;
    }

    const labelClick = e => {
        let label = e.currentTarget

        if (label.parentNode.classList.contains('active')) {
            label.parentNode.classList.remove('active');
        } else {
            label.parentNode.classList.add('active');
        }
    }

    const boxCheck = idx => {
        let select_idx = selected.indexOf(idx)

        let new_arr = selected.slice()

        if (select_idx == -1) {
            new_arr.push(idx)
        } else {
            new_arr.splice(select_idx, 1)
        }
        set_selected(new_arr)

    }
    const deletePost = () => {
        if(selected.length==0){
            alert('삭제 할 게시물을 선택해 주십시오.')
            return
        }
        if (window.confirm('삭제하시겠습니까?'))
            API.noticeDelete({ 'ids': selected }).then(val => {
                alert('삭제성공')
                window.location.reload()
            })

    }

    return (
        <AllWrap>
            <Wrapper>
                <Title>FAQ</Title>
                <TopWrap>
                    <Wrap>
                    {isCpr&&<>
                        <TopButton onClick={() => window.location.href = '/faqregister'}>글쓰기</TopButton>
                        <TopButton onClick={deletePost} style={{ background: '#C9C9C9' }}>삭제</TopButton>
                        </>
                        }
                    </Wrap>

                    <Wrap>

                        {period == 3 ? <> 기간 입력 <Small_input onChange={start_handdle} type="date" /> ~ <Small_input onChange={end_handdle} type="date" />

                        </> :
                            <div className="selectBox2 " style={{ marginRight: '6px' }}>
                                <button onClick={labelClick} className="label">전체기간</button>
                                <ul className="optionList">
                                    <li onClick={optionClick} className="optionItem">전체기간</li>
                                    <li onClick={optionClick} className="optionItem">1개월</li>
                                    <li onClick={optionClick} className="optionItem">6개월</li>
                                    <li onClick={optionClick} className="optionItem">기간입력</li>
                                </ul>
                            </div>
                        }
                        <div className="selectBox2 " style={{ marginRight: '6px' }}>
                            <button onClick={labelClick} className="label">제목 + 내용</button>
                            <ul className="optionList">
                                <li onClick={optionClick} className="optionItem">제목 + 내용</li>
                                <li onClick={optionClick} className="optionItem">제목</li>
                            </ul>
                        </div>


                        <Input  onChange={word_handdle} value={word} onKeyDown={e=>{if(e.keyCode==13)search()}} placeholder='검색어를 입력하세요' />
                        <GlassWrap onClick={search}>
                        <img src={MagnifyingGlass}/>
                        </GlassWrap>
                    </Wrap>


                </TopWrap>
                <SensorWrapper>
                    <SensorItem>
                        <div style={{ width: '18px' }} />

                        <Names style={{ fontWeight: "500" }}>번호</Names>
                        <Setting style={{ flex: '1', fontWeight: "500" }}>제목</Setting>
                        <Setting style={{ fontWeight: "500" }}>등록날짜</Setting>
                    </SensorItem>

                    {
                    (searching ?filterArr:arr).slice(idx * 10, idx * 10 + 10).map((arr, arr_idx) => {
                            return <SensorItem isODD={arr_idx % 2 == 0}>

                                <Label>
                                    {isCpr?
                                    <>
                                    <CheckBox onClick={() => boxCheck(arr.boardFaqId)} checked={selected.indexOf(arr.boardFaqId) != -1} type='checkbox' />
                                    <div />
                                    </>
                                    :
                                    <>
                                    <div style={{width:'1.8rem',height:'1.8rem'}}/>
                                    </>
                                    }   
                                </Label>

                                <Names>{arr.boardFaqId}</Names>
                                <Item_Title to={`/faq/detail/${arr.boardFaqId}`} >{arr.title}</Item_Title>
                                <Setting>{arr.regDate.slice(0, 10)}</Setting>

                            </SensorItem>

                        })
                    }
                </SensorWrapper>



                <ButtonWrap>
                    {spreadButton()}

                </ButtonWrap>

            </Wrapper>
        </AllWrap>
    );
}
const GlassWrap=styled.div`
height: 51px;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius:0px 5px 5px 0px;
display: flex;
align-items: center;
border-left: none;
margin-left: -5px;
padding-right: 8px;
cursor: pointer;
`
const AllWrap = styled.div`
height: 120vh;
background: #f5f5f5;
`
const Small_input = styled.input`
width: 12rem;
height: 51px;
margin: 0 0.6rem;
background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 5px;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.2rem;
line-height: 23px;
/* identical to box height */
padding: 12px;
display: flex;
align-items: center;

color: #656565;



`
const Input = styled.input`
width: 383px;
height: 51px;

background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 5px 0px 0px 5px;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */
padding: 12px;
display: flex;
align-items: center;

color: #656565;

border-right: none;


`

const TopWrap = styled.div`
display: flex;
margin: 24px 0px;
justify-content: space-between;
`

const Wrap = styled.div`
display: flex;
align-items: center;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */

display: flex;
align-items: center;

color: #656565;

`


const TopButton = styled.div`
width: 123px;
height: 51px;
margin-right: 6px;
background: #17B9B6;
border-radius: 5px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;

font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 23px;
/* identical to box height */

display: flex;
align-items: center;

color: #FFFFFF;
`

const Wrapper = styled.div`
width: 70%;
margin: 0 auto;
margin-top: 67px;
background: #FFFFFF;
border: 1px solid #C9C9C9;
box-sizing: border-box;
border-radius: 10px;
min-width: 1300px;

padding: 32px 28px;
margin-bottom: 30px;
`
const Title = styled.div`
width: 100%;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 28px;
/* identical to box height */

display: flex;
align-items: center;

color: #2A3A4B;

`


const SensorWrapper = styled.div`
width: 100%;

border-top: 0.1rem solid rgba(201, 201, 201, 0.5);

`

const SensorItem = styled.div`
width: 100%;
display: flex;
padding:18px 30px;
background:${props => props.isODD ? '#F5F5F5' : '#FFFFFF'} ;

`
const Names = styled.div`
width: 10%;
display: flex;
align-items: center;
margin-left: 16px;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 400;
font-size: 1.8rem;

/* identical to box height */

display: flex;
align-items: center;

color: #656565;

`
const Setting = styled.div`
width: 12%;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.8rem;
line-height: 23px;
/* identical to box height */

display: flex;
align-items: center;

color: #656565;

`
const Item_Title = styled(Link)`
flex: 1;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 500;
font-size: 1.8rem;
cursor: pointer;
/* identical to box height */

display: flex;
align-items: center;

	transition: color 250ms ease-in-out;
color: #656565;
&:hover{
    color:#17B9B6;
}
`
const Blue = styled.span`
color: #17B9B6;
font-size: inherit;
`

const Button = styled.div`
background: #F5F5F5;
border-radius: 2.25806px;
width: 28px;
height: 28px;
margin: 0px 3px;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Spoqa Han Sans Neo';
font-style: normal;
font-weight: 400;
font-size: 1.4rem;
line-height: 23px;
cursor: pointer;
color: #8B9295;

${props => props.checked && css`
color:#FFFFFF;
background-color: #17B9B6;
`}
`
const ButtonWrap = styled.div`
display: flex;

justify-content: flex-end;
margin-top:26px;
`
const Arrow = styled.img`
width: 2.8rem;
height: 2.8rem;
cursor: pointer;

&:hover{
    content: url(${clicked_rightArrow});
}
`


export default Main;