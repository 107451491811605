import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Summary from "components/mainPage/material/summary";
import Message from "components/mainPage/material/message";
import upArrow from "img/icon_upArrow.png";
import Icon from "components/mainPage/material/icons";
import Tool from "components/mainPage/material/tools";
import { useInput } from "hooks/commonHooks";
import boxArrow from "img/icon_boxArrow.png";
import boxArrowOn from "img/icon_boxArrowOn.png";
import Arrow from "img/arrow.png";
import blue_circle from "img/blue_circle.png";
import circle from "img/circle.png";

import { useLocation } from "react-router-dom";
import { code_mapping } from "components/utils/mapping";
import Slider from "react-slick";

const Arrow_img = styled.img`
  width: 2rem;
  height: 2rem;
`;

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 27px;
  background-color: ${(props) => props.backgroundColor || "#000000"};
  margin-top: -20px;
`;

const Title = styled.span`
  display: flex;
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;

  font-size: 2.2rem;
  color: #2a3a4b;
  margin-right: 1.2rem;
  text-align: center;
  align-items: center;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Img = styled.img`
  ${(props) =>
    props.fold &&
    css`
      transform: rotate(180deg);
    `}
  cursor:pointer;
  width: 33px;
  height: 33px;
  /* margin-left: 10px; */
`;

const Time = styled.div`
  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #a4a4a4;
  margin-right: 10px;
`;
const Wrap = styled.span`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 23px;
  width: 204px;
  height: 43px;
  background: #4f80e2;
  border-radius: 5px;

  font-family: Spoqa Han Sans Neo;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  color: #ffffff;
`;
const Middle = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 13px 0px;
`;

const back_color = {
  0: "#FFFFFF",
  1: "#E7E7E7",
  2: "#CACACA",
  3: "#949494",
  4: "#757575",
  5: "#575757",
  6: "#3A3A3A3A",
};
const Icon_wrap = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  transition: 0.3s;

  transform: ${(props) => `translateX(-${props.translate}px)`};
  justify-content: center;
`;
const Contets_wrap = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;

  & > div {
    width: 100%;
  }
`;

const Contets_wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 23px;
`;

const Contents = styled.div`
  flex: 1;
  display: -webkit-box;
  overflow-x: hidden;
  overflow-y: visible;
`;
const Circle = styled.img`
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0.7rem;
  cursor: pointer;

  ${(props) =>
    props.is_blue &&
    css`
      content: url(${blue_circle});
    `}
`;
const Circle_Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 3.6rem;
`;
const BoxImg = styled.img`
  ${(props) =>
    props.fold &&
    css`
      transform: rotate(180deg);
    `}
  &:hover {
    content: url(${boxArrowOn});
  }
  cursor: pointer;
  width: 45px;
  height: 45px;
`;

function Main(props) {
  const [fold, fold_handdle, set_fold] = useInput(false);

  const [icon, icon_handdle, set_icon] = useInput([]);
  const [iconIdx, iconIdx_handdle, set_iconIdx] = useInput(0);
  const [maxIconIdx, maxIconIdx_handdle, set_maxIconIdx] = useInput(0);
  const [iconPosition, set_iconPosition] = useState(0);

  const [tool, tool_handdle, set_tool] = useInput([]);
  const [toolIdx, toolIdx_handdle, set_toolIdx] = useInput(0);
  const [maxToolIdx, maxToolIdx_handdle, set_maxToolIdx] = useInput(0);
  const [toolPosition, set_toolPosition] = useState(0);

  const [updateTime, set_updateTime] = useState("");

  const [warning, set_warning] = useState(0);
  const [connectionError, set_connectionError] = useState(0);

  const [sliderWidth, setSliderWidth] = React.useState(0);
  const slider = React.useRef(<div />);

  useEffect(() => {
    setSliderWidth(slider.current.offsetWidth - 90);
    window.addEventListener("resize", () => {
      if (slider.current != null)
        setSliderWidth(slider.current.offsetWidth - 90);
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (slider.current != null)
          setSliderWidth(slider.current.offsetWidth - 90);
      });
    };
  }, []);

  //한자리 수 2자리로변환
  const make2 = (data) => {
    if (data / 10 < 1) {
      return "0" + data;
    } else {
      return data;
    }
  };

  const init = () => {
    set_warning(0);
    set_connectionError(0);

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate(); // 일
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let seconds = today.getSeconds();

    set_updateTime(
      year +
        "-" +
        make2(month) +
        "-" +
        make2(date) +
        "  " +
        make2(hours) +
        ":" +
        make2(minutes) +
        ":" +
        make2(seconds)
    );
  };

  const rigth_tool_button = () => {
    set_toolIdx(toolIdx + 1);
    if (toolPosition < 2) set_toolPosition(toolPosition + 1);
  };

  const left_tool_button = () => {
    set_toolIdx(toolIdx - 1);
    if (toolPosition > 0) set_toolPosition(toolPosition - 1);
  };

  const rigth_icon_button = () => {
    set_iconIdx(iconIdx + 1);
    if (iconPosition < 2) set_iconPosition(iconPosition + 1);
  };

  const left_icon_button = () => {
    set_iconIdx(iconIdx - 1);
    if (iconPosition > 0) set_iconPosition(iconPosition - 1);
  };

  const spreadIcon = () => {
    var result = [];
    for (let i = 0; i < icon.length; i += 16)
      result.push(icon.slice(i, i + 16));
    return result.map((arr) => {
      return (
        <Icon_wrap translate={sliderWidth * iconIdx}>
          {arr.map((data) => {
            return <Icon data={data} />;
          })}
        </Icon_wrap>
      );
    });
  };

  // 각 UI 장비 그려주는 함수
  const spreadTool = () => {
    var result = [];
    for (let i = 0; i < tool.length; i += 16)
      result.push(tool.slice(i, i + 16));
    return result.map((arr) => {
      return (
        <Icon_wrap translate={sliderWidth * toolIdx}>
          {arr.map((data) => {
            let sensors = data.sensors;
            let status_code = code_mapping[data.equipClass.code];

            let digital = true;
            sensors.map((val) => {
              if (
                val.tagClass.code == status_code &&
                val.tagClass.isDigital == "N"
              ) {
                digital = false;
              }
            });
            return (
              <Tool data={data} onClick={props.onClick} isDigital={digital} />
            );
          })}
        </Icon_wrap>
      );
    });
  };

  const onenPopUp = () => {
    let new_data = props.data;
    new_data.name = props.name;
    new_data.depth = props.depth;
    sessionStorage.setItem("farm", JSON.stringify(new_data));
    window.open(
      `popUp`,
      "_blank",
      "width=1400, height=800, resizable=no, menubar=no, status=no, toolbar=no"
    );
  };

  useEffect(() => {
    let sensor = [];
    let equips = [];
    let warn = 0;
    let conErr = 0;

    init();
    if (props.length != []) {
      let equip = props.data.equips;
      equip.map((val) => {
        // 센서 그룹 분리
        if (val.equipClass.enableControlYn == "N") {
          let solo = val.sensors;
          // solo = solo.filter(val => {
          //     if (val.tagClass.code != 'HD')
          //         return true
          // })
          sensor = sensor.concat(solo);
        }

        // 장비 그룹 분리
        else if (val.equipClass.enableControlYn == "Y") {
          // val['normalYn'] = 'Y'
          // console.log
          // if(val.normalYn=='N'){
          //         val['normalYn'] = 'N'
          //         conErr += 1
          // }
          val.sensors.map((data) => {
            if (data.normalYn == "N") {
              val["normalYn"] = "N";
            }
          });
          if (val["normalYn"] == "N") {
            conErr += 1;
          }

          equips.push(val);
        }
      });

      sensor.map((data) => {
        data["isExcess"] = false;
        data["isConnectionError"] = false;

        if (
          data?.value > data?.tagRange?.alarmUpperLimit ||
          data?.value < data?.tagRange?.alarmLowerLimit
        ) {
          warn += 1;
          data["isExcess"] = true;
        }
        if (data?.normalYn == "N") {
          conErr += 1;
          data["isConnectionError"] = true;
        }
      });
    }

    set_icon(sensor);
    set_maxIconIdx(Math.ceil(sensor.length / 16) - 1);
    set_tool(equips);
    set_maxToolIdx(Math.ceil(equips.length / 16) - 1);
    set_warning(warn);
    set_connectionError(conErr);
  }, [props.data]);

  useEffect(() => {
    set_fold(!props.depth == 0);
    set_iconIdx(0);
    set_toolIdx(0);
  }, [props.name]);

  const titleFuntion = () => {
    return props.name.split(">").map((val, idx) => {
      if (idx == 0) return val;
      return (
        <>
          <Arrow_img src={Arrow} />
          {val}
        </>
      );
    });
  };

  return (
    <Wrapper backgroundColor={back_color[props.depth]}>
      <Top>
        <Wrap>
          <Title>{titleFuntion()}</Title>
          {/* <Summary type="bell" /> */}

          {warning == 0 || <Summary data={warning} type="warning" />}
          {connectionError == 0 || (
            <Summary data={connectionError} type="question" />
          )}

          {props.heat && <Message type="heat" />}
          {props.humidity && <Message type="humidity" />}
          {props.cool && <Message type="cool" />}
          {props.water && <Message type="water" />}
          {props.comfort && <Message type="comfort" />}
        </Wrap>

        <Wrap>
          <Time>업데이트 {updateTime}</Time>

          {props.depth == 0 || (
            <Img fold={fold} onClick={() => set_fold(!fold)} src={upArrow} />
          )}
        </Wrap>
      </Top>

      {/* {fold || <> */}
      <Overflow>
        <FoldAnimation fold={fold}>
          <Middle>
            <Button onClick={onenPopUp}>
              {props.product == "INSECT" ? "사육" : "재배"}환경 데이터 정보
            </Button>
          </Middle>

          <Contets_wrapper ref={slider}>
            {icon.length == 0 || (
              <>
                <Contets_wrap>
                  {iconIdx > 0 ? (
                    <BoxImg onClick={left_icon_button} src={boxArrow} />
                  ) : (
                    <BoxImg style={{ visibility: "hidden" }} src={boxArrow} />
                  )}

                  <Contents>{spreadIcon()}</Contents>

                  {iconIdx + 1 <= maxIconIdx ? (
                    <BoxImg
                      onClick={rigth_icon_button}
                      fold={true}
                      src={boxArrow}
                    />
                  ) : (
                    <BoxImg style={{ visibility: "hidden" }} src={boxArrow} />
                  )}
                </Contets_wrap>
                <Circle_Wrapper>
                  {maxIconIdx > 0 && (
                    <Circle
                      onClick={() => {
                        set_iconPosition(0);
                        set_iconIdx(iconIdx - iconPosition);
                      }}
                      is_blue={iconPosition == 0}
                      src={circle}
                    />
                  )}
                  {maxIconIdx >= 1 && (
                    <Circle
                      onClick={() => {
                        set_iconPosition(1);
                        set_iconIdx(iconIdx + 1 - iconPosition);
                      }}
                      is_blue={iconPosition == 1}
                      src={circle}
                    />
                  )}
                  {maxIconIdx >= 2 && (
                    <Circle
                      onClick={() => {
                        set_iconPosition(2);
                        set_iconIdx(iconIdx + 2 - iconPosition);
                      }}
                      is_blue={iconPosition == 2}
                      src={circle}
                    />
                  )}
                </Circle_Wrapper>
              </>
            )}

            {tool.length > 0 && (
              <Contets_wrap>
                {toolIdx > 0 ? (
                  <BoxImg onClick={left_tool_button} src={boxArrow} />
                ) : (
                  <BoxImg style={{ visibility: "hidden" }} src={boxArrow} />
                )}
                <Contents style={{ paddingTop: "2rem" }}>
                  {spreadTool()}
                </Contents>
                {toolIdx + 1 <= maxToolIdx ? (
                  <BoxImg
                    onClick={rigth_tool_button}
                    fold={true}
                    src={boxArrow}
                  />
                ) : (
                  <BoxImg style={{ visibility: "hidden" }} src={boxArrow} />
                )}
              </Contets_wrap>
            )}
            <Circle_Wrapper>
              {maxToolIdx > 0 && (
                <Circle
                  onClick={() => {
                    set_toolPosition(0);
                    set_toolIdx(toolIdx - toolPosition);
                  }}
                  is_blue={toolPosition == 0}
                  src={circle}
                />
              )}
              {maxToolIdx >= 1 && (
                <Circle
                  onClick={() => {
                    set_toolPosition(1);
                    set_toolIdx(toolIdx + 1 - toolPosition);
                  }}
                  is_blue={toolPosition == 1}
                  src={circle}
                />
              )}
              {maxToolIdx >= 2 && (
                <Circle
                  onClick={() => {
                    set_toolPosition(2);
                    set_toolIdx(toolIdx + 2 - toolPosition);
                  }}
                  is_blue={toolPosition == 2}
                  src={circle}
                />
              )}
            </Circle_Wrapper>
          </Contets_wrapper>
        </FoldAnimation>
      </Overflow>
      {/* </>} */}
    </Wrapper>
  );
}
const Overflow = styled.div`
  overflow: hidden;
`;

const FoldAnimation = styled.div`
  max-height: 1500px;
  overflow: hidden;

  /* Set our transitions up. */
  transition: max-height 0.5s;

  ${(props) =>
    props.fold &&
    css`
      /* height: 0px; */
      /* transform:translate(0,-40rem); */
      max-height: 0;
    `}
`;

export default Main;
